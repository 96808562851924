<div class="dialog dialog--small dialog-ip-prefixes-request">
  <div class="dialog__header">
    <h3 class="dialog__title">{{ "OverflowMenu.AddPrefix" | translate }}</h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" mat-dialog-close></svg-icon>
  </div>
  <div class="dialog-ip-prefixes-request__body">
    @if (activeStep() === 1) {
      <section>
        <p>{{ "Dialog.IPP.AddDescription" | translate }}</p>
        <br />
        <div class="form-label">{{ "Dialog.IPP.When" | translate }}</div>
        <p>{{ "Dialog.IPP.WhenDescription" | translate }}</p>
        <br />
        <div class="form-label">{{ "Dialog.IPP.IPRegister" | translate }}</div>
        <div class="textarea__wrapper">
          <textarea
            class="textarea"
            placeholder="f.e. 124.50.134.0/24"
            [(ngModel)]="__fulfilmentRequest.ipRegister"
          ></textarea>
        </div>
      </section>
    }

    @if (activeStep() === 2) {
      <section>
        <div class="form-label">{{ "Dialog.IPP.Contact" | translate }}</div>
        <p>{{ "Dialog.IPP.ContactText" | translate }}</p>
        <br />
        <label for="" class="form-label">{{ "Global.Name" | translate }}</label>
        <div class="input__wrapper">
          <input
            type="text"
            class="input"
            placeholder="{{ 'Global.Name' | translate }}"
            [(ngModel)]="__fulfilmentRequest.fullName"
            autofocus
          />
        </div>
        <br />
        <label for="" class="form-label">{{ "Global.Email" | translate }}</label>
        <div class="input__wrapper">
          <input
            type="email"
            class="input"
            placeholder="{{ 'Global.Email' | translate }}"
            [(ngModel)]="__fulfilmentRequest.email"
            autofocus
          />
        </div>
        <br />
        <label for="" class="form-label">{{ "Global.Phone" | translate }}</label>
        <div class="input__wrapper">
          <input
            type="text"
            class="input"
            placeholder="{{ 'Global.Phone' | translate }}"
            [(ngModel)]="__fulfilmentRequest.phone"
            autofocus
          />
        </div>
        @if (errorMessage()) {
          <div class="red">{{ errorMessage() }}</div>
        }
      </section>
    }

    @if (activeStep() === 3) {
      <section>
        <div class="dialog-ip-prefixes-request__success">
          <img src="assets/images/success-illustration.svg" alt="" />
          <h3>{{ "Dialog.IPP.RequestSent" | translate }}</h3>
          <p>{{ "Dialog.IPP.RequestSentText" | translate }}</p>
        </div>
      </section>
    }
  </div>

  <div class="dialog__footer dialog__footer--center dialog__footer--border">
    <div></div>
    <div class="button__flex">
      @if (activeStep() === 1) {
        <button class="button button--secondary" mat-dialog-close>
          {{ "Global.Action.Cancel" | translate }}
        </button>
      }
      @if (activeStep() !== 1 && activeStep() !== 3) {
        <button class="button button--secondary" (click)="prev()">
          {{ "Global.Action.Back" | translate }}
        </button>
      }
      @if (activeStep() !== 2 && activeStep() !== 3) {
        <button class="button" (click)="next()">
          {{ "Global.Action.Continue" | translate }}
        </button>
      }
      @if (activeStep() === 2) {
        <button class="button" [ngClass]="{ 'button--disabled': disableSubmitButton }" (click)="submit()">
          {{ "Global.Action.Confirm" | translate }}
        </button>
      }
      @if (activeStep() === 3) {
        <button class="button button--secondary" mat-dialog-close>
          {{ "Global.Action.Close" | translate }}
        </button>
      }
    </div>
  </div>
</div>
