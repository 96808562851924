import { Component } from "@angular/core";
import { FilterBarComponent } from "../../common/filter-bar/filter-bar";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "filter-by-status",
  templateUrl: "filter-by-status.html",
  imports: [FilterBarComponent, TranslateModule],
})
export class FilterByStatus {}
