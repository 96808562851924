import { Component, computed, inject, input } from "@angular/core";
import { ProductType } from "../../../helpers/enums/productType";
import { Router } from "@angular/router";
import { CombinedServiceTileHealthIndicators, CombinedServiceTileInput } from "./combined-service-tile.helpers";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";

const ALLOWED_PRODUCT_TYPES = [
  ProductType.IP,
  ProductType.L3VPN,
  ProductType.Port,
  ProductType.L2VPN,
  ProductType.IPPrefixes,
  ProductType.LightPath,
];

@Component({
  selector: "combined-service-tile",
  templateUrl: "combined-service-tile.html",
  styleUrls: ["combined-service-tile.scss", "../service-tile/service-tile.scss"],
  imports: [AngularSvgIconModule, CommonModule, MatTooltipModule, TranslateModule],
})
export class CombinedServiceTileComponent {
  private router = inject(Router);
  protected subscriptions = input.required<Map<ProductType, CombinedServiceTileInput>>();

  get healthIndicators(): CombinedServiceTileHealthIndicators {
    // we don't include the IP prefix in the count, as it can't report on traffic status
    const withoutIpPrefix = ALLOWED_PRODUCT_TYPES.filter((type) => type !== ProductType.IPPrefixes)
      .map((type) => this.subscriptions().get(type)?.counts)
      .filter((counts) => counts);

    const okCounts = withoutIpPrefix.reduce((acc, counts) => acc + counts.ok, 0);
    const notrafficCounts = withoutIpPrefix.reduce((acc, counts) => acc + counts.notraffic, 0);
    const malfunctionCounts = withoutIpPrefix.reduce((acc, counts) => acc + counts.malfunction, 0);

    return [
      {
        name: "ok",
        icon: "status-ok",
        tooltip: "Services.Status.Ok",
        count: okCounts,
      },
      {
        name: "notraffic",
        icon: "status-no-traffic",
        tooltip: "Services.Status.NoTraffic",
        count: notrafficCounts,
      },
      {
        name: "malfunction",
        icon: "status-warning",
        tooltip: "Services.Status.Warning",
        count: malfunctionCounts,
      },
    ];
  }

  protected servicesCount = computed(() =>
    ALLOWED_PRODUCT_TYPES.map((type) => this.subscriptions().get(type))
      .filter((productSubscription) => productSubscription && typeof productSubscription.subscriptions === "number")
      .reduce((acc, productSubscription) => acc + productSubscription.subscriptions, 0),
  );

  protected noServices = computed(() => this.servicesCount() === 0);

  serviceClick(subscription): void {
    const url = subscription.value.url;
    if (url) {
      window.open(url, "_blank");
    } else {
      if (subscription.key === ProductType.IPPrefixes) {
        this.router.navigate([`/ip-prefixes`]);
      } else {
        this.router.navigate([`/diensten/${subscription.key}`]);
      }
    }
  }
}
