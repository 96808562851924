import { Component, computed, inject, input, OnChanges } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../services/AuthService";
import { SubscriptionDetailHeaderModel } from "./subscription-detail-header.model";
import { CommonModule } from "@angular/common";
import { NotificationButton } from "../notification-button/notification-button";
import { ReportIncident } from "../report-incident/report-incident";

@Component({
  selector: "subscription-detail-header",
  templateUrl: "subscription-detail-header.html",
  imports: [CommonModule, TranslateModule, NotificationButton, ReportIncident],
})
export class SubscriptionDetailHeader implements OnChanges {
  subscription = input.required<SubscriptionDetailHeaderModel>();
  isTerminated = input.required<boolean>();
  translate = inject(TranslateService);
  auth = inject(AuthService);

  // TODO: generic components shouldn't depend on product types!
  isPort = computed(() => this.subscription().product.type === "Port");
  isWireless = computed(() => this.subscription().product.type === "Wireless");

  isCurrentOrganisation = true;

  ngOnChanges() {
    if (this.subscription) {
      this.isCurrentOrganisation = this.auth?.isCurrentOrganisation(this.subscription().customerId);
    }
  }
}
