<div class="connection">
  <div class="connection__row">
    <div class="connection__header">
      <div class="connection__title">
        <h3>
          {{ "Global.Connection" | translate }} {{ connectionNumber() }}
          @if (endpoints()) {
            <span> {{ endpoints()[0].port.address.city }} - {{ endpoints()[1].port.address.city }} </span>
          }
        </h3>
      </div>
      <!-- Disable Selfservice -->
      <div>
        <div
          *ngIf="
            auth?.hasRole(['Infraverantwoordelijke'])
            && auth?.isCurrentOrganisation(subscription().customerId)
            && isSelfServiceAvailable
          "
          class="overflow-menu"
          (clickOutside)="overflowMenuActive = false"
        >
          <button
            class="overflow-menu__button button button--small button--secondary"
            [ngClass]="{ active: overflowMenuActive }"
            (click)="overflowMenuActive = !overflowMenuActive"
          >
            <svg-icon src="assets/images/icons/cog.svg" class="button__icon"></svg-icon>
            <span> {{ "Global.Modify" | translate }}</span>
          </button>
          <div class="overflow-menu__items">
            <div
              class="overflow-menu__item"
              (click)="openSelfserviceDialog('vlan-lightpath')"
              [matTooltip]="'Selfservice.Tooltip.Vlan' | translate"
              [matTooltipPosition]="'above'"
            >
              {{ "OverflowMenu.ModifyVlan" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="connection__flex">
      @if (endpoints()) {
        <port-tile [sap]="endpoints()[0]" [healthState]="healthData?.[0]?.status"></port-tile>
        <div class="connection__connector-wrapper">
          <div class="connection__connector-meta">
            <div class="connection__connector-heading">{{ "ServiceElement.Chart.NowIn" | translate }}</div>
            <div>{{ healthData?.[0]?.traffic?.last?.in | readable_size }}</div>
          </div>
          <div class="connection__connector">
            <div class="connection__connector-left">
              <span class="connection__connector-left-top"></span>
              <span class="connection__connector-left-bottom"></span>
            </div>
            <div class="connection__connector-center">
              <span class="connection__connector-center-left"></span>
              <span class="connection__connector-center-right"></span>
            </div>
            <div class="connection__connector-right">
              <span class="connection__connector-right-top"></span>
              <span class="connection__connector-right-bottom"></span>
            </div>
          </div>
          <div class="connection__connector-meta">
            <div class="connection__connector-heading">{{ "ServiceElement.Chart.NowOut" | translate }}</div>
            <div>{{ healthData?.[0]?.traffic?.last?.out | readable_size }}</div>
          </div>
        </div>

        <port-tile [sap]="endpoints()[1]" [healthState]="healthData?.[1]?.status"></port-tile>
      }
    </div>
  </div>
</div>
