<div class="dialog">
  <div>
    <div class="dialog__header">
      <h3 class="dialog__title">{{ "SLA.Report.AvailabilityReport" | translate }} - {{ data?.presentableName }}</h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>
    <div class="availability">
      <sla-report [service]="data"></sla-report>
    </div>
  </div>
</div>
