@if (auth?.hasRole(["Infraverantwoordelijke", "surfcert-kernel", "Beveiligingsverantwoordelijke"])) {
  <button class="shortcut-menu__button" (click)="startSelfService()">
    @if (isAuthenticatedForSelfService()) {
      <svg-icon src="assets/images/icons/cog-check.svg" class="shortcut-menu__button-icon--green"></svg-icon>
      <span class="shortcut-menu__started">{{ "ShortcutMenu.SelfServiceStarted" | translate }}</span>
      <div class="shortcut-menu__green-text"><!-- 19:40 -->{{ auth.selfServiceTokenValidFor() | formatduration }}</div>
    } @else {
      <svg-icon src="assets/images/icons/cog.svg"></svg-icon>
      <span>{{ "ShortcutMenu.SelfService" | translate }}</span>
    }
  </button>
} @else {
  <button class="shortcut-menu__button" (click)="showWrongRoleMessage()">
    <svg-icon src="assets/images/icons/cog.svg"></svg-icon>
    <span>{{ "ShortcutMenu.SelfService" | translate }}</span>
  </button>
}
