<div>
  <div class="title-flex">
    <h3 class="title-with-icon">
      <svg-icon src="assets/images/icons/ip.svg" class="blue"></svg-icon>
      <span>{{ "ServiceElement.SURFinternetUplink" | translate }}</span>
    </h3>
  </div>
  @if (subscription() && ipGateway()) {
    <div class="card card--no-padding tabs">
      <div class="tabs__content">
        <div class="tabs__grid">
          <div class="tabs__grid-item">
            <service-summary
              [service]="subscription()"
              [vlanranges]="[]"
              [portId]=""
              [showTraffic]="false"
              [showTrafficButton]="true"
              [showMoreDetailsButton]="false"
            ></service-summary>
          </div>
          <div class="tabs__grid-item">
            <div class="tabs__flex-center">
              <h3>{{ "ServiceElement.IPConfiguration" | translate }}</h3>
            </div>
            <div class="tabs__table">
              <div class="tabs__table-row">
                <div class="tabs__table-col">{{ "ServiceElement.DDoSFilter" | translate }}</div>
                <div class="tabs__table-col">{{ ipGateway().surfcertFilter | enabled_or_value }}</div>
              </div>
              @if (ipGateway().internetpinnen_ip_prefixes?.length > 0) {
                <div class="tabs__table-row">
                  <div class="tabs__table-col">{{ "ServiceElement.InternetpinnenPrefix" | translate }}</div>
                  <div class="tabs__table-col">
                    @for (pin of ipGateway().internetpinnen_ip_prefixes; track pin.ip_prefix.prefix) {
                      <li>{{ pin.ip_prefix.prefix }}</li>
                    }
                  </div>
                </div>
              }
              <div class="tabs__table-row">
                <div class="tabs__table-col">{{ "IP.IPv4MTU" | translate }}</div>
                <div class="tabs__table-col">{{ ipGateway().ipv4Mtu }}</div>
              </div>
              <div class="tabs__table-row">
                <div class="tabs__table-col">{{ "IP.IPv6MTU" | translate }}</div>
                <div class="tabs__table-col">{{ ipGateway().ipv6Mtu }}</div>
              </div>

              <div class="tabs__table-row">
                <div class="tabs__table-col">{{ "IP.IPPrefixes" | translate }}</div>
                <div class="tabs__table-col">
                  <div class="tabs__overflow-box">
                    @for (prefix of ipGateway().ipPrefixes; track prefix) {
                      <div class="tabs__overflow-box-item">{{ prefix }}</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  } @else {
    <div class="card card--no-padding">
      <empty-state [title]="'ServiceElement.SURFinternetUplink' | translate" [icons]="['ip']"></empty-state>
    </div>
  }
</div>
