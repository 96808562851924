import { Component, input } from "@angular/core";
import { MalfunctionItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/malfunction/malfunction-item";
import { SendConfigItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/send-config/send-config-item";
import { ShortcutMenu } from "../../../../../../components/subscription-detail/shortcut-menu/shortcut-menu";

@Component({
  selector: "port-shortcut-menu",
  templateUrl: "port-shortcut-menu.html",
  imports: [MalfunctionItem, SendConfigItem, ShortcutMenu],
})
export class PortShortcutMenuComponent {
  protected subscription = input.required<any>();
  protected isTerminated = input.required<boolean>();
  protected activities = input.required<Notification[]>();
}
