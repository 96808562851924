import { Component, input, output, computed, signal, inject } from "@angular/core";
import { ServiceType } from "../../../gql/generated-ingestor";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ServiceSpeedPipe } from "../../../pages/subscription-detail/components/service-speed-pipe/service-speed-pipe";
import { Router } from "@angular/router";

@Component({
  selector: "port-visualiser",
  templateUrl: "port-visualiser.html",
  styleUrls: ["port-visualiser.scss"],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, MatTooltipModule, ServiceSpeedPipe],
})
export class PortVisualiser {
  router = inject(Router);

  unavailable = input<boolean>();
  subscription = input.required<any>();
  barsWillRoute = input(true);
  highlightedSubscriptionId = input("");

  onSelected = output<string>();

  protected highlightedServiceSubscriptionId = signal(() => this.highlightedSubscriptionId());

  protected amountReserved = computed(() => this.subscription().amountReserved);
  protected bookingPercentage = computed(
    () => (this.subscription().amountReserved / this.subscription().portSpeed) * 100,
  );

  clickBar(service: ServiceType): void {
    if (this.barsWillRoute()) {
      const link = getDetailRouterLink(service, service.product.type);
      this.router.navigate([link]);
    } else {
      this.onSelected.emit(service.subscriptionId);
      this.highlightedServiceSubscriptionId.set(service.subscriptionId);
    }
  }
}
