import { Component, input } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { GuidPipe } from "../../../helpers/guidpipe/guidpipe";
import { ClipboardModule } from "ngx-clipboard";

@Component({
  selector: "service-id",
  templateUrl: "service-id.html",
  imports: [AngularSvgIconModule, GuidPipe, ClipboardModule],
})
export class ServiceId {
  protected subscriptionId = input.required<string>();
}
