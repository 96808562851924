import { Component, inject, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiHelper } from "../../../helpers/apihelper";
import { AuthService } from "../../../services/AuthService";
import { NotificationSetting } from "../../../helpers/types";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FormsModule } from "@angular/forms";

export const getSelfserviceMinWsUrl = (subscriptionId: string, customerId: string) =>
  `fulfilment/subscription/${subscriptionId}/customer/${customerId}/minimal_impact_notification`;

@Component({
  selector: "dialog-notification-settings",
  templateUrl: "dialog-notification-settings.html",
  styleUrls: ["dialog-notification-settings.scss"],
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, FormsModule],
})
export class DialogNotificationSettingsComponent {
  private readonly api = inject(ApiHelper);
  private readonly dialogRef = inject(MatDialogRef);
  protected readonly translate = inject(TranslateService);
  protected readonly auth = inject(AuthService);

  protected name: string;
  protected notificationSetting: NotificationSetting;
  protected subscriptionId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.subscriptionId = data.subscriptionId;
    this.name = data.name;
    this.notificationSetting = data.impactSetting || "Loss of Resiliency";
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveSettings() {
    const customerGuid = localStorage.getItem("viewingCustomerGUID");
    this.api
      .set_minimal_impact_notification(customerGuid, this.subscriptionId, this.notificationSetting)
      .then((result) => {
        this.dialogRef.close({ refresh: true, minimalImpactNotification: this.notificationSetting });
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
