<page [subscription]="subscription">
  @if (isLoading()) {
    <loader />
  } @else if (isNotFound()) {
    <not-found />
  } @else if (isNotAuthorized()) {
    <not-authorized />
  } @else if (pageLoaded()) {
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col--flex">
          <lightpath-shortcut-menu
            [subscription]="subscription"
            [isTerminated]="isTerminated"
            [activities]="activities"
          ></lightpath-shortcut-menu>
        </div>

        <div class="col-lg-9">
          <div class="card">
            <subscription-detail-header [subscription]="subscription" [isTerminated]="isTerminated" />

            <div class="row">
              <div class="col-12 col-sm-7">
                <table class="table-properties table-properties--no-margin">
                  <tr>
                    <td>{{ "ServiceElement.Owner" | translate }}</td>
                    <td>{{ owner() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.OwnAlias" | translate }}</td>
                    <td>
                      <alias-editor [customerDescription]="customerDescription()" [subscriptionId]="subscriptionId()" />
                    </td>
                  </tr>
                  @if (hasReferences()) {
                    <tr third-party-service-ids-row-content [subscription]="subscription"></tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.SURFnetDescription" | translate }}</td>
                    <td>{{ description() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ServiceID" | translate }}</td>
                    <td>
                      <service-id [subscriptionId]="subscriptionId()" />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ProductName" | translate }}</td>
                    <td>EVPN - Point-to-point (aka SURFlichtpad)</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ServiceSpeed" | translate }}</td>
                    <td>{{ subscription.serviceSpeed | service_speed }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-12 col-sm-5">
                <table class="table-properties table-properties--no-margin">
                  @if (speedPolicer() !== null) {
                    <tr>
                      <td>{{ "ServiceElement.SpeedPolicer" | translate }}</td>
                      <td>
                        <span
                          *ngIf="
                            auth?.isSelfServiceEnabled
                              && auth?.hasRole(['Infraverantwoordelijke'])
                              && auth?.isCurrentOrganisation(subscription?.customerId)
                              && !isTerminated;
                            else readOnly
                          "
                          class="table-properties__flex"
                          [ngClass]="{ 'table-properties__flex--blue': speedPolicer() }"
                        >
                          {{ speedPolicer() | enabled_or_value }}
                          <selfservice-toggle
                            [active]="speedPolicer()"
                            (click)="openSelfServiceDialog('sp')"
                            [tooltipText]="'Selfservice.Tooltip.SpeedPolicer' | translate"
                          ></selfservice-toggle>
                        </span>
                        <ng-template #readOnly>
                          {{ speedPolicer() | enabled_or_value }}
                        </ng-template>
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Protection" | translate }}</td>
                    <td>{{ subscription.protection }}</td>
                  </tr>
                  @if (remotePortshutdown() !== null) {
                    <tr>
                      <td>{{ "ServiceElement.RemotePortShutdown" | translate }}</td>
                      <td>
                        <span
                          *ngIf="
                            auth?.hasRole(['Infraverantwoordelijke'])
                              && !isTerminated
                              && auth?.isSelfServiceEnabled
                              && allPortsAreNotAggregated()
                              && allPortsAreUntagged();
                            else readOnly
                          "
                          class="table-properties__flex"
                          [ngClass]="{ 'table-properties__flex--blue': remotePortshutdown() }"
                        >
                          {{ remotePortshutdown() | enabled_or_value }}
                          <selfservice-toggle
                            [active]="remotePortshutdown()"
                            (click)="openSelfServiceDialog('rps')"
                            [tooltipText]="'Selfservice.Tooltip.RemotePortShutdown' | translate"
                          ></selfservice-toggle>
                        </span>
                        <ng-template #readOnly>
                          {{ remotePortshutdown() | enabled_or_value }}
                        </ng-template>
                      </td>
                    </tr>
                  }
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.Status" | translate }}</td>
                      <td>{{ status() }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Start" | translate }}</td>
                    <td>{{ startDate() | subscription_date }}</td>
                  </tr>
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.End" | translate }}</td>
                      <td>{{ endDate() | subscription_date }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Domain" | translate }}</td>
                    <td>{{ subscription.domain }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      @if (isTerminated) {
        <div class="hide-everything-after-this-div"></div>
      }
      <lighpath-config [activeTab]="activeTab()" [productType]="productType()" [subscription]="subscription" />
      <linechart id="chart" #chart [subscription]="subscription" />
    </div>
  }
</page>
