import { Component, computed, inject, input, OnChanges, signal, SimpleChanges } from "@angular/core";
import { SurfNotification } from "../../models/notification";

import { MatDialog } from "@angular/material/dialog";
import { DialogAffectedServicesComponent } from "../../../components/dialogs/dialog-affected-services/dialog-affected-services";

import { ICalendar } from "datebook";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "malfunction-card",
  templateUrl: "malfunction-card.html",
  styleUrls: ["malfunction-card.scss"],
  imports: [AngularSvgIconModule, CommonModule, TranslateModule],
})
export class MalfunctionCardComponent implements OnChanges {
  protected dialog = inject(MatDialog);

  protected showTimeElement = input.required<boolean>();
  protected message = input.required<SurfNotification>();

  protected isFutureMessage = signal(false);
  protected impactsString = signal("");

  protected affectedSubscriptions = computed(() => this.message().affected_subscriptions);
  protected nrOfAffectedServices = computed(() => this.affectedSubscriptions().length);
  protected isMalfunction = computed(() => this.message().category === "Malfunction");
  protected isPlannedWork = computed(() => this.message().category === "PlannedWork");
  protected startTimestamp = computed(() => this.message().start_timestamp);
  protected endTimestamp = computed(() => this.message().end_timestamp);
  protected planTimestamp = computed(() => this.message().plan_timestamp);

  openDialog() {
    const dialogRef = this.dialog.open(DialogAffectedServicesComponent, {
      data: {
        message: this.message,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.forceScrollTop) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["message"]) {
      const today = Math.round(new Date().getTime() / 1000);
      const msgTs = new Date(this.startTimestamp()).getTime();

      if (this.startTimestamp() > today) {
        this.isFutureMessage.set(true);
      }
      if (msgTs > new Date().getTime()) {
        this.isFutureMessage.set(true);
      }

      this.generateImpactsString();
    }
  }

  addToCalendar() {
    const icalendar = new ICalendar({
      title: this.message().heading,
      description: this.message().contents,
      // start: new Date('2020-12-04T19:00:00'),
      // end: new Date('2020-12-04T23:30:00'),
      start: new Date(this.startTimestamp()),
      end: new Date(this.endTimestamp()),
    });
    icalendar.download();
  }

  /**
   * find all unique impact_types in the affected_subscriptions array
   * */
  generateImpactsString() {
    const arr = this.affectedSubscriptions().map((p) => p.impact_type);
    const s = new Set(arr); // remove duplicates
    this.impactsString.set([...s].join(" & ")); // convert object to array -> to a comma string
  }
}
