import { Component, computed, inject } from "@angular/core";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { L3vpnSubscriptionDetailsGQL, L3vpnSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { L3vpnSubscriptionDetails } from "../../types";
import { ApolloQueryResult } from "@apollo/client";
import { TranslateModule } from "@ngx-translate/core";
import { PageComponent } from "../../../../components/page/page";
import { CommonModule } from "@angular/common";
import { AliasEditorComponent } from "../../../../components/common/alias-editor/alias-editor.component";
import { LinechartComponent } from "../../../../components/subscription-detail/linechart/linechart";
import { NetworkgraphComponent } from "../../../../components/subscription-detail/networkgraph/networkgraph";
import { PageTabComponent } from "../../../../components/subscription-detail/page-tab/page-tab";
import { ServiceId } from "../../../../components/subscription-detail/service-id/service-id";
import { SubscriptionDetailHeader } from "../../../../components/subscription-detail/subscription-detail-header/subscription-detail-header";
import { TabbedDetailContainer } from "../../../../components/subscription-detail/tabbed-detail-container/tabbed-detail-container";
import { ThirdPartyServiceIdsRowContentComponent } from "../../../../components/subscription-detail/third-party-service-ids-row-content/third-party-service-ids-row-content";
import { EnabledPipe } from "../../../../helpers/enabledpipe/enabledpipe";
import { SubscriptionDatePipe } from "../../../../helpers/subscription-datepipe/subscription-datepipe";
import { ServiceSpeedPipe } from "../service-speed-pipe/service-speed-pipe";
import { L3vpnShortCutMenuComponent } from "./components/l3vpn-shortcut-menu/l3vpn-shortcut-menu";
import { SubscriptionDetailTab } from "../../../../components/subscription-detail/page-tab/tab-types";
import { NotFoundComponent } from "../../../../components/common/not-found/not-found";
import { LoaderComponent } from "../../../../components/common/loader/loader";
import { NotAuthorizedComponent } from "../../../../components/common/not-authorized/not-authorized";

@Component({
  selector: "l3vpn-subscription",
  templateUrl: "l3vpn-subscription.html",
  imports: [
    PageComponent,
    L3vpnShortCutMenuComponent,
    SubscriptionDatePipe,
    SubscriptionDetailHeader,
    TranslateModule,
    AliasEditorComponent,
    ServiceId,
    CommonModule,
    EnabledPipe,
    PageTabComponent,
    ServiceSpeedPipe,
    TabbedDetailContainer,
    NetworkgraphComponent,
    LinechartComponent,
    ThirdPartyServiceIdsRowContentComponent,
    NotFoundComponent,
    LoaderComponent,
    NotAuthorizedComponent,
  ],
})
export class L3vpnSubscriptionComponent extends GenericSubscriptionComponent<
  L3vpnSubscriptionDetailsGQL,
  L3vpnSubscriptionDetailsQuery,
  L3vpnSubscriptionDetails
> {
  protected query = inject(L3vpnSubscriptionDetailsGQL);

  protected speedPolicer = computed(() => this._subscription().circuits[0].speedPolicer);
  protected l3vpnAsn = computed(() => this._subscription().l3vpnss?.l3vpnAsn);
  protected topology = computed(() => this._subscription().topology);
  protected domain = computed(() => this._subscription().domain);
  protected protection = computed(() => this._subscription().protection);
  protected serviceSpeed = computed(() => this._subscription().serviceSpeed);

  protected isGraphicViewTabActive = computed(() => this.activeTab() === SubscriptionDetailTab.GRAPHIC_VIEW);
  protected isConfigTabActive = computed(() => this.activeTab() === SubscriptionDetailTab.CONFIG);

  protected onQuerySuccess(result: ApolloQueryResult<L3vpnSubscriptionDetailsQuery>) {
    return result.data.l3vpnDetails;
  }
}
