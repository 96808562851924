@if (hasNotifications()) {
  <div [routerLink]="[getRouterLink()]">
    <div class="service__status-wrapper service--{{ notificationColor() }}">
      <span class="service-status__icon">
        <svg-icon src="assets/images/icons/{{ notificationIcon() }}.svg"></svg-icon>
      </span>
      <div class="service-status__caption">
        @if (!isNowActive()) {
          <span>
            {{ startTimestamp() | date: "d MMM" }}, {{ startTimestamp() | date: "HH:mm" }}
            {{ "Global.Until" | translate }}
            {{ endTimestamp() | date: "HH:mm" }}
          </span>
        }
        <span class="service-status__title">{{ heading() }}</span>
        <span> - </span>
        <span class="service-status__impacttype">{{ impactType() | translate }}</span>
      </div>
    </div>
  </div>
}
