import { Input, Component, OnChanges, SimpleChanges, EventEmitter, inject, input, computed } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { MatDialog } from "@angular/material/dialog";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { LightPathSubscriptionDetails } from "../../../pages/subscription-detail/types";
import { HealthIndicator } from "../../models/HealthIndicator";
import { openSelfServiceDialog } from "../../dialogs/dialog-selfservice/dialog-selfservice.helpers";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PortTile } from "../port-tile/port-tile";
import { ReadableSizePipe } from "../../../helpers/bitspipe/bitspipe";

@Component({
  selector: "connection",
  templateUrl: "connection.html",
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, MatTooltipModule, PortTile, ReadableSizePipe],
})
export class ConnectionComponent implements OnChanges {
  public auth = inject(AuthService);
  public dialog = inject(MatDialog);
  private subscriptionService = inject(SubscriptionService);

  circuit = input.required<LightPathSubscriptionDetails["circuits"][0]>();
  subscription = input.required<LightPathSubscriptionDetails>();

  @Input() bus: EventEmitter<string>;
  connectionNumber = input(1);
  productType = input.required<string>();

  @Input() healthData: HealthIndicator[] = [];

  public overflowMenuActive = false;

  protected endpoints = computed(() => this.circuit().endpoints);

  // setting this here to keep the template readable.
  get isSelfServiceAvailable(): boolean {
    return !this.somePortsAreUntagged;
  }

  get somePortsAreUntagged() {
    return this.endpoints().some((endpoint) => endpoint.port.portMode === "Untagged");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.circuit && this.endpoints()) {
      const promises = this.endpoints().map((endpoint) =>
        this.subscriptionService.getHealthDataForSubscription(endpoint.port.subscriptionId),
      );
      Promise.all(promises).then((result) => {
        this.healthData = result;
      });
    }
  }

  openSelfserviceDialog(type) {
    const roleCheckResult = this.auth.checkRoleAccess(this.productType(), "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.selfServiceStarted()) {
      this.dialog.open(DialogAuthComponent, {
        data: {
          selfserviceState: { connectionNumber: this.connectionNumber },
          initialAction: type,
        },
      });
    } else {
      const data = {
        type,
        circuit: this.circuit(),
        subscription: this.subscription(),
        titleSuffix: this.connectionNumber(),
      };

      const dialogRef = openSelfServiceDialog(this.dialog, data);
      dialogRef.componentInstance.close.subscribe((event: string) => {
        this.bus.emit(event);
      });
    }
  }
}
