import { Component, inject, input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogIncidentComponent } from "../../dialogs/dialog-incident/dialog-incident";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "report-incident",
  templateUrl: "report-incident.html",
  imports: [TranslateModule],
})
export class ReportIncident {
  subscription = input();
  dialog = inject(MatDialog);

  openIncidentDialog(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.dialog.open(DialogIncidentComponent, {
      data: {
        subscription: this.subscription(),
      },
    });
    return false;
  }
}
