<div class="title-flex" style="margin: 0">
  <h3 class="title-with-icon"><svg-icon src="assets/images/icons/chart.svg"></svg-icon><span>System stats</span></h3>
</div>
<div class="card card--no-padding">
  <period-picker [firstDateInitial]="firstDate" [secondDateInitial]="secondDate" (newDates)="newDates($event)" />
  <div class="system-stats">
    <div class="system-stats__col">
      <div class="system-stats__box">
        <div class="system-stats__box-title">CPU Usage</div>
        @if (isBrowser()) {
          @if (retrievingChartData) {
            <loader [iconOnly]="true"></loader>
          } @else {
            <highcharts-chart
              [Highcharts]="highcharts"
              [options]="chartOptionsCPUUsage"
              (chartInstance)="setCpuUsageChartInstance($event)"
            >
            </highcharts-chart>
          }
        }
      </div>
    </div>
    <div class="system-stats__col">
      <div class="system-stats__box">
        <div class="system-stats__box-title">Memory Usage</div>
        @if (isBrowser()) {
          @if (retrievingChartData) {
            <loader [iconOnly]="true"></loader>
          } @else {
            <highcharts-chart
              [Highcharts]="highcharts"
              [options]="chartOptionsMemoryUsage"
              (chartInstance)="setMemoryUsageChartInstance($event)"
            >
            </highcharts-chart>
          }
        }
      </div>
    </div>
    <div class="system-stats__col">
      <div class="system-stats__box">
        <div class="system-stats__box-title">Sessions</div>
        @if (isBrowser()) {
          @if (retrievingChartData) {
            <loader [iconOnly]="true"></loader>
          } @else {
            <highcharts-chart
              [Highcharts]="highcharts"
              [options]="chartOptionsSessions"
              (chartInstance)="setSessionsChartInstance($event)"
            >
            </highcharts-chart>
          }
        }
      </div>
    </div>
    <div class="system-stats__col">
      <div class="system-stats__box">
        <div class="system-stats__box-title">Session Rate</div>
        @if (isBrowser()) {
          @if (retrievingChartData) {
            <loader [iconOnly]="true"></loader>
          } @else {
            <highcharts-chart
              [Highcharts]="highcharts"
              [options]="chartOptionsSessionRate"
              (chartInstance)="setSessionRateChartInstance($event)"
            >
            </highcharts-chart>
          }
        }
      </div>
    </div>
  </div>
</div>
