import { Component, input } from "@angular/core";
import { MalfunctionItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/malfunction/malfunction-item";
import { SendConfigItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/send-config/send-config-item";
import { ShortcutMenu } from "../../../../../../components/subscription-detail/shortcut-menu/shortcut-menu";
import { FortiManagerItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/forti-manager/forti-manager-item";
import { FortiAnalyzerItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/forti-analyzer/forti-analyzer-item";

@Component({
  selector: "firewall-shortcut-menu",
  templateUrl: "firewall-shortcut-menu.html",
  imports: [FortiAnalyzerItem, FortiManagerItem, MalfunctionItem, SendConfigItem, ShortcutMenu],
})
export class FirewallShortcutMenuComponent {
  subscription = input.required<any>();
  isTerminated = input.required<boolean>();
  activities = input.required<Notification[]>();
}
