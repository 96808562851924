import { Product } from "./product";
import { CircuitEndpoint } from "./endpoint";
import { StorageHelper } from "../../helpers/storage/storagehelper";
import { HealthIndicator } from "./HealthIndicator";
import { Instance } from "./instance";
import { getTags, transformCircuits } from "../../helpers/subscription/lightpath";
import { transformProduct, transformReferences } from "../../helpers/subscription/shared";
import { translate } from "../../helpers/subscription/tags";
import {
  Sn8LightPathRedundantSubscription as LightPathRedundantSubscription,
  Sn8LightPathSubscription as LightPathSubscription,
} from "../../gql/generated";

type LightPathSubscriptionWithType = LightPathRedundantSubscription
  & LightPathSubscription & {
    subscriptionType: string;
  };

export class LightPathInstance extends Instance {
  private viewingCustomerId: string;
  subscriptionId: string;
  customerDescriptions: any[];
  _circuits: any[];
  customerName = "";
  customerId: string;
  description: string;
  name: string;
  _ports: any[];
  product: Product;
  protection: string;
  servicespeed: string;
  tags: string[];
  _pairs: any[];
  _otherPortsCount: 0;
  _chartData: any;
  aggregated_stats: any;
  healthIndicator: HealthIndicator;
  serviceId: string;

  constructor(data?: LightPathSubscriptionWithType) {
    super();
    this.viewingCustomerId = StorageHelper.currentUser;

    if (data) {
      this.subscriptionId = data?.subscriptionId;
      this.customerId = data?.customerId;
      this.customerDescriptions = data?.customerDescriptions;
      this.protection = data?.protectionType;
      this.serviceSpeed =
        data?.subscriptionType === "Sn8LightPathRedundantSubscription" ?
          data?.lrss.vcs[0].serviceSpeed
        : data?.vc.serviceSpeed;
      this.product = transformProduct(data?.product);
      this.domain = data?.domain;
      this.organisation = data?.customer?.name;
      this.name = data?.name;
      this.description = data?.description;
      this.status = data?.status;
      this.startDate = data?.startDate;
      this.endDate = data?.endDate;
      this.insync = data?.insync;
      this.md_references = transformReferences(data?.metadata?.references);
      this.gqlCircuits = data;
      this.tags = translate(
        this.product.productType,
        getTags(this.product.tag, this.serviceSpeed, this.protection, this.domain),
        null,
      );
    }
  }

  set gqlCircuits(data: any) {
    const vc = data?.subscriptionType === "Sn8LightPathRedundantSubscription" ? data?.lrss.vcs : [data.vc];
    this.circuits = transformCircuits(data.domain, vc);
    // once the platform is updated to es2019, we can use flatMap
    // until then, this will have to do.
    this.saps = this.circuits.map((circuit: any) => circuit.endpoints).reduce((acc, val) => acc.concat(val), []);
  }

  get circuits() {
    return this._circuits;
  }

  set circuits(c: any[]) {
    this._circuits = c;
  }

  set saps(ports: any[]) {
    this._ports = ports;
  }

  get saps(): any[] {
    return this._ports;
  }

  get sapPairs(): any[] {
    return this._pairs;
  }

  get endpointPairs(): any[] {
    return this._pairs;
  }

  set chartData(d: any) {
    this._chartData = d;
  }

  get chartData(): any {
    return this._chartData;
  }

  postProcessData() {
    this._pairs = [];
    let mySubscriptionInstanceId = "";

    this._circuits.forEach(
      function (circuit: any) {
        mySubscriptionInstanceId = "";
        const pair = [];
        if (circuit.hasOwnProperty("endpoints")) {
          circuit.endpoints.forEach(
            function (endpoint: any) {
              const instance = Object.assign(new CircuitEndpoint(), endpoint);
              if (endpoint.port === undefined || endpoint.port.customerId === this.viewingCustomerId) {
                pair.unshift(instance);
                mySubscriptionInstanceId = endpoint.subscriptionInstanceId;
              } else {
                pair.push(instance);
              }
            }.bind(this),
          );

          circuit.mySubscriptionInstanceId = mySubscriptionInstanceId;
          this._pairs.push(pair);
        }
      }.bind(this),
    );
  }
}
