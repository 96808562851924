import { Component, computed, input } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { UserStatsComponent } from "../../../../../../components/subscription-detail/user-stats/user-stats";
import { AccessPointsComponent } from "../access-points/access-points";
import { TranslateModule } from "@ngx-translate/core";
import { WirelessHealthDetail } from "../../../../../../gql/generated-ingestor";
import { WirelessSubscriptionDetails } from "../../../../types";

@Component({
  selector: "access-points-section",
  templateUrl: "access-points-section.html",
  imports: [AccessPointsComponent, AngularSvgIconModule, TranslateModule, UserStatsComponent],
})
export class AccessPointsSectionComponent {
  protected subscription = input.required<WirelessSubscriptionDetails>();
  protected healthData = input.required<WirelessHealthDetail>();

  protected accessPoints = computed(() => this.subscription().location.accessPoints ?? null);
}
