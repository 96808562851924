<div
  *ngIf="currentStep === 1"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ "Selfservice.RemovePort.Subheading" | translate }}</h3>
    <p class="no-margin">{{ "Selfservice.RemovePort.Text" | translate }}</p>
  </div>
  <div>
    <div *ngFor="let item of mappedPorts; index as i">
      <div *ngIf="item.endpoints && item.endpoints.length === 1" class="selfservice-add-port__bar">
        <div class="selfservice-add-port__left">
          <div class="selfservice-add-port__details" [ngClass]="{ active: item.selected }">
            <div class="selfservice-add-port__icon-box">
              <svg-icon src="assets/images/icons/port.svg"></svg-icon>
            </div>
            <div>
              <h3>{{ item.endpoints[0].port.customerDescription }}</h3>
              <div class="selfservice-add-port__meta">
                {{ item.endpoints[0].port.subscriptionId | shorten_guid }} -
                {{ item.endpoints[0].port.customer.fullname }}, {{ item.endpoints[0].port.address.city }} ({{
                  item.endpoints[0].port.address.street
                }}), VLAN {{ item.endpoints[0].vlanrange }}
              </div>
            </div>
          </div>
        </div>
        <div class="selfservice-add-port__right">
          <div *ngIf="isMyPort(item.endpoints[0].port)" class="selfservice-add-port__toggle">
            <input [id]="'toggle-' + i" type="checkbox" [(ngModel)]="item.selected" />
            <label [for]="'toggle-' + i">
              <selfservice-toggle [active]="item.selected"></selfservice-toggle>
            </label>
          </div>
          <div *ngIf="!isMyPort(item.endpoints[0].port)">
            <div class="selfservice-add-port__no-access">
              <svg-icon src="assets/images/icons/lock.svg"></svg-icon>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="item.endpoints && item.endpoints.length > 1"
        class="selfservice-add-port__bar selfservice-add-port__bar--col"
      >
        <div class="selfservice-add-port__bar-flex">
          <div class="selfservice-add-port__details" [ngClass]="{ active: item.selected }">
            <div class="selfservice-add-port__icon-box">
              <svg-icon src="assets/images/icons/port.svg"></svg-icon>
            </div>
            <div>
              <h3>Multihomed interface</h3>
              <div class="selfservice-add-port__meta">
                {{ item.endpoints[0].port.customer.fullname }}, VLAN {{ item.endpoints[0].vlanrange }}
              </div>
            </div>
          </div>
          <div>
            <div class="selfservice-add-port__toggle">
              <input [id]="'toggle-' + i" type="checkbox" [(ngModel)]="item.selected" />
              <label [for]="'toggle-' + i">
                <selfservice-toggle [active]="item.selected"></selfservice-toggle>
              </label>
            </div>
          </div>
        </div>
        <ul class="selfservice-add-port__list">
          <li
            *ngFor="let subitem of item.endpoints"
            class="selfservice-add-port__list-item"
            [ngClass]="{ 'selfservice-add-port__list-item--purple': item.selected }"
          >
            <svg-icon src="assets/images/icons/port.svg"></svg-icon>
            <div>
              <div class="selfservice-add-port__list-title">{{ subitem.port | custom_description }}</div>
              {{ subitem.port.subscriptionId | shorten_guid }} - {{ subitem.port.customer.fullname }},
              {{ subitem.port.address.city }} ({{ subitem.port.address.street }}), VLAN {{ subitem.vlanrange }},
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="currentStep === 2"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ "Global.Summary" | translate }}</h3>
    <p class="no-margin t-newline">{{ "Selfservice.RemovePort.SummaryText" | translate }}</p>
  </div>

  <div class="selfservice-add-port__summary">
    <div class="selfservice-add-port__summary-item">
      <div class="selfservice-add-port__summary-top" (click)="toggleSummaryItemOne()">
        <div class="selfservice-add-port__summary-top-left">{{ "Selfservice.AddPort.RemovedPorts" | translate }}</div>
        <div class="selfservice-add-port__summary-top-right">
          <div>{{ "Selfservice.IPP.Total" | translate }}</div>
          <div>{{ removedPorts.length }}</div>
          <svg-icon src="assets/images/icons/chevron-down.svg" [ngClass]="{ active: summaryItemOneExpanded }">
          </svg-icon>
        </div>
      </div>
      <div *ngIf="summaryItemOneExpanded" class="selfservice-add-port__summary-expand">
        <ul class="selfservice-add-port__list">
          <li
            *ngFor="let item of removedPorts; index as i"
            class="selfservice-add-port__list-item selfservice-add-port__list-item--red"
          >
            <svg-icon src="assets/images/icons/x.svg"></svg-icon>
            <div *ngIf="item.endpoints">
              <div *ngIf="item.endpoints.length === 1" class="selfservice-add-port__list-title">
                {{ item.endpoints[0].port.customerDescription }}
              </div>
              <div *ngIf="item.endpoints.length > 1" class="selfservice-add-port__list-title">Multihomed interface</div>
              <div>
                {{ item.endpoints[0].port.subscriptionId | shorten_guid }} -
                {{ item.endpoints[0].port.customer.fullname }}, {{ item.endpoints[0].port.address.city }} ({{
                  item.endpoints[0].port.address.street
                }}), VLAN {{ item.endpoints[0].vlanrange }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="selfservice-add-port__summary">
    <div class="selfservice-add-port__summary-item">
      <div class="selfservice-add-port__summary-top" (click)="toggleSummaryItemTwo()">
        <div class="selfservice-add-port__summary-top-left">
          {{ "Selfservice.AddPort.AlreadyLinkedPorts" | translate }}
        </div>
        <div class="selfservice-add-port__summary-top-right">
          <div>{{ "Selfservice.IPP.Total" | translate }}</div>
          <div>{{ existingPorts.length }}</div>
          <svg-icon src="assets/images/icons/chevron-down.svg" [ngClass]="{ active: summaryItemTwoExpanded }">
          </svg-icon>
        </div>
      </div>

      <div *ngIf="summaryItemTwoExpanded" class="selfservice-add-port__summary-expand">
        <ul class="selfservice-add-port__list">
          <li
            *ngFor="let item of existingPorts"
            class="selfservice-add-port__list-item selfservice-add-port__list-item--purple"
          >
            <div *ngIf="item.endpoints && item.endpoints.length === 1" class="selfservice-add-port__list-item-inner">
              <svg-icon src="assets/images/icons/port.svg"></svg-icon>
              <div>
                <div class="selfservice-add-port__list-title">{{ item.endpoints[0].port.customerDescription }}</div>
                <div>
                  {{ item.endpoints[0].port.subscriptionId | shorten_guid }} -
                  {{ item.endpoints[0].port.customer.fullname }}, {{ item.endpoints[0].port.address.city }} ({{
                    item.endpoints[0].port.address.street
                  }}), VLAN {{ item.endpoints[0].vlanrange }}
                </div>
              </div>
            </div>

            <div *ngIf="item.endpoints && item.endpoints.length > 1" class="selfservice-add-port__list-item-multihome">
              <div class="selfservice-add-port__list-item-multihome-title">Multihomed interface</div>
              <div *ngFor="let subitem of item.endpoints" class="selfservice-add-port__list-item-inner">
                <svg-icon src="assets/images/icons/port.svg"></svg-icon>
                <div>
                  <div class="selfservice-add-port__list-title">{{ subitem.port.description }}</div>
                  <div>
                    {{ subitem.port.subscriptionId | shorten_guid }} - {{ subitem.port.customer.fullname }},
                    {{ subitem.port.address.city }} ({{ subitem.port.address.street }}), VLAN {{ subitem.vlanrange }}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="hasErrors" class="dialog__error dialog__error--margin-x">
    <ul>
      <li *ngFor="let e of errors">{{ e.msg }}</li>
    </ul>
  </div>
</div>
<div *ngIf="currentStep === 2" class="dialog__bottom-message">
  <div class="dialog__bottom-message-inner">
    <svg-icon src="assets/images/icons/status-failure.svg" class="dialog__bottom-message-icon"></svg-icon>
    <span>{{ "Selfservice.RemovePort.Message" | translate }} </span>
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  <div *ngIf="currentStep === 1" class="button__flex">
    <button class="button button--secondary" mat-dialog-close>{{ "Global.Action.Cancel" | translate }}</button>
    <button (click)="next()" class="button" [ngClass]="{ 'button--disabled': disableSubmitButton }">
      {{ "Global.Action.Continue" | translate }}
    </button>
  </div>
  <div *ngIf="currentStep === 2" class="button__flex">
    <button class="button button--secondary" (click)="prev()">{{ "Global.Action.Back" | translate }}</button>
    <button class="button" (click)="submit()" [ngClass]="{ loading: buttonLoading }">
      <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
      <span>{{ "Global.Action.Confirm" | translate }}</span>
    </button>
  </div>
</div>
