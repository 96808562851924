/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiConfiguration, ApiConfigurationParams } from "./api-configuration";

import { OverviewService } from "./services/overview.service";
import { NameserversService } from "./services/nameservers.service";
import { ZonesService } from "./services/zones.service";
import { ReverseService } from "./services/reverse.service";
import { TemplatesService } from "./services/templates.service";
import { ContactsService } from "./services/contacts.service";
import { DomainsService } from "./services/domains.service";
import { DnssecService } from "./services/dnssec.service";
import { HistoryService } from "./services/history.service";
import { ValidateService } from "./services/validate.service";
import { InfoService } from "./services/info.service";

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    OverviewService,
    NameserversService,
    ZonesService,
    ReverseService,
    TemplatesService,
    ContactsService,
    DomainsService,
    DnssecService,
    HistoryService,
    ValidateService,
    InfoService,
    ApiConfiguration,
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error("ApiModule is already loaded. Import in your base AppModule only.");
    }
    if (!http) {
      throw new Error(
        "You need to import the HttpClientModule in your AppModule! \n"
          + "See also https://github.com/angular/angular/issues/20575",
      );
    }
  }
}
