import { Component, EventEmitter, Input, OnChanges, SimpleChanges } from "@angular/core";
import { WebsocketListener } from "../../../helpers/websocketListener";
import { SelfserviceMessage } from "../../dialogs/dialog-selfservice/dialog-selfservice";
import { WebsocketService } from "../../../helpers/websocket.service";
import { CommonModule } from "@angular/common";
import { SelfserviceAuthenticationBoxComponent } from "../selfservice-authentication-box/selfservice-authentication-box";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TranslateModule } from "@ngx-translate/core";

export interface SubscriptionUpdateMessage<S> {
  kind: "subscription-update";
  subscription: S;
}
export interface ProcessProgressMessage {
  kind: "process-progress";
  processName: string;
  percentage: number;
  status: string;
}
export type LoaderMessage<S> = ProcessProgressMessage | SubscriptionUpdateMessage<S>;

const getSelfserviceWsUrl = (processId: string, subscriptionId: string) =>
  `fulfilment/process/${processId}/subscription/${subscriptionId}`;

@Component({
  selector: "selfservice-loader",
  templateUrl: "selfservice-loader.html",
  styleUrls: ["selfservice-loader.scss"],
  imports: [AngularSvgIconModule, CommonModule, SelfserviceAuthenticationBoxComponent, TranslateModule],
})
export class SelfserviceLoaderComponent<S> implements OnChanges {
  @Input() processId = "";
  @Input() subscriptionId = "";
  @Input() bus: EventEmitter<SelfserviceMessage<S>>;
  public progress = 0;
  public errorState = false;
  public loading = false;
  public buttonLoading = false;
  private timerId;
  private websocketListener: WebsocketListener<LoaderMessage<S>>;

  constructor(private wsService: WebsocketService) {
    this.websocketListener = new WebsocketListener(this.wsService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["processId"]) {
      const websocketUrl = getSelfserviceWsUrl(this.processId, this.subscriptionId);
      const handleMessage = (msg: LoaderMessage<S>) => {
        switch (msg.kind) {
          case "process-progress":
            this.progress = msg.percentage;
            if (msg?.status == "failed") {
              this.errorState = true;
              clearInterval(this.timerId);
              this.websocketListener.close();
            }
            break;
          case "subscription-update":
            clearInterval(this.timerId);
            this.websocketListener.close();
            this.bus.emit(msg);
            break;
        }
      };

      const handleError = (err) => {
        this.errorState = true;
      };

      this.websocketListener.listen(websocketUrl, { next: handleMessage, error: handleError });

      this.timerId = setTimeout(() => (this.errorState = true), 600000);
    }
  }

  handleError(err) {
    this.errorState = true;
  }

  close() {
    this.bus.emit({ kind: "status", status: "error" });
    this.websocketListener.close();
  }

  notifySupport() {
    this.bus.emit({ kind: "status", status: "notify_support" });
  }
}
