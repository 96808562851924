import { Component, computed, input } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { LoaderComponent } from "../../../../../../components/common/loader/loader";
import { KpiTableComponent } from "../../../../../../components/subscription-detail/kpi-table/kpi-table";
import { WirelessHealthDetail } from "../../../../../../gql/generated-ingestor";
import { WirelessSubscriptionDetails } from "../../../../types";

@Component({
  selector: "kpi-section",
  templateUrl: "kpi-section.html",
  imports: [AngularSvgIconModule, KpiTableComponent, LoaderComponent],
})
export class KpiSectionComponent {
  protected subscription = input.required<WirelessSubscriptionDetails>();
  protected healthData = input.required<WirelessHealthDetail>();

  protected sensorPresent = computed(() => this.subscription().sensorPresent);
  protected vendor = computed(() => this.subscription().supplier?.toLowerCase());
}
