import { Component, computed, inject, input } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SafePipe } from "../../../helpers/safepipe/safepipe";

@Component({
  selector: "empty-state",
  templateUrl: "empty-state.html",
  imports: [TranslateModule, AngularSvgIconModule, SafePipe],
})
export class EmptyStateComponent {
  private translate = inject(TranslateService);

  title = input.required<string>();
  icons = input.required<string[]>();

  get translatedTitle() {
    return this.translate.instant("ServiceElement.EmptyState.Title", {
      product: this.title() || "data",
    });
  }
}
