import { Component, inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AffectedSubscription, SubscriptionMessage } from "../../../helpers/types";
import { Router } from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "dialog-activity",
  templateUrl: "dialog-activity.html",
  imports: [AngularSvgIconModule, CommonModule, TranslateModule],
})
export class DialogActivityComponent {
  protected data: SubscriptionMessage = inject(MAT_DIALOG_DATA);
  protected dialogRef = inject(MatDialogRef<DialogActivityComponent>);
  protected router = inject(Router);

  constructor() {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  segueToService(affectedService: AffectedSubscription) {
    this.router.navigate([`/subscription/${affectedService.subscription_id}`]);
    this.closeDialog();
  }
}
