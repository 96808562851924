import { ApiHelper } from "../helpers/apihelper";
import { AuthService } from "../services/AuthService";
import { inject, Injectable } from "@angular/core";
import { Instance } from "../components/models/instance";
import { HealthIndicator } from "../components/models/HealthIndicator";
import { SubscriptionCount } from "../helpers/types";

@Injectable()
export class SubscriptionService {
  private api = inject(ApiHelper);
  private auth = inject(AuthService);

  private customerGuid: string;

  constructor() {
    this.customerGuid = localStorage.getItem("viewingCustomerGUID");
  }

  canViewSubscription(subscription: Instance) {
    // check if customerID is in my list of accessible customers.
    const sid = subscription.customerId || subscription?.customer?.customerId || subscription.port?.customerId || null;
    return this.customerGuid === sid;
  }

  // TODO: this method needs to die!
  async getSubscriptionsOfType(productType: string): Promise<any> {
    return this.api.subscriptionsOfType(this.getCustomerGuid(), productType);
  }

  async getSubscriptionCounts(productType: string, options: any = {}): Promise<SubscriptionCount> {
    const subscriptionCount = await this.api.subscriptionCounts(this.getCustomerGuid(), productType, options);
    return {
      productType,
      ...subscriptionCount,
    };
  }

  async getHealthDataForSubscription(
    subscriptionId: string,
    instanceId: string = "",
    productType: string = "",
  ): Promise<HealthIndicator> {
    return this.api.health(subscriptionId, instanceId, productType);
  }

  async getWirelessHealthDataForSubscription(subscriptionId: string) {
    return this.api.wireless_health_details(subscriptionId);
  }

  async getWirelessListKpiForSubscription(subscriptionId: string): Promise<any> {
    return this.api.wireless_list_kpi(subscriptionId, false);
  }

  async getSlsReport(subscriptionId: string) {
    return this.api.sls_report(subscriptionId);
  }

  getCustomerGuid(): string {
    // if customerGuid is loaded after constructor call, reload viewingCustomerGUID from localStorage
    if (!this.customerGuid) {
      this.customerGuid = localStorage.getItem("viewingCustomerGUID");
    }
    return this.customerGuid;
  }
}
