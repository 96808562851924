<div
  *ngIf="currentStep === 1"
  class="selfservice-ipp dialog__body dialog__body--grow dialog__body--scroll dialog__body--grey dialog__body--no-padding"
>
  <div class="dialog__port-rule" *ngIf="subscriptionCopy?._saps">
    <div class="dialog__port-number">{{ sapIndex + 1 }}</div>
    <div style="flex-grow: 1">
      <div class="dialog__port-text">{{ subscriptionCopy._saps[sapIndex]?.port | custom_description }}</div>
      <div class="dialog__port-subtext">
        {{ subscriptionCopy._saps[sapIndex]?.port.subscriptionId | shorten_guid }} -
        {{ subscriptionCopy._saps[sapIndex]?.port.customer.fullname }},
        {{ subscriptionCopy._saps[sapIndex]?.port.locations[0].address.city }} ({{
          subscriptionCopy._saps[sapIndex]?.port.locations[0].address.street
        }})
      </div>
    </div>
    <div *ngIf="subscriptionCopy?._saps.length > 1" class="selfservice-ipp__port-controls">
      <div class="selfservice-ipp__port-control" (click)="prevPort()">
        <svg-icon src="assets/images/icons/arrow-fill-down.svg"></svg-icon>
      </div>
      <div class="selfservice-ipp__port-control" (click)="nextPort()">
        <svg-icon src="assets/images/icons/arrow-fill-down.svg"></svg-icon>
      </div>
    </div>
  </div>

  <!-- <div class="selfservice-ipp__header">
    <div class="selfservice-ipp__header-left">
      <h4 class="selfservice-ipp__title">
        {{ 'Selfservice.IPP.Available' | translate }}
      </h4>
      <div class="selfservice-ipp__search">
        <input
          type="text"
          [(ngModel)]="term"
          class="searchTerm"
          placeholder="Zoek een IP prefix"
        />
        <svg-icon src="assets/images/icons/search.svg"></svg-icon>
      </div>
    </div>
    <div class="selfservice-ipp__header-right">
      <h4 class="selfservice-ipp__title">
        {{ 'Selfservice.IPP.Port' | translate }}
      </h4>
      <div class="selfservice-ipp__port">
        <div class="selfservice-ipp__port-left">
          <div class="selfservice-ipp__port-number dialog__port-number">
            {{ sapIndex + 1 }}
          </div>
          <div class="selfservice-ipp__port-text dialog__port-text">
            {{ subscription._saps[sapIndex].port.description }}
          </div>
        </div>
        <div class="selfservice-ipp__port-right">
          <div class="selfservice-ipp__port-controls">
            <div class="selfservice-ipp__port-control" (click)="prevPort()">
              <svg-icon
                src="assets/images/icons/arrow-fill-down.svg"
              ></svg-icon>
            </div>
            <div class="selfservice-ipp__port-control" (click)="nextPort()">
              <svg-icon
                src="assets/images/icons/arrow-fill-down.svg"
              ></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="selfservice-ipp__content">
    <div class="selfservice-ipp__content-left">
      <h4 class="selfservice-ipp__title">{{ "Selfservice.IPP.Available" | translate }}</h4>
      <div class="selfservice-ipp__search">
        <input type="text" [(ngModel)]="term" class="searchTerm" placeholder="Zoek een IP prefix" />
        <svg-icon src="assets/images/icons/search.svg"></svg-icon>
      </div>
      <div
        cdkDropList
        #availableList="cdkDropList"
        [cdkDropListData]="availableItems"
        [cdkDropListConnectedTo]="[selectedList]"
        class="selfservice-ipp__list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="selfservice-ipp__list-item"
          *ngFor="let item of availableItems | filter: term"
          cdkDrag
          [cdkDragData]="item"
          [cdkDragDisabled]="item.subscriptionId === undefined"
        >
          <div class="selfservice-ipp__list-placeholder" *cdkDragPlaceholder></div>
          {{ item.ip_prefix.prefix }}
        </div>
      </div>
    </div>
    <div class="selfservice-ipp__content-right">
      <h4 class="selfservice-ipp__title">{{ selectedItems.length }} {{ "Selfservice.IPP.Assigned" | translate }}</h4>
      <div
        cdkDropList
        #selectedList="cdkDropList"
        [cdkDropListData]="selectedItems"
        [cdkDropListConnectedTo]="[availableList]"
        class="selfservice-ipp__list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="selfservice-ipp__list-item"
          [ngClass]="{ 'selfservice-ipp__list-item--new': !isOriginalItem(item.ip_prefix?.prefix) }"
          *ngFor="let item of selectedItems"
          [cdkDragData]="item"
          cdkDrag
          [cdkDragDisabled]="item.subscriptionId === undefined"
          [matTooltipDisabled]="item.subscriptionId !== undefined"
          [matTooltip]="'Selfservice.IPP.Notyours' | translate"
          [matTooltipPosition]="'above'"
        >
          <div class="selfservice-ipp__list-placeholder" *cdkDragPlaceholder></div>
          <svg-icon *ngIf="item.subscriptionId === undefined" src="assets/images/icons/lock.svg"></svg-icon>
          {{ item.ip_prefix.prefix }}
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="currentStep === 2"
  class="selfservice-ipp dialog__body dialog__body--grow dialog__body--scroll dialog__body--grey dialog__body--no-padding"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ "Selfservice.IPP.Title" | translate }}</h3>
    <p class="no-margin t-newline">{{ "Selfservice.IPP.Text" | translate }}</p>
    <div *ngIf="!matchPrefixes()" class="dialog__error">{{ "Selfservice.IPP.Warning" | translate }}</div>
    <div *ngIf="!constraints()" class="dialog__error">{{ "Selfservice.IPP.Error.AtLeastOne" | translate }}</div>
    <div *ngIf="didRemovePrefixes" class="dialog__error">{{ "Selfservice.IPP.Warning.Remove" | translate }}</div>
  </div>
  <div class="selfservice-ipp__summary">
    <div
      *ngFor="let sap of subscriptionCopy?._saps; first as isFirst; index as i"
      class="selfservice-ipp__summary-item"
    >
      <div class="selfservice-ipp__summary-top" (click)="expand(i)">
        <div class="selfservice-ipp__summary-left">
          <div class="selfservice-ipp__summary-number dialog__port-number">{{ i + 1 }}</div>
          <div class="selfservice-ipp__summary-text dialog__port-text">{{ sap.port.description }}</div>
        </div>
        <div class="selfservice-ipp__summary-right">
          <ul class="selfservice-ipp__summary-right-list">
            <li>
              <span>{{ "Selfservice.IPP.Total" | translate }}</span> {{ subscriptionCopy?._saps[i].ip_prefixes.length }}
            </li>
            <li>
              <span>{{ "Selfservice.IPP.New" | translate }}</span>
              {{ countNewItems(subscriptionCopy?._saps[i].ip_prefixes, i) }}
            </li>
            <li>
              <span>{{ "Selfservice.IPP.Removed" | translate }}</span> {{ removedItems(i).length }}
            </li>
          </ul>
          <svg-icon [ngClass]="{ active: expandIndexes.includes(i) }" src="assets/images/icons/chevron-down.svg">
          </svg-icon>
        </div>
      </div>
      <div *ngIf="expandIndexes.includes(i)" class="selfservice-ipp__summary-expand">
        <div *ngIf="subscriptionCopy?._saps[i].ip_prefixes.length > 0" class="selfservice-ipp__summary-expand-section">
          <div class="selfservice-ipp__summary-subtitle">{{ "Selfservice.IPP.AssignedTwo" | translate }}</div>
          <ul class="selfservice-ipp__summary-list">
            <li *ngFor="let item of sortedPrefixes(subscriptionCopy?._saps[i].ip_prefixes)">
              <div [ngClass]="{ new: !isOriginalItemInSap(i, item.ip_prefix.prefix) }">{{ item.ip_prefix.prefix }}</div>
            </li>
          </ul>
        </div>
        <div *ngIf="removedItems(i).length > 0" class="selfservice-ipp__summary-expand-section">
          <div class="selfservice-ipp__summary-subtitle">{{ "Selfservice.IPP.RemovedTwo" | translate }}</div>
          <ul class="selfservice-ipp__summary-list">
            <li *ngFor="let item of removedItems(i)">
              <div>
                <svg-icon src="assets/images/icons/x.svg"> </svg-icon>
                <span>{{ item.ip_prefix.prefix }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasErrors" class="dialog__error dialog__error--margin-x">
    <ul>
      <li *ngFor="let e of errors">{{ e.msg }}</li>
    </ul>
  </div>
</div>

<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  <div *ngIf="currentStep === 1" class="button__flex">
    <button class="button button--secondary" (click)="emitCloseDialog()">
      {{ "Global.Action.Cancel" | translate }}
    </button>
    <button
      (click)="next()"
      class="button"
      [ngClass]="{ loading: buttonLoading, 'button--disabled': buttonLoading || errors.length > 0 }"
    >
      <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
      {{ "Global.Action.Continue" | translate }}
    </button>
  </div>
  <div *ngIf="currentStep === 2" class="button__flex">
    <button class="button button--secondary" (click)="prev()" [ngClass]="{ 'button--disabled': buttonLoading }">
      {{ "Global.Action.Back" | translate }}
    </button>
    <button
      *ngIf="constraints() && matchPrefixes()"
      class="button"
      (click)="submit()"
      [ngClass]="{ loading: buttonLoading, 'button--disabled': buttonLoading || errors.length > 0 }"
    >
      <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
      <span>{{ "Global.Action.Confirm" | translate }}</span>
    </button>
  </div>
</div>
