import { Component, input, computed } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "hive-item",
  templateUrl: "hive-item.html",
  styleUrls: ["../../shortcut-menu.scss"],
  imports: [TranslateModule, AngularSvgIconModule],
})
export class HiveItem {
  protected subscription = input.required<{ supplier: string }>();
  protected isAerohive = computed(() => this.subscription().supplier === "Aerohive");
}
