import { CommonModule, DatePipe } from "@angular/common";
import { Component, inject, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "dialog-tickets",
  templateUrl: "dialog-tickets.html",
  styleUrls: ["dialog-tickets.scss"],
  imports: [AngularSvgIconModule, CommonModule, DatePipe, MatTooltipModule, TranslateModule, MatDialogClose],
})
export class DialogTicketsComponent {
  protected dialogRef = inject(MatDialogRef<DialogTicketsComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  // TODO: can we use one of the datetime pipes here instead?
  formattableDate(d: any) {
    if (typeof d === "string") {
      return new Date(d).getTime();
    } else if (typeof d === "number") {
      return d * 1000;
    }
    return d;
  }
}
