import { Component, EventEmitter, Input, output, inject } from "@angular/core";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { ModifyDdosFilterCommand } from "../../../helpers/self-service/modify_ddos_filter";
import { ApiHelper } from "../../../helpers/apihelper";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ModifyErrorDetail } from "../../../helpers/self-service/models/types";
import { transformError } from "../../../helpers/self-service/transform-error";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SelfserviceAuthenticationBoxComponent } from "../selfservice-authentication-box/selfservice-authentication-box";
import { EnabledPipe } from "../../../helpers/enabledpipe/enabledpipe";

@Component({
  selector: "selfservice-ddos-auto-mitigation",
  templateUrl: "selfservice-ddos-auto-mitigation.html",
  styleUrls: ["selfservice-ddos-auto-mitigation.scss"],
  imports: [
    AngularSvgIconModule,
    CommonModule,
    EnabledPipe,
    FormsModule,
    SelfserviceAuthenticationBoxComponent,
    TranslateModule,
  ],
})
export class SelfserviceDdosAutoMitigationComponent {
  protected api = inject(ApiHelper);

  @Input() subscription;
  @Input() mitigationState: "enabled" | "disabled";
  @Input() bus: EventEmitter<any>;

  dialogCloseEmitter = output<string>();

  public processId: string;
  public errorMessage = null;
  public buttonLoading = false;
  public hasErrors = false;
  public errors: ModifyErrorDetail[];

  get newMitigationState(): "enabled" | "disabled" {
    return this.mitigationState === "enabled" ? "disabled" : "enabled";
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  submit() {
    this.buttonLoading = true;
    const modify = new ModifyDdosFilterCommand(this.newMitigationState === "enabled");
    const command = new SelfServiceCommand(this.subscription.subscriptionId, SelfServiceCommandKeys.ModifyDdosFilter);
    command.payload = modify;

    this.api
      .updateAutoMitigation({
        subscriptionId: this.subscription.subscriptionId,
        modify_ddos_filter: this.newMitigationState,
      })
      .then((response) => {
        this.bus.emit({ kind: "status", status: "completed" });
      })
      .catch((err) => {
        this.buttonLoading = false;
        console.log(err);
        this.errors = transformError(err.error.detail);
        this.hasErrors = true;
      });
  }
}
