<div class="service-summary" [ngClass]="{ 'service-summary--force-column': forceTrafficBottom }">
  <div class="service-summary__main">
    <div class="service-summary__header">
      <div class="service-summary__icon service-summary__icon--{{ icon }}">
        <svg-icon src="assets/images/icons/{{ icon }}.svg"></svg-icon>
        @if (showFirewallEnabled()) {
          <span class="service-summary__icon-overlap-square">
            <svg-icon src="assets/images/icons/fw-overlap.svg" class="service-tile__overlap-icon"></svg-icon>
          </span>
        }
      </div>
      <div class="service-summary__header-right">
        <div class="service-summary__title-wrapper">
          <svg-icon src="assets/images/icons/status-{{ healthData()?.status | status_to_icon }}.svg"></svg-icon>
          <h3 class="service-summary__title">{{ service() | custom_description }}</h3>
        </div>
        <div class="tags">
          @for (tag of service().tags; track tag) {
            <div class="tag">{{ tag }}</div>
          }
        </div>
      </div>
    </div>
    <div class="service-summary__table">
      <div class="service-summary__table-row">
        <div class="service-summary__table-col">{{ "ServiceElement.Owner" | translate }}</div>
        <div class="service-summary__table-col">{{ organisationName() }}</div>
      </div>
      <div class="service-summary__table-row">
        <div class="service-summary__table-col">
          <span>{{ (isPort() ? "ServiceElement.PortID" : "ServiceElement.ServiceID") | translate }}</span>
        </div>
        <div class="service-summary__table-col">{{ service().subscriptionId | shorten_guid }}</div>
      </div>
      @if (vlanranges && vlanranges.length > 0 && !isMsc()) {
        <div class="service-summary__table-row">
          <div class="service-summary__table-col">{{ "ServiceElement.VLANID" | translate }}</div>
          <div class="service-summary__table-col">
            @for (vlanrange of vlanranges; track vlanrange) {
              <div>
                @if (vlanrange && vlanrange !== 0) {
                  <span>{{ vlanrange }}</span>
                } @else {
                  <span>{{ "Subscription.UntaggedPort" | translate }}</span>
                }
              </div>
            }
          </div>
        </div>
      }
      @if (service()?.port?.serviceTag; as serviceTag) {
        <div class="service-summary__table-row">
          <div class="service-summary__table-col">{{ "ServiceElement.ServiceTag" | translate }}</div>
          <div class="service-summary__table-col">{{ serviceTag }}</div>
        </div>
      }
      @if (locations.length > 0) {
        <div class="service-summary__table-row">
          <div class="service-summary__table-col">
            {{ (locations.length === 1 ? "ServiceElement.Location" : "ServiceElement.Locations") | translate }}
          </div>
          <div class="service-summary__table-col service-summary__table-col--flex-newline">
            @for (location of locations; track location) {
              <span>{{ location }}</span>
            }
          </div>
        </div>
      }
      @if (service()?.ipGateway; as ipGateway) {
        <div class="service-summary__table-row">
          <div class="service-summary__table-col">{{ "ServiceElement.ServiceSpeed" | translate }}</div>
          <div class="service-summary__table-col">{{ ipGateway.serviceSpeed | service_speed }}</div>
        </div>
      }
    </div>
    @if (isPort()) {
      <port-visualiser [unavailable]="!isAccessible" [subscription]="service()" />
    }
    <div class="service-summary__bottom">
      @if (showTrafficButton) {
        <div class="button button--secondary service-summary__scroll-button" (click)="navigateToChart(400)">
          {{ "Global.ShowTraffic" | translate }}
        </div>
      }
      @if (showMoreDetailsButton && isAccessible) {
        <div class="button button--secondary" [routerLink]="routerLinkForService(service())">
          {{ "Global.MoreDetails" | translate }}
        </div>
      }
    </div>
  </div>
  @if (isFirewall()) {
    <traffic-speed [showTraffic]="showTraffic()" [healthData]="healthData()" />
  }
</div>
