<navigation></navigation>
<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
        <div class="card">
          <h2>{{ "Global.Error" | translate }}</h2>
          <p>{{ "Page.Error.Description1" | translate }}</p>
          <p>{{ "Page.Error.Description2" | translate }}</p>
        </div>

        <div class="card card--cols">
          <h2>{{ "Page.Error.Heading1" | translate }}</h2>
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <p>{{ "Page.Error.Description3" | translate }}</p>
            </div>
            <div class="col-xs-12 col-sm-6">
              <p>{{ "Page.Error.Description4" | translate }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <div class="badge">{{ "Page.Error.Description5" | translate }}</div>
            </div>
            <div class="col-xs-12 col-sm-6">
              <ul class="list">
                <li>{{ "Page.Error.Description6" | translate }}</li>
                <li><a href="mailto:helpdesk@surf.nl">helpdesk&#64;surf.nl</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container"><footer></footer></div>
</div>
