<div class="dialog dialog--small">
  <div>
    <div class="dialog__header">
      <h3 class="dialog__title">
        <span
          >{{ "Malfunction.AffectedServices" | translate }} ({{ data.message.affected_subscriptions?.length }})</span
        >
      </h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>
    <div class="affected-service-item__wrapper">
      @for (subscription of data.message.affected_subscriptions; track subscription.subscription_id) {
        <div class="affected-service-item" (click)="segueToService(subscription)">
          <div
            class="affected-service-item__icon-box affected-service-item__icon-box--{{
              subscription.product_type.toLowerCase()
            }}"
          >
            <svg-icon
              src="assets/images/icons/{{ subscription.product_type.toLowerCase() }}.svg"
              class="affected-service-item__icon"
            ></svg-icon>
          </div>

          <div class="affected-service-item__caption">
            <div class="affected-service-item__title">
              @if (subscription.customer_description !== "") {
                <div>{{ subscription.customer_description }}</div>
              } @else {
                <div>{{ subscription.description }}</div>
              }
            </div>
            <div class="affected-service-item__subtitle">{{ subscription.impact_type }}</div>
          </div>
          <svg-icon class="affected-service-item__arrow" src="assets/images/icons/arrow-small-right.svg"> </svg-icon>
        </div>
      }
    </div>
  </div>
</div>
