import { CommonModule } from "@angular/common";
import { Component, computed, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SubscriptionDetailTab } from "./tab-types";
import { ProductType } from "../../../helpers/enums/productType";

@Component({
  selector: "page-tab",
  templateUrl: "page-tab.html",
  styleUrls: ["page-tab.scss"],
  imports: [CommonModule, TranslateModule],
})
export class PageTabComponent {
  protected activeTab = input(SubscriptionDetailTab.CONFIG);
  protected productType = input.required<string>();

  activeTabEmitter = output<SubscriptionDetailTab>();

  protected isFirewall = computed(() => this.productType() == ProductType.Firewall);

  protected isConfigTabActive = computed(() => this.activeTab() === SubscriptionDetailTab.CONFIG);
  protected isGraphicViewTabActive = computed(() => this.activeTab() === SubscriptionDetailTab.GRAPHIC_VIEW);
  protected isSystemStatsTabActive = computed(() => this.activeTab() === SubscriptionDetailTab.SYSTEM_STATS);

  private changeActiveTab(tab: SubscriptionDetailTab) {
    this.activeTabEmitter.emit(tab);
  }

  protected selectConfigTab = () => this.changeActiveTab(SubscriptionDetailTab.CONFIG);
  protected selectGraphicViewTab = () => this.changeActiveTab(SubscriptionDetailTab.GRAPHIC_VIEW);
  protected selectSystemStatsTab = () => this.changeActiveTab(SubscriptionDetailTab.SYSTEM_STATS);
}
