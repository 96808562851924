import { Component, input } from "@angular/core";
import { MalfunctionItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/malfunction/malfunction-item";
import { SelfServiceItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/self-service/self-service-item";
import { SendConfigItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/send-config/send-config-item";
import { ShortcutMenu } from "../../../../../../components/subscription-detail/shortcut-menu/shortcut-menu";
import { AvailabilityItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/availability/availability-item";
import { AvailabilityExportItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/availability-export/availability-export-item";

@Component({
  selector: "ip-shortcut-menu",
  templateUrl: "ip-shortcut-menu.html",
  imports: [AvailabilityItem, AvailabilityExportItem, MalfunctionItem, SelfServiceItem, SendConfigItem, ShortcutMenu],
})
export class IpShortCutMenuComponent {
  subscription = input.required<any>();
  isTerminated = input.required<boolean>();
  activities = input.required<Notification[]>();
}
