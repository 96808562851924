import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { Apollo, ApolloBase, gql } from "apollo-angular";
import { AuthService } from "../../../services/AuthService";
import { Router } from "@angular/router";
import { DialogMessageComponent } from "../../dialogs/dialog-message/dialog-message";
import { MatDialog } from "@angular/material/dialog";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { openSelfServiceDialog } from "../../dialogs/dialog-selfservice/dialog-selfservice.helpers";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "../../common/loader/loader";
import { SelfserviceToggleComponent } from "../../selfservice/selfservice-toggle/selfservice-toggle";
import { EnabledPipe } from "../../../helpers/enabledpipe/enabledpipe";
import { SurfCertTypeConnectionV2 } from "../../../gql/generated-ingestor";

const SURF_CERT_QUERY = gql`
  query CertCert($customer: UUID!) {
    surfCertList(searchFilter: { accessCustomerId: $customer }) {
      page {
        subscriptionId
        insync
        customerId
        productType
        productTag
        presentableName: description
        surfcertFilterEnabled
      }
    }
  }
`;

@Component({
  selector: "cert-security",
  templateUrl: "cert-security.html",
  styleUrls: ["cert-security.scss"],
  imports: [
    AngularSvgIconModule,
    CommonModule,
    EnabledPipe,
    LoaderComponent,
    SelfserviceToggleComponent,
    TranslateModule,
  ],
})
export class CertSecurityComponent implements OnInit {
  private apollo = inject(Apollo);
  protected auth = inject(AuthService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private translate = inject(TranslateService);

  private replicatedData: ApolloBase;
  constructor() {
    this.replicatedData = this.apollo.use("replicatedData");
  }

  // TODO: replace any by SurfCertType
  protected subscriptions = signal<any[]>([]);

  private subscriptionCount = 1;

  protected loading = computed(() => this.subscriptions().length < this.subscriptionCount);

  get hasCorrectRole(): boolean {
    return this.auth.hasRole(["surfcert-kernel", "Beveiligingsverantwoordelijke", "Infraverantwoordelijke"]);
  }

  ngOnInit() {
    this.getSubscriptionData();
  }

  private getSubscriptionData() {
    this.replicatedData
      .query<{ surfCertList: SurfCertTypeConnectionV2 }>({
        query: SURF_CERT_QUERY,
        variables: { customer: localStorage.getItem("viewingCustomerGUID") },
      })
      .subscribe(({ data }) => {
        this.subscriptionCount = data.surfCertList.page.length;
        this.subscriptions.set(data.surfCertList.page);

        this.checkForPendingAction();
      });
  }

  private checkForPendingAction() {
    // check for pending auth action.
    // if it's there, the state contains a subscription
    if (this.auth.hasPendingStrongAction("certFilter")) {
      this.auth.clearPendingStrongAction("certFilter");
      const state = this.auth.getSelfserviceState();
      const subscriptionId = state?.subscription?.subscriptionId;
      if (subscriptionId) {
        const subscription = this.subscriptions().find((sub) => sub.subscriptionId === subscriptionId);

        if (subscription) {
          this.openDdosDialog(subscription);
        }
      }
    }
  }

  toggleClick(subscription: any) {
    if (!this.hasCorrectRole) {
      return false;
    }

    if (subscription.productType === "IP") {
      if (!this.auth.selfServiceStarted()) {
        this.dialog.open(DialogAuthComponent, {
          data: {
            selfserviceState: { subscription },
            initialAction: "certFilter",
          },
        });
      } else {
        this.openDdosDialog(subscription);
      }
    } else {
      this.showUnchangeableDialog();
    }
  }

  // notify the user that DDOS cannot be changed
  showUnchangeableDialog() {
    this.dialog.open(DialogMessageComponent, {
      data: {
        title: this.translate.instant("Cert.DDOSSecurity"),
        message: this.translate.instant("Cert.Unchangeable"),
      },
    });
  }

  // open the self service dialog for DDoS.
  // Only supply the needed bits (subscription ID and surfcertFilterEnabled)
  openDdosDialog(subscription: any) {
    const data = {
      type: "ddos",
      subscription: {
        customerId: subscription.customerId,
        description: "",
        insync: subscription.insync,
        ipss: {
          surfcertFilterEnabled: subscription.surfcertFilterEnabled,
        },
        product: {
          type: subscription.productType,
          tag: subscription.productTag,
        },
        subscriptionId: subscription.subscriptionId,
      },
    };

    const dialogRef = openSelfServiceDialog(this.dialog, data);
    dialogRef.componentInstance.close.subscribe((event: string) => {
      if (event === "refresh") {
        this.getSubscriptionData();
      }
    });
  }

  routeToService({ productType, productTag, subscriptionId }) {
    this.router.navigate(["/subscription", productType, productTag, subscriptionId]);
  }
}
