<div class="section">
  <div class="row">
    <div class="col-12 col-lg-7">
      <h3 class="title-with-icon">
        <svg-icon src="assets/images/icons/accesspoint.svg" class="black-medium"></svg-icon>
        <span>Type access points</span>
      </h3>
      <access-points [accessPoints]="accessPoints()"></access-points>
    </div>
    <div class="col-12 col-lg-5">
      <h3 class="title-with-icon">
        <svg-icon src="assets/images/icons/usergroup.svg" class="black-medium"></svg-icon>
        <span>{{ "Wireless.Stats.MaxUsers" | translate }}</span>
      </h3>
      @if (healthData()) {
        <user-stats [wirelessHealthData]="healthData()"></user-stats>
      }
    </div>
  </div>
</div>
