<div class="page-tab">
  <div class="page-tab__dividers">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="page-tab__buttons">
    <button class="page-tab__button" (click)="changeTab('scan')" [ngClass]="{ active: scanActive() }">
      {{ "Tabs.Scan" | translate }}
    </button>
    <button class="page-tab__button" (click)="changeTab('ddos')" [ngClass]="{ active: ddosActive() }">
      {{ "Tabs.DDOS" | translate }}
    </button>
  </div>
</div>
