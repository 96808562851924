<navigation [forceTitle]="'Subscription.Cert.Name' | translate"></navigation>
<div class="cert">
  <div class="container">
    <cert-header></cert-header>
    <cert-tabs [activeTab]="activeTab" (tabEmitter)="changeTab($event)"></cert-tabs>
    @switch (activeTab) {
      @case ("scan") {
        <scan-tab />
      }
      @case ("ddos") {
        <ddos-tab />
      }
    }
    <footer></footer>
  </div>
</div>
