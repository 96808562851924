import { gql } from "apollo-angular";
import { SubscriptionWithDdosStatus } from "../custom-types";
import { FirewallFragment } from "../fragments/firewall-subscription";
import { ProductFragment, SubscriptionDetailsFragment } from "../fragments/fragments";
import { IpBgpSubscriptionFragment } from "../fragments/ip-bgp-subscription";
import { IpStaticSubscriptionFragment } from "../fragments/ip-static-subscription";

export const transform = (
  result: SubscriptionWithDdosStatus,
): { productType: string; productTag: string; surfcertFilterEnabled: boolean } => {
  // TODO: This is ugly! All this info should come from replication (Issue #248)
  let surfcertFilterEnabled = false;
  let productType = "IP";
  let productTag = "";
  switch (result.__typename) {
    case "FwSubscription":
      surfcertFilterEnabled = result.firewall?.ipGwEndpoint?.ip?.settings?.surfcertFilterEnabled || false;
      productType = "FW";
      break;
    case "Sn8IpStaticSubscription":
      surfcertFilterEnabled = result.vc?.settings?.surfcertFilterEnabled || false;
      productTag = "IPS";
      break;
    case "Sn8IpBgpSubscription":
      surfcertFilterEnabled = result.vc?.settings?.surfcertFilterEnabled || false;
      productTag = "IPBGP";
      break;
  }
  return {
    productType,
    productTag,
    surfcertFilterEnabled,
  };
};

export const DDOS_CHART_QUERY = gql`
  ${IpBgpSubscriptionFragment}
  ${IpStaticSubscriptionFragment}
  ${FirewallFragment}
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  query SubscriptionsOfTypeDDosQuery($customer: String!, $status: [String!]) {
    customer(organisationId: $customer) {
      id: uuid
      subscriptions(productsIncludedFilter: ["IP", "FW"], statusFilter: $status) {
        subscriptionType: __typename
        ...SubscriptionDetails
        ...Product
        ...IpBgpSubscriptionDetails
        ...IpStaticSubscriptionDetails
        ...FirewallDetails
      }
    }
  }
`;
