import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit, inject } from "@angular/core";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { ModifyBfdCommand } from "../../../helpers/self-service/modify_bfd";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ApiHelper } from "../../../helpers/apihelper";
import { ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";
import { HttpErrorResponse } from "@angular/common/http";
import { transformError } from "../../../helpers/self-service/transform-error";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SelfserviceAuthenticationBoxComponent } from "../selfservice-authentication-box/selfservice-authentication-box";
import { SelfserviceToggleComponent } from "../selfservice-toggle/selfservice-toggle";
import { CustomDescriptionPipe } from "../../../helpers/descriptionpipe/customdescription";

@Component({
  selector: "selfservice-bfd",
  templateUrl: "selfservice-bfd.html",
  styleUrls: ["selfservice-bfd.scss"],
  imports: [
    AngularSvgIconModule,
    CommonModule,
    CustomDescriptionPipe,
    FormsModule,
    SelfserviceToggleComponent,
    SelfserviceAuthenticationBoxComponent,
    TranslateModule,
  ],
})
export class SelfserviceBfdComponent implements OnChanges, OnInit {
  protected api = inject(ApiHelper);

  @Input() bfdActivated: boolean;
  @Input() timeInterval = 900;
  @Input() multiplier = 3;
  @Input() currentStep = 1;
  @Input() subscription;
  @Input() instance;
  @Input() sapIndex;
  @Input() bus: EventEmitter<any>;
  @Output() dialogCloseEmitter = new EventEmitter<string>();

  public processId: string;
  public hasErrors = false;
  public errors: ModifyErrorDetail[];
  public errorMessage = null;
  public buttonLoading = false;

  get validTimeInterval() {
    const minTimeInterval = 300;
    const maxTimeInterval = 10000;
    return this.timeInterval >= minTimeInterval && this.timeInterval <= maxTimeInterval;
  }

  get validMultiplier() {
    const minMultiplier = 3;
    const maxMultiplier = 10;
    return this.multiplier >= minMultiplier && this.multiplier <= maxMultiplier;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["instance"]) {
      this.timeInterval = this.instance.bfdMinimumInterval;
      this.multiplier = this.instance.bfdMultiplier;
    }
  }

  ngOnInit() {
    this.bfdActivated = this.instance.bfd;
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  prev() {
    this.currentStep--;
  }

  next() {
    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.currentStep++;
  }

  submit() {
    this.buttonLoading = true;
    const modify = new ModifyBfdCommand();

    modify.setForInstance(this.instance.subscriptionInstanceId, this.bfdActivated, this.timeInterval, this.multiplier);

    const command = new SelfServiceCommand(this.subscription.subscriptionId, SelfServiceCommandKeys.ModifyBfd);
    command.payload = modify;

    // reset error div trigger
    this.hasErrors = false;

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({
          processId: this.processId,
          action: SelfServiceCommandKeys.ModifyBfd,
        });
      })
      .catch((err) => {
        this.onError(err);
        console.log(err);
        this.buttonLoading = false;
        this.errorMessage = err.error.detail;
      });
  }

  handleChange(e) {
    this.timeInterval = 900;
    this.multiplier = 3;
  }

  onError(err: HttpErrorResponse) {
    try {
      this.errors = transformError(err.error.detail);
      this.hasErrors = true;
    } catch (e) {
      console.log("unknown error", err);
    }
  }
}
