import { Component, OnInit, OnDestroy, inject, signal, computed } from "@angular/core";
import { AuthService } from "../../services/AuthService";
import { Subscription as RxSubscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { collectTags } from "../../helpers/subscription/collect-tags";
import { MessageService } from "../../services/messageservice";
import { Instance } from "../../components/models/instance";
import { ApiHelper } from "../../helpers/apihelper";
import { getFilteredItems, IFilter } from "./helpers";

import { Apollo, ApolloBase } from "apollo-angular";
import { LIST_QUERIES } from "../../graphql/replication/subscription-list";
import { StorageHelper } from "../../helpers/storage/storagehelper";
import { LoadingState } from "../../services/loading-state";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FilterByStatus } from "../../components/subscriptions-list/filter-by-status/filter-by-status";
import { ChecktagComponent } from "../../components/subscriptions-list/checktag/checktag";
import { ServiceElementSimple } from "../../components/subscriptions-list/service-element-simple/service-element-simple";
import { LoaderComponent } from "../../components/common/loader/loader";
import { CompleterLocationComponent } from "../../components/subscriptions-list/completer-location/completer-location";
import { FooterComponent } from "../../components/common/footer/footer";

@Component({
  selector: "page-subscriptions",
  templateUrl: "subscriptions.html",
  imports: [
    ChecktagComponent,
    CommonModule,
    FilterByStatus,
    FooterComponent,
    FormsModule,
    NavigationComponent,
    ServiceElementSimple,
    TranslateModule,
    LoaderComponent,
    CompleterLocationComponent,
  ],
})
export class SubscriptionsPage implements OnInit, OnDestroy {
  private auth = inject(AuthService);
  public api = inject(ApiHelper);
  private route = inject(ActivatedRoute);
  private msgsrv = inject(MessageService);
  public loadingState = inject(LoadingState);
  private apolloProvider = inject(Apollo);

  public subscriptions: Instance[];
  public servicesSearch: string;
  public collectedTags: string[] = [];
  public collectedLocations: string[] = [];
  public linkedOrganisations: string[] = [];

  public filteredSubscriptions: Instance[];
  public activeFilters: string[] = [];
  public activeTags: string[] = [];
  public activeLocations: string[] = [];
  public filterActive = false;

  protected productType = signal("");
  protected isWireless = computed(() => this.productType() == "Wireless");

  private replicatedData: ApolloBase;

  private rxSubscriptions: RxSubscription[] = [];

  constructor() {
    this.replicatedData = this.apolloProvider.use("replicatedData");
    this.productType.set(this.extractProductType());

    const subEnableFilter = this.msgsrv.enableFilter$.subscribe((filter: IFilter) => {
      switch (filter.type) {
        case "state":
          // Add to active filters
          this.activeFilters.push(filter.value);
          break;
        case "tag":
          // Add to active tags
          this.activeTags.push(filter.value);
          break;
        case "location":
          this.activeLocations.push(filter.value);
          break;
        default:
          break;
      }

      this.filteredSubscriptions = getFilteredItems(
        this.subscriptions,
        this.activeFilters,
        this.activeTags,
        this.activeLocations,
        this.servicesSearch,
      );
      this.filterActive = true;
    });
    this.rxSubscriptions.push(subEnableFilter);

    const subDisableFilter = this.msgsrv.disableFilter$.subscribe((filter) => {
      switch (filter.type) {
        case "state":
          // Remove from active filters
          const filterIndex = this.activeFilters.indexOf(filter.value);
          if (filterIndex > -1) {
            this.activeFilters.splice(filterIndex, 1);
          }
          break;
        case "tag":
          // Remove from active tags
          const tagIndex = this.activeTags.indexOf(filter.value);
          if (tagIndex > -1) {
            this.activeTags.splice(tagIndex, 1);
          }
          break;
        case "location":
          const locIndex = this.activeLocations.indexOf(filter.value);
          if (locIndex > -1) {
            this.activeLocations.splice(locIndex, 1);
          }
          break;
        default:
          break;
      }

      // No filters active anymore
      if (this.activeTags.length === 0 && this.activeFilters.length === 0 && this.activeLocations.length === 0) {
        this.filterActive = false;
        this.filteredSubscriptions = [];
        return;
      }

      // Apply filters that are still active
      this.filteredSubscriptions = getFilteredItems(
        this.subscriptions,
        this.activeFilters,
        this.activeTags,
        this.activeLocations,
        this.servicesSearch,
      );
    });
    this.rxSubscriptions.push(subDisableFilter);
  }

  ngOnInit() {
    const subscription = this.auth.userLoaded.subscribe((loaded) => (loaded ? this.gqlFetch() : undefined));
    this.rxSubscriptions.push(subscription);
  }

  ngOnDestroy() {
    while (this.rxSubscriptions.length) {
      this.rxSubscriptions.pop().unsubscribe();
    }
  }

  extractProductType(): string {
    let productType = this.route.snapshot.paramMap.get("productType");
    if (!productType) {
      const pathDirs = (this.route.snapshot.routeConfig.path ?? "").split("/");
      if (pathDirs.length > 0) {
        productType = pathDirs[pathDirs.length - 1];
      }
    }

    if (!productType) {
      throw Error("Unable to load subscription list page: product type unknown");
    }

    return productType;
  }

  async gqlFetch() {
    this.subscriptions = [];
    const query = LIST_QUERIES[this.productType()];
    this.replicatedData
      .query({
        query,
        variables: { customer: StorageHelper.currentUser },
      })
      .subscribe(({ data }) => {
        this.subscriptions = data?.subscriptions.page
          .filter((s) => s.status !== "terminated")
          .map((sub) => Object.assign(new Instance(), sub));
        this.subscriptions.sort((a, b) => a.subscriptionId.localeCompare(b.subscriptionId));
        this.collectedTags = collectTags(this.subscriptions);

        this.subscriptions.forEach((subscription) => {
          (subscription.locations ?? []).forEach((location) => {
            const city = location?.address?.city ?? "-";
            const street = location?.address?.street ?? "-";
            const number = location?.address?.number ?? "-";
            const label = `${city.toLowerCase()} (${street.toLowerCase()} ${number})`;
            if (!this.collectedLocations.includes(label)) {
              this.collectedLocations.push(label);
            }
          });
        });
      });
  }

  searchValueChange(event: any) {
    this.filterActive = true;

    // Apply filters that active
    this.filteredSubscriptions = getFilteredItems(
      this.subscriptions,
      this.activeFilters,
      this.activeTags,
      this.activeLocations,
      this.servicesSearch,
    );
  }
}
