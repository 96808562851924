<div class="card card--no-padding service service--clickable" [routerLink]="[getRouterLink()]">
  <div class="service__header">
    <div class="service__left">
      @if (isWireless()) {
        <svg-icon class="service-icon-wireless" src="assets/images/icons/wireless.svg"></svg-icon>
      } @else {
        <svg-icon src="assets/images/icons/status-{{ health()?.status | status_to_icon }}.svg"></svg-icon>
      }
      <div class="service__header-caption">
        <alias-editor
          [customerDescription]="customerDescription()"
          [subscriptionId]="subscriptionId()"
          class="list-alias-editor"
        />
        <div class="service__subtitle">
          <span class="black">{{ subscriptionId() | shorten_guid }}</span>
          -
          <span class="black">{{ "ServiceElement.Owner" | translate }}: {{ owner() }}</span
          ><br />
          @for (location of locations(); track $index) {
            <span class="service__endpoint">
              @if (location.address.city) {
                <span>
                  {{ location.address | location_address }}
                </span>
              }
            </span>
          }
        </div>
        <service-element-tags [firewallEnabled]="firewallEnabled()" [tags]="tags()" />

        @if (isWireless()) {
          <div class="service__access-points">
            <div class="service__access-points-item">
              <span>{{ "ServiceElement.APsActive" | translate }}</span>
              <span *ngIf="wirelessKpiList && wirelessKpiList.activeAps !== null">{{ wirelessKpiList.activeAps }}</span>
              <span *ngIf="wirelessKpiList?.activeAps === null">-</span>
            </div>
            <div class="service__access-points-item">
              <span>{{ "ServiceElement.APsInactive" | translate }}</span>
              <span *ngIf="wirelessKpiList && wirelessKpiList.inactiveAps !== null">{{
                wirelessKpiList.inactiveAps
              }}</span>
              <span *ngIf="wirelessKpiList?.inactiveAps === null">-</span>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="service__right">
      <div class="service__button" (click)="openIncidentDialog($event)">
        {{ "Services.Fulfilment.ReportProblem" | translate }}
      </div>
      @if (isWireless()) {
        <div>
          <ng-container [ngSwitch]="subscription().sensorPresent">
            <loader *ngSwitchCase="undefined" [small]="true" [paddingSize]="'small'"></loader>
            <ng-container *ngSwitchCase="true">
              <kpi-row
                *ngIf="wirelessKpiList !== undefined"
                [healthData]="wirelessKpiList"
                [vendor]="wirelessVendor"
              ></kpi-row>
              <loader *ngIf="wirelessKpiList === undefined" [small]="true" [paddingSize]="'small'"></loader>
            </ng-container>
            <p *ngSwitchCase="false">No sensor present</p>
            <p *ngSwitchDefault>Unknown sensor state</p>
          </ng-container>
        </div>
      } @else if (traffic()) {
        <div class="service__current">
          <div class="service__current-col">
            <div class="service__current-title">{{ "ServiceElement.Chart.NowIn" | translate }}</div>
            <div>{{ traffic().last.in | readable_size }}</div>
          </div>
          <div class="service__current-col">
            <div class="service__current-title">{{ "ServiceElement.Chart.NowOut" | translate }}</div>
            <div>{{ traffic().last.out | readable_size }}</div>
          </div>
        </div>
      }
    </div>
  </div>

  <div class="service__graph">
    @if (showPortVisualizer()) {
      <port-visualiser [subscription]="subscription()" />
    }
  </div>

  @if (!isWireless()) {
    <service-status class="service__status" productType="{{ productType() }}" subscriptionId="{{ subscriptionId() }}">
    </service-status>
  }
</div>
