import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  input,
  OnChanges,
  output,
  SimpleChanges,
} from "@angular/core";
import dayjs from "dayjs";
import { SearchFilter, Severity } from "../../../gql/generated";
import { AuthService } from "../../../services/AuthService";
import { StorageHelper } from "../../../helpers/storage/storagehelper";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { CustomDropdownComponent } from "../../common/custom-dropdown/custom-dropdown";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FormsModule } from "@angular/forms";
import { FlatpickrModule } from "angularx-flatpickr";

@Component({
  selector: "cert-filter",
  templateUrl: "cert-filter.html",
  styleUrls: ["cert-filter.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CustomDropdownComponent, AngularSvgIconModule, FormsModule, TranslateModule, FlatpickrModule],
})
export class CertFilterComponent implements OnChanges, AfterViewInit {
  prefixes = input<string[]>([]);
  filtersChanged = output<SearchFilter>();

  public initialStartDate: Date = new Date();
  public initialEndDate: Date = new Date();
  public enableSpecificFilters = false;

  public classifications = [
    { id: 1, select: false, name: "critical", value: Severity.Critical },
    { id: 2, select: false, name: "high", value: Severity.High },
    { id: 3, select: false, name: "medium", value: Severity.Medium },
    { id: 4, select: false, name: "low", value: Severity.Low },
    { id: 5, select: false, name: "info", value: Severity.Info },
  ];

  public ip = "";

  public scanOptions = [
    { id: 1, select: false, name: "Scan option 1" },
    { id: 2, select: false, name: "Scan option 2" },
  ];

  public prefixOptions: any[] = [];

  constructor(private auth: AuthService) {
    this.initialStartDate.setDate(this.initialStartDate.getDate() - 7);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prefixes) {
      if (changes.prefixes.currentValue?.length !== changes.prefixes.previousValue?.length) {
        this.prefixOptions = this.prefixes()
          .map((prefix: string, index: number) => ({
            id: index,
            select: false,
            name: prefix,
          }))
          .sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
      }
    }
  }

  submit() {
    this.filtersChanged.emit(this.searchFilter());
  }

  onFilterChanged() {}

  searchFilter(): SearchFilter {
    const filter: SearchFilter = {};

    // if (this.prefixOptions.length == 0 ) {
    //   return filter;
    // }

    const selectedPrefixes = this.prefixOptions.filter((prefix) => prefix.select);
    if (selectedPrefixes.length) {
      filter.ipPrefixesSource = selectedPrefixes.map((prefix) => prefix.name as string);
    } else {
      //filter.ipPrefixesSource = this.prefixOptions.map((prefix) => prefix.name as string);
    }

    filter.customerId = StorageHelper.currentUser;
    const selectedClassifications = this.classifications.filter((prefix) => prefix.select);
    filter.severity = selectedClassifications.map((prefix) => prefix.value as Severity);

    filter.afterSourceDate = dayjs(this.initialStartDate).startOf("day").toDate();
    filter.beforeSourceDate = dayjs(this.initialEndDate).endOf("day").toDate();

    if (this.ip !== "") {
      filter.ip = `${this.ip}`.trim().replace(/[^0-9.]/g, "");
    }

    return filter;
  }

  ngAfterViewInit(): void {
    this.filtersChanged.emit(this.searchFilter());
  }

  resetFilters() {
    this.ip = "";

    this.prefixOptions.map((prefix) => {
      prefix.select = false;
    });

    this.classifications.map((prefix) => {
      prefix.select = false;
    });

    this.initialStartDate = dayjs().subtract(7, "day").startOf("day").toDate();
    this.initialEndDate = dayjs().endOf("day").toDate();

    this.filtersChanged.emit(this.searchFilter());
  }
}
