<div>
  @if (displayLoadingBanner()) {
    <div class="dialog-loading-banner">
      <span>{{ "Selfservice.IP.Waiting" | translate }}</span>
    </div>
  }
  <div class="title-flex">
    <h3 class="title-with-icon">
      <svg-icon src="assets/images/icons/port.svg" class="purple"></svg-icon>
      @if (isIp()) {
        <span>{{ "ServiceElement.PortInternet" | translate }}</span>
      }
      @if (isL2Vpn() || isL3Vpn()) {
        <span>{{ "ServiceElement.ConnectedPorts" | translate }}</span>
      }
      @if (isFw()) {
        <span>{{ "ServiceElement.ConnectedNetworks" | translate }}</span>
      }
    </h3>
    @if (auth.isSelfServiceEnabled && isIp() && !firewallEnabled()) {
      <div>
        @if (isInfrastructureDelegate()) {
          <div class="overflow-menu" (clickOutside)="overflowMenuIPActive = false">
            <button
              class="overflow-menu__button button button--small button--secondary"
              [ngClass]="{ active: overflowMenuIPActive }"
              (click)="overflowMenuIPActive = !overflowMenuIPActive"
            >
              <svg-icon src="assets/images/icons/cog.svg" class="button__icon"></svg-icon>
              <span> {{ "Global.Modify" | translate }}</span>
            </button>
            <div class="overflow-menu__items">
              @if (isIpBgp()) {
                <div
                  class="overflow-menu__item"
                  (click)="openSelfserviceDialog('bgp')"
                  [matTooltip]="'Selfservice.Tooltip.BGP' | translate"
                  [matTooltipPosition]="'above'"
                >
                  {{ "OverflowMenu.ModifyBGP" | translate }}
                </div>
              }
              @if (activeTab.item.ipPrefixes) {
                <div
                  class="overflow-menu__item"
                  [matTooltip]="'Selfservice.Tooltip.IPPrefixes' | translate"
                  [matTooltipPosition]="'above'"
                  (click)="openSelfserviceDialog('ip-prefixes')"
                >
                  {{ "OverflowMenu.ModifyIPP" | translate }}
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
    <!-- Disable Selfservice -->
    <div *ngIf="auth.isSelfServiceEnabled && showModifyOptions">
      @if (isInfrastructureDelegate()) {
        <div class="overflow-menu" (clickOutside)="overflowMenuIPActive = false">
          <button
            class="overflow-menu__button button button--small button--secondary"
            [ngClass]="{ active: overflowMenuIPActive }"
            (click)="overflowMenuIPActive = !overflowMenuIPActive"
          >
            <svg-icon src="assets/images/icons/cog.svg" class="button__icon"></svg-icon>
            <span> {{ "Global.Modify" | translate }}</span>
          </button>
          <div class="overflow-menu__items">
            <div
              class="overflow-menu__item"
              (click)="openSelfserviceDialog('vlan-l2vpn')"
              [matTooltip]="'Selfservice.Tooltip.Vlan' | translate"
              [matTooltipPosition]="'above'"
            >
              {{ "OverflowMenu.ModifyVlan" | translate }}
            </div>
            <div
              class="overflow-menu__item"
              (click)="openSelfserviceDialog('add-port')"
              [matTooltip]="'Selfservice.Tooltip.AddPort' | translate"
              [matTooltipPosition]="'above'"
            >
              {{ "OverflowMenu.AddPort" | translate }}
            </div>
            <div
              class="overflow-menu__item"
              (click)="openSelfserviceDialog('remove-port')"
              [matTooltip]="'Selfservice.Tooltip.RemovePort' | translate"
              [matTooltipPosition]="'above'"
            >
              {{ "OverflowMenu.RemovePort" | translate }}
            </div>
          </div>
        </div>
      }
    </div>
  </div>

  <div class="card card--no-padding tabs" *ngIf="service().product && tabs.length > 0">
    <div class="tabs__nav-wrapper">
      <div class="tabs__nav" #tabsnav>
        @for (tab of tabs; track $index) {
          <div
            class="tabs__nav-item"
            (click)="activeTabIndex = $index"
            [ngClass]="{ active: activeTabIndex === $index }"
          >
            <div class="tabs__nav-caption">
              <div class="tabs__nav-title">{{ tab.title }}</div>
              <div class="tabs__nav-subtitle">{{ tab.subtitle }}</div>
            </div>
          </div>
        }
        <div class="tabs__controls">
          <div class="tabs__control" (click)="moveTab(-1)">
            <svg-icon src="assets/images/icons/arrow-small-left.svg"></svg-icon>
          </div>
          <div class="tabs__control" (click)="moveTab(1)">
            <svg-icon src="assets/images/icons/arrow-small-right.svg"></svg-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="tabs__content">
      <div class="tabs__grid" *ngIf="activeTab">
        <div *ngFor="let serviceSummary of activeTab.content.serviceSummaries" class="tabs__grid-item">
          <!-- FIXME Implement soundly producing type ServiceSummaries.
          Even though the serviceSummary is of type ServiceSummary, it is produced by a series of functions
          whose contents and structure are unknown/any. For example, the any addTab's item, subsequently directly
          pushed into serviceSummaries by `producePortTab`, does not guarantee to correctly follow ServiceSummary's
          structure.

          This is also why we check for serviceSummary.product's presence before using it
          -->
          <service-summary
            [parent]="service()"
            [service]="serviceSummary.port ? serviceSummary.port : serviceSummary"
            [portId]="serviceSummary.port?.subscriptionId"
            [subscriptionInstanceId]="serviceSummary.subscriptionInstanceId"
            [vlanranges]="serviceSummary.vlanrange ? [serviceSummary.vlanrange] : []"
            [showTraffic]="activeTab.content.showTrafic"
            [showTrafficButton]="
              (
                (serviceSummary.product
                  && (serviceSummary.product.type === 'L2VPN' || serviceSummary.product.type === 'L3VPN'))
                || serviceSummary.port
                || serviceSummary.l2Endpoints
                || serviceSummary.l3Endpoints
              ) ?
                true
              : false
            "
            [forceTrafficBottom]="activeTab.content?.configurationDetails.rows.length > 0"
            style="width: 100%"
          />
        </div>

        <div *ngIf="activeTab.content.configurationDetails.rows.length > 0" class="tabs__grid-item">
          <div class="tabs__flex-center">
            <h3>{{ activeTab.content.configurationDetails.title }}</h3>
          </div>
          <div class="tabs__table">
            <div *ngFor="let row of activeTab.content.configurationDetails.rows" class="tabs__table-row">
              <div class="tabs__table-col">{{ row.title }}</div>

              <div *ngIf="!row.display || row.display === 'default'" class="tabs__table-col">
                {{ row.scalarValue | enabled_or_value }}
              </div>
              <div *ngIf="auth.isSelfServiceEnabled && row.display === 'bgp-component'" class="tabs__table-col">
                <span
                  *ngIf="isInfrastructureDelegate() && !firewallEnabled(); else readOnly"
                  class="table-properties__flex"
                  [ngClass]="{
                    'table-properties__flex--blue': row.objectValue.bfd === true,
                    'table-properties__flex--disabled': false,
                  }"
                >
                  {{ row.objectValue.bfd | enabled_or_value }}
                  <selfservice-toggle
                    [active]="row.objectValue.bfd === true"
                    [tooltipText]="'Selfservice.Tooltip.BFD' | translate"
                    (click)="openSelfserviceDialog('bfd')"
                  ></selfservice-toggle>
                </span>
                <ng-template #readOnly> {{ row.objectValue.bfd | enabled_or_value }} </ng-template>
              </div>
              <div *ngIf="row.display === 'textbox' && row.arrayValue" class="tabs__table-col">
                <div class="tabs__overflow-box">
                  <div *ngFor="let value of row.arrayValue" class="tabs__overflow-box-item">{{ value }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  @if (!service().product || tabs.length === 0) {
    <div class="card card--no-padding">
      <empty-state [title]="'ServiceElement.NoConnectedNetworks' | translate" [icons]="['ip', 'l2vpn']"></empty-state>
    </div>
  }
</div>
