import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "terminated-card",
  templateUrl: "terminated-card.html",
  styleUrls: ["terminated-card.scss"],
  imports: [AngularSvgIconModule, TranslateModule],
})
export class TerminatedCardComponent {
  date = input.required<string>();
}
