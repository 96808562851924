import { Component, input } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "service-element-tags",
  templateUrl: "service-element-tags.html",
  imports: [AngularSvgIconModule],
})
export class ServiceElementTagsComponent {
  tags = input.required<string[]>();
  firewallEnabled = input.required<boolean>();
}
