<div class="timeline-footer">
  @if (showEmptyState()) {
    <div class="timeline-footer__center">
      <svg-icon class="timeline-footer__clock" src="assets/images/icons/icon-nothing-found.svg"></svg-icon>
      <div class="timeline-footer__title">{{ "Malfunction.TimelineFooterTitle" | translate }}</div>
      <div class="timeline-footer__text">{{ "Malfunction.TimelineFooterText" | translate }}</div>
    </div>
  } @else {
    <div class="timeline-footer__center">
      @if (hasMoreResults()) {
        <button class="button button--secondary" (click)="loadMore()">
          {{ "Malfunction.TimelineFooterLoadMore" | translate }}
        </button>
      } @else {
        <div class="timeline-footer__title">-</div>
      }
      <div class="timeline-footer__infoline">
        <svg-icon class="timeline-footer__infoline-icon" src="assets/images/icons/icon-info.svg"></svg-icon>
        <div>{{ "Malfunction.TimelineFooterText" | translate }}</div>
      </div>
    </div>
  }
</div>
