<div class="card card--no-padding">
  <div class="subscription-detail-container__wrapper">
    <div class="subscription-detail-container__part">
      <div class="subscription-detail-container__header">
        <div class="subscription-detail-container__header-flex">
          <h3>{{ "Service.ActiveServices" | translate }}</h3>
          <div class="tags">
            <div *ngFor="let tag of subscription?.tags" class="tag">{{ tag }}</div>
          </div>
        </div>
        <div v-if="subscription?.subscriptionId">
          <port-visualiser
            [subscription]="subscription"
            [barsWillRoute]="false"
            (onSelected)="OnClickBar($event)"
            [highlightedSubscriptionId]="activeSubscription?.subscriptionId"
          >
          </port-visualiser>
        </div>
      </div>
      <div class="subscription-detail-container__list">
        <!-- sub.product.tag.startsWith('MSC')? activeSubscription?.subscriptionId === sub.subscriptionId  : activeSubscription?.portSubscriptionInstanceId === sub.portSubscriptionInstanceId -->
        @for (sub of services; track sub.portSubscriptionInstanceId) {
          <div
            class="subscription-detail-container__item"
            [ngClass]="{ active: sub.portSubscriptionInstanceId === activeSubscription?.portSubscriptionInstanceId }"
            (click)="setActiveSubscription(sub)"
          >
            <div class="subscription-detail-container__item-left" *ngIf="sub.product.type !== 'FW'">
              <div
                class="subscription-detail-container__icon subscription-detail-container__icon--{{
                  sub.product.type | lowercase
                }}"
              >
                <svg-icon src="assets/images/icons/{{ sub.product.type ?? 'Port' | lowercase }}.svg"> </svg-icon>
              </div>
              <span class="subscription-detail-container__item-title">{{
                sub.customerDescription ?? sub.description
              }}</span>
            </div>
            <div class="subscription-detail-container__item-left" *ngIf="sub.product.type === 'FW'">
              <div class="subscription-detail-container__icon subscription-detail-container__icon--ip">
                <svg-icon src="assets/images/icons/ip.svg"> </svg-icon>
                <span class="subscription-detail-container__icon-overlap-square">
                  <svg-icon
                    src="assets/images/icons/fw-overlap.svg"
                    class="subscription-detail-container__overlap-icon"
                  >
                  </svg-icon>
                </span>
              </div>
              <span class="subscription-detail-container__item-title">{{
                sub.customerDescription ?? sub.description
              }}</span>
            </div>
            <div class="subscription-detail-container__item-right">
              <span *ngIf="sub.product.tag !== 'MSC'"> {{ sub.speed | service_speed }} </span>
              <span *ngIf="sub.product.tag === 'MSC'">-</span>
              <svg-icon src="assets/images/icons/arrow-small-right.svg"></svg-icon>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="subscription-detail-container__part subscription-detail-container__part--right">
      <div *ngIf="activeSubscription" class="subscription-detail-container__animate">
        <service-summary
          [service]="activeSubscription"
          [showTraffic]="true"
          [vlanranges]="activeSubscriptionVlanranges"
          [portId]="activeSubscription.product.type === 'Port' ? activeSubscription.subscriptionId : null"
        />
      </div>
    </div>
  </div>
</div>
