import * as Highcharts from "highcharts";
import HC_nodata from "highcharts/modules/no-data-to-display";
import HC_export from "highcharts/modules/exporting";
import HC_exportdata from "highcharts/modules/export-data";
import HC_networkgraph from "highcharts/modules/networkgraph";
import HC_offline from "highcharts/modules/offline-exporting";

export const extendHighcharts = (hcInstance: typeof Highcharts) => {
  HC_nodata(hcInstance);
  HC_export(hcInstance);
  HC_exportdata(hcInstance);
  HC_networkgraph(hcInstance);
  HC_offline(hcInstance);

  hcInstance.setOptions({
    time: {
      timezone: "Europe/Amsterdam",
      useUTC: false,
    },
    lang: {
      noData: "Geen data beschikbaar",
    },
  });
};
