<div class="combined-service-tile card card--flex">
  <header class="combined-service-tile__header">
    <h3 class="combined-service-tile__title">{{ "Overview.CombinedServiceTile.Title" | translate }}</h3>
    <p class="combined-service-tile__description">{{ servicesCount() }} services</p>
  </header>

  <div class="combined-service-tile__content">
    <ul class="combined-service-tile__list">
      @for (subscription of subscriptions() | keyvalue; track $index) {
        <li class="combined-service-tile__list-item">
          <div
            class="combined-service-tile__list-item-inner"
            [ngClass]="{ 'no-subscriptions': !subscription.value.subscriptions }"
            (click)="serviceClick(subscription)"
          >
            <span class="service-tile__icon-container service-tile__{{ subscription.key | lowercase }}">
              <svg-icon
                src="assets/images/icons/{{ subscription.key | lowercase }}.svg"
                class="service-tile__icon"
              ></svg-icon>
              @if (subscription.value.firewallsEnabled > 0) {
                <span class="service-tile__overlap">
                  <span class="service-tile__overlap-square">
                    <div class="service-tile__overlap-tooltip">
                      {{ subscription.value.firewallsEnabled }} {{ "Subscription.FW.Tooltip" | translate }}
                    </div>
                    <svg-icon src="assets/images/icons/fw-overlap.svg" class="service-tile__overlap-icon"></svg-icon>
                  </span>
                </span>
              }
            </span>
            <div>
              <h3>{{ "Subscription." + subscription.key + ".Name.Tile" | translate }}</h3>
              @if (subscription.value.subscriptions; as count) {
                <div>{{ "Overview.Services" | translate: { count: count } }}</div>
              }
              @if (subscription.value.available) {
                <div class="blue">{{ "Global.MoreInfo" | translate }}</div>
              } @else if (!subscription.value.subscriptions) {
                <div class="combined-service-tile__loader"></div>
              }
            </div>
          </div>
        </li>
      }
    </ul>
  </div>

  <div class="combined-service-tile__footer">
    <div class="combined-service-tile__health-indicators" [ngClass]="{ 'no-services': noServices() }">
      @for (healthIndicator of healthIndicators; track $index) {
        <div class="combined-service-tile__health-indicators-item">
          <svg-icon
            src="assets/images/icons/{{ healthIndicator.icon }}.svg"
            matTooltip="{{ healthIndicator.tooltip | translate }}"
            [matTooltipPosition]="'above'"
          >
          </svg-icon>
          <span>{{ healthIndicator.count }}</span>
        </div>
      }
    </div>
  </div>
</div>
