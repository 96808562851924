<div class="dialog dialog-tickets">
  <div>
    <div class="dialog__header">
      <h3 class="dialog__title">{{ "Dialog.Tickets.Title" | translate }}</h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" mat-dialog-close></svg-icon>
    </div>
    <div class="dialog-tickets__body">
      <div class="dialog-tickets__top">
        <p>{{ "Dialog.Tickets.Description" | translate }}</p>
      </div>

      <div class="dialog-tickets__table">
        <div class="dialog-tickets__table-row">
          <div>{{ "Dialog.Tickets.Name" | translate }}</div>
          <div>ID</div>
          <div>{{ "Dialog.Tickets.Startdate" | translate }}</div>
          <!-- <div>{{ 'Dialog.Tickets.Prio' | translate }}</div> -->
          <div>Status</div>
        </div>
        <div
          *ngFor="let message of data.serviceMessages; let i = index"
          class="dialog-tickets__table-row"
          [matTooltip]="'Ticket beschrijving:\n ' + message.summary"
          [matTooltipPosition]="'above'"
        >
          <div>{{ message.heading }}</div>
          <div>{{ message.id }}</div>
          <div>{{ formattableDate(message.start_timestamp) | date: "d MMM HH:mm" }}</div>
          <!-- <div>
            <div
              class="dialog-tickets__priority dialog-tickets__priority--{{message.urgency}}"
            >
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div> -->
          <div>{{ message.status }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
