import { Component, inject, input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogSendConfigComponent } from "../../../../dialogs/dialog-send-config/dialog-send-config";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "send-config-item",
  templateUrl: "send-config-item.html",
  styleUrls: ["../../shortcut-menu.scss"],
  imports: [TranslateModule, AngularSvgIconModule],
})
export class SendConfigItem {
  private dialog = inject(MatDialog);

  protected subscription = input.required();

  openSendConfigDialog() {
    this.dialog.open(DialogSendConfigComponent, {
      data: {
        subscription: this.subscription(),
      },
    });
  }
}
