<div class="page-tab">
  <div class="page-tab__dividers">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="page-tab__buttons">
    <button class="page-tab__button" (click)="selectConfigTab()" [ngClass]="{ active: isConfigTabActive() }">
      {{ "Tabs.ConfigAndStats" | translate }}
    </button>
    @if (isFirewall()) {
      <button
        class="page-tab__button"
        (click)="selectSystemStatsTab()"
        [ngClass]="{ active: isSystemStatsTabActive() }"
      >
        {{ "Tabs.SystemStats" | translate }}
      </button>
    }
    <button class="page-tab__button" (click)="selectGraphicViewTab()" [ngClass]="{ active: isGraphicViewTabActive() }">
      {{ "Tabs.GraphicView" | translate }}
    </button>
  </div>
</div>
