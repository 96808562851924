import dayjs from "dayjs";
import { get_customer_description, to_message_subscription, Urgency } from "./misc";
import { translate } from "./tags";
import { SurfNotification } from "../../components/models/notification";
import { Notification } from "../../gql/generated";
import { CimImpactType, CimStatus } from "../../graphql/custom-types";

export const PlannedWorkTitle = {
  nl: "NEDERLANDS KORT",
  en: "ENGELS KORT",
};

export const PlannedWorkContent = {
  nl: "NEDERLANDS UITGEBREID",
  en: "ENGELS UITGEBREID",
};

const LOG = {
  nl: "NL",
  en: "ENG",
};

export const CimCategoryToPlannedWorkCategory = {
  PLANNEDWORK: "PlannedWork",
  MALFUNCTION: "Malfunction",
  INCIDENT: "Malfunction",
};

// These are still used by filterCimAndPlannedWork.
// CIM already uses GraphQL filters for this, but
// keeping it as long as some tickets are still fetched
// through the subscription
const SkipImpactTypes = [CimImpactType.NONE];
const SkipStatuses = [CimStatus.ABORTED];

export const transformImpactType = (impactType: string) => impactType.replace("_", " ");

// eslint-disable-next-line arrow-body-style
export const sortNotifications = (notifications: any[]): any[] => {
  return notifications.sort((a, b) => {
    const categoryValues = { Malfunction: 1, PlannedWork: 2, General: 3 };
    const urgencyValues = { high: 1, low: 2 };

    const aCategory = a.category;
    const bCategory = b.category;
    const aUrgency = a.urgency;
    const bUrgency = b.urgency;

    if (aCategory !== bCategory) {
      return categoryValues[aCategory] < categoryValues[bCategory] ? -1 : 1;
    } else {
      return urgencyValues[aUrgency] < urgencyValues[bUrgency] ? -1 : 1;
    }
  });
};

/**
 * Transform a groomed piece of plannedwork into a SurfNotification.
 * Enrich the notification with category, urgency, status, tags, etc.
 *
 * @param work any
 * @param customerId string
 * @param lang string
 * @returns SurfNotification
 */
export const transform = (work: any, customerId: string, lang: string = "nl") => {
  const start_datetime = dayjs(work.startDateTime);
  const category = ["Malfunction", "Incident"].includes(work.category) ? "Malfunction" : "PlannedWork";
  const threshold = dayjs().subtract(7, "day");
  const start_in_less_than_7_days = start_datetime.isAfter(threshold);

  const affected = (work.affected_subscriptions || []).map((affectedSub) =>
    to_message_subscription(work, customerId, affectedSub),
  );
  const downtime_types = affected.map((affectedSub) => affectedSub.impact_type);
  const impact = downtime_types.join(" & ");
  const title = work.customerInfo.find((info) => info.label === PlannedWorkTitle[lang]);
  const contents = work.customerInfo.find((info) => info.label === PlannedWorkContent[lang]);

  return Object.assign(new SurfNotification(), {
    id: work.id,
    category,
    heading: `${work.category} - ${work.name}`,
    summary: title?.contents,
    contents: contents?.contents,
    plan_timestamp: work.plannedTime,
    start_timestamp: work.startDatetime,
    end_timestamp: work.endDatetime,
    urgency: start_in_less_than_7_days ? Urgency.HIGH : Urgency.NORMAL, // or high, if starting within 7 days
    status: work.status,
    impact,
    tags: [category, ...downtime_types],
    affected_subscriptions: affected, // make objects
    updates: work.logs?.filter((log) => log.language === LOG[lang]).map((update) => transformWorkUpdate(update)), // based on customer language
    cursor: "cursor",
    // timestamp: new Date().getTime(),
    impact_type: impact,
  });
};

const transformWorkUpdate = (update: any) => ({
  language: update.language,
  timestamp: update.entryDatetime,
  description: update.logging,
});

/**
 * Temporary function to convert CIM notifications to a SurfNotification.
 * This should be changed once the old notifications are phased out.
 *
 * @param cim a Notification object from graphql
 * @returns an object usable by the SurfNotification class
 */
export const cimToPlannedWork = (cim: Partial<Notification>, convertTimestamps = true, lang = "nl") => {
  // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
  const updates = cim.updates.filter((update) => update.language === LOG[lang]);
  return {
    ...cim,
    category: CimCategoryToPlannedWorkCategory[cim.category],
    contents: updates[0]?.description || "-",
    plan_timestamp: convertTimestamps ? dayjs(cim.planTimestamp).unix() * 1000 : cim.planTimestamp,
    start_timestamp: convertTimestamps ? dayjs(cim.startTimestamp).unix() * 1000 : cim.startTimestamp,
    end_timestamp: convertTimestamps ? dayjs(cim.endTimestamp).unix() * 1000 : cim.endTimestamp,
    // field 'timestamp' added to prevent many duplicate sorts because of different keys
    timestamp: convertTimestamps ? dayjs(cim.planTimestamp).unix() * 1000 : cim.planTimestamp,
    affected_subscriptions: cim.affectedSubscriptions?.map((affectedSub) => ({
      subscription_id: affectedSub.subscriptionId,
      description: affectedSub.subscription.description,
      customer_description: get_customer_description(
        localStorage.getItem("viewingCustomerGUID"),
        affectedSub.subscription,
      ),
      impact_type: transformImpactType(affectedSub.impactType),
      name: affectedSub.subscription.product.name,
      product_type: affectedSub.subscription.product.type,
    })),
    cursor: "cursor",
    impact_type: transformImpactType(cim.impact),
    impact: transformImpactType(cim.impact),
    urgency: Urgency[cim.urgency],
    tags: translate("NOTIFICATION", cim.tags),
    _updates: updates,
    updates: updates.slice(1),
  };
};

/**
 * Merge CIM and ims notifications to a uniform list.
 *
 * @param cim new CIM notifications
 * @param plannedWork current IMS planned work
 * @returns list of items resembling SurfNotification
 */
export const mergeCimAndPlannedWork = (cim: Partial<Notification>[], plannedWork: any[]) => {
  const cimNotifications = cim
    .filter((cimNotification) => !SkipStatuses[cimNotification.status] && !SkipImpactTypes[cimNotification.impact])
    .map((cimNotification) => cimToPlannedWork(cimNotification));
  // prefer CIM notifications over IMS tickets (if they exist in both).
  const cimNotificationIds = cim.map((cimNotification) => cimNotification.id);
  const workItems = plannedWork.filter((pw) => !cimNotificationIds.includes(pw.id));
  return [...cimNotifications, ...workItems];
};

/**
 * Filter out unwanted notifications (usually status not in open|closed|updated)
 */
export const filterCimAndPlannedWork = (notifications: any[], keepStatuses: any[]): Partial<SurfNotification>[] => {
  const filteredNotifications = notifications
    .filter((notification) => !keepStatuses.includes(notification.status))
    .filter((notification) => notification.impact_type !== "No impact");
  return filteredNotifications;
};

/**
 * Generator to flatten the plannedwork entries for a single subscription.
 *
 * @param subscription CustomerDescription
 */
function* allPlannedWork(subscription: any) {
  for (const circuit of subscription.imsCircuits) {
    for (const work of circuit.plannedWork) {
      // customersInformed is true for old (not CIM) notifications.
      // these will be phased out.
      if (work.customersInformed) {
        yield work;
      }
    }
  }
}

/**
 * Generator to flatten the plannedwork entries
 * in a list of subscriptions.
 *
 * @param subscriptions CustomerDescription[]
 */
function* allPlannedWorkForSubscriptions(subscriptions: any[]) {
  for (const subscription of subscriptions) {
    for (const work of allPlannedWork(subscription)) {
      yield work;
    }
  }
}

/**
 * Generator to transform an array into
 * an array in which each entry is unique.
 * Could have done this with [...new Set(list)] maybe.
 * The `key` argument is used to check uniqueness.
 *
 * @param list any[]
 * @param key string
 */
function* uniqueEver(list: any[], key: string) {
  const seen = [];
  for (const item of list) {
    if (!seen.includes(item[key])) {
      seen.push(item[key]);
      yield item;
    }
  }
}

/**
 * Fetch the plannedwork entries for the given subscriptions.
 * Return a list of unique work items with their affected subscriptions.
 *
 * @param subscriptions CustomerDescription[]
 * @returns any[]
 */
export const getPlannedWork = (subscriptions: any[]) => {
  const getAffectedSubscriptions = (work: any) => {
    const affectsSubscription = (subscription: any): boolean => {
      const workList = [...allPlannedWork(subscription)];
      return workList.find((w) => w.id === work.id) !== undefined;
    };

    const affectedSubscriptions = subscriptions.filter((subscription) => affectsSubscription(subscription));
    return {
      planned_work: work,
      affected_subscriptions: affectedSubscriptions,
    };
  };

  const plannedWork = [...allPlannedWorkForSubscriptions(subscriptions)];
  const uniquePlannedWork = [...uniqueEver(plannedWork, "id")];
  return uniquePlannedWork.map((work) => getAffectedSubscriptions(work));
};

/**
 * From a list of customersubscriptions, distill the
 * unique plannedwork entries. Enrich the work entries
 * with affected services and other usefulness.
 *
 * @param subscriptions CustomerSubcription[]
 * @param customerId string
 * @returns SurfNotification[]
 */
export const subscriptionsToMessages = (subscriptions: any[], customerId: string) => {
  const plannedWork = getPlannedWork(subscriptions);

  return plannedWork.map((work) =>
    transform({ ...work.planned_work, affected_subscriptions: work.affected_subscriptions }, customerId),
  );
};

export const singleSubscriptionToMessages = (subscription: any, customerId: string) => {
  switch (subscription.product?.type) {
    case "Wireless":
      return getWirelessMessages(subscription);
    default:
      return subscriptionsToMessages([subscription], customerId);
  }
};

/**
 * Transform a wireless issue into a SurfNotification.
 *
 * @param issue any
 * @return SurfNotification
 */
export const toWirelessMessage = (ticket: any) => {
  const updates = ticket.comments;
  return Object.assign(new SurfNotification(), {
    id: ticket.id,
    heading: ticket.summary,
    summary: ticket.description ?? "-",
    category: "General",
    urgency: Urgency.NORMAL,
    status: ticket.status,
    impact: ticket.impact,
    tags: ["WaaS", "SURFWireless"],
    updates,
    affected_subscriptions: [],
    start_timestamp: ticket.startDate,
  });
};

/**
 * Retrieve the wireless messages for a subscription.
 * Coerce the messages into tickets.
 *
 * @param subscription CustomerDescription
 * @return SurfNotification[]
 */
export const getWirelessMessages = (subscription: any) => {
  const issueNotClosed = (ticket: any) => ticket.status !== "Closed";
  const issues = subscription.wifiLocation.detail.tickets || [];

  return issues.filter((issue) => issueNotClosed(issue)).map((issue) => toWirelessMessage(issue));
};
