@if (!unavailable()) {
  <div>
    <div class="port__items-container">
      @for (service of subscription().services; track service.portSubscriptionInstanceId) {
        <span
          class="port__items-bar service-{{ service.product.type | lowercase }}"
          [style.width.%]="service.percentage"
          matTooltip="{{ service.customerDescription ?? service.description }}"
          [matTooltipPosition]="'above'"
          (click)="clickBar(service)"
          [ngClass]="{
            active: service.subscriptionId === highlightedSubscriptionId(),
            'disable-animation': !barsWillRoute,
          }"
        >
        </span>
      }
    </div>
    <div class="port__items-footer">
      <span class="port__items-footer port__items-footer-left">
        <span class="port__items-footer-left-notransform"> {{ amountReserved() | service_speed }} </span>&nbsp;{{
          "ServiceElement.Reserved" | translate
        }}
      </span>
      <span class="port__items-footer port__items-footer-right">
        <span> {{ bookingPercentage() }}% {{ "ServiceElement.BookingsPercentage" | translate }} </span>
      </span>
    </div>
  </div>
} @else {
  <div class="service__unavailable-bar">
    <span class="service__unavailable-bar-side">
      <svg-icon class="service__unavailable-bar-icon" src="assets/images/icons/lock.svg" />
    </span>
    <span class="service__unavailable-bar-text">
      <strong>{{ "Global.NoAccess" | translate }}</strong> {{ "Global.PleaseContact" | translate }}
      {{ subscription().customer.fullname }}
    </span>
    <span class="service__unavailable-bar-side"></span>
  </div>
}
