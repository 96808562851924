<div class="dialog__body dialog__body--no-padding dialog__body--grow dialog__body--grey">
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ "Selfservice.Global.Title" | translate }}</h3>
    <p class="no-margin">{{ "Selfservice.DDOS.Text" | translate }}</p>
    <div *ngIf="errorMessage" class="dialog__error">{{ errorMessage }}</div>
  </div>

  <div *ngIf="hasErrors" class="dialog__error dialog__error--margin-x">
    <ul>
      <li *ngFor="let e of errors">{{ e.msg }}</li>
    </ul>
  </div>

  <div class="dialog__rule">
    <h3 class="dialog__sub-heading">{{ "Selfservice.Global.CurrentSetting" | translate }} DDoS Auto mitigation</h3>
    <div class="dialog__rule-answer">{{ mitigationState | enabled_or_value }}</div>
  </div>
  <div class="dialog__rule">
    <h3 class="dialog__sub-heading">{{ "Selfservice.Global.NewSetting" | translate }} DDoS Auto mitigation</h3>
    <div class="dialog__rule-answer blue">{{ newMitigationState | enabled_or_value }}</div>
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  <div class="button__flex">
    <button class="button button--secondary" (click)="emitCloseDialog()">
      {{ "Global.Action.Cancel" | translate }}
    </button>
    <button class="button" (click)="submit()" [ngClass]="{ loading: buttonLoading }">
      <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
      <span>{{ "Global.Action.Confirm" | translate }}</span>
    </button>
  </div>
</div>
