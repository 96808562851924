import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "[third-party-service-ids-row-content]",
  templateUrl: "third-party-service-ids-row-content.html",
  imports: [TranslateModule],
})
export class ThirdPartyServiceIdsRowContentComponent {
  subscription = input.required<{ references: { name: string; externalId: string }[] }>();
}
