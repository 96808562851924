import { humanizeMbit } from "../../../helpers/bitspipe/bitspipe";
import { getPortId, toPortImsInfo, toPortMode, transformShared } from "../port";

export const transformSp = (subscription: any) => {
  function* getTags() {
    yield humanizeMbit(subscription.portSpeed);
    yield toPortMode(subscription.port.portMode);
    yield subscription.domain;
  }

  const shared = transformShared(subscription, getTags());
  const imsDetails = toPortImsInfo(subscription);

  return {
    ...shared,
    name: subscription.port?.name,
    portSpeed: subscription.portSpeed,
    portId: getPortId(subscription.domain, subscription.port),
    portMode: toPortMode(subscription.port?.portMode),
    autoNegotiation: subscription.port?.autoNegotiation || "N/A",
    subscriptionInstanceId: subscription.port?.subscriptionInstanceId,
    port: {
      portMode: toPortMode(subscription.port?.portMode),
    },
    ...imsDetails,
  };
};
