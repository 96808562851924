import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { IrbSubscriptionDetailsType } from "../../../gql/generated-ingestor";
import {
  AggspSubscriptionDetails,
  MscSubscriptionDetails,
  ServicePortSubscriptionDetails,
  ServiceType,
} from "../../../pages/subscription-detail/types";
import { TranslateModule } from "@ngx-translate/core";
import { PortVisualiser } from "../port-visualiser/port-visualiser";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CommonModule, LowerCasePipe } from "@angular/common";
import { ServiceSpeedPipe } from "../../../pages/subscription-detail/components/service-speed-pipe/service-speed-pipe";
import { ServiceSummaryComponent } from "../service-summary/service-summary";

@Component({
  selector: "subscription-detail-container",
  templateUrl: "subscription-detail-container.html",
  imports: [
    AngularSvgIconModule,
    CommonModule,
    LowerCasePipe,
    ServiceSpeedPipe,
    ServiceSummaryComponent,
    TranslateModule,
    PortVisualiser,
  ],
})
export class SubscriptionDetailContainer implements OnChanges {
  @Input() subscription:
    | ServicePortSubscriptionDetails
    | MscSubscriptionDetails
    | AggspSubscriptionDetails
    | IrbSubscriptionDetailsType;
  @Input() productType: string;
  @Input() services: ServiceType[] = [];
  @Input() productTag: string;
  public activeSubscription: ServiceType;
  public customerGuid: string;
  public activeSubscriptionVlanranges: any[] = [];

  constructor(public subscriptionService: SubscriptionService) {
    this.customerGuid = localStorage.getItem("viewingCustomerGUID");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.subscription || this.subscription.services === undefined) {
      return;
    }
    // @todo update when linked-subscription data is available
    this.services = this.subscription.services;
    if (!this.activeSubscription && Array.isArray(this.services) && this.services.length > 0) {
      this.activeSubscription = this.services[0];
      this.getVlanIds();
    }
  }

  setActiveSubscription(sub) {
    this.activeSubscription = null;

    setTimeout(() => {
      this.activeSubscription = sub;
      this.getVlanIds();
    }, 1);
  }

  getVlanIds() {
    if (!this.activeSubscription) {
      return;
    }
    this.activeSubscriptionVlanranges = [this.activeSubscription.vlanrange];
  }

  OnClickBar(subscriptionId) {
    const findSubscription = this.services.find((x) => x.subscriptionId === subscriptionId);
    this.setActiveSubscription(findSubscription);
  }
}
