<div class="dialog__body dialog__body--grow dialog__body--grey dialog__body--center">
  <div class="selfservice-loader">
    @if (!errorState) {
      <img src="assets/images/configuration-illustration.svg" alt="" />
      <h3 class="selfservice-loader__title">
        <span>{{ "Selfservice.Global.LoaderTitle" | translate }}</span>
      </h3>
      <p>{{ "Selfservice.Global.LoaderText" | translate }}</p>
      <div class="selfservice-loader__bar">
        <div class="selfservice-loader__bar-progress" [style.width.%]="progress"></div>
      </div>
    } @else {
      <img src="assets/images/outofsync-illustration.svg" alt="" />
      <h3 class="selfservice-loader__title">
        <span>{{ "Selfservice.Global.ErrorOccurredTitle" | translate }}</span>
      </h3>
      <p>{{ "Selfservice.Global.ErrorOccurredText" | translate }}</p>
    }
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  @if (errorState) {
    <div class="button__flex">
      <button class="button button--secondary" (click)="notifySupport()" [ngClass]="{ loading: buttonLoading }">
        <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
        <span>{{ "Global.Action.NotifySupport" | translate }}</span>
      </button>
      @if (!loading) {
        <button class="button" (click)="close()">
          <span>{{ "Global.Action.Close" | translate }}</span>
        </button>
      }
    </div>
  }
</div>
