<div class="dialog dialog--small dialog-ip-prefixes-request">
  <div class="dialog__header">
    <h3 class="dialog__title">{{ "OverflowMenu.RequestPrefix" | translate }}</h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" mat-dialog-close></svg-icon>
  </div>
  <div class="dialog-ip-prefixes-request__body">
    @if (activeStep() === 1) {
      <section>
        <div class="form-label">{{ "Dialog.IPP.ChooseVersion" | translate }}</div>
        <div class="dialog-ip-prefixes-request__radios">
          <label>
            <input type="radio" name="ipv" value="4" (change)="IPVChanged()" [(ngModel)]="model.ipv" />
            <div>IPv4</div>
          </label>
          <label>
            <input type="radio" name="ipv" value="6" (change)="IPVChanged()" [(ngModel)]="model.ipv" />
            <div>IPv6</div>
          </label>
        </div>
        <br />
        <div class="form-label">{{ "Dialog.IPP.AddressSpace" | translate }}</div>
        <p>{{ "Dialog.IPP.AddressSpaceText" | translate }}</p>
        <div class="select-wrapper">
          <select name="priority" [(ngModel)]="model.addressSpace">
            <option *ngFor="let address of possibleAddresses" [value]="address">{{ address }}</option>
          </select>
          <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
        </div>

        <br />
        <div class="form-label">{{ "Dialog.IPP.NumberplanUpload" | translate }}</div>
        <div class="select-wrapper">
          <input type="file" id="numberplanUpload" name="numberplanUpload" (change)="onFileSelected($event)" />
        </div>
      </section>
    }

    @if (activeStep() === 2) {
      <section>
        <div class="form-label">{{ "Dialog.IPP.Motivation" | translate }}</div>
        <p>{{ "Dialog.IPP.MotivationText" | translate }}</p>
        <br />
        <div class="form-label">{{ "Dialog.IPP.AddressSpaceReason" | translate }}</div>
        <div class="textarea__wrapper">
          <textarea
            class="textarea"
            placeholder="{{ 'Dialog.IPP.DescriptionPlaceholder' | translate }}"
            [(ngModel)]="model.addressSpaceReason"
          ></textarea>
        </div>
      </section>
    }

    @if (activeStep() === 3) {
      <section>
        <div class="form-label">{{ "Dialog.IPP.Amount" | translate }}</div>
        <div class="textarea__wrapper">
          <textarea
            class="textarea"
            placeholder="{{ 'Dialog.IPP.DescriptionPlaceholder' | translate }}"
            [(ngModel)]="model.ippAmount"
          ></textarea>
        </div>
        <br />
        <div class="form-label">{{ "Dialog.IPP.AddressSpaceReasonWhyNot" | translate }}</div>
        <div class="textarea__wrapper">
          <textarea
            class="textarea"
            placeholder="{{ 'Dialog.IPP.DescriptionPlaceholder' | translate }}"
            [(ngModel)]="model.addressSpaceReasonWhyNot"
          ></textarea>
        </div>
      </section>
    }

    @if (activeStep() === 4) {
      <section>
        <div class="form-label">{{ "Dialog.IPP.GrowthExpected" | translate }}</div>
        <div class="textarea__wrapper">
          <textarea
            class="textarea"
            placeholder="{{ 'Dialog.IPP.DescriptionPlaceholder' | translate }}"
            [(ngModel)]="model.ippGrowthExpected"
          ></textarea>
        </div>
        <br />
        <div class="form-label">{{ "Dialog.IPP.IPV6" | translate }}</div>
        <div class="textarea__wrapper">
          <textarea
            class="textarea"
            placeholder="{{ 'Dialog.IPP.DescriptionPlaceholder' | translate }}"
            [(ngModel)]="model.ippIPV6"
          ></textarea>
        </div>
      </section>
    }

    @if (activeStep() === 5) {
      <section>
        <div class="form-label">{{ "Dialog.IPP.Contact" | translate }}</div>
        <p>{{ "Dialog.IPP.ContactText" | translate }}</p>
        <br />
        <label for="" class="form-label">{{ "Global.Name" | translate }}</label>
        <div class="input__wrapper">
          <input
            type="text"
            class="input"
            placeholder="{{ 'Global.Name' | translate }}"
            [(ngModel)]="model.fullName"
            autofocus
          />
        </div>
        <br />
        <label for="" class="form-label">{{ "Global.Email" | translate }}</label>
        <div class="input__wrapper">
          <input
            type="email"
            class="input"
            placeholder="{{ 'Global.Email' | translate }}"
            [(ngModel)]="model.email"
            autofocus
          />
        </div>
        <br />
        <label for="" class="form-label">{{ "Global.Phone" | translate }}</label>
        <div class="input__wrapper">
          <input
            type="text"
            class="input"
            placeholder="{{ 'Global.Phone' | translate }}"
            [(ngModel)]="model.phone"
            autofocus
          />
        </div>
        @if (errorMessage()) {
          <div class="red">{{ errorMessage() }}</div>
        }
      </section>
    }

    @if (activeStep() === 6) {
      <section>
        <div class="dialog-ip-prefixes-request__success">
          <img src="assets/images/success-illustration.svg" alt="" />
          <h3>{{ "Dialog.IPP.RequestSent" | translate }}</h3>
          <p>{{ "Dialog.IPP.RequestSentText" | translate }}</p>
        </div>
      </section>
    }
  </div>

  <div class="dialog__footer dialog__footer--center dialog__footer--border">
    <div></div>
    <div class="button__flex">
      @if (activeStep() === 1) {
        <button class="button button--secondary" mat-dialog-close>
          {{ "Global.Action.Cancel" | translate }}
        </button>
      }
      @if (activeStep() !== 1 && activeStep() !== 6) {
        <button class="button button--secondary" (click)="prev()">
          {{ "Global.Action.Back" | translate }}
        </button>
      }
      @if (activeStep() !== 5 && activeStep() !== 6) {
        <button class="button" (click)="next()">
          {{ "Global.Action.Continue" | translate }}
        </button>
      }
      @if (activeStep() === 5) {
        <button class="button" (click)="submit()">
          {{ "Global.Action.Confirm" | translate }}
        </button>
      }
      @if (activeStep() === 6) {
        <button class="button button--secondary" mat-dialog-close>
          {{ "Global.Action.Close" | translate }}
        </button>
      }
    </div>
  </div>
</div>
