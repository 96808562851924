import { Component, computed, inject, signal } from "@angular/core";
import {
  WirelessHealthDetail,
  WirelessSubscriptionDetailsGQL,
  WirelessSubscriptionDetailsQuery,
} from "../../../../gql/generated-ingestor";
import { RouterModule } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { WirelessSubscriptionDetails } from "../../types";
import { SubscriptionService } from "../../../../services/subscriptionservice";
import { ApolloQueryResult } from "@apollo/client";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { AliasEditorComponent } from "../../../../components/common/alias-editor/alias-editor.component";
import { PageComponent } from "../../../../components/page/page";
import { LinechartComponent } from "../../../../components/subscription-detail/linechart/linechart";
import { ServiceId } from "../../../../components/subscription-detail/service-id/service-id";
import { SubscriptionDetailHeader } from "../../../../components/subscription-detail/subscription-detail-header/subscription-detail-header";
import { SubscriptionDatePipe } from "../../../../helpers/subscription-datepipe/subscription-datepipe";
import { AngularSvgIconModule } from "angular-svg-icon";
import { LoaderComponent } from "../../../../components/common/loader/loader";
import { WirelessShortcutMenuComponent } from "./components/wireless-shortcut-menu/wireless-shortcut-menu";
import { NotFoundComponent } from "../../../../components/common/not-found/not-found";
import { NotAuthorizedComponent } from "../../../../components/common/not-authorized/not-authorized";
import { AccessPointsSectionComponent } from "./components/access-points-section/access-points-section";
import { KpiSectionComponent } from "./components/kpi-section/kpi-section";
import { ChartChoice } from "../../../../components/subscription-detail/linechart/support";

const WIRELESS_CHART_CHOICES: { [key: string]: ChartChoice[] } = {
  AEROHIVE: [
    { key: "dhcp", label: "KPI 1 - DHCP" },
    { key: "voipup", label: "KPI 2 - MOS VoIP Up" },
    { key: "voipdown", label: "KPI 3 - MOS VoIP Down" },
    { key: "throughputup", label: "KPI 4 - Throughput Up" },
    { key: "throughputdown", label: "KPI 5 - Throughput Down" },
    { key: "radius", label: "KPI 6 - Radius" },
  ],
  ARUBA: [
    { key: "wifi_assoc_time", label: "KPI 1 - WiFi Association Time" },
    { key: "dhcp_res_time", label: "KPI 2 - DHCP Response Time" },
    { key: "dns_res_time", label: "KPI 3 - DNS Resolution Time" },
    { key: "voice_quality", label: "KPI 4 - Voice Quality" },
    { key: "throughput_up", label: "KPI 5 - Throughput Up" },
    { key: "throughput_down", label: "KPI 6 - Throughput Down" },
  ],
};

@Component({
  selector: "wireless-subscription",
  templateUrl: "wireless-subscription.html",
  imports: [
    AccessPointsSectionComponent,
    AliasEditorComponent,
    AngularSvgIconModule,
    CommonModule,
    KpiSectionComponent,
    LinechartComponent,
    LoaderComponent,
    NotFoundComponent,
    PageComponent,
    RouterModule,
    ServiceId,
    SubscriptionDatePipe,
    SubscriptionDetailHeader,
    TranslateModule,
    NotFoundComponent,
    NotAuthorizedComponent,
    WirelessShortcutMenuComponent,
  ],
})
export class WirelessSubscriptionComponent extends GenericSubscriptionComponent<
  WirelessSubscriptionDetailsGQL,
  WirelessSubscriptionDetailsQuery,
  WirelessSubscriptionDetails
> {
  protected query = inject(WirelessSubscriptionDetailsGQL);
  private subscriptionService = inject(SubscriptionService);

  protected healthData = signal<WirelessHealthDetail | undefined>(undefined);
  protected chartChoices = signal<ChartChoice[]>([]);

  protected supplier = computed(() => this._subscription().supplier);

  constructor() {
    super();
    this.subscriptionChange.subscribe((s) => this.onSubscriptionChange(s));
  }

  protected onQuerySuccess(result: ApolloQueryResult<WirelessSubscriptionDetailsQuery>): WirelessSubscriptionDetails {
    return result.data.wirelessDetails;
  }

  private onSubscriptionChange(subscription: WirelessSubscriptionDetails) {
    this.chartChoices.set(WIRELESS_CHART_CHOICES?.[subscription.supplier.toUpperCase()] ?? []);

    this.subscriptionService
      .getWirelessHealthDataForSubscription(subscription.subscriptionId)
      .catch((reason) => console.warn("Failed to retrieve wireless health data:", reason))
      // @ts-ignore
      .then((healthData) => this.healthData.set(healthData));
  }
}
