import { CommonModule } from "@angular/common";
import { Component, computed, Inject, signal } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "dialog-cert",
  templateUrl: "dialog-cert.html",
  styleUrls: ["dialog-cert.scss"],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule],
})
export class DialogCertComponent {
  public activeTab = signal("details");
  detailsActive = computed(() => this.activeTab() === "details");
  extraActive = computed(() => this.activeTab() === "extra");

  public extraData: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCertComponent>,
  ) {
    this.setExtraData();
  }

  setExtraData(): void {
    this.extraData = [];
    const makeDataEntry = (header: string, value: string) => ({ header, value });
    if (!this.data?.extra) {
      return;
    }

    for (const key of Object.keys(this.data?.extra)) {
      const value = this.data.extra[key];
      if (typeof value === "string") {
        this.extraData.push(makeDataEntry(key, value));
      } else if (Array.isArray(value)) {
        this.extraData.push(makeDataEntry(key, value.join(", ")));
      }
    }
  }

  changeTab(tab: string) {
    this.activeTab.set(tab);
  }
}
