import { Component, input } from "@angular/core";

import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "availability-export-item",
  templateUrl: "availability-export-item.html",
  styleUrls: ["../../shortcut-menu.scss"],
  imports: [TranslateModule, AngularSvgIconModule, RouterLink],
})
export class AvailabilityExportItem {
  protected subscription = input.required<{ subscriptionId: string; product: { type: string; tag: string } }>();
}
