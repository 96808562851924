<a *ngIf="serviceUrl !== 'Wireless'" (click)="navigateTo()" class="card card--clickable service-tile">
  <div class="card__container">
    <div class="card__container" *ngIf="isLoading === true; else dataIsReady">
      <span class="service-tile__icon-container service-tile__available">
        <svg-icon src="assets/images/icons/{{ serviceIcon }}.svg" class="service-tile__icon"></svg-icon>
      </span>
      <h3 class="service-tile__title">{{ serviceTitle }}</h3>
      <div class="card__content">
        <ngx-skeleton-loader class="card__placeholder-message" count="1"></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="1"
          appearance="circle"
          [theme]="{
            height: '20px',
            width: '20px',
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>

    <ng-template #dataIsReady>
      <span class="service-tile__icon-container service-tile__{{ serviceIcon }}">
        <svg-icon src="assets/images/icons/{{ serviceIcon }}.svg" class="service-tile__icon"></svg-icon>
        <span *ngIf="firewallCount > 0" class="service-tile__overlap">
          <span class="service-tile__overlap-square">
            <div class="service-tile__overlap-tooltip">
              {{ firewallCount }} {{ "Subscription.FW.Tooltip" | translate }}
            </div>
            <svg-icon src="assets/images/icons/fw-overlap.svg" class="service-tile__overlap-icon"></svg-icon>
          </span>
          <span class="service-tile__overlap-text">{{ firewallCount }} firewalls</span>
        </span>
      </span>

      <h3 class="service-tile__title">{{ serviceTitle }}</h3>
      <div class="card__content">
        <span
          *ngIf="serviceUrl !== 'IP_PREFIX' && serviceUrl !== 'cert'"
          [ngSwitch]="serviceMessage && serviceMessage.category"
        >
          <div *ngSwitchCase="'Malfunction'" class="red" (click)="openDialog($event)">
            {{ "Subscription.Message.Malfunction" | translate }}
          </div>
          <div *ngSwitchCase="'PlannedWork'" class="blue" (click)="openDialog($event)">
            {{ "Subscription.Message.PlannedWork" | translate }}
          </div>
          <div *ngSwitchCase="'General'" class="green" (click)="openDialog($event)">
            {{ "Subscription.Message.General" | translate }}
          </div>
          <p *ngSwitchDefault>{{ "Subscription.Message.None" | translate }}</p>
        </span>

        <span *ngIf="serviceUrl === 'IP_PREFIX'">
          <p>{{ subscriptionCount }} {{ "Global.Active" | translate }}</p>
        </span>

        @if (serviceUrl === "cert") {
          <span>
            @if (vulnerabilitiesDetectedCount > 0) {
              <p class="red">{{ vulnerabilitiesDetectedCount }} {{ "Cert.VulnerabilitiesDetected" | translate }}</p>
            } @else {
              <p class="green">{{ "Cert.NoVulnerabilitiesDetected" | translate }}</p>
            }
          </span>
        }

        <div class="service-tile__bottom">
          <div
            target="_blank"
            *ngIf="serviceUrl === 'IP_PREFIX' || serviceUrl === 'cert' || serviceUrl === 'domains'"
            class="service-tile__more-info"
          >
            <strong></strong>{{ "Global.SeeOverview" | translate }}
          </div>
          <div
            class="service-tile__status__flex"
            *ngIf="serviceUrl !== 'IP_PREFIX' && serviceUrl !== 'cert' && serviceUrl !== 'domains'"
          >
            <div class="service-tile__status" *ngFor="let state of healthIndicators | keys | slice: 0 : 3">
              <svg-icon
                src="assets/images/icons/status-{{ state | status_to_icon }}.svg"
                class="service-tile__status__icon service-tile__status__icon--{{ colors[state] }}"
                matTooltip="{{ 'Services.Status.' + (state | state_to_camelcase) | translate }}"
                [matTooltipPosition]="'above'"
              ></svg-icon>
              <div class="service-tile__status__number">{{ healthIndicators[state] }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</a>

<a
  *ngIf="serviceUrl === 'Wireless' && wirelessEnabled"
  (click)="navigateTo()"
  class="card card--clickable service-tile"
>
  <div class="card__container">
    <div class="card__container" *ngIf="isLoading === true; else dataIsReady">
      <span class="service-tile__icon-container service-tile__available">
        <svg-icon src="assets/images/icons/{{ serviceIcon }}.svg" class="service-tile__icon"></svg-icon>
      </span>
      <h3 class="service-tile__title">{{ serviceTitle }}</h3>
      <div class="card__content">
        <ngx-skeleton-loader class="card__placeholder-message" count="1"></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="1"
          appearance="circle"
          [theme]="{
            height: '20px',
            width: '20px',
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>

    <ng-template #dataIsReady>
      <span class="service-tile__icon-container service-tile__{{ serviceIcon }}">
        <svg-icon src="assets/images/icons/{{ serviceIcon }}.svg" class="service-tile__icon"></svg-icon>
      </span>

      <h3 class="service-tile__title">{{ serviceTitle }}</h3>
      <div class="card__content">
        <p>{{ "Subscription.Message.None" | translate }}</p>
        <span> </span>

        <div class="service-tile__bottom">
          <div class="service-tile__status__flex">
            <div class="service-tile__status">
              <svg-icon
                src="assets/images/icons/status-ok.svg"
                matTooltip="Wireless Locations"
                [matTooltipPosition]="'above'"
              ></svg-icon>
              <div class="service-tile__status__number">{{ subscriptionCount }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</a>

<a
  *ngIf="serviceUrl === 'Wireless' && !wirelessEnabled"
  href="https://portal.surfwireless.nl"
  class="card service-tile"
>
  <div class="card__container">
    <div class="card__container" *ngIf="isLoading === true; else dataIsReady">
      <span class="service-tile__icon-container service-tile__available">
        <svg-icon src="assets/images/icons/{{ serviceIcon }}.svg" class="service-tile__icon"></svg-icon>
      </span>
      <h3 class="service-tile__title">{{ serviceTitle }}</h3>
      <div class="card__content">
        <ngx-skeleton-loader class="card__placeholder-message" count="1"></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="1"
          appearance="circle"
          [theme]="{
            height: '20px',
            width: '20px',
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>

    <ng-template #dataIsReady>
      <span class="service-tile__icon-container service-tile__{{ serviceIcon }}">
        <svg-icon src="assets/images/icons/{{ serviceIcon }}.svg" class="service-tile__icon"></svg-icon>
      </span>

      <h3 class="service-tile__title">{{ serviceTitle }}</h3>
      <div class="card__content">
        <span [ngSwitch]="serviceMessage && serviceMessage.category">
          <p *ngSwitchCase="'Malfunction'" class="red link" (click)="openDialog($event)">
            {{ "Subscription.Message.Malfunction" | translate }}
          </p>
          <p *ngSwitchCase="'PlannedWork'" class="blue link" (click)="openDialog($event)">
            {{ "Subscription.Message.PlannedWork" | translate }}
          </p>
          <p *ngSwitchCase="'General'" class="green link">
            {{ "Subscription.Message.General" | translate }}:
            <b>{{ serviceMessageAmount }}</b>
          </p>
          <p *ngSwitchDefault>{{ "Subscription.Message.None" | translate }}</p>
        </span>

        <div class="service-tile__bottom">
          <div class="service-tile__status__flex">
            <div class="service-tile__status" *ngFor="let state of healthIndicators | keys | slice: 0 : 3">
              <svg-icon
                src="assets/images/icons/status-{{ state | status_to_icon }}.svg"
                class="service-tile__status__icon service-tile__status__icon--{{ colors[state] }}"
                matTooltip="{{ 'Services.Status.' + (state | state_to_camelcase) | translate }}"
                [matTooltipPosition]="'above'"
              ></svg-icon>
              <div class="service-tile__status__number">{{ healthIndicators[state] }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</a>
