import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "forti-analyzer-item",
  templateUrl: "forti-analyzer-item.html",
  styleUrls: ["../../shortcut-menu.scss"],
  imports: [TranslateModule, AngularSvgIconModule],
})
export class FortiAnalyzerItem {}
