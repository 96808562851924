<div class="webarchive-calendar">
  @for (month of months; track month) {
    <div>
      <input
        type="text"
        mwlFlatpickr
        [convertModelValue]="true"
        [disableMobile]="true"
        [value]="year + '-' + month"
        [monthSelectorType]="'static'"
        [showMonths]="1"
        [inline]="true"
        (flatpickrDayCreate)="onDayCreate($event)"
        (flatpickrChange)="onDayChange($event)"
      />
    </div>
  }
</div>
