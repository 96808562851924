import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "cert-header",
  templateUrl: "cert-header.html",
  styleUrls: ["cert-header.scss"],
  imports: [TranslateModule],
})
export class CertHeaderComponent {}
