import { Component } from "@angular/core";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { TranslateModule } from "@ngx-translate/core";
import { FooterComponent } from "../../components/common/footer/footer";
@Component({
  selector: "page-forbidden",
  templateUrl: "forbidden.html",
  imports: [FooterComponent, NavigationComponent, TranslateModule],
})
export class ForbiddenPage {}
