import { Component, inject, Input, signal } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { StorageHelper } from "../../../helpers/storage/storagehelper";
import { MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "alias-editor",
  templateUrl: "alias-editor.component.html",
  styleUrls: ["alias-editor.scss"],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, FormsModule],
})
export class AliasEditorComponent {
  protected api = inject(ApiHelper);
  protected dialog = inject(MatDialog);

  @Input() customerDescription: string;
  @Input() subscriptionId: string;

  protected isEditable = signal(false);

  temporarySubscriptionName = "";

  startEditingCustomerDescription(event: Event) {
    event.stopPropagation();
    this.temporarySubscriptionName = this.customerDescription;
    this.isEditable.set(true);
  }

  cancelEvent(event: Event) {
    event.stopPropagation();
  }

  async saveCustomerDescription(event: Event) {
    event.stopPropagation();
    if (this.temporarySubscriptionName.length > 0) {
      this.api
        .set_customer_description(StorageHelper.currentUser, this.subscriptionId, this.temporarySubscriptionName)
        .then((result) => {
          this.customerDescription = this.temporarySubscriptionName;
        })
        .catch((err) => {
          console.log(err);
        });
      this.isEditable.set(false);
    }
  }

  cancelEditingCustomerDescription(event: Event) {
    event.stopPropagation();
    this.isEditable.set(false);
  }
}
