<div class="system-stats__header">
  <div class="chart__header-right">
    <div class="chart__header-right-flex">
      <label for="" class="chart__select-label">
        <span>{{ "Chart.Period" | translate }}</span>
        @if (!defaultDate) {
          <span class="chart__select-reset" (click)="resetDatePickers()">Reset</span>
        }
      </label>
      <div class="chart__period-filter">
        @for (preset of presets; track preset) {
          <button [ngClass]="{ active: selectedPreset === preset }" (click)="setDatePreset(preset)">
            {{ preset }}
          </button>
        }
      </div>
    </div>
    <div class="chart__header-date-inputs">
      <div class="chart__select-wrapper">
        <input
          class="chart__input"
          type="text"
          id="startDate"
          mwlFlatpickr
          [enableTime]="true"
          [dateFormat]="'d-m-Y H:i'"
          [time24hr]="true"
          [convertModelValue]="true"
          [disableMobile]="true"
          [(ngModel)]="firstDate"
          [prevArrow]="'<i></i>'"
          [nextArrow]="'<i></i>'"
          [maxDate]="tomorrow"
          (ngModelChange)="onDateChange()"
        />
        <svg-icon class="chart__select-calendar-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
      </div>
      <span class="chart__header-date-seperator">-</span>
      <div class="chart__select-wrapper">
        <input
          class="chart__input"
          type="text"
          mwlFlatpickr
          [enableTime]="true"
          [dateFormat]="'d-m-Y H:i'"
          [time24hr]="true"
          [convertModelValue]="true"
          [disableMobile]="true"
          [(ngModel)]="secondDate"
          [maxDate]="tomorrow"
          (ngModelChange)="onDateChange()"
        />
        <svg-icon class="chart__select-calendar-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
      </div>
    </div>
  </div>
</div>
