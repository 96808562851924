<header class="header sticky">
  <section class="topbar">
    <div class="container">
      <div class="topbar__flex">
        <a href="" class="logo"></a>
      </div>
    </div>
  </section>
</header>
<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
        <div class="card">
          <h2>{{ "Global.Action.Login" | translate }}</h2>
          <loader [iconOnly]="true"></loader>
        </div>
      </div>
    </div>
  </div>
  <div class="container"><footer></footer></div>
</div>
