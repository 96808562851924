import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ErrorHandler, NgModule, inject, provideAppInitializer } from "@angular/core";
import { Router } from "@angular/router";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import * as Sentry from "@sentry/angular";

import { TruncateModule } from "@yellowspot/ng-truncate";
import { NgxMdModule } from "ngx-md";

import { AppComponent } from "./app.component";

import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
registerLocaleData(localeNl);

// Pages
import { HighchartsChartModule } from "highcharts-angular";
import { AppRoutingModule } from "./app-routing.module";

import { ApiHelper } from "../helpers/apihelper";
import { AuthService } from "../services/AuthService";
import { MessageService } from "../services/messageservice";
import { SubscriptionService } from "../services/subscriptionservice";

import { AsInstancePipe } from "../helpers/instance/to-instance.pipe";

import { NWDHttpInterceptor } from "../helpers/interceptor/http.interceptor";

import { FlatpickrModule } from "angularx-flatpickr";
import { WebsocketService } from "../helpers/websocket.service";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { DialogMessageComponent } from "../components/dialogs/dialog-message/dialog-message";

import { ApolloModule } from "apollo-angular";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { TagsInputModule } from "../components/common/ngx-tags-input/tags-input.module";
import { CacheService } from "../services/cacheservice";
import { NwdSharedModule } from "./nwd-shared/nwd-shared.module";
import { ApiModule as DomainsApiModule } from "./domains-api/api.module";
import { EffectsModule } from "@ngrx/effects";

import { ENV } from "./app.runtime";
import { NewsModule } from "./news/news.module";
import { NWD_VERSION } from "../version";
import { AuthHttpConfigModule } from "./auth/auth-http-config.module";
import { AuthInterceptor } from "angular-auth-oidc-client";

// AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, "./assets/i18n/", `.json?v=${NWD_VERSION}`);
export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, "./assets/i18n/", `.json?v=${NWD_VERSION}`);

const providers = [
  ApiHelper,
  AuthService,
  SubscriptionService,
  MessageService,
  CacheService,
  WebsocketService,
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: NWDHttpInterceptor, multi: true },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
];

if (ENV.ENABLED_ROUTES.split(",").includes("dashboard")) {
  // TODO Make dashboard, news, domains part of an enum
  // TODO Put dashboard specific declarations, providers, etc. in dashboard module
  // Hack: In case we have dashboard routes enabled, we want to set the site title to Netwerk Dashboard
  // This is done like this, because when we only enable the news routes, we don't want to see Netwerk Dashboard in the title
  const setNwdTitle = provideAppInitializer(() => {
    const initializerFn = (
      (title: Title) => () =>
        title.setTitle("Netwerk Dashboard")
    )(inject(Title));
    return initializerFn();
  });
  // @ts-ignore Provider object is valid
  providers.push(setNwdTitle);
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    NwdSharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    TruncateModule,
    BrowserAnimationsModule,
    TagsInputModule.forRoot(),
    NgxMdModule.forRoot(),
    HighchartsChartModule,
    NgxSkeletonLoaderModule,
    FlatpickrModule.forRoot(),
    DragDropModule,
    NgMultiSelectDropDownModule.forRoot(),
    ApolloModule,
    DomainsApiModule.forRoot({
      rootUrl: ENV.DOMAINS_API,
    }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    EffectsModule.forRoot([]),
    AsInstancePipe,
    NewsModule,
    AuthHttpConfigModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()), ...providers],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
