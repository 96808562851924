import { Component, inject, Input } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { DialogActivityComponent } from "../../dialogs/dialog-activity/dialog-activity";
import { Notification as GqlNotification } from "../../../gql/generated";
import { cimToPlannedWork } from "../../../helpers/subscription/planned-work";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";

@Component({
  selector: "notification-container",
  templateUrl: "notification-container.html",
  imports: [AngularSvgIconModule, CommonModule, RouterLink, TranslateModule],
})
export class NotificationContainer {
  public dialog = inject(MatDialog);

  @Input() messages: GqlNotification[];

  openDialog(message: GqlNotification) {
    // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
    const subscription_message = cimToPlannedWork(message);
    this.dialog.open(DialogActivityComponent, {
      data: subscription_message,
    });
  }
}
