import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SelfserviceAuthenticationBoxComponent } from "../selfservice-authentication-box/selfservice-authentication-box";

@Component({
  selector: "selfservice-unavailable",
  templateUrl: "selfservice-unavailable.html",
  styleUrls: ["../selfservice-loader/selfservice-loader.scss"],
  imports: [SelfserviceAuthenticationBoxComponent, TranslateModule],
})
export class SelfserviceUnavailableComponent {}
