import { Component, inject, input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { DialogTicketsComponent } from "../../../../../components/dialogs/dialog-tickets/dialog-tickets";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "tickets-item",
  templateUrl: "tickets-item.html",
  styleUrls: ["../../shortcut-menu.scss"],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule],
})
export class TicketsItem {
  private dialog = inject(MatDialog);

  protected messages = input.required<Notification[]>();

  openTicketsDialog(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogTicketsComponent, {
      data: {
        serviceMessages: this.messages(),
      },
    });
    return false;
  }
}
