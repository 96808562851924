import { Component, computed, inject } from "@angular/core";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { MscSubscriptionDetailsGQL, MscSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { RouterModule } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { TranslateModule } from "@ngx-translate/core";
import { MscSubscriptionDetails } from "../../types";
import { CommonModule } from "@angular/common";
import { AliasEditorComponent } from "../../../../components/common/alias-editor/alias-editor.component";
import { PageComponent } from "../../../../components/page/page";
import { LinechartComponent } from "../../../../components/subscription-detail/linechart/linechart";
import { ServiceId } from "../../../../components/subscription-detail/service-id/service-id";
import { SubscriptionDetailContainer } from "../../../../components/subscription-detail/subscription-detail-container/subscription-detail-container";
import { SubscriptionDetailHeader } from "../../../../components/subscription-detail/subscription-detail-header/subscription-detail-header";
import { ThirdPartyServiceIdsRowContentComponent } from "../../../../components/subscription-detail/third-party-service-ids-row-content/third-party-service-ids-row-content";
import { SubscriptionDatePipe } from "../../../../helpers/subscription-datepipe/subscription-datepipe";
import { ServiceSpeedPipe } from "../service-speed-pipe/service-speed-pipe";
import { PortShortcutMenuComponent } from "./components/port-shortcut-menu/port-shortcut-menu";
import { NotFoundComponent } from "../../../../components/common/not-found/not-found";
import { LoaderComponent } from "../../../../components/common/loader/loader";
import { NotAuthorizedComponent } from "../../../../components/common/not-authorized/not-authorized";

@Component({
  selector: "msc-subscription",
  templateUrl: "msc-subscription.html",
  imports: [
    CommonModule,
    PageComponent,
    PortShortcutMenuComponent,
    SubscriptionDetailHeader,
    TranslateModule,
    AliasEditorComponent,
    SubscriptionDatePipe,
    ServiceId,
    ServiceSpeedPipe,
    RouterModule,
    SubscriptionDetailContainer,
    LinechartComponent,
    ThirdPartyServiceIdsRowContentComponent,
    NotFoundComponent,
    LoaderComponent,
    NotAuthorizedComponent,
  ],
})
export class MscSubscriptionComponent extends GenericSubscriptionComponent<
  MscSubscriptionDetailsGQL,
  MscSubscriptionDetailsQuery,
  MscSubscriptionDetails
> {
  protected query = inject(MscSubscriptionDetailsGQL);

  protected domain = computed(() => this._subscription().domain);
  protected portMode = computed(() => this._subscription().portMode);
  protected portSpeed = computed(() => this._subscription().portSpeed);
  protected portSubscription = computed(() => this._subscription().portSubscription);
  protected serviceTag = computed(() => this._subscription().serviceTag);

  protected linkPortSubscription = computed(() => this.portSubscription().customerId === this.subscription.customerId);

  protected onQuerySuccess(result: ApolloQueryResult<MscSubscriptionDetailsQuery>): MscSubscriptionDetails {
    return result.data.mscDetails;
  }
}
