import { Pipe, PipeTransform } from "@angular/core";
import { Instance } from "../../components/models/instance";

@Pipe({
  name: "as_instance",
})
export class AsInstancePipe implements PipeTransform {
  transform(value: any): Instance {
    return Object.assign(new Instance(), value);
  }
}
