@defer {
  <div class="availability-export">
    <div class="container">
      <availability-export-header [subscription]="subscription()" [productType]="productType" />
      <section>
        <table>
          <thead>
            <tr>
              <th colspan="4">Service details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th width="16%">{{ "ServiceElement.Owner" | translate }}</th>
              <td width="34%">{{ subscription().organisation }}</td>
              <th width="16%">{{ "ServiceElement.Start" | translate }}</th>
              <td width="34%">{{ subscription().startDate | subscription_date }}</td>
            </tr>
            <tr>
              <th>{{ "ServiceElement.OwnAlias" | translate }}</th>
              <td>{{ customerDescriptionExists() ? subscription().customerDescription : "-" }}</td>
              <th>{{ "ServiceElement.SURFnetDescription" | translate }}</th>
              <td>{{ subscription().description }}</td>
            </tr>
            <tr>
              <th>{{ "ServiceElement.ServiceID" | translate }}</th>
              <td>{{ subscription().subscriptionId | shorten_guid }}</td>
              <th>{{ "ServiceElement.ServiceSpeed" | translate }}</th>
              <td>{{ subscription().serviceSpeed | service_speed }}</td>
            </tr>
            <tr>
              <th>{{ "ServiceElement.Domain" | translate }}</th>
              <td>{{ subscription().domain }}</td>
              <th>URL</th>
              <td>{{ url() }}</td>
            </tr>
          </tbody>
        </table>

        <h2>{{ "Page.AvailabilityExport.Heading1" | translate }}</h2>
        @if (!slsReport) {
          <div>{{ "Page.AvailabilityExport.NotFound" | translate }}</div>
        } @else {
          <table>
            <thead>
              <tr>
                <th width="16%">{{ "Page.AvailabilityExport.Month" | translate }}</th>
                <th width="16%">{{ "Page.AvailabilityExport.Availability" | translate }}</th>
                <th width="68%">Service tickets</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Last year</td>
                <td>{{ slsReport.yearAvailability }}%</td>
                <td>&nbsp;</td>
              </tr>
              <tr *ngFor="let row of slsReport.months">
                <td>{{ row.date.getMonth() | monthname: 3 }}, {{ row.date.getFullYear() }}</td>
                <td>{{ row.availability }}%</td>
                <td>
                  <div *ngFor="let event of row.events">
                    <strong [innerHtml]="event.description | sls_event_description | safe: 'html'"></strong>
                    <span>
                      &nbsp;{{ "SlsReport.Event.From" | translate }}&nbsp;
                      {{
                        event.startTime * 1000
                          | date
                            : "yyyy-MM-dd
                  HH:mm:ss"
                      }}
                      - {{ event.endTime * 1000 | date: "yyyy-MM-dd HH:mm:ss" }}
                    </span>
                    <div><strong>Impact:</strong> {{ event.impact }}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        }
      </section>
      <availability-export-footer [description]="subscription().description" />
    </div>
  </div>
} @loading {
  <loader />
}
