import { Component, inject, OnInit } from "@angular/core";
import { ApiHelper } from "../../helpers/apihelper";
import { Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { AuthService } from "../../services/AuthService";
import { CUSTOMER_WITH_MINIMAL_IMPACT_NOTIFICATIONS_QUERY } from "../../graphql/domain/customer";
import { MatSortModule, Sort } from "@angular/material/sort";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { LoaderComponent } from "../../components/common/loader/loader";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "page-notification-settings",
  templateUrl: "notification-settings.html",
  styleUrls: ["notification-settings.scss"],
  imports: [AngularSvgIconModule, FormsModule, LoaderComponent, MatSortModule, NavigationComponent, TranslateModule],
})
export class NotificationSettingsPage implements OnInit {
  private api = inject(ApiHelper);
  private apollo = inject(Apollo);
  private router = inject(Router);
  public auth = inject(AuthService);

  public sortedData = [];
  public loadingState = [];
  public loading = false;
  public impactSettings = [];

  private customerId = "";

  constructor() {
    this.customerId = localStorage.getItem("viewingCustomerGUID");
  }

  ngOnInit(): void {
    this.retrieveSubscriptionData();
  }

  retrieveSubscriptionData() {
    this.loading = true;

    this.apollo
      .watchQuery<any>({
        query: CUSTOMER_WITH_MINIMAL_IMPACT_NOTIFICATIONS_QUERY,
        fetchPolicy: "network-only",
        variables: {
          customerId: this.customerId,
          products: ["IP", "FW", "L2VPN", "L3VPN", "LightPath", "Port"],
        },
      })
      .valueChanges.subscribe(({ data }) => {
        this.sortedData = data.customer.subscriptions.map((subscription) => {
          this.impactSettings[subscription.subscriptionId] = this.getImpactSetting(
            subscription.minimalImpactNotifications,
          );
          return subscription;
        });
        this.loading = false;
      });
  }

  sortData(sort: Sort) {
    const data = this.sortedData;

    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "description":
          return this.compare(a.description, b.description, isAsc);
        case "presentableName":
          return this.compare(this.presentableName(a), this.presentableName(b), isAsc);
        case "type":
          return this.compare(a.product.type, b.product.type, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  presentableName(subscription): string {
    const customerDescription = subscription.customerDescriptions.find(
      (desc: any) => desc.customerId === this.customerId,
    );
    return customerDescription ? customerDescription.description : "-";
  }

  public getImpactSetting(minimalImpactNotifications) {
    const customerGuid = localStorage.getItem("viewingCustomerGUID");
    const minimalImpactNotification = minimalImpactNotifications.find((el) => el.customerId === customerGuid);

    return minimalImpactNotification?.impact ?? "Loss of Resiliency";
  }

  inProgress(subscriptionId: string): boolean {
    return !!this.loadingState[subscriptionId];
  }

  impactTypeChanged(subscriptionId: string) {
    this.loadingState[subscriptionId] = false;

    // Only allow for infraverantwoordelijke
    const notificationSetting = this.impactSettings[subscriptionId];
    const customerGuid = this.customerId;
    this.api
      .set_minimal_impact_notification(customerGuid, subscriptionId, notificationSetting)
      .then((result) => {
        this.loadingState[subscriptionId] = true;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  routeToService(subscription: any) {
    this.router.navigate([`/subscription/${subscription.subscriptionId}`]);
  }
}
