<a href="{{ serviceUrl() }}" target="_blank" class="card service-tile">
  <div class="card__container">
    <span class="service-tile__icon-container service-tile__available">
      <svg-icon src="assets/images/icons/{{ serviceIcon() }}.svg" class="service-tile__icon"></svg-icon>
    </span>
    <h3 class="service-tile__title">{{ serviceTitle() }}</h3>
    <div class="card__content">
      <p>{{ serviceBody() }}</p>
      <div class="service-tile__bottom">
        <a href="{{ serviceUrl() }}" target="_blank" class="service-tile__more-info"
          ><strong></strong>{{ "Global.MoreInfo" | translate }}</a
        >
      </div>
    </div>
  </div>
</a>
