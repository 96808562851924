<div class="card">
  <div class="service">
    <div class="service__left service__left--no-padding">
      <svg-icon src="assets/images/icons/status-{{ healthState | status_to_icon }}.svg"></svg-icon>
      <div class="service__header-caption">
        @if (showVisual) {
          <div
            class="service__title-wrapper service__title-wrapper--space-between"
            [ngClass]="{ 'card--clickable': portMode !== 'Link Member' }"
            [routerLink]="portMode !== 'Link Member' ? routerLinkForService(sap?.port) : []"
          >
            <h3 class="service__title">{{ customerDescription }}</h3>
            <svg-icon class="service__title-arrow" src="assets/images/icons/arrow-small-right.svg" />
          </div>
        } @else {
          <div class="service__title-wrapper service__title-wrapper--space-between">
            <h3 class="service__title">{{ customerDescription }}</h3>
            <svg-icon class="service__title-arrow" src="assets/images/icons/arrow-small-right.svg" />
          </div>
        }
        <div class="tags">
          <div *ngFor="let tag of sap?.port?.tags" class="tag">{{ tag }}</div>
        </div>
      </div>
    </div>
    <div class="service__flex-table" *ngIf="portMode !== 'Link Member'">
      <div class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ "ServiceElement.Owner" | translate }}</div>
          <div class="service__flex-table-subtitle">{{ portOwner }}</div>
        </div>
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ "Services.Fulfilment.Lightpath.Location" | translate }}</div>
          <div class="service__flex-table-subtitle">
            {{ sap?.port?.address?.city }} ({{ sap?.port?.address?.street }} {{ sap?.port?.address?.number }})
          </div>
        </div>
      </div>
      <div class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ "ServiceElement.PortID" | translate }}</div>
          <div class="service__flex-table-subtitle">{{ sap?.port?.subscriptionId | shorten_guid }}</div>
        </div>
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ "Subscription.VLANID" | translate }}</div>
          <div class="service__flex-table-subtitle">
            <span class="port__title" *ngIf="sap?.vlanrange && sap?.vlanrange !== '0'"> {{ sap?.vlanrange }} </span>
            <span class="port__title" *ngIf="!sap?.vlanrange || sap?.vlanrange === '0'">
              {{ "Subscription.UntaggedPort" | translate }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="serviceTag" class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">S-TAG</div>
          <div class="service__flex-table-subtitle">{{ serviceTag }}</div>
        </div>
        <div class="service__flex-table-col">
          <div class="service__flex-table-title"></div>
          <div class="service__flex-table-subtitle"></div>
        </div>
      </div>
    </div>
    <div class="service__flex-table" *ngIf="portMode === 'Link Member'">
      <div class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ "ServiceElement.PortSpeed" | translate }}</div>
          <div class="service__flex-table-subtitle">{{ service?.portSpeed | service_speed }}</div>
        </div>
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ "ServiceElement.PatchPosition" | translate }}</div>
          <div class="service__flex-table-subtitle">{{ service?.patchPosition }}</div>
        </div>
      </div>
      <div class="service__flex-table-row">
        <div class="service__flex-table-col">
          <div class="service__flex-table-title">{{ "ServiceElement.InterfaceType" | translate }}</div>
          <div class="service__flex-table-subtitle">{{ service?.ieeeInterfaceType }}</div>
        </div>
      </div>
    </div>
    @if (sap) {
      <port-visualiser [subscription]="sap.port" [unavailable]="!showVisual" />
    }
  </div>
</div>
