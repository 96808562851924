import { Component, Input, Inject, signal, inject } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SurfNotification } from "../../models/notification";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AffectedSubscription } from "../../../helpers/types";

@Component({
  selector: "dialog-affected-services",
  templateUrl: "dialog-affected-services.html",
  styleUrls: ["dialog-affected-services.scss"],
  imports: [AngularSvgIconModule, CommonModule, TranslateModule],
})
export class DialogAffectedServicesComponent {
  private readonly dialogRef = inject(MatDialogRef<DialogAffectedServicesComponent>);
  private readonly router = inject(Router);

  protected affectedSubscriptions = signal<AffectedSubscription[]>([]);
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: SurfNotification }) {
    this.affectedSubscriptions.update(() => this.data.message.affected_subscriptions);
  }

  closeDialog(): void {
    this.dialogRef.close({ forceScrollTop: false });
  }

  segueToService(affectedService: any) {
    this.router.navigate([`/subscription/${affectedService.product_type}/${affectedService.subscription_id}`]);
    this.dialogRef.close({ forceScrollTop: true });
  }
}
