import { computed, Signal } from "@angular/core";
import { LightPathBothSubscriptionDetails } from "../../types";

type GConstructor<T = {}> = new (...args: any[]) => T;

type CircuitType = LightPathBothSubscriptionDetails["circuits"][0];
type HasCircuits = GConstructor<{ circuits: Signal<CircuitType[]> }>;

export function WithCircuits<TBase extends HasCircuits>(Base: TBase) {
  return class WithCircuits extends Base {
    protected remotePortshutdown = computed(() => this.circuits()[0].remotePortShutdown);
    protected speedPolicer = computed(() => this.circuits()[0].speedPolicer);

    protected allPortsAreUntagged = computed(() =>
      this.circuits().every((circuit) => circuit.endpoints.every((endpoint) => endpoint.port.portMode === "Untagged")),
    );

    protected allPortsAreNotAggregated = computed(() =>
      this.circuits().every((circuit) => circuit.endpoints.every((endpoint) => endpoint.port.product.tag !== "AGGSP")),
    );
  };
}
