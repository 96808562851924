<section class="cert-security">
  <h3 class="title-with-icon">
    <svg-icon src="assets/images/icons/cert.svg" class="black-medium"></svg-icon>
    <span>{{ "Cert.DdosFilter" | translate }}</span>
  </h3>
  <p>{{ "Cert.DdosFilter.Explainer" | translate }}</p>
  @if (loading()) {
    <loader />
  } @else {
    <ul class="cert-security__items">
      @for (item of subscriptions(); track item.subscriptionId) {
        <li class="cert-security__item">
          <div class="card">
            <div class="cert-security__item-header">
              <div class="cert-security__item-icon cert-security__item-icon--{{ item.productType.toLowerCase() }}">
                <svg-icon src="assets/images/icons/{{ item.productType.toLowerCase() }}.svg"></svg-icon>
              </div>
              <div class="cert-security__item-toggle" (click)="toggleClick(item)">
                <div [ngClass]="{ active: item.surfcertFilterEnabled }">
                  <p>{{ item.surfcertFilterEnabled | enabled_or_value }}</p>
                </div>
                @if (auth?.isSelfServiceEnabled && hasCorrectRole && item.productType.toLowerCase() !== "fw") {
                  <selfservice-toggle [active]="item.surfcertFilterEnabled" />
                }
                <!-- <ng-template #readOnly> {{ item.surfcertFilterEnabled | enabled_or_value }} </ng-template> -->
              </div>
            </div>
            <div class="cert-security__item-title">{{ item.presentableName }}</div>
            <div class="cert-security__item-link" (click)="routeToService(item)">
              {{ "Cert.ServiceDetails" | translate }}
            </div>
          </div>
        </li>
      }
    </ul>
  }
</section>
