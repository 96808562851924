import { Component, computed, inject } from "@angular/core";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { AggspSubscriptionDetailsGQL, AggspSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { RouterModule } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { StorageHelper } from "../../../../helpers/storage/storagehelper";
import { TranslateModule } from "@ngx-translate/core";
import { AggspSubscriptionDetails } from "../../types";
import { CommonModule } from "@angular/common";
import { AliasEditorComponent } from "../../../../components/common/alias-editor/alias-editor.component";
import { PageComponent } from "../../../../components/page/page";
import { LinechartComponent } from "../../../../components/subscription-detail/linechart/linechart";
import { ServiceId } from "../../../../components/subscription-detail/service-id/service-id";
import { SubscriptionDetailContainer } from "../../../../components/subscription-detail/subscription-detail-container/subscription-detail-container";
import { SubscriptionDetailHeader } from "../../../../components/subscription-detail/subscription-detail-header/subscription-detail-header";
import { ThirdPartyServiceIdsRowContentComponent } from "../../../../components/subscription-detail/third-party-service-ids-row-content/third-party-service-ids-row-content";
import { SubscriptionDatePipe } from "../../../../helpers/subscription-datepipe/subscription-datepipe";
import { ServiceSpeedPipe } from "../service-speed-pipe/service-speed-pipe";
import { LinkMemberSubscriptionsComponent } from "../link-member-subscriptions/link-member-subscriptions";
import { PortShortcutMenuComponent } from "./components/port-shortcut-menu/port-shortcut-menu";
import { NotFoundComponent } from "../../../../components/common/not-found/not-found";
import { LoaderComponent } from "../../../../components/common/loader/loader";
import { NotAuthorizedComponent } from "../../../../components/common/not-authorized/not-authorized";

@Component({
  selector: "aggsp-subscription",
  templateUrl: "aggsp-subscription.html",
  imports: [
    CommonModule,
    PageComponent,
    PortShortcutMenuComponent,
    SubscriptionDetailHeader,
    TranslateModule,
    AliasEditorComponent,
    SubscriptionDatePipe,
    ServiceId,
    ServiceSpeedPipe,
    RouterModule,
    SubscriptionDetailContainer,
    LinechartComponent,
    ThirdPartyServiceIdsRowContentComponent,
    LinkMemberSubscriptionsComponent,
    NotFoundComponent,
    LoaderComponent,
    NotAuthorizedComponent,
  ],
})
export class AggspSubscriptionComponent extends GenericSubscriptionComponent<
  AggspSubscriptionDetailsGQL,
  AggspSubscriptionDetailsQuery,
  AggspSubscriptionDetails
> {
  protected query = inject(AggspSubscriptionDetailsGQL);

  protected domain = computed(() => this._subscription().domain);
  protected linkMemberSubscriptions = computed(() => this._subscription().linkMemberSubscriptions);
  protected portMode = computed(() => this._subscription().portMode);
  protected portSpeed = computed(() => this._subscription().portSpeed);

  // TODO: should be handled by making subscription a Signal and these fields computed!!!!!
  public linkPortSubscription = false; // customer can view the linked port subscription?
  public portSubscriptionName = "";

  constructor() {
    super();
    this.subscriptionChange.subscribe((s) => this.onSubscriptionChange(s));
  }

  protected onQuerySuccess(result: ApolloQueryResult<AggspSubscriptionDetailsQuery>): AggspSubscriptionDetails {
    return result.data.aggspDetails as AggspSubscriptionDetails;
  }

  // TODO: should be handled by making subscription a Signal and these fields computed!!!!!
  private onSubscriptionChange(subscription: AggspSubscriptionDetails) {
    this.portSubscriptionName = subscription.portSubscription?.customerDescription;
    this.linkPortSubscription = subscription.portSubscription?.customerId === StorageHelper.currentUser;
  }
}
