<div class="dialog dialog--small">
  <div class="onboarding">
    <div #flickityelement class="onboarding__carousel">
      @for (screen of screens; track screen.index) {
        <div class="onboarding__slide">
          <div class="onboarding__aspect">
            @for (img of screen.imageUrl; track $index) {
              <div
                class="onboarding__image"
                [ngClass]="{ 'onboarding__image--from-right': screen.imageUrl.length > 0 && $first }"
                [style.background-image]="'url(' + img + ')'"
              ></div>
            }
          </div>
          <div class="onboarding__caption">
            <div class="onboarding__dots">
              @for (info of dotInfo; track $index) {
                <div class="onboarding__dot" (click)="flickityClick($index)" [ngClass]="{ active: info.active }"></div>
              }
            </div>
            <div class="onboarding__text">
              <h3 class="onboarding__heading">{{ screen.title }}</h3>
              <p>{{ screen.content }}</p>
            </div>
            <div class="onboarding__bottom">
              <div class="onboarding__checkbox-wrapper">
                <label for="skip-onboarding" class="onboarding__checkbox">
                  <input [(ngModel)]="neverAgain" type="checkbox" id="skip-onboarding" />
                  <div>
                    <div class="onboarding__checkbox-box"></div>
                    <div class="onboarding__checkbox-text">{{ "Onboarding.Disable" | translate }}</div>
                  </div>
                </label>
              </div>
              <span class="onboarding__skip" (click)="closeDialog()">{{ "Global.Action.Skip" | translate }}</span>
              @if (!screen.isLast) {
                <button class="button" (click)="flickityNext()">{{ "Global.Action.Next" | translate }}</button>
              } @else {
                <button class="button" (click)="markAsCompleted()">{{ "Global.Action.Complete" | translate }}</button>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
