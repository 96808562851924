<div class="card__top-center">
  @if (isPort()) {
    <h3>{{ "PortDetails" | translate }}</h3>
  } @else {
    <h3>{{ "ServiceDetails" | translate }}</h3>
  }
  @if (subscription()) {
    <div class="card__top-actions">
      @if (!isTerminated()) {
        @if (!isWireless()) {
          <notification-button [subscription]="subscription()" />
        }
        @if (isCurrentOrganisation) {
          <report-incident [subscription]="subscription()" />
        }
      }
    </div>
  }
</div>
