<navigation [isChildRoute]="true"></navigation>

<div class="wrapper notification-settings">
  <div class="container">
    <header class="notification-settings__header">
      <div class="row">
        <div class="col-xs-12 col-lg-6">
          <h3 class="no-margin">{{ "Notification.PageTitle" | translate }}</h3>
          <p>{{ "Notification.PageDescription" | translate }}</p>
        </div>
        <div class="col-xs-12 col-lg-5 offset-lg-1">
          <div class="notification-settings__warning">
            <h3 class="no-margin" style="margin: 0">{{ "Notification.WarningTitle" | translate }}</h3>
            <p>{{ "Notification.WarningDescription" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-lg-11">
          <h3 class="no-margin">{{ "Notification.Legend.Title.Down" | translate }}</h3>
          <p>{{ "Notification.Legend.Down" | translate }}</p>
          <br />
          <h3 class="no-margin">{{ "Notification.Legend.Title.LossOfResilience" | translate }}</h3>
          <p>{{ "Notification.Legend.LossOfResilience" | translate }}</p>
          <br />
          <h3 class="no-margin">{{ "Notification.Legend.Title.ReducedRedundancy" | translate }}</h3>
          <p>{{ "Notification.Legend.ReducedRedundancy" | translate }}</p>
        </div>
      </div>
    </header>
    <div class="card card--no-padding">
      <div class="table-wrapper">
        <table matSort matSortActive="description" matSortDirection="desc" (matSortChange)="sortData($event)">
          <thead>
            <tr>
              <th mat-sort-header="type">
                <div>
                  <span>{{ "ServiceElement.Type" | translate }}</span>
                </div>
              </th>
              <th mat-sort-header="description">
                <div>
                  <span>{{ "Notification.Subscription" | translate }}</span>
                </div>
              </th>
              <th mat-sort-header="presentableName">
                <div>
                  <span>{{ "ServiceElement.OwnAlias" | translate }}</span>
                </div>
              </th>
              <th>
                <div>
                  <span>{{ "Notification.Level" | translate }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (subscription of sortedData; track subscription.subscriptionId) {
              <tr>
                <td>
                  <div
                    class="notification-settings__table-icon notification-settings__table-icon--{{
                      subscription.product.type.toLowerCase()
                    }}"
                  >
                    <svg-icon src="assets/images/icons/{{ subscription.product.type.toLowerCase() }}.svg"></svg-icon>
                  </div>
                </td>
                <td>
                  <div class="minl-table__link" (click)="routeToService(subscription)">
                    {{ subscription.description }}
                  </div>
                </td>
                <td>{{ presentableName(subscription) }}</td>
                <td style="width: 400px">
                  <div class="save-wrapper">
                    <div [hidden]="!inProgress(subscription.subscriptionId)">
                      <svg-icon class="save-icon green" src="assets/images/icons/ok.svg"></svg-icon>
                    </div>
                    <div class="select-wrapper">
                      <select
                        [disabled]="!auth?.state?.currentUser?.roles.includes('Infraverantwoordelijke')"
                        name="notification"
                        [(ngModel)]="impactSettings[subscription.subscriptionId]"
                        (ngModelChange)="impactTypeChanged(subscription.subscriptionId)"
                      >
                        <option value="Never">{{ "Notification.Status.Never" | translate }}</option>
                        <option value="Downtime">{{ "Notification.Status.Downtime" | translate }}</option>
                        <option value="Loss of Resiliency">
                          {{ "Notification.Status.LossofResiliency" | translate }}
                        </option>
                        <option value="Reduced Redundancy">
                          {{ "Notification.Status.ReducedRedundancy" | translate }}
                        </option>
                      </select>
                      <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
                    </div>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
    @if (loading) {
      <loader />
    }
  </div>
  <div class="container"><footer></footer></div>
</div>
