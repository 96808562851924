import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { LoaderComponent } from "../../common/loader/loader";
import { TerminatedCardComponent } from "../terminated-card/terminated-card";
import { SubscriptionDatePipe } from "../../../helpers/subscription-datepipe/subscription-datepipe";

@Component({
  selector: "shortcut-menu",
  templateUrl: "shortcut-menu.html",
  styleUrls: ["shortcut-menu.scss"],
  imports: [TranslateModule, LoaderComponent, SubscriptionDatePipe, TerminatedCardComponent],
})
export class ShortcutMenu {
  protected subscription = input.required<any>();
  protected isTerminated = input.required<boolean>();
}
