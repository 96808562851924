<section class="cert-mitigation">
  <h3 class="title-with-icon">
    <svg-icon src="assets/images/icons/cert.svg" class="black-medium"></svg-icon>
    <span>{{ "Cert.MitigationSecurity" | translate }}</span>
  </h3>
  <p>{{ "Cert.MitigationSecurity.Explainer" | translate }}</p>
  @if (loading) {
    <loader [detailed]="true" [text]="loadingState.asText"></loader>
  } @else {
    <div class="cert-table card card--no-padding">
      <div class="table-wrapper">
        <table matSort matSortActive="description" matSortDirection="desc" (matSortChange)="changeSort($event)">
          <thead>
            <tr>
              <th class="asc" mat-sort-header="description">
                <div>
                  <span>{{ "Prefixes.Table.Description" | translate }}</span>
                </div>
              </th>
              <th class="desc" mat-sort-header="prefix">
                <div>
                  <span>IP prefix</span>
                </div>
              </th>
              <th mat-sort-header="version">
                <div>
                  <span>{{ "Prefixes.Table.Version" | translate }}</span>
                </div>
              </th>
              <th>
                <div>
                  <span>{{ "Prefixes.Table.auto-mitigation" | translate }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (prefix of sortedData; track prefix.prefix) {
              <tr>
                <td>{{ prefix.description }}</td>
                <td>{{ prefix.prefix }}</td>
                <td>{{ prefix.version | ip_version }}</td>
                <td>
                  @if (auth.isSelfServiceEnabled && auth.isCurrentOrganisation(prefix.customerId)) {
                    <selfservice-toggle
                      [active]="prefix.autoMitigation"
                      (click)="toggleClick(prefix)"
                    ></selfservice-toggle>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  }
</section>
