<div class="tags">
  @if (firewallEnabled()) {
    <div class="tag tag--firewall">
      <svg-icon src="assets/images/icons/fw.svg"></svg-icon>
    </div>
  }
  @for (tag of tags(); track tag) {
    <div class="tag">{{ tag }}</div>
  }
</div>
