<div class="cert-table card card--no-padding">
  <div class="table-wrapper">
    <table matSort matSortActive="ip" matSortDirection="asc" (matSortChange)="sortData($event)">
      <thead>
        <tr>
          <th class="asc" mat-sort-header="ip">
            <div>
              <span>{{ "Cert.IPAddress" | translate }}</span>
            </div>
          </th>
          <th mat-sort-header="scan">
            <div>
              <span>{{ "Cert.Scan" | translate }}</span>
            </div>
          </th>
          <th mat-sort-header="firstObservation">
            <div>
              <span>{{ "Cert.First" | translate }}</span>
            </div>
          </th>
          <th mat-sort-header="lastObservation">
            <div>
              <span>{{ "Cert.Last" | translate }}</span>
            </div>
          </th>
          <th>
            <div>
              <span>{{ "Cert.NumberOfHits" | translate }}</span>
            </div>
          </th>
          <th mat-sort-header="severity">
            <div>
              <span>{{ "Cert.Severity" | translate }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        @for (item of items; track item.node?.source?.ip) {
          @let node = item.node;
          <tr>
            <td>
              <div class="cert-table__link" (click)="openDialog(item)">{{ node?.source?.ip || "---" }}</div>
            </td>
            <td>{{ node?.classification?.identifier }} {{ node?.extra?.reason }}</td>
            <td>{{ node?.firstSeen }}</td>
            <td>{{ node?.lastSeen }}</td>
            <td>{{ node?.numberOfHits }}</td>
            <td>{{ node?.severity }}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>
  <div class="table-bottom">
    <pagination [pageInfo]="pageInfo()" (paginationChanged)="onPaginationChanged($event)"></pagination>
  </div>
</div>
