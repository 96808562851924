import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FirewallSubscriptionComponent } from "../../pages/subscription-detail/components/firewall-subscription/firewall-subscription";
import { AuthGuard } from "../auth/guard";
import { WirelessSubscriptionComponent } from "../../pages/subscription-detail/components/wireless-subscription/wireless-subscription";
import { L2vpnSubscriptionComponent } from "../../pages/subscription-detail/components/l2vpn-subscription/l2vpn-subscription";
import { IPPrefixesPage } from "../../pages/ip-prefixes/ip-prefixes";
import { SubscriptionsPage } from "../../pages/subscriptions/subscriptions";
import { L3vpnSubscriptionComponent } from "../../pages/subscription-detail/components/l3vpn-subscription/l3vpn-subscription";
import { IpBgpSubscriptionComponent } from "../../pages/subscription-detail/components/ip-subscription/ip-bgp-subscription";
import { IpStaticSubscriptionComponent } from "../../pages/subscription-detail/components/ip-subscription/ip-static-subscription";
import { LightpathSubscriptionComponent } from "../../pages/subscription-detail/components/lightpath-subscription/lightpath-subscription";
import { LightpathRedundantSubscriptionComponent } from "../../pages/subscription-detail/components/lightpath-subscription/lightpath-redundant-subscription";
import { PortSubscriptionComponent } from "../../pages/subscription-detail/components/port-subscription/port-subscription";
import { MscSubscriptionComponent } from "../../pages/subscription-detail/components/port-subscription/msc-subscription";
import { AggspSubscriptionComponent } from "../../pages/subscription-detail/components/port-subscription/aggsp-subscription";
import { SubscriptionDetailRedirectionPage } from "../../pages/subscription-detail/subscription-detail-redirection";
import { SupportPage } from "../../pages/support/support";
import { MalfunctionPage } from "../../pages/malfunction/malfunction";
import { AvailabilityExportPage } from "../../pages/availability-export/availability-export";
import { NotificationSettingsPage } from "../../pages/notification-settings/notification-settings";
import { CertPage } from "../../pages/cert/cert";
import { DashboardPage } from "../../pages/dashboard/dashboard";
import { IrbSubscriptionComponent } from "../../pages/subscription-detail/components/irb-subscription/irb-subscription";
import { LoginPage } from "../../pages/login/login";
import { autoLoginPartialRoutesGuard } from "angular-auth-oidc-client";
import { ENV } from "../app.runtime";
import { SubscriptionDetailTab } from "../../components/subscription-detail/page-tab/tab-types";

const OIDC_GUARD = (ENV.AUTH_ENABLED ?? true) ? autoLoginPartialRoutesGuard : () => true;

export const routes: Routes = [
  { path: "login", component: LoginPage },
  { path: "oauth2/callback", component: LoginPage },
  {
    path: "subscription/FW/FW/:subscriptionId",
    redirectTo: "subscription/FW/:subscriptionId",
  },
  {
    path: "subscription/FW/:subscriptionId",
    redirectTo: `subscription/FW/:subscriptionId/${SubscriptionDetailTab.CONFIG}`,
    pathMatch: "full",
  },
  {
    path: "subscription/FW/:subscriptionId/:activeTab",
    component: FirewallSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/Wireless/:subscriptionId",
    component: WirelessSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/L2VPN/:subscriptionId",
    redirectTo: `subscription/L2VPN/:subscriptionId/${SubscriptionDetailTab.CONFIG}`,
    pathMatch: "full",
  },
  {
    path: "subscription/L2VPN/:subscriptionId/:activeTab",
    component: L2vpnSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "ip-prefixes",
    component: IPPrefixesPage,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "diensten/:productType",
    component: SubscriptionsPage,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/L3VPN/:subscriptionId",
    redirectTo: `subscription/L3VPN/:subscriptionId/${SubscriptionDetailTab.CONFIG}`,
    pathMatch: "full",
  },
  {
    path: "subscription/L3VPN/:subscriptionId/:activeTab",
    component: L3vpnSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/IP/IPBGP/:subscriptionId",
    redirectTo: `subscription/IP/IPBGP/:subscriptionId/${SubscriptionDetailTab.CONFIG}`,
  },
  {
    path: "subscription/IP/IPBGP/:subscriptionId/:activeTab",
    component: IpBgpSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/IP/IPS/:subscriptionId",
    redirectTo: `subscription/IP/IPS/:subscriptionId/${SubscriptionDetailTab.CONFIG}`,
  },
  {
    path: "subscription/IP/IPS/:subscriptionId/:activeTab",
    component: IpStaticSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/LightPath/LP/:subscriptionId",
    redirectTo: `subscription/LightPath/LP/:subscriptionId/${SubscriptionDetailTab.CONFIG}`,
  },
  {
    path: "subscription/LightPath/LP/:subscriptionId/:activeTab",
    component: LightpathSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/LightPath/LR/:subscriptionId",
    redirectTo: `subscription/LightPath/LR/:subscriptionId/${SubscriptionDetailTab.CONFIG}`,
  },
  {
    path: "subscription/LightPath/LR/:subscriptionId",
    component: LightpathRedundantSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/Port/SP/:subscriptionId",
    component: PortSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/Port/MSC/:subscriptionId",
    component: MscSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/Port/AGGSP/:subscriptionId",
    component: AggspSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/Port/SPNL/:subscriptionId",
    component: PortSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/Port/MSCNL/:subscriptionId",
    component: MscSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/Port/IRBSP/:subscriptionId",
    component: IrbSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/Port/AGGSPNL/:subscriptionId",
    component: AggspSubscriptionComponent,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "subscription/:subscriptionId",
    component: SubscriptionDetailRedirectionPage,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  { path: "support", component: SupportPage },
  {
    path: "storing-en-onderhoud",
    component: MalfunctionPage,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "storing-en-onderhoud/:productType/:subscriptionId",
    component: MalfunctionPage,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  {
    path: "storing-en-onderhoud/:productType",
    component: MalfunctionPage,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  { path: "inloggen.php", redirectTo: "/" },
  {
    path: "availability-export/:type/:tag/:id",
    component: AvailabilityExportPage,
    canActivate: [OIDC_GUARD],
  },
  {
    path: "notification-settings",
    component: NotificationSettingsPage,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  { path: "cert", component: CertPage, canActivate: [OIDC_GUARD, AuthGuard] },
  {
    path: "cert/:initialTab",
    component: CertPage,
    canActivate: [OIDC_GUARD, AuthGuard],
  },
  { path: "", component: DashboardPage, canActivate: [OIDC_GUARD] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
