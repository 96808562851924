import { Component, computed, inject } from "@angular/core";
import {
  LightpathRedundantSubscriptionDetailsGQL,
  LightpathRedundantSubscriptionDetailsQuery,
} from "../../../../gql/generated-ingestor";
import { RouterModule } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { LightPathRedundantSubscriptionDetails } from "../../types";
import { ApolloQueryResult } from "@apollo/client";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { AliasEditorComponent } from "../../../../components/common/alias-editor/alias-editor.component";
import { PageComponent } from "../../../../components/page/page";
import { SelfserviceToggleComponent } from "../../../../components/selfservice/selfservice-toggle/selfservice-toggle";
import { LinechartComponent } from "../../../../components/subscription-detail/linechart/linechart";
import { ServiceId } from "../../../../components/subscription-detail/service-id/service-id";
import { SubscriptionDetailHeader } from "../../../../components/subscription-detail/subscription-detail-header/subscription-detail-header";
import { ThirdPartyServiceIdsRowContentComponent } from "../../../../components/subscription-detail/third-party-service-ids-row-content/third-party-service-ids-row-content";
import { SubscriptionDatePipe } from "../../../../helpers/subscription-datepipe/subscription-datepipe";
import { ServiceSpeedPipe } from "../service-speed-pipe/service-speed-pipe";
import { LightpathConfigComponent } from "./components/lightpath-config/lightpath-config";
import { LightpathShortCutMenuComponent } from "./components/lightpath-shortcut-menu/lightpath-shortcut-menu";
import { EnabledPipe } from "../../../../helpers/enabledpipe/enabledpipe";
import { NotFoundComponent } from "../../../../components/common/not-found/not-found";
import { LoaderComponent } from "../../../../components/common/loader/loader";
import { NotAuthorizedComponent } from "../../../../components/common/not-authorized/not-authorized";
import { WithCircuits } from "./lightpath-subscription-mixins";

export class _LightpathRedundantSubscriptionComponent extends GenericSubscriptionComponent<
  LightpathRedundantSubscriptionDetailsGQL,
  LightpathRedundantSubscriptionDetailsQuery,
  LightPathRedundantSubscriptionDetails
> {
  protected query = inject(LightpathRedundantSubscriptionDetailsGQL);

  public circuits = computed(() => this._subscription().circuits);

  protected onQuerySuccess(
    result: ApolloQueryResult<LightpathRedundantSubscriptionDetailsQuery>,
  ): LightPathRedundantSubscriptionDetails {
    return result.data.lrDetails;
  }
}

@Component({
  selector: "lightpath-redundant-subscription",
  templateUrl: "lightpath-subscription.html",
  imports: [
    CommonModule,
    PageComponent,
    LightpathShortCutMenuComponent,
    SubscriptionDetailHeader,
    TranslateModule,
    AliasEditorComponent,
    EnabledPipe,
    SubscriptionDatePipe,
    ServiceId,
    ServiceSpeedPipe,
    RouterModule,
    LinechartComponent,
    ThirdPartyServiceIdsRowContentComponent,
    SelfserviceToggleComponent,
    LightpathConfigComponent,
    NotFoundComponent,
    LoaderComponent,
    NotAuthorizedComponent,
  ],
})
export class LightpathRedundantSubscriptionComponent extends WithCircuits(_LightpathRedundantSubscriptionComponent) {}
