import { Component, inject, input } from "@angular/core";
import { MessageService } from "../../../services/messageservice";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "timeline-footer",
  templateUrl: "timeline-footer.html",
  styleUrls: ["timeline-footer.scss"],
  imports: [AngularSvgIconModule, TranslateModule],
})
export class TimelineFooterComponent {
  private msgsrv = inject(MessageService);

  protected showEmptyState = input.required<boolean>();
  protected hasMoreResults = input.required<boolean>();

  protected loadMore() {
    this.msgsrv.setMalfunctionLoadMore({});
  }
}
