import { Component, Inject, inject, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { FulfilmentRequest } from "../../../helpers/self-service/models/types";
import { AuthService } from "../../../services/AuthService";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "dialog-ip-prefixes-add",
  templateUrl: "dialog-ip-prefixes-add.html",
  styleUrls: ["../dialog-ip-prefixes-request/dialog-ip-prefixes-request.scss"],
  imports: [AngularSvgIconModule, CommonModule, FormsModule, MatDialogClose, TranslateModule],
})
export class DialogIPPrefixesAddComponent {
  public dialogRef = inject(MatDialogRef<DialogIPPrefixesAddComponent>);
  private auth = inject(AuthService);
  private translate = inject(TranslateService);
  private api = inject(ApiHelper);

  protected readonly activeStep = signal(1);
  protected readonly errorMessage = signal("");
  protected readonly successMessage = signal("");

  public __fulfilmentRequest: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.__fulfilmentRequest = {};

    this.__fulfilmentRequest.fullName = this.auth.state.currentUser.displayName;
    this.__fulfilmentRequest.email = this.auth.state.currentUser.email;
    this.__fulfilmentRequest.phone = "";
    this.__fulfilmentRequest.ipRegister = "";
  }

  get disableSubmitButton() {
    return !this.__fulfilmentRequest.ipRegister;
  }

  next() {
    this.activeStep.update((activeStep) => activeStep + 1);
  }

  prev() {
    this.activeStep.update((activeStep) => activeStep - 1);
  }

  submit() {
    const customerId = localStorage.getItem("viewingCustomerGUID");

    const request: FulfilmentRequest = {
      contacts: [
        {
          fullName: this.__fulfilmentRequest.fullName,
          email: this.__fulfilmentRequest.email,
          phone: this.__fulfilmentRequest.phone,
        },
      ],
      serviceRequest: "create",
      text: this.translate.instant("Dialog.IPP.IPRegister") + "\nAnswer:\n\n" + this.__fulfilmentRequest.ipRegister,
      product: "IP_PREFIX",
    };

    this.api
      .add_new_fulfilment_problem(customerId, JSON.stringify(request))
      .then((result) => {
        this.successMessage.set(this.translate.instant("Selfservice.NotificationSent"));
        this.next();
      })
      .catch((err) => {
        if (err.error) {
          this.errorMessage.set(err.error.detail);
        }
      });
  }
}
