<div
  *ngIf="currentStep === 1"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ "Selfservice.BGP.Subheading" | translate }}</h3>
    <p class="no-margin">{{ "Selfservice.BGP.Text" | translate }}</p>
  </div>
  <div>
    <div
      *ngFor="let sap of subscriptionCopy?._saps; first as isFirst; index as i"
      class="dialog__port-rule dialog__port-rule--flex"
    >
      <div>
        <div class="dialog__port-number">{{ i + 1 }}</div>
        <div>
          <div class="dialog__port-text">{{ sap.port | custom_description }}</div>
          <div class="dialog__port-subtext">
            {{ sap.port.locationDescription }},
            <span *ngIf="sap?.vlanrange && sap?.vlanrange !== 0"
              >{{ "ServiceElement.VLAN" | translate }} {{ sap?.vlanrange }}</span
            >
            <span *ngIf="!sap?.vlanrange || sap?.vlanrange === 0">{{ "Subscription.UntaggedPort" | translate }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="select-wrapper">
          <select name="priority" [(ngModel)]="sap.bgpSessionPriority">
            <option value="primary">primary</option>
            <option value="secondary">secondary</option>
            <option value="tertiary">tertiary</option>
            <option value="quaternary">quaternary</option>
          </select>
          <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="currentStep === 2"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ "Global.Summary" | translate }}</h3>
    <p class="no-margin t-newline">{{ "Selfservice.BGP.SummaryText" | translate }}</p>
  </div>

  <div class="dialog__port-summary">
    <div *ngFor="let sap of subscriptionCopy?._saps; first as isFirst; index as i" class="dialog__port-summary-item">
      <div class="dialog__port-summary-left">
        <div class="dialog__port-number">{{ i + 1 }}</div>
        <div class="dialog__port-text">{{ sap.port | custom_description }}</div>
      </div>
      <div class="dialog__port-summary-right">
        <div>{{ getInitialSessionPriority(i) }}</div>
        <div *ngIf="getInitialSessionPriority(i) !== sap.bgpSessionPriority" class="dialog__port-summary-right-flex">
          <svg-icon src="assets/images/icons/arrow-long.svg"></svg-icon>
          <span class="blue">{{ sap.bgpSessionPriority }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasErrors" class="dialog__error dialog__error--margin-x">
    <ul>
      <li *ngFor="let e of errors">{{ e.msg }}</li>
    </ul>
  </div>
</div>
<!-- <div class="red" *ngIf="hasErrors">
  <ul>
    <li *ngFor="let e of errors">{{ e.msg }}</li>
  </ul>
</div> -->
<div *ngIf="currentStep === 1" class="dialog__bottom-message">
  <div class="dialog__bottom-message-inner">
    <svg-icon src="assets/images/icons/status-failure.svg" class="dialog__bottom-message-icon"></svg-icon>
    <span> {{ "Selfservice.BGP.Message" | translate }} </span>
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  <div *ngIf="currentStep === 1" class="button__flex">
    <button class="button button--secondary" (click)="emitCloseDialog()">
      {{ "Global.Action.Cancel" | translate }}
    </button>
    <button (click)="next()" class="button">{{ "Global.Action.Continue" | translate }}</button>
  </div>
  <div *ngIf="currentStep === 2" class="button__flex">
    <button class="button button--secondary" (click)="prev()">{{ "Global.Action.Back" | translate }}</button>
    <button class="button" (click)="submit()" [ngClass]="{ loading: buttonLoading }">
      <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
      <span>{{ "Global.Action.Confirm" | translate }}</span>
    </button>
  </div>
</div>
