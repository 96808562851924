import { Component, computed, inject, Injector, OnInit, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SlsReport } from "../../helpers/self-service/models/types";
import { SubscriptionService } from "../../services/subscriptionservice";
import { LoaderComponent } from "../../components/common/loader/loader";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TranslateModule } from "@ngx-translate/core";
import { ServiceSpeedPipe } from "../subscription-detail/components/service-speed-pipe/service-speed-pipe";
import { GuidPipe } from "../../helpers/guidpipe/guidpipe";
import { MonthNamePipe } from "../../helpers/datehelper/monthname";
import { CommonModule, DatePipe } from "@angular/common";
import { SafePipe } from "../../helpers/safepipe/safepipe";
import { SlsEventDescriptionPipe } from "../../helpers/self-service/pipes/eventdescription";
import { SubscriptionDatePipe } from "../../helpers/subscription-datepipe/subscription-datepipe";
import {
  IpBgpSubscriptionDetailsGQL,
  IpStaticSubscriptionDetailsGQL,
  LightpathRedundantSubscriptionDetailsGQL,
  LightpathSubscriptionDetailsGQL,
} from "../../gql/generated-ingestor";
import { AvailabilityExportFooterComponent } from "./components/availability-export-footer/availability-export-footer";
import { AvailabilityExportHeaderComponent } from "./components/availability-export-header/availability-export-header";

@Component({
  selector: "page-availability-export",
  templateUrl: "availability-export.html",
  styleUrls: ["availability-export.scss"],
  imports: [
    CommonModule,
    LoaderComponent,
    TranslateModule,
    AngularSvgIconModule,
    SlsEventDescriptionPipe,
    ServiceSpeedPipe,
    GuidPipe,
    MonthNamePipe,
    DatePipe,
    SafePipe,
    SubscriptionDatePipe,
    AvailabilityExportFooterComponent,
    AvailabilityExportHeaderComponent,
  ],
})
export class AvailabilityExportPage implements OnInit {
  private route = inject(ActivatedRoute);
  private subscriptionService = inject(SubscriptionService);

  protected subscription = signal(undefined);
  public slsReport: SlsReport;
  public productType = this.route.snapshot.paramMap.get("type");
  public productTag = this.route.snapshot.paramMap.get("tag");

  private injector = inject(Injector);

  ngOnInit(): void {
    const subscriptionId = this.route.snapshot.paramMap.get("id");
    const accessCustomerId = localStorage.getItem("viewingCustomerGUID");

    const [gql, extractor] = this.getQuery(this.productTag);
    const query = this.injector.get(gql);

    query.watch({ subscriptionId, accessCustomerId }).valueChanges.subscribe((result) => {
      this.subscription.set(extractor(result));
      this.getSlsReport(subscriptionId);
    });
  }

  getQuery(productTag: string): [any, any] {
    if (productTag === "IPBGP") return [IpBgpSubscriptionDetailsGQL, (result) => result.data.ipBgpDetails];
    if (productTag === "IPS") return [IpStaticSubscriptionDetailsGQL, (result) => result.data.ipStaticDetails];
    if (productTag === "LP") return [LightpathSubscriptionDetailsGQL, (result) => result.data.lpDetails];
    if (productTag === "LR") return [LightpathRedundantSubscriptionDetailsGQL, (result) => result.data.lrDetails];
  }

  url = computed(
    () =>
      `${window.location.hostname}/subcription/${this.productType}/${this.productTag}/${this.subscription().subscriptionId}`,
  );

  customerDescriptionExists = computed(
    () => this.subscription().customerDescription !== this.subscription().description,
  );

  getSlsReport(subscriptionId: string) {
    this.subscriptionService.getSlsReport(subscriptionId).then((sls: SlsReport) => {
      this.slsReport = sls;
      this.slsReport.months = sls.months.map((m) => ({
        ...m,
        date: new Date(m.firstDayOfTheMonth),
      }));
    });
  }
}
