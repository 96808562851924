import { Apollo } from "apollo-angular";
import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import dayjs from "dayjs";
import { CIM_QUERY } from "../../../graphql/domain/cim";
import { Notification } from "../../../gql/generated";
import { mergeCimAndPlannedWork, sortNotifications } from "../../../helpers/subscription/planned-work";
import { CimQueryImpactTypes, CimQueryStatuses } from "../../../graphql/custom-types";
import { AngularSvgIconModule } from "angular-svg-icon";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "service-status",
  templateUrl: "service-status.html",
  imports: [AngularSvgIconModule, CommonModule, RouterModule, TranslateModule],
})
export class ServiceStatusComponent implements OnInit {
  private apollo = inject(Apollo);

  protected subscriptionId = input.required<string>();
  protected productType = input.required<string>();

  //  A list of notifications received from the backend.
  private notifications = signal<any[]>([]);
  private cimNotifications = signal<Notification[]>([]);

  protected hasNotifications = computed(() => this.notifications()?.length > 0);
  protected firstNotification = computed(() => (this.hasNotifications() ? this.notifications()[0] : null));
  protected startTimestamp = computed(() => this.firstNotification()?.start_timestamp);
  protected endTimestamp = computed(() => this.firstNotification()?.end_timestamp);
  protected category = computed(() => this.firstNotification()?.category);
  protected heading = computed(() => this.firstNotification()?.heading);

  protected isNowActive = computed(() => this.startTimestamp() && Date.now() > this.startTimestamp());

  protected getRouterLink = computed(() => `/storing-en-onderhoud/${this.productType()}/${this.subscriptionId()}`);

  protected notificationIcon = computed(() => this.getNotificationIcon(this.category(), this.isNowActive()));
  protected notificationColor = computed(() => this.getNotificationColor(this.category()));
  protected impactType = computed(() => this.getImpactType(this.firstNotification()));

  private getNotificationIcon(category: string, isNowActive: boolean): string {
    switch (category) {
      case "Malfunction":
        return "status-malfunction";
      case "PlannedWork":
        return isNowActive ? "status-maintenance" : "status-maintenance-planned";
      case "General":
        return "status-maintenance";
    }
  }

  private getNotificationColor(category: string): string {
    switch (category) {
      case "Malfunction":
        return "red";
      case "PlannedWork":
        return "blue";
      case "General":
        return "blue";
    }
  }

  private getImpactType(firstNotification: any): string {
    const affectedSubscriptions = firstNotification.affected_subscriptions;
    if (affectedSubscriptions?.length) {
      return affectedSubscriptions[Object.keys(affectedSubscriptions)[0]].impact_type;
    }
    return firstNotification.impact;
  }

  ngOnInit() {
    this.loadHealthForSubscriptions(this.subscriptionId());
  }

  async loadHealthForSubscriptions(subscriptionId: string) {
    const beginTimestamp = dayjs().startOf("day").toDate();
    const endTimestamp = dayjs().add(2, "week").toDate();
    const impacts = [
      CimQueryImpactTypes.DOWN,
      CimQueryImpactTypes.REDUCED_REDUNDANCY,
      CimQueryImpactTypes.RESILIENCE_LOSS,
    ];

    this.apollo
      .query<any>({
        query: CIM_QUERY,
        variables: {
          includeAffectedSubscriptions: false,
          filter: {
            subscriptionIds: [subscriptionId],
            beginTimestamp,
            endTimestamp,
            impacts,
            statuses: [CimQueryStatuses.OPEN, CimQueryStatuses.UPDATED],
          },
        },
      })
      .subscribe((result) => {
        // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
        const notifications = sortNotifications(mergeCimAndPlannedWork(result.data.notifications, []));
        this.cimNotifications.set(notifications);
        this.notifications.set(notifications);
      });
  }
}
