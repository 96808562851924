import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ip_version",
  pure: true,
})
export class IpVersionPipe implements PipeTransform {
  transform(afi: number): string {
    switch (afi) {
      case 4:
        return "IPv4";
      case 6:
        return "IPv6";
      default:
        return "";
    }
  }
}
