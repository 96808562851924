import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SelfServiceDialogData } from "./dialog-selfservice.types";
import { DialogSelfServiceComponent } from "./dialog-selfservice";

export const openSelfServiceDialog = <S>(
  dialog: MatDialog,
  data: SelfServiceDialogData,
): MatDialogRef<DialogSelfServiceComponent<S>> =>
  /**
   * This function is a dumb wrapper around MatDialog.open to allow for type checking
   * DialogSelfServiceComponent's data.
   */
  dialog.open(DialogSelfServiceComponent<S>, { data });
