import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "location_address",
})
export class LocationAddressPipe implements PipeTransform {
  transform(address: any): string {
    const city = address.city;
    const capitalizedCity = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
    return `${capitalizedCity} (${address.street} ${address.number})`;
  }
}
