import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { PortTile } from "../../../../components/subscription-detail/port-tile/port-tile";

@Component({
  selector: "link-member-subscriptions",
  templateUrl: "link-member-subscriptions.html",
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, PortTile],
})
export class LinkMemberSubscriptionsComponent {
  protected linkMemberSubscriptions = input.required<any[]>();
}
