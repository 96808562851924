import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "service-tile-available",
  templateUrl: "service-tile-available.html",
  styleUrls: ["../service-tile/service-tile.scss"],
  imports: [AngularSvgIconModule, TranslateModule],
})
export class ServiceTileAvailableComponent {
  protected serviceUrl = input.required<string>();
  protected serviceTitle = input.required<string>();
  protected serviceIcon = input.required<string>();
  protected serviceBody = input.required<string>();
}
