import { Component, inject, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AuthService } from "../../../services/AuthService";

@Component({
  selector: "not-authorized",
  templateUrl: "not-authorized.html",
  imports: [TranslateModule],
})
export class NotAuthorizedComponent {
  private auth = inject(AuthService);
  protected canImpersonate = signal(false);

  constructor() {
    if (this.auth.isSuperUserRO()) {
      this.canImpersonate.set(true);
    }
  }
}
