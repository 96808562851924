import { CommonModule } from "@angular/common";
import { Component, input, OnInit, output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FlatpickrModule } from "angularx-flatpickr";

export interface PeriodChanged {
  firstDate: Date;
  secondDate: Date;
}

@Component({
  selector: "period-picker",
  templateUrl: "period-picker.html",
  styleUrls: ["../../system-stats.scss"],
  imports: [AngularSvgIconModule, CommonModule, FormsModule, TranslateModule, FlatpickrModule],
})
export class PeriodPickerComponent implements OnInit {
  protected firstDateInitial = input.required<Date>();
  protected secondDateInitial = input.required<Date>();

  protected firstDate: Date;
  protected secondDate: Date;

  protected newDates = output<PeriodChanged>();

  protected selectedPreset = "1d";
  protected presets = ["1d", "1w", "1m", "1y"];

  protected tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  protected defaultTimeWindow = 1 * 24 * 3600 * 1000;

  protected defaultDate = true;

  ngOnInit(): void {
    this.firstDate = this.firstDateInitial();
    this.secondDate = this.secondDateInitial();
  }

  onDateChange() {
    this.selectedPreset = "";
    if (this.secondDate > new Date()) {
      this.secondDate = new Date();
    }

    if (this.firstDate > this.secondDate) {
      this.firstDate = new Date(this.secondDate.getTime() - this.defaultTimeWindow);
    }

    this.defaultDate = false;
    this.newDates.emit({ firstDate: this.firstDate, secondDate: this.secondDate });
  }

  resetDatePickers() {
    this.selectedPreset = "";
    this.firstDate = this.firstDateInitial();
    this.secondDate = this.secondDateInitial();
    this.defaultDate = true;
    this.newDates.emit({ firstDate: this.firstDate, secondDate: this.secondDate });
  }

  setDatePreset(preset: string) {
    this.selectedPreset = preset;
    this.firstDate = new Date();

    switch (preset) {
      case "1d":
        this.firstDate.setDate(this.firstDate.getDate() - 1);
        break;
      case "1w":
        this.firstDate.setDate(this.firstDate.getDate() - 7);
        break;
      case "1m":
        this.firstDate.setMonth(this.firstDate.getMonth() - 1);
        break;
      case "1y":
        this.firstDate.setDate(this.firstDate.getDate() - 365);
        break;
    }

    this.defaultDate = false;
    this.secondDate = new Date();
    this.newDates.emit({ firstDate: this.firstDate, secondDate: this.secondDate });
  }
}
