import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AboutPage } from "../pages/about/about";
import { DisclaimerPage } from "../pages/disclaimer/disclaimer";
import { ErrorPage } from "../pages/error/error";
import { NotfoundPage } from "../pages/notfound/notfound";
import { ForbiddenPage } from "../pages/forbidden/forbidden";
import { AuthGuard } from "./auth/guard";
import { ENV } from "./app.runtime";

import { routes as dashboard } from "./dashboard/dashboard-routing.module"; // TODO Put components in this module
import { LoginPage } from "../pages/login/login";

const domains: Routes = [
  {
    path: "domains",
    loadChildren: () => import("./domains/domains.module").then((m) => m.DomainsModule),
    canActivate: [AuthGuard],
  },
];

const news: Routes = [
  {
    path: "news",
    loadChildren: () => import("./news/news.module").then((m) => m.NewsModule),
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "news",
  },
];

const general: Routes = [
  { path: "over-surf", component: AboutPage },
  { path: "disclaimer", component: DisclaimerPage },
  { path: "error", component: ErrorPage },
  { path: "notfound", component: NotfoundPage },
  { path: "forbidden", component: ForbiddenPage },
  { path: "**", redirectTo: "notfound" },
];

const allowed = ENV.ENABLED_ROUTES.split(",");
let routes: Routes = [];
if (allowed.includes("dashboard")) routes = routes.concat(dashboard);
if (allowed.includes("domains")) routes = routes.concat(domains);
if (allowed.includes("news")) routes = routes.concat(news);
if (ENV.DOMAINS_ENABLED) routes = routes.concat(domains);
routes = routes.concat(general);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
