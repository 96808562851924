import { Component, inject } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { TranslateModule } from "@ngx-translate/core";
import { TwentyFourHourTimePipe } from "../../../helpers/datehelper/24htime";

@Component({
  selector: "selfservice-authentication-box",
  templateUrl: "selfservice-authentication-box.html",
  styleUrls: ["selfservice-authentication-box.scss"],
  imports: [TranslateModule, TwentyFourHourTimePipe],
})
export class SelfserviceAuthenticationBoxComponent {
  protected auth = inject(AuthService);
}
