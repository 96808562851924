<div>
  <page-tab [activeTab]="activeTab" [productType]="productType" (activeTabEmitter)="changeActiveTab($event)">
  </page-tab>

  @switch (activeTab) {
    @case (SubscriptionDetailTab.GRAPHIC_VIEW) {
      <div>
        <networkgraph [topology]="subscription.topology" #chart></networkgraph>
      </div>
    }
    @case (SubscriptionDetailTab.CONFIG) {
      <div class="section">
        <div>
          @for (circuit of subscription.circuits; track circuit.subscriptionInstanceId) {
            <connection
              [circuit]="circuit"
              [subscription]="subscription"
              [connectionNumber]="$index + 1"
              [productType]="productType"
              [bus]="messageBus"
            ></connection>
          }
        </div>
      </div>
    }
  }
</div>
