import { Component, input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ReadableSizePipe } from "../../../../../helpers/bitspipe/bitspipe";

@Component({
  selector: "traffic-speed",
  templateUrl: "traffic-speed.html",
  imports: [AngularSvgIconModule, CommonModule, TranslateModule],
})
export class TrafficSpeedComponent {
  showTraffic = input.required<boolean>();
  healthData = input.required<any>();

  get traffic() {
    if (!this.showTraffic() || !this.healthData()) {
      return null;
    }

    // The lines are needed to format the speed and unit in different ways (instead of using a pipe in the html)

    const bitsPipe = new ReadableSizePipe();

    const formatSpeed = (speed: string) => {
      const [value, unit = ""] = speed.split(" ");
      return { speed: value, unit };
    };

    const up = bitsPipe.transform(this.healthData().trafficIn);
    const down = bitsPipe.transform(this.healthData().trafficOut);

    return {
      up: formatSpeed(up),
      down: formatSpeed(down),
    };
  }
}
