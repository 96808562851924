import { Component, input } from "@angular/core";
import { ShortcutMenu } from "../../../../../../components/subscription-detail/shortcut-menu/shortcut-menu";
import { TicketsItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/tickets/tickets-item";
import { HiveItem } from "../../../../../../components/subscription-detail/shortcut-menu/items/hive/hive-item";

@Component({
  selector: "wireless-shortcut-menu",
  templateUrl: "wireless-shortcut-menu.html",
  imports: [ShortcutMenu, TicketsItem, HiveItem],
})
export class WirelessShortcutMenuComponent {
  protected subscription = input.required<any>();
  protected isTerminated = input.required<boolean>();
  protected activities = input.required<Notification[]>();
}
