import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { FooterComponent } from "../../components/common/footer/footer";
import { NotFoundComponent } from "../../components/common/not-found/not-found";
@Component({
  selector: "page-notfound",
  templateUrl: "notfound.html",
  imports: [FooterComponent, NavigationComponent, TranslateModule, NotFoundComponent],
})
export class NotfoundPage {}
