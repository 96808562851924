<div
  *ngIf="currentStep === 1"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ "Selfservice.VlanLightpath.Subheading" | translate }}</h3>
    <p class="no-margin">{{ "Selfservice.VlanLightpath.Text" | translate }}</p>
  </div>
  <div *ngIf="loadingData">
    <loader></loader>
  </div>
  <div
    *ngFor="let item of endpoints; index as endpointIndex"
    class="selfservice-add-port__bar selfservice-add-port__bar--col"
  >
    <div class="selfservice-add-port__bar-flex">
      <div class="selfservice-add-port__left">
        <div class="selfservice-add-port__details active">
          <div class="selfservice-add-port__icon-box">
            <svg-icon src="assets/images/icons/port.svg"></svg-icon>
          </div>
          <div>
            <h3>{{ item.port | custom_description }}</h3>
            <div class="selfservice-add-port__meta">
              {{ item.port.subscriptionId | shorten_guid }} - {{ item.port.organisation }},
              {{ item.port.address.city }} ({{ item.port.address.street }})
            </div>
          </div>
        </div>
        <div class="selfservice-add-port__left-bottom" *ngIf="isMyPort(item.port)">
          <div>{{ "Selfservice.AddPort.NotAvailableVlans" | translate }}</div>
          <ul class="selfservice-add-port__tags">
            <li *ngFor="let v of item.port.usedVlans">{{ v }}</li>
          </ul>
        </div>
      </div>
      <div class="selfservice-add-port__right">
        <div class="selfservice-add-port__form">
          <label>{{ "Selfservice.AddPort.CurrentVlan" | translate }}</label>
          <div>{{ item.vlanrange }}</div>
        </div>
        <div class="selfservice-add-port__form" *ngIf="isMyPort(item.port)">
          <label>{{ "Selfservice.AddPort.NewVlan" | translate }}</label>
          <input
            type="string"
            [(ngModel)]="item.vlan"
            [ngClass]="{
              error: item.vlan && (!validVlan(item.port, item.vlan, item.vlanrange) || errorsAtIndex(endpointIndex)),
            }"
          />
        </div>
        <div class="red" *ngIf="item.vlan && !validVlan(item.port, item.vlan, item.vlanrange)" style="font-size: 12px">
          {{ "Selfservice.AddPort.VlanInUse" | translate }}
          <br />
          <br />
        </div>
        <p class="selfservice-add-port__footnote" *ngIf="isMyPort(item.port)">
          {{ "Selfservice.AddPort.VlanHint" | translate }}
        </p>
      </div>
    </div>
    <div class="service__unavailable-bar" *ngIf="!isMyPort(item.port)">
      <span class="service__unavailable-bar-side">
        <svg-icon class="service__unavailable-bar-icon" src="assets/images/icons/lock.svg"></svg-icon>
      </span>
      <span class="service__unavailable-bar-text">
        <strong>{{ "Global.NoAccess" | translate }}</strong> {{ "Global.PleaseContact" | translate }}
        {{ item.port.organisation }}
      </span>
      <span class="service__unavailable-bar-side"></span>
    </div>
  </div>
</div>
<div
  *ngIf="currentStep === 2"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ "Global.Summary" | translate }}</h3>
    <p class="no-margin t-newline">{{ "Selfservice.VlanLightpath.SummaryText" | translate }}</p>
  </div>
  <div *ngIf="hasErrors" class="dialog__error dialog__error--margin-x">
    <ul>
      <li *ngFor="let e of errors">{{ e.msg }}</li>
    </ul>
  </div>
  <div class="dialog__port-summary">
    <div *ngFor="let item of endpoints; index as endpointIndex" class="dialog__port-summary-item">
      <div class="dialog__port-summary-left dialog__port-summary-left--center">
        <svg-icon class="dialog__port-icon" src="assets/images/icons/port.svg"></svg-icon>
        <div class="dialog__port-text">{{ item.port | custom_description }}</div>
      </div>
      <div class="dialog__port-summary-right">
        <div>VLAN {{ item.vlanrange }}</div>
        <div *ngIf="item.vlan" class="dialog__port-summary-right-flex">
          <svg-icon src="assets/images/icons/arrow-long.svg"></svg-icon>
          <span class="blue">VLAN {{ item.vlan }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="currentStep === 2" class="dialog__bottom-message">
  <div class="dialog__bottom-message-inner">
    <svg-icon src="assets/images/icons/status-failure.svg" class="dialog__bottom-message-icon"></svg-icon>
    <span>{{ "Selfservice.VlanLightpath.Message" | translate }} </span>
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  <div *ngIf="currentStep === 1" class="button__flex">
    <button class="button button--secondary" mat-dialog-close>{{ "Global.Action.Cancel" | translate }}</button>
    <button (click)="next()" class="button" [ngClass]="{ 'button--disabled': !vlanMatches() }">
      {{ "Global.Action.Continue" | translate }}
    </button>
  </div>
  <div *ngIf="currentStep === 2" class="button__flex">
    <button class="button button--secondary" (click)="prev()">{{ "Global.Action.Back" | translate }}</button>
    <button class="button" (click)="submit()" [ngClass]="{ loading: buttonLoading }">
      <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
      <span>{{ "Global.Action.Confirm" | translate }}</span>
    </button>
  </div>
</div>
