<div class="chart">
  <div style="position: relative">
    <div class="row">
      <div class="col-6">
        <h3 class="title-with-icon">
          <svg-icon src="assets/images/icons/chart.svg" class="black-medium"></svg-icon>
          <span>{{ "Chart.TrafficGraph.DDoS" | translate }}</span>
        </h3>
        <p>{{ "Chart.TrafficGraph.DDoS.Explainer" | translate }}</p>
      </div>
    </div>
    <div class="card card--no-padding">
      <div class="chart__header">
        <div class="chart__header-left">
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label">{{ "Chart.Ddos.SelectConnection" | translate }}</label>
            <div class="chart__select-wrapper">
              <select class="chart__select" [(ngModel)]="selectedSubscription" (change)="updateSubscriptionFilter()">
                @for (service of subscriptions; track service.subscriptionId) {
                  <option [ngValue]="service">{{ service.presentableName }}</option>
                }
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>
        <div class="chart__header-left">
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label">{{ "Chart.Ddos.SelectVersion" | translate }}</label>
            <div class="chart__select-wrapper">
              <select class="chart__select" [(ngModel)]="ipVersionFilter" (change)="updateSubscriptionFilter()">
                <option value="4" selected="selected">IPv4</option>
                <option value="6">IPv6</option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>

        <div class="chart__header-right">
          <div class="chart__header-right-flex">
            <label for="" class="chart__select-label">
              <span>{{ "Chart.Period" | translate }}</span>
              <span class="chart__select-reset" (click)="resetDatePickers()" *ngIf="!defaultDate">Reset</span>
            </label>
            <div class="chart__period-filter">
              @for (preset of presets; track preset) {
                <button [ngClass]="{ active: selectedPreset === preset }" (click)="setDatePreset(preset)">
                  {{ preset }}
                </button>
              }
            </div>
          </div>
          <div class="chart__header-date-inputs">
            <div class="chart__select-wrapper">
              <input
                class="chart__input"
                type="text"
                id="startDate"
                mwlFlatpickr
                [enableTime]="true"
                [dateFormat]="'d-m-Y H:i'"
                [time24hr]="true"
                [convertModelValue]="true"
                [disableMobile]="true"
                [(ngModel)]="firstDate"
                [prevArrow]="'<i></i>'"
                [nextArrow]="'<i></i>'"
                [maxDate]="tomorrow"
                (ngModelChange)="onDateChange()"
              />
              <svg-icon class="chart__select-calendar-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
            </div>
            <span class="chart__header-date-seperator">-</span>
            <div class="chart__select-wrapper">
              <input
                class="chart__input"
                type="text"
                mwlFlatpickr
                [enableTime]="true"
                [dateFormat]="'d-m-Y H:i'"
                [time24hr]="true"
                [convertModelValue]="true"
                [disableMobile]="true"
                [(ngModel)]="secondDate"
                [maxDate]="tomorrow"
                (ngModelChange)="onDateChange()"
              />
              <svg-icon class="chart__select-calendar-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="chart__center">
        <div *ngIf="enableCurrentTraffic" class="chart__current-traffic">
          <div class="chart__current-traffic-text">
            <span>{{ "ServiceElement.Chart.NowIn" | translate }}</span>
            {{ subscription?.health?.traffic.last.in | readable_size }}
          </div>
          <div class="chart__current-traffic-text">
            <span>{{ "ServiceElement.Chart.NowOut" | translate }}</span>
            {{ subscription?.health?.traffic.last.out | readable_size }}
          </div>
        </div>
        @if (!happyState) {
          @if (isBrowser()) {
            <highcharts-chart
              #chart
              [Highcharts]="highcharts"
              [constructorType]="chartConstructor"
              [options]="chartOptions"
              [(update)]="updateFlag"
              [oneToOne]="oneToOneFlag"
              [callbackFunction]="chartCallback"
              (chartInstance)="setChartInstance($event)"
              style="width: 100%; display: block"
            ></highcharts-chart>
          }
        } @else {
          <div class="chart__happy-state">
            <div>
              <svg-icon src="assets/images/icons/rocket.svg"></svg-icon>
              @if (selectedSubscription?.surfcertFilterEnabled) {
                <div>{{ "Chart.TrafficGraph.NotUnderAttack" | translate }}</div>
              } @else {
                <div>{{ "Chart.TrafficGraph.NoDDoSProtection" | translate }}</div>
              }
            </div>
          </div>
        }
      </div>
      <div class="chart__bottom">
        <div class="chart__bottom-left">
          <div class="chart__legend">
            <div *ngIf="chartDataModus === 'packets'" class="chart__select-wrapper">
              <select class="chart__select" (change)="updatePacketsSubFilters($any($event.target).value)">
                <option *ngFor="let packetsSubFilter of packetsSubFilters" value="{{ packetsSubFilter }}">
                  {{ packetsSubFilter }}
                </option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
            <div
              class="chart__legend-item-misc"
              *ngFor="let series of legend; let i = index"
              (click)="toggleLegendItem(series.name)"
              [class.inactive]="isSeriesHidden(series.name)"
            >
              <span class="bolletje" [style.backgroundColor]="series.color"></span>
              <span>{{ series.name }}</span>
            </div>
          </div>
        </div>
        <div class="chart__bottom-right">
          <div class="chart__export-button-wrapper" (clickOutside)="onClickedOutside()">
            <div (click)="toggleExportOptions()" class="chart__export-button">
              <svg-icon src="assets/images/icons/icon-export.svg"></svg-icon>
            </div>
            @if (exportOverflowVisible) {
              <div class="chart__export-overflow">
                <div class="chart__export-overflow-item" (click)="exportAsPNG()">
                  {{ "Chart.Export.PNG" | translate }}
                </div>
                <div class="chart__export-overflow-item" (click)="exportAsJPG()">
                  {{ "Chart.Export.JPG" | translate }}
                </div>
                <div class="chart__export-overflow-item" (click)="exportAsCSV()">
                  {{ "Chart.Export.CSV" | translate }}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
