import { CommonModule } from "@angular/common";
import { Component, computed, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "cert-tabs",
  templateUrl: "cert-tabs.html",
  styleUrls: ["cert-tabs.scss"],
  imports: [CommonModule, TranslateModule],
})
export class CertTabsComponent {
  activeTab = input.required<string>();

  scanActive = computed(() => this.activeTab() === "scan");
  ddosActive = computed(() => this.activeTab() === "ddos");
  tabEmitter = output<string>();

  changeTab(tab: string) {
    this.tabEmitter.emit(tab);
  }
}
