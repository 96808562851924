<div class="dialog dialog--small dialog-wireless">
  <div class="dialog__header">
    <h3 class="dialog__title">
      {{ "Services.Fulfilment.ReportProblem" | translate }}
      <span class="dialog__title-suffix">{{ title }}</span>
    </h3>

    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" mat-dialog-close></svg-icon>
  </div>
  @if (successMessage) {
    <div class="dialog__body dialog__body--grow dialog__body--grey dialog__body--center">
      <div class="dialog-wireless__success">
        <img src="assets/images/success-illustration.svg" alt="" />
        <h3>{{ "SendConfig.SuccessTitle" | translate }}</h3>
        <p>{{ "Dialog.IPP.RequestSentText" | translate }}</p>
      </div>
    </div>
  } @else {
    <div class="dialog__body dialog__body--grow dialog__body--scroll">
      @if (isWireless()) {
        <div class="form-section">
          <div class="form-label">{{ "Incident.Dialogue.SelectLocations" | translate }}</div>
          @if (dropdownLoaded) {
            <div>
              <ng-multiselect-dropdown
                [placeholder]="'Loading locations please wait...'"
                [settings]="dropdownSettings"
                [data]="dropdownList"
                [(ngModel)]="selectedItems"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
              >
              </ng-multiselect-dropdown>
            </div>
          }
        </div>
      }
      <div class="form-section">
        <div class="form-label">{{ "Incident.Dialogue.Description" | translate }}</div>
        <div class="textarea__wrapper">
          <textarea
            class="textarea"
            placeholder="{{ 'Incident.Dialogue.Description' | translate }}"
            [(ngModel)]="__fulfilmentRequest.text"
          ></textarea>
        </div>
      </div>
      <div class="dialog-wireless__bottom">
        <label for="" class="form-label">{{ "Incident.Dialogue.Name" | translate }}</label>
        <div class="input__wrapper">
          <input type="text" class="input" [(ngModel)]="__fulfilmentRequest.fullName" />
        </div>
        <label for="" class="form-label">{{ "Incident.Dialogue.Email" | translate }}</label>
        <div class="input__wrapper">
          <input type="email" class="input" [(ngModel)]="__fulfilmentRequest.email" />
        </div>
        <label for="" class="form-label">{{ "Incident.Dialogue.Phone" | translate }}</label>
        <div class="input__wrapper">
          <input type="phone" class="input" [(ngModel)]="__fulfilmentRequest.phone" />
        </div>
      </div>
    </div>
  }
  <div class="dialog__footer dialog__footer--center">
    <div>
      @if (errorMessage) {
        <div class="red">{{ errorMessage }}</div>
      }
    </div>
    <div class="button__flex">
      @if (!successMessage) {
        <span class="button button--secondary" mat-dialog-close> {{ "Global.Action.Cancel" | translate }} </span>
        <span class="button" (click)="notifySupport()"> {{ "Global.Action.Send" | translate }} </span>
      } @else {
        <span class="button button--secondary" mat-dialog-close> {{ "Global.Action.Close" | translate }} </span>
      }
    </div>
  </div>
</div>
