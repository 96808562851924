import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { LoaderComponent } from "../../components/common/loader/loader";

@Component({
  selector: "page-login",
  templateUrl: "login.html",
  styleUrls: ["../../components/common/navigation/navigation.scss"],
  imports: [LoaderComponent, TranslateModule],
})
export class LoginPage {}
