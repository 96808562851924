import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiHelper } from "../../../helpers/apihelper";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ModifyErrorDetail } from "../../../helpers/self-service/models/types";
import { ResendConfirmation } from "../../../helpers/self-service/resend_confirmation";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "dialog-send-config",
  templateUrl: "dialog-send-config.html",
  styleUrls: ["dialog-send-config.scss", "../../selfservice/selfservice-loader/selfservice-loader.scss"],
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, FormsModule],
})
export class DialogSendConfigComponent {
  private readonly dialogRef = inject(MatDialogRef<DialogSendConfigComponent>);
  private readonly api = inject(ApiHelper);

  protected persons = [
    {
      name: "",
      email: "",
      valid: false,
    },
  ];

  protected currentStep = 1;
  protected errors: ModifyErrorDetail[];
  protected hasErrors = false;
  protected buttonLoading = false;
  private subscriptionId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      type: string;
      subscription: any;
    },
  ) {
    this.subscriptionId = data.subscription.subscriptionId;
  }

  get allValid() {
    return this.persons.filter((e) => !e.valid).length === 0;
  }

  closeDialog(event: Event): void {
    this.dialogRef.close();
  }

  setState(index: number, state: boolean) {
    this.persons[index].valid = state;
  }

  verify(index: number, event: any) {
    this.persons[index].valid = event.srcElement.classList.contains("ng-valid");
  }

  paste(index: number, event: any) {
    setTimeout(() => this.verify(index, event), 300);
  }

  addRow() {
    const person = { name: "", email: "", valid: false };
    this.persons.push(person);
  }

  removeRow(i) {
    this.persons.splice(i, 1);
  }

  submit() {
    this.buttonLoading = true;
    const command = new ResendConfirmation(this.subscriptionId, SelfServiceCommandKeys.ResendConfirmation);
    this.persons.map((p) => {
      command.addPerson({ fullName: p.name, email: p.email });
    });

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.buttonLoading = false;
        this.currentStep = 2;
      })
      .catch((err) => {
        this.onError(err);
        console.log(err);
        this.buttonLoading = false;
      });
  }

  onError(err: HttpErrorResponse) {
    try {
      this.errors = err.error.detail as ModifyErrorDetail[];
      this.hasErrors = true;
    } catch (e) {
      console.log("unknown error", err);
    }
  }
}
