<div class="malfunction-filter__wrapper" [ngClass]="{ 'mobile-filter-active': mobileFilterActive }">
  <div class="malfunction-filter__mobile-button" (click)="toggleFilter()">
    {{ "Malfunction.FilterResults" | translate }}
  </div>
  <div class="malfunction-filter__mobile-show-results" (click)="toggleFilter()">
    {{ "Malfunction.FilterViewResults" | translate }}
  </div>
  <div class="malfunction-filter">
    <div class="malfunction-filter__section">
      <div class="malfunction-filter__title">{{ "Malfunction.FilterOnService" | translate }}</div>
      <div class="malfunction-filter__select-wrapper">
        <select class="malfunction-filter__select" [(ngModel)]="productType" (ngModelChange)="productTypeChanged()">
          <option value="" selected="selected">{{ "Chart.AllServices" | translate }}</option>
          @if (showTypeFilter("IP")) {
            <option value="IP">{{ "Subscription.IP.Name" | translate }}</option>
          }
          @if (showTypeFilter("LightPath")) {
            <option value="LightPath">{{ "Subscription.LightPath.Name" | translate }}</option>
          }
          @if (showTypeFilter("L2VPN")) {
            <option value="L2VPN">{{ "Subscription.L2VPN.Name" | translate }}</option>
          }
          @if (showTypeFilter("L3VPN")) {
            <option value="L3VPN">{{ "Subscription.L3VPN.Name" | translate }}</option>
          }
          @if (showTypeFilter("FW")) {
            <option value="FW">{{ "Subscription.FW.Name" | translate }}</option>
          }
          @if (showTypeFilter("Port")) {
            <option value="Port">{{ "Subscription.Port.Name" | translate }}</option>
          }
        </select>
        <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
      </div>
      <div class="malfunction-filter__select-wrapper" *ngIf="productType !== ''">
        <select
          class="malfunction-filter__select"
          [(ngModel)]="subscription"
          (ngModelChange)="checkBeforeTriggerFilter()"
        >
          <option value="" selected="selected" *ngIf="productType === 'IP'">
            {{ "Malfunction.Filter.All.IP" | translate }}
          </option>
          <option value="" selected="selected" *ngIf="productType === 'LightPath'">
            {{ "Malfunction.Filter.All.LightPath" | translate }}
          </option>
          <option value="" selected="selected" *ngIf="productType === 'L2VPN'">
            {{ "Malfunction.Filter.All.L2VPN" | translate }}
          </option>
          <option value="" selected="selected" *ngIf="productType === 'L3VPN'">
            {{ "Malfunction.Filter.All.L3VPN" | translate }}
          </option>
          <option value="" selected="selected" *ngIf="productType === 'FW'">
            {{ "Malfunction.Filter.All.Firewall" | translate }}
          </option>
          <option value="" selected="selected" *ngIf="productType === 'Port'">
            {{ "Malfunction.Filter.All.Port" | translate }}
          </option>
          <option *ngFor="let subscription of subscriptionList[productType]" [ngValue]="subscription">
            {{ subscription.presentableName }}
          </option>
        </select>
        <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
      </div>
    </div>

    @if (subscription !== "") {
      <div class="malfunction-filter__section">
        <div class="malfunction-filter__title">{{ "Malfunction.FilterOnPeriod" | translate }}</div>
        <div class="malfunction-filter__split">
          <div class="malfunction-filter__select-wrapper">
            <input
              class="malfunction-filter__input"
              type="text"
              id="startDate"
              mwlFlatpickr
              [dateFormat]="'d-m-Y'"
              [convertModelValue]="true"
              [disableMobile]="true"
              [(ngModel)]="initialStartDate"
              (ngModelChange)="triggerFilter()"
            />
            <svg-icon class="malfunction-filter__select-calendar-icon" src="assets/images/icons/select-calendar.svg">
            </svg-icon>
          </div>
          <div class="malfunction-filter__select-wrapper">
            <input
              class="malfunction-filter__input"
              type="text"
              id="endDate"
              mwlFlatpickr
              [dateFormat]="'d-m-Y'"
              [convertModelValue]="true"
              [disableMobile]="true"
              [(ngModel)]="initialEndDate"
              (ngModelChange)="triggerFilter()"
            />
            <svg-icon class="malfunction-filter__select-calendar-icon" src="assets/images/icons/select-calendar.svg">
            </svg-icon>
          </div>
        </div>
      </div>
    }

    <div class="malfunction-filter__section">
      <div class="malfunction-filter__title">{{ "Malfunction.FilterOnType" | translate }}</div>
      <filter-bar
        filterTitle="{{ 'Malfunction.PlannedMaintenance' | translate }}"
        filterIcon="status-maintenance"
        filterType="type"
        filterValue="PlannedWork"
        filterClassActive="active"
        filterDefaultActive="true"
      ></filter-bar>
      <filter-bar
        filterTitle="{{ 'Malfunction.Malfunction' | translate }}"
        filterIcon="status-malfunction"
        filterType="type"
        filterValue="Malfunction"
        filterClassActive="active"
        filterDefaultActive="true"
      ></filter-bar>
    </div>

    <div class="malfunction-filter__section">
      <div class="malfunction-filter__title">{{ "Malfunction.FilterOnImpact" | translate }}</div>
      <filter-bar
        filterTitle="Resilience loss"
        filterType="impact"
        filterValue="Resilience loss"
        filterClassActive="active"
        filterDefaultActive="true"
      ></filter-bar>
      <filter-bar
        filterTitle="Down time"
        filterType="impact"
        filterValue="Down time"
        filterClassActive="active"
        filterDefaultActive="true"
      ></filter-bar>
      <filter-bar
        filterTitle="Reduced redundancy"
        filterType="impact"
        filterValue="Reduced Redundancy"
        filterClassActive="active"
        filterDefaultActive="true"
      ></filter-bar>
      <filter-bar
        filterTitle="No impact"
        filterType="impact"
        filterValue="No impact"
        filterClassActive="active"
      ></filter-bar>
    </div>

    <div class="malfunction-filter__section">
      <div class="malfunction-filter__reset" (click)="resetAllFilters()">Reset filters</div>
    </div>
  </div>
</div>
