import { Component, ViewChild, ElementRef, AfterViewInit, inject, output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FlickityDirective } from "ngx-flickity";

// For Flickity
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CommonModule, isPlatformServer } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
@Injectable()
export class AppConfigService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  isPlatformServer() {
    return isPlatformServer(this.platformId);
  }
}

interface OnboardingScreen {
  imageUrl: Array<string>;
  index: number;
  title: string;
  content: string;
  isFirst?: boolean;
  isLast?: boolean;
}

@Component({
  selector: "dialog-onboarding",
  templateUrl: "dialog-onboarding.html",
  imports: [CommonModule, FormsModule, TranslateModule],
})
export class DialogOnboardingComponent implements AfterViewInit {
  protected dialogRef = inject(MatDialogRef<DialogOnboardingComponent>);
  protected translate = inject(TranslateService);

  done = output();

  @ViewChild("flickityelement", { static: true }) flickity: ElementRef;

  public dotInfo: Array<any> = [];
  public neverAgain = false;
  public screens: Array<OnboardingScreen>;

  private flickityElement: any = null;

  constructor() {
    const language = this.translate.currentLang;

    // temporarily hardcode the onboarding contents.
    // eventually this will come from an API call.

    this.screens = [1, 2, 3, 4, 5].map((slide) => ({
      index: slide - 1,
      imageUrl: [`assets/images/onboarding/visual-slide89_${slide}@2x.png`],
      title: this.translate.instant(`Onboarding.Slide89_${slide}.Title`),
      content: this.translate.instant(`Onboarding.Slide89_${slide}.Content`),
      isFirst: slide === 1,
      isLast: slide === 5,
    }));
  }

  closeDialog(): void {
    this.dialogRef.close();
    if (this.neverAgain) {
      this.done.emit();
    }
  }

  markAsCompleted(): void {
    if (this.neverAgain) {
      this.done.emit();
    }
    this.dialogRef.close();
  }

  generateDotInfo() {
    let activeSlide = 0;
    let slideCount = 2;

    if (this.flickityElement) {
      activeSlide = this.flickityElement.flkty.selectedIndex;
      slideCount = this.flickityElement.flkty.slides.length;
    }

    this.dotInfo = Array.from({ length: slideCount }, (_, i) => ({ active: i === activeSlide }));
  }

  flickityClick(index) {
    if (this.flickityElement) {
      this.flickityElement.flkty.select(index, false, false);
    }

    this.generateDotInfo();
  }

  ngAfterViewInit() {
    const acs: AppConfigService = new AppConfigService({});
    this.flickityElement = new FlickityDirective(this.flickity, acs as any);
    this.flickityElement.config = {
      cellAlign: "left",
      imagesLoaded: true,
      pageDots: false,
      prevNextButtons: false,
      draggable: false,
    };
    this.flickityElement.init();
    setTimeout(() => {
      this.generateDotInfo();
    });
  }

  flickityNext() {
    if (this.flickityElement) {
      this.flickityElement.next();
    }

    this.generateDotInfo();
  }
}
