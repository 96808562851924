import { Component } from "@angular/core";
import { CertMitigationComponent } from "../../../../components/cert/cert-mitigation/cert-mitigation";
import { CertDdosLinechartComponent } from "../../../../components/cert/cert-ddos-chart/cert-ddos-chart";
import { CertSecurityComponent } from "../../../../components/cert/cert-security/cert-security";

@Component({
  selector: "ddos-tab",
  templateUrl: "ddos-tab.html",
  imports: [CertDdosLinechartComponent, CertMitigationComponent, CertSecurityComponent],
})
export class DdosTabComponent {}
