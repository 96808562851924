import { Component, computed, Input, OnInit } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { DialogAuthComponent } from "../../../../dialogs/dialog-auth/dialog-auth";

import { AuthService } from "../../../../../services/AuthService";
import { DialogMessageComponent } from "../../../../dialogs/dialog-message/dialog-message";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { openSelfServiceDialog } from "../../../../dialogs/dialog-selfservice/dialog-selfservice.helpers";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FormatDurationPipe } from "../../../../../helpers/datehelper/duration";

@Component({
  selector: "self-service-item",
  templateUrl: "self-service-item.html",
  styleUrls: ["../../shortcut-menu.scss"],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, FormatDurationPipe],
})
export class SelfServiceItem implements OnInit {
  @Input() subscription;

  public isAuthenticatedForSelfService = computed(() => this.auth && this.auth.selfServiceStarted());
  public selfServiceTokenExpires = 0;

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    // check for a pending result from selfservice-authentication.
    // if strong_pending is found, show the auth dialog with
    // a checkmark indicating auth was successful.
    if (this.auth.hasPendingStrongAction("")) {
      this.auth.clearPendingStrongAction("");
      this.showAuthDialog(true);
    }

    if (this.auth.selfServiceStarted()) {
      this.selfServiceTokenExpires = this.auth.selfServiceTokenExpiryTime();
    }
  }

  showAuthDialog(state: boolean = false) {
    this.dialog.open(DialogAuthComponent, { data: { state } });
  }

  startSelfService() {
    this.showAuthDialog();
  }

  showWrongRoleMessage() {
    this.dialog.open(DialogMessageComponent, {
      data: {
        title: this.translate.instant("Dialog.Selfservice.RoleError.Title"),
        message: this.translate.instant("Dialog.Selfservice.RoleError.Message", {
          role: this.auth.getRoleNames(),
        }),
      },
    });
  }

  openSelfserviceDialog(type) {
    const data = { type, subscription: this.subscription };
    openSelfServiceDialog(this.dialog, data);
  }
}
