@if (traffic) {
  <div class="service-summary__speed">
    <h3>{{ "ServiceElement.Traffic" | translate }}</h3>

    <div class="service-summary__speed--block">
      <div class="service-summary__speed--block-title">
        <svg-icon src="assets/images/icons/arrow-long.svg" class="down"></svg-icon>
        <span>{{ "ServiceElement.TrafficIn" | translate }}</span>
      </div>
      <div class="service-summary__speed--block-speed">
        <span>{{ traffic.down.speed }}</span>
        <span>{{ traffic.down.unit }}</span>
      </div>
    </div>

    <div class="service-summary__speed--block">
      <div class="service-summary__speed--block-title">
        <svg-icon src="assets/images/icons/arrow-long.svg" class="up"></svg-icon>
        <span>{{ "ServiceElement.TrafficOut" | translate }}</span>
      </div>
      <div class="service-summary__speed--block-speed">
        <span>{{ traffic.up.speed }}</span>
        <span>{{ traffic.up.unit }}</span>
      </div>
    </div>
  </div>
}
