<a
  class="shortcut-menu__button"
  [routerLink]="[
    '/availability-export/',
    subscription().product.type,
    subscription().product.tag,
    subscription().subscriptionId,
  ]"
  target="_blank"
>
  <svg-icon src="assets/images/icons/export.svg"></svg-icon>
  <span>{{ "ShortcutMenu.Export" | translate }}</span>
</a>
