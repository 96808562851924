import { Component, input, OnChanges, SimpleChanges } from "@angular/core";
import { WirelessHealthDetail } from "../../../gql/generated";
import { KpiItem, KPI_ITEMS_AEROHIVE, KPI_ITEMS_ARUBA, getStatusColor } from "../../../helpers/kpi/kpi-item";
import { isString } from "../../../helpers/is-string";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

interface FormattedKpi {
  days: number;
  values: { value: number; kpi: KpiItem }[];
}

@Component({
  selector: "kpi-table",
  templateUrl: "kpi-table.html",
  styleUrls: ["kpi-table.scss"],
  imports: [CommonModule, MatTooltipModule, TranslateModule],
})
export class KpiTableComponent implements OnChanges {
  wirelessHealthData = input.required<WirelessHealthDetail>();
  vendor = input.required<string>();

  public kpiItems: KpiItem[] = [];
  public kpiRowsLeft: FormattedKpi[] = [];
  public kpiRowsRight: FormattedKpi[] = [];

  getStatus = getStatusColor;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["vendor"]) {
      if (isString(changes["vendor"].currentValue)) {
        const vendor: string = changes["vendor"].currentValue.toLowerCase().trim().replace(" ", "-");
        if (vendor === "aerohive") {
          this.kpiItems = KPI_ITEMS_AEROHIVE;
        } else if (vendor === "aruba") {
          this.kpiItems = KPI_ITEMS_ARUBA;
        } else {
          console.warn(`Unknown wifi vendor: ${vendor}.`);
          this.kpiItems = [];
        }
      } else {
        this.kpiItems = [];
      }
    }

    if (changes["wirelessHealthData"]) {
      this.updateKpiData("twofour");
      this.updateKpiData("five");
    }
  }

  updateKpiData(frequency: "twofour" | "five") {
    if (this.wirelessHealthData() === undefined) {
      return;
    }

    let kpiMoments: string[] = [];
    kpiMoments[3] = "last3Days";
    kpiMoments[7] = "lastWeek";
    kpiMoments[1] = "today";

    kpiMoments.forEach((momentName, numDays) => {
      const extractKpiMoment = (kpiItem) => {
        let kpiValue = this.wirelessHealthData()[momentName][frequency][kpiItem.key];
        if (!kpiItem.percentage) {
          kpiValue = parseFloat(kpiValue).toFixed(2);
        }
        return { value: kpiValue, kpi: kpiItem };
      };

      const kpiMomentData: { value: number; kpi: KpiItem }[] = this.kpiItems.map(extractKpiMoment);
      const kpi = { days: numDays, values: kpiMomentData };
      if (frequency === "five") {
        this.kpiRowsRight.push(kpi);
      } else {
        this.kpiRowsLeft.push(kpi);
      }
    });
  }
}
