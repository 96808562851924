<page [subscription]="subscription">
  @if (isLoading()) {
    <loader />
  } @else if (isNotFound()) {
    <not-found />
  } @else if (isNotAuthorized()) {
    <not-authorized />
  } @else if (pageLoaded()) {
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col--flex">
          <port-shortcut-menu [subscription]="subscription" [isTerminated]="isTerminated" [activities]="activities" />
        </div>

        <div class="col-lg-9">
          <div class="card">
            <subscription-detail-header [subscription]="subscription" [isTerminated]="isTerminated" />

            <div class="row">
              <div class="col-12 col-sm-7">
                <table class="table-properties table-properties--no-margin">
                  <tr>
                    <td>{{ "ServiceElement.Owner" | translate }}</td>
                    <td>{{ owner() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.OwnAlias" | translate }}</td>
                    <td>
                      <alias-editor [customerDescription]="customerDescription()" [subscriptionId]="subscriptionId()" />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.SURFnetDescription" | translate }}</td>
                    <td>{{ description() }}</td>
                  </tr>
                  @if (hasReferences()) {
                    <tr third-party-service-ids-row-content [subscription]="subscription"></tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.PortID" | translate }}</td>
                    <td><service-id [subscriptionId]="subscriptionId()" /></td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.PortSpeed" | translate }}</td>
                    <td>{{ portSpeed() | service_speed }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.PortMode" | translate }}</td>
                    <td>{{ portMode() }}</td>
                  </tr>
                  @if (autoNegotiation()) {
                    <tr>
                      <td>{{ "ServiceElement.Autonegotiation" | translate }}</td>
                      <td>{{ autoNegotiation() | enabled_or_value }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.L2MTU" | translate }}</td>
                    <td>9100</td>
                  </tr>
                  @if (aggregateParent()) {
                    <tr>
                      <td>{{ "ServiceElement.MemberOfAggregate" | translate }}</td>
                      <td>
                        <a [routerLink]="['/subscription/Port/AGGSP/', aggregateParent().subscriptionId]" class="link">
                          {{ aggregateParent().customerDescription }}
                        </a>
                      </td>
                    </tr>
                  }
                </table>
              </div>
              <div class="col-12 col-sm-5">
                <table class="table-properties table-properties--no-margin">
                  @if (!isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.City" | translate }}</td>
                      <td>
                        @if (subscription.address; as address) {
                          <span> {{ address.city }} ({{ subscription.surfLocationCode }}) </span>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>{{ "ServiceElement.Address" | translate }}</td>
                      <td>
                        @if (subscription.address; as address) {
                          <span> {{ address.street }} {{ address.number }} {{ address.city }} </span>
                        }
                      </td>
                    </tr>
                    @if (subscription.patchPosition; as patchPosition) {
                      <tr>
                        <td>{{ "ServiceElement.PatchPosition" | translate }}</td>
                        <td>{{ patchPosition }}</td>
                      </tr>
                    }
                    @if (subscription.ieeeInterfaceType; as ieeeInterfaceType) {
                      <tr>
                        <td>{{ "ServiceElement.InterfaceType" | translate }}</td>
                        <td>{{ ieeeInterfaceType }}</td>
                      </tr>
                    }
                    @if (subscription.fiberType; as fiberType) {
                      <tr>
                        <td>{{ "ServiceElement.FiberConnectorType" | translate }}</td>
                        <td>{{ fiberType }} {{ subscription.connectorType }}</td>
                      </tr>
                    }
                  }
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.Status" | translate }}</td>
                      <td>{{ status() }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Start" | translate }}</td>
                    <td>{{ startDate() | subscription_date }}</td>
                  </tr>
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.End" | translate }}</td>
                      <td>{{ endDate() | subscription_date }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Domain" | translate }}</td>
                    <td>{{ domain() }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      @if (isTerminated) {
        <div class="hide-everything-after-this-div"></div>
      }
      <div class="divider">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div>
        <div class="section">
          <subscription-detail-container
            [productType]="productType()"
            [subscription]="subscription"
          ></subscription-detail-container>
        </div>
      </div>
      <linechart id="chart" #chart [subscription]="subscription" />
    </div>
  }
</page>
