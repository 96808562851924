import { Component, Input, EventEmitter, input, computed } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TranslateModule } from "@ngx-translate/core";
import { ServiceSummaryComponent } from "../service-summary/service-summary";
import { EmptyStateComponent } from "../empty-state/empty-state";
import { EnabledPipe } from "../../../helpers/enabledpipe/enabledpipe";

@Component({
  selector: "uplink-detail-container",
  templateUrl: "uplink-detail-container.html",
  imports: [AngularSvgIconModule, TranslateModule, ServiceSummaryComponent, EmptyStateComponent, EnabledPipe],
})
export class UplinkDetailContainer {
  protected subscription = input.required<any>();

  protected ipGateway = computed(() => this.subscription().ipGateway);

  @Input() bus: EventEmitter<string>;
}
