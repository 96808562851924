import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "keys",
})
export class KeysPipe implements PipeTransform {
  transform(value: object): string[] {
    return Object.keys(value);
  }
}
