import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SelfserviceAuthenticationBoxComponent } from "../selfservice-authentication-box/selfservice-authentication-box";
import { MatDialogClose } from "@angular/material/dialog";

@Component({
  selector: "selfservice-success",
  templateUrl: "selfservice-success.html",
  styleUrls: ["../selfservice-loader/selfservice-loader.scss"],
  imports: [SelfserviceAuthenticationBoxComponent, TranslateModule, MatDialogClose],
})
export class SelfserviceSuccessComponent {}
