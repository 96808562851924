import { PipeTransform, Pipe } from "@angular/core";

const STATE_MAPPING = {
  ok: "Ok",
  notfound: "NotFound",
  unknown: "Unknown",
  notraffic: "NoTraffic",
  malfunction: "Warning",
  initial: "Initial",
  pending: "Pending",
  stopped: "Stopped",
  "active-corero-filters": "ActiveCoreroFilters",
  "inactive-corero-filters": "InactiveCoreroFilters",
  "open-incidents": "OpenIncidents",
};

@Pipe({
  name: "state_to_camelcase",
})
export class StateToCamelcase implements PipeTransform {
  transform(value: string): string {
    return STATE_MAPPING[value] || "Unknown";
  }
}
