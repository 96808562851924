import { Component } from "@angular/core";
import { FooterComponent } from "../../components/common/footer/footer";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { TranslateModule } from "@ngx-translate/core";
@Component({
  selector: "page-about",
  templateUrl: "about.html",
  imports: [FooterComponent, NavigationComponent, TranslateModule],
})
export class AboutPage {}
