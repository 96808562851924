export const CertChartOptions = {
  chart: {
    type: "packedbubble",
    spacingTop: 0,
    spacingRight: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    plotBorderWidth: 0,
    margin: [0, 0, 0, 0],
    height: "100%",
  },
  lang: {
    noData: "",
  },
  title: {
    text: "",
  },
  loading: {
    labelStyle: { backgroundImage: "/assets/images/icons/loader.svg" },
    // style: {
    //   backgroundImage: "/assets/images/icons/loader.svg",
    // },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },

  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  colors: ["#4FB3CF", "#7AC5DA", "#A7D9E7"],
  plotOptions: {
    packedbubble: {
      minSize: "50px",
      maxSize: "100%",
      zMin: 0,
      layoutAlgorithm: {
        splitSeries: false,
        gravitationalConstant: 0.02,
      },
      dataLabels: {
        enabled: true,
        format: "{point.name}",
        filter: {
          property: "y",
          operator: ">",
          value: 30,
        },
        style: {
          color: "white",
          textOutline: "none",
          fontWeight: "bold",
          fontFamily: "Proxima, sans-serif",
          fontSize: "12px",
        },
      },
      marker: {
        fillOpacity: 1,
      },
    },
  },
  tooltip: {
    useHTML: true,
    // eslint-disable-next-line object-shorthand
    formatter: function () {
      let childtooltip = "";
      if (this.point !== undefined && this.point !== null) {
        if (
          this.point.name !== undefined
          && this.point.name != null
          && this.point.value !== undefined
          && this.point.value !== null
        ) {
          childtooltip = `<b>${this.point.name}:</b> ${this.point.value}`;
        }
      }
      if (childtooltip === "") {
        return `<b>${this.series.userOptions.name}:</b> ${this.series.userOptions.value}<br>`;
      } else {
        return `${childtooltip}`;
      }
    },
  },
  series: [],
};
