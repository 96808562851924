<div class="container">
  <div class="row">
    <div class="col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
      <div class="card">
        <h2>{{ "Global.Forbidden" | translate }}</h2>
        <p>{{ "Page.Forbidden.Description" | translate }}</p>
      </div>

      @if (canImpersonate()) {
        <div class="well well--orange">
          <strong>{{ "Global.Impersonate.Subscription" | translate }}</strong>
          <p>{{ "Global.Impersonate" | translate }}</p>
        </div>
      }
    </div>
  </div>
</div>
