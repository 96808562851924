import { Component, input, output, OnChanges, SimpleChanges, inject } from "@angular/core";
import { PageInfo } from "../../../gql/generated";
import { MatDialog } from "@angular/material/dialog";
import { DialogCertComponent } from "../../dialogs/dialog-cert/dialog-cert";
import { MatSortModule, Sort } from "@angular/material/sort";
import { TranslateModule } from "@ngx-translate/core";
import { PaginationComponent } from "../../common/pagination/pagination";
import { CommonModule } from "@angular/common";

@Component({
  selector: "cert-table",
  templateUrl: "cert-table.html",
  styleUrls: ["cert-table.scss"],
  imports: [CommonModule, MatSortModule, PaginationComponent, TranslateModule],
})
export class CertTableComponent implements OnChanges {
  private dialog = inject(MatDialog);

  readonly airtScans = input.required<any>();
  pageInfo = input.required<PageInfo>();
  paginationChanged = output<number>();
  sortingChanged = output<Sort>();

  protected items = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["airtScans"]) {
      const airtScans = this.airtScans();
      if (airtScans.edges) {
        this.items.splice(0);
        this.items = [...airtScans.edges];
      }
    }
  }

  onPaginationChanged($event) {
    this.paginationChanged.emit($event);
  }

  openDialog(item) {
    const dialogRef = this.dialog.open(DialogCertComponent, {
      data: item.node,
    });
  }

  sortData($event: Sort) {
    this.sortingChanged.emit($event);
  }
}
