<div class="alias-editor">
  @if (!isEditable()) {
    <span class="alias">
      {{ customerDescription }}
      <div class="alias__icon" role="button" aria-label="Edit" (click)="startEditingCustomerDescription($event)">
        <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
      </div>
    </span>
  } @else {
    <div class="alias__form">
      <input
        [(ngModel)]="temporarySubscriptionName"
        (click)="cancelEvent($event)"
        class="input"
        type="text"
        role="textbox"
        value="{{ customerDescription }}"
        autofocus
      />
      <span
        class="alias__form-button alias__form-button--save"
        role="button"
        aria-label="Save"
        (click)="saveCustomerDescription($event)"
      >
        <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
      </span>
      <span
        role="button"
        aria-label="Cancel"
        class="alias__form-button alias__form-button--cancel"
        (click)="cancelEditingCustomerDescription($event)"
      >
        <svg-icon src="assets/images/icons/x.svg"></svg-icon>
      </span>
    </div>
  }
</div>
