import { Component, computed, inject, Inject, OnInit, signal } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from "@angular/material/dialog";
import { SubscriptionDetails } from "../../../helpers/types";
import { IDropdownSettings, NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FulfilmentRequest } from "../../../helpers/self-service/models/types";
import { ApiHelper } from "../../../helpers/apihelper";
import { AuthService } from "../../../services/AuthService";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Instance } from "../../models/instance";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "dialog-incident",
  templateUrl: "dialog-incident.html",
  styleUrls: ["dialog-incident.scss"],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    TranslateModule,
    FormsModule,
    NgMultiSelectDropDownModule,
    MatDialogClose,
  ],
})
export class DialogIncidentComponent implements OnInit {
  private readonly dialogRef = inject(MatDialogRef<DialogIncidentComponent>);
  private readonly api = inject(ApiHelper);
  private readonly auth = inject(AuthService);
  private readonly translate = inject(TranslateService);
  private readonly subscriptionService = inject(SubscriptionService);

  dropdownSettings: IDropdownSettings;
  public __fulfilmentRequest: any;
  public dropdownLoaded = false;
  public subscription;
  public subscriptions: any[];
  public subscriptionsMeta = {};
  public dropdownList = [{ item_id: "", item_text: "" }];
  public selectedItems = [];
  public errorMessage = "";
  public successMessage = "";
  public title = "";

  protected isWireless = signal(false);

  private customerId = "";

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {
    this.subscription = data.subscription;
    this.__fulfilmentRequest = {};
    this.__fulfilmentRequest.fullName = this.auth.state.currentUser.displayName;
    this.__fulfilmentRequest.email = this.auth.state.currentUser.email;
    this.customerId = this.auth.state.viewingCustomer.customerId;

    this.selectedItems = [{ item_id: 1, item_text: "Locatie 1" }];
    this.isWireless.set(this.subscription.product.type === "Wireless");
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  fetch() {
    // TODO: handle this via replication so we can remove this deprecated API call! (#353)
    this.subscriptionService.getSubscriptionsOfType(this.subscription.product.type).then((subs) => {
      this.subscriptions = subs;
      this.subscriptions = subs.map((sub) => {
        this.setMeta(sub.subscriptionId);
        return Object.assign(new Instance(), sub);
      });
    });
  }

  setMeta(id) {
    this.api.raw_details(this.subscription.product.type, id).then((data) => {
      this.subscriptions.map((subscription) => {
        if (subscription.subscriptionId === id) {
          let subscriptionDetails = data as SubscriptionDetails;
          this.subscriptionsMeta[id] = subscriptionDetails;
          this.collectLocations(this.subscriptionsMeta[id]);
        }

        // sort the dropdownList
        this.dropdownList.sort((a, b) => a.item_text.localeCompare(b.item_text));
      });
    });
  }

  collectLocations(meta: SubscriptionDetails) {
    if (meta.location) {
      let item_text = meta.location.city + " - " + this.presentableName(meta);
      this.dropdownList = this.dropdownList.concat({
        item_id: meta.subscriptionId,
        item_text,
      });

      if (meta.subscriptionId === this.subscription.subscriptionId) {
        this.selectedItems = this.selectedItems.concat({
          item_id: meta.subscriptionId,
          item_text,
        });

        this.title = this.presentableName(meta);
      }
    }
  }

  presentableName(subscription) {
    if (subscription.customerDescriptions && subscription.customerDescriptions[this.customerId]) {
      return subscription.customerDescriptions[this.customerId];
    }

    return subscription.description;
  }

  ngOnInit() {
    this.dropdownList = [];
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 1,
      allowSearchFilter: true,
      defaultOpen: false,
    };

    this.dropdownLoaded = true;

    // fetch extra data if this is a wireless product
    if (this.isWireless()) {
      this.fetch();
    }

    this.title = this.presentableName(this.subscription);
  }
  onItemSelect(item: any) {}
  onSelectAll(items: any) {}

  notifySupport(): void {
    this.successMessage = "";
    this.errorMessage = "";

    if (!this.__fulfilmentRequest.text || this.__fulfilmentRequest.text.length < 10) {
      this.errorMessage = this.translate.instant("Selfservice.InvalidDescription");
      return;
    }

    if (!this.__fulfilmentRequest.fullName || this.__fulfilmentRequest.fullName.length < 5) {
      this.errorMessage = this.translate.instant("Selfservice.InvalidFullname");
      return;
    }

    if (!this.__fulfilmentRequest.phone || this.__fulfilmentRequest.phone.length < 5) {
      this.errorMessage = this.translate.instant("Selfservice.InvalidPhone");
      return;
    }

    const customerId = localStorage.getItem("viewingCustomerGUID");
    const locationText = this.selectedItems.map((item) => `- ${item.item_text}`).join("\n");
    const requestMessage = [
      this.__fulfilmentRequest.text,
      `${this.translate.instant("Selfservice.Locations")}: `,
      locationText,
    ].join("\n\n");

    const request: FulfilmentRequest = {
      contacts: [
        {
          fullName: this.__fulfilmentRequest.fullName,
          email: this.__fulfilmentRequest.email,
          phone: this.__fulfilmentRequest.phone,
        },
      ],
      serviceRequest: "problem",
      text: requestMessage,
      subscriptionId: this.subscription.subscriptionId,
      product: this.subscription.product.type,
    };

    this.api
      .add_new_fulfilment_problem(customerId, JSON.stringify(request))
      .then((result) => {
        this.successMessage = this.translate.instant("Selfservice.NotificationSent");
      })
      .catch((err) => {
        this.errorMessage = err;
      });
  }
}
