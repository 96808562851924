<div class="malfunction-card__timeline" [ngClass]="{ 'malfunction-card__timeline--future': this.isFutureMessage() }">
  <div class="malfunction-card__timeline-item">
    @if (showTimeElement) {
      <div class="malfunction-card__timeline-date">
        {{ startTimestamp() | date: "d MMM y" }}
      </div>
    }
    <div class="malfunction-card">
      <div class="malfunction-card__inner">
        <header class="malfunction-card__header">
          @if (isMalfunction()) {
            <div class="malfunction-card__icon-box malfunction-card__icon-box--malfunction">
              <svg-icon src="assets/images/icons/status-malfunction.svg"> </svg-icon>
            </div>
          }
          @if (isPlannedWork() && !isFutureMessage()) {
            <div class="malfunction-card__icon-box malfunction-card__icon-box--maintenance">
              <svg-icon src="assets/images/icons/status-maintenance.svg"> </svg-icon>
            </div>
          }
          @if (isPlannedWork() && isFutureMessage()) {
            <div class="malfunction-card__icon-box malfunction-card__icon-box--maintenance-planned">
              <svg-icon src="assets/images/icons/status-maintenance-planned.svg"> </svg-icon>
            </div>
          }
          <div class="malfunction-card__header-caption">
            <h3 class="malfunction-card__title">{{ message()?.heading }}</h3>
            <div>{{ "Malfunction.RegisteredOn" | translate }}: {{ planTimestamp() | date: "d MMM y, hh:mm" }}</div>
          </div>
          <div class="malfunction-card__calendar-button" (click)="addToCalendar()">
            <svg-icon src="assets/images/icons/icon-add-to-calendar.svg"> </svg-icon>
            <span>{{ "Malfunction.AddToCalendar" | translate }}</span>
          </div>
        </header>
        <div class="malfunction-card__body">
          <p>{{ message()?.contents }}</p>
          <ul class="malfunction-card__update-list">
            <li class="malfunction-card__update-item" *ngFor="let update of message()?.updates; let i = index">
              <div class="malfunction-card__update-time">{{ update.timestamp | date: "d MMM y, HH:mm" }}</div>
              <div class="malfunction-card__update-description">{{ update.description }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="malfunction-card__bottom">
        <div class="malfunction-card__bottom-col">
          <div class="malfunction-card__bottom-box">
            <div class="malfunction-card__bottom-title">{{ "Malfunction.StartMalfunction" | translate }}</div>
            <div>{{ startTimestamp() | date: "d MMM y, HH:mm" }}</div>
          </div>
        </div>
        <div class="malfunction-card__bottom-col">
          <div class="malfunction-card__bottom-box">
            <div class="malfunction-card__bottom-title">{{ "Malfunction.EndMalfunction" | translate }}</div>
            <div>{{ endTimestamp() | date: "d MMM y, HH:mm" }}</div>
          </div>
        </div>
        <div class="malfunction-card__bottom-col">
          <div class="malfunction-card__bottom-box">
            <div class="malfunction-card__bottom-title">{{ "Malfunction.Impact" | translate }}</div>
            <div>{{ impactsString() }}</div>
          </div>
        </div>
        <div class="malfunction-card__bottom-col">
          <div class="malfunction-card__bottom-box malfunction-card__bottom-box--clickable" (click)="openDialog()">
            <div class="malfunction-card__bottom-title">{{ "Malfunction.AffectedServices" | translate }}</div>
            <div class="malfunction-card__bottom-link" style="text-transform: lowercase">
              {{ nrOfAffectedServices() }} {{ "Malfunction.AffectedServices" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
