import { Component, inject, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SLAReport } from "../../subscription-detail/sla-report/sla-report";

@Component({
  selector: "dialog-sls",
  templateUrl: "dialog-sls.html",
  imports: [AngularSvgIconModule, SLAReport, TranslateModule],
})
export class DialogSlsComponent {
  dialogRef = inject(MatDialogRef<DialogSlsComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  protected closeDialog(): void {
    this.dialogRef.close();
  }
}
