@if (!isTerminated()) {
  <div class="shortcut-menu card card--no-padding">
    <div class="shortcut-menu__header">
      <h3>{{ "ShortcutMenu.Title" | translate }}</h3>
    </div>
    @if (subscription()) {
      <div class="shortcut-menu__body">
        <ng-content />
      </div>
    } @else {
      <div>
        <loader></loader>
      </div>
    }
  </div>
} @else {
  <terminated-card [date]="subscription().endDate | subscription_date" />
}
