import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CertChartComponent } from "../../../../components/cert/cert-chart/cert-chart";
import { CertFilterComponent } from "../../../../components/cert/cert-filter/cert-filter";
import { CertTableComponent } from "../../../../components/cert/cert-table/cert-table";
import { TranslateModule } from "@ngx-translate/core";
import { PageInfo, SearchFilter } from "../../../../gql/generated";
import { AuthService } from "../../../../services/AuthService";
import { AIRT_SCAN_QUERY } from "../../../../graphql/domain/airtscan";
import { Sort } from "@angular/material/sort";
import { CustomerWithSubscriptionIds } from "../../../../graphql/custom-types";
import { SUBSCRIPTIONS_OF_TYPE_QUERY } from "../../../../graphql/domain/subscription";

@Component({
  selector: "scan-tab",
  templateUrl: "scan-tab.html",
  styleUrls: ["../../cert.scss"],
  imports: [CertChartComponent, CertFilterComponent, CertTableComponent, TranslateModule],
})
export class ScanTabComponent implements OnInit {
  public subscriptions: any[] = [];
  public airtScans: any[] = [];
  public searchFilter: SearchFilter = {};
  public pageInfo: PageInfo;
  public prefixes: string[] = [];

  private paginationAfter = 0;
  private sortInfo: Sort;

  constructor(
    private apollo: Apollo,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.fetchPrefixSubscriptions();
  }

  fetchPrefixSubscriptions() {
    this.apollo
      .query<CustomerWithSubscriptionIds>({
        query: SUBSCRIPTIONS_OF_TYPE_QUERY,
        errorPolicy: "all",
        variables: {
          customer: localStorage.getItem("viewingCustomerGUID"),
          products: ["IP_PREFIX"],
          status: ["active", "migrated"],
          portServices: false,
        },
      })
      .subscribe(({ data }) => {
        this.subscriptions = [];
        data.customer.subscriptions?.forEach((sub) => {
          const prefix = sub?.ipPrefix?.ipam?.prefix;
          if (prefix) {
            this.subscriptions.push({
              id: sub.subscriptionId,
              prefix,
            });
          }
          this.prefixes = this.subscriptions.map((subscription) => subscription.prefix);
        });
      });
  }

  async fetchAirtScanInformation() {
    this.apollo
      .query<any>({
        query: AIRT_SCAN_QUERY,
        fetchPolicy: "network-only",
        errorPolicy: "all",
        variables: {
          filter: this.searchFilter,
          first: 20,
          after: this.paginationAfter,
          sort: this.buildSort(),
        },
      })
      .subscribe(({ data }) => {
        this.airtScans = data?.airtScans || [];
        this.pageInfo = data?.airtScans?.pageInfo;
      });
  }

  onFiltersChanged(event: any) {
    this.searchFilter = event;
    this.fetchAirtScanInformation();
  }

  onPaginationChanged(event: number) {
    this.paginationAfter = event > -1 ? event : 0;
    this.fetchAirtScanInformation();
  }

  onSortingChanged(event: any) {
    this.sortInfo = event;
    this.fetchAirtScanInformation();
  }

  buildSort() {
    return {
      order: [{ [this.sortInfo?.active || "ip"]: this.sortInfo?.direction?.toUpperCase() || "ASC" }],
    };
  }
}
