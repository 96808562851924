<div class="section">
  @if (linkMemberSubscriptions() !== null && linkMemberSubscriptions() !== undefined) {
    <div class="row">
      <div class="col-12">
        <br />
        <h3 class="title-with-icon">
          <svg-icon src="assets/images/icons/port.svg" class="purple"></svg-icon>
          <span>Linkmembers</span>
        </h3>
      </div>
      @for (linkMemberSubscription of linkMemberSubscriptions(); track linkMemberSubscription.subscriptionId) {
        <div class="col-12 col-md-6">
          <port-tile [service]="linkMemberSubscription" />
        </div>
      }
    </div>
  }
</div>
