import { gql } from "apollo-angular";
import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
  IPv4Address: any;
  IPv4Network: any;
  IPv6Address: any;
  IPv6Network: any;
  UUID: any;
};

/** Information about wireless access point */
export type AccessPointType = {
  __typename?: "AccessPointType";
  brand?: Maybe<Scalars["String"]>;
  ciStatus?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  supplier?: Maybe<Scalars["String"]>;
};

/** Information about address */
export type AddressType = {
  __typename?: "AddressType";
  addition?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  extraAddressLine?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

/** Information about aggregate parent of a service port */
export type AggregateParentType = {
  __typename?: "AggregateParentType";
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description: Scalars["String"];
  product: ProductType;
  subscriptionId: Scalars["UUID"];
};

/** Information about aggsp */
export type AggspSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "AggspSubscriptionDetailsType";
  address?: Maybe<AddressType>;
  amountReserved: Scalars["Int"];
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  linkMemberSubscriptions: Array<LinkMemberDetailsType>;
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  portId?: Maybe<Scalars["Int"]>;
  portMode?: Maybe<Scalars["String"]>;
  portSpeed?: Maybe<Scalars["Int"]>;
  portSubscription?: Maybe<Scalars["UUID"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  services?: Maybe<Array<ServiceType>>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  surfLocationCode?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

export type BaseEnrichedTagsSubscription = {
  enrichedTags: Array<Scalars["String"]>;
  subscriptionId: Scalars["UUID"];
};

export type BaseHealthSubscription = {
  enrichedTags: Array<Scalars["String"]>;
  healthStatus?: Maybe<HealthStatus>;
  subscriptionId: Scalars["UUID"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type BaseSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Base sort ordering of results */
export type BaseSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about a circuit */
export type CircuitType = {
  __typename?: "CircuitType";
  endpoints: Array<ServiceAttachPointType>;
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

export type Counts = {
  __typename?: "Counts";
  /** Firewall subscriptions count */
  fw: SubscriptionCount;
  /** IP subscriptions count */
  ip: SubscriptionCount;
  /** IP Prefix subscriptions count */
  ipPrefix: SubscriptionCount;
  /** L2VPN subscriptions count */
  l2vpn: SubscriptionCountWithFirewallsEnabled;
  /** L3VPN subscriptions count */
  l3vpn: SubscriptionCountWithFirewallsEnabled;
  /** LightPath subscriptions count */
  lp: SubscriptionCount;
  /** Port subscriptions count */
  port: SubscriptionCount;
  /** IP Prefix subscriptions count */
  wireless: SubscriptionCount;
};

export type CountsFwArgs = {
  customerId: Scalars["UUID"];
};

export type CountsIpArgs = {
  customerId: Scalars["UUID"];
};

export type CountsIpPrefixArgs = {
  customerId: Scalars["UUID"];
};

export type CountsL2vpnArgs = {
  customerId: Scalars["UUID"];
};

export type CountsL3vpnArgs = {
  customerId: Scalars["UUID"];
};

export type CountsLpArgs = {
  customerId: Scalars["UUID"];
};

export type CountsPortArgs = {
  customerId: Scalars["UUID"];
};

export type CountsWirelessArgs = {
  customerId: Scalars["UUID"];
};

/** list of subscription description by customer */
export type CustomerDescriptionType = {
  __typename?: "CustomerDescriptionType";
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  subscriptionId?: Maybe<Scalars["UUID"]>;
  version: Scalars["Int"];
};

/** Information about customer */
export type CustomerType = {
  __typename?: "CustomerType";
  customerId: Scalars["String"];
  fullname: Scalars["String"];
};

/** Information about ESI */
export type EthernetSegmentIdentifierType = {
  __typename?: "EthernetSegmentIdentifierType";
  endpoints?: Maybe<Array<ServiceAttachPointType>>;
  firewallInterconnect?: Maybe<L2InterconnectType>;
  segmentId?: Maybe<Scalars["Int"]>;
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type EvpnPtpSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type EvpnPtpSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about firewall */
export type FirewallSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "FirewallSubscriptionDetailsType";
  availabilityZone?: Maybe<Scalars["String"]>;
  customer?: Maybe<CustomerType>;
  customerAsn?: Maybe<Scalars["Int"]>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  firewallEnabled: Scalars["Boolean"];
  fwAsn: Scalars["Float"];
  insync: Scalars["Boolean"];
  ipGateway?: Maybe<IpGatewayType>;
  l2Endpoints: Array<L2EndpointType>;
  l3Endpoints: Array<L3EndpointType>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  serviceSpeed: Scalars["Int"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
  version: Scalars["Int"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type FirewallSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type FirewallSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about SURFfirewall */
export type FirewallSubscriptionType = SubscriptionDetails & {
  __typename?: "FirewallSubscriptionType";
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

export type FirewallSubscriptionTypeConnectionV2 = {
  __typename?: "FirewallSubscriptionTypeConnectionV2";
  page: Array<FirewallSubscriptionType>;
  pageInfo: PageInfo;
};

export type FwSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "FwSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type HealthStatus = {
  __typename?: "HealthStatus";
  status?: Maybe<Scalars["String"]>;
  traffic?: Maybe<Traffic>;
};

/** Information about ip service attach point */
export type IpServiceAttachPointType = {
  __typename?: "IPServiceAttachPointType";
  address?: Maybe<AddressType>;
  bfd?: Maybe<Scalars["Boolean"]>;
  bfdMinimumInterval?: Maybe<Scalars["Int"]>;
  bfdMultiplier?: Maybe<Scalars["Int"]>;
  bgpExportPolicy?: Maybe<Scalars["String"]>;
  bgpHashAlgorithm?: Maybe<Scalars["String"]>;
  bgpPassword?: Maybe<Scalars["String"]>;
  bgpSessionPriority?: Maybe<Scalars["String"]>;
  ipPrefixes: Array<Scalars["String"]>;
  ipv4Mtu?: Maybe<Scalars["Int"]>;
  ipv4PointToPointPrefix?: Maybe<IpPointToPointPrefixType>;
  ipv6Mtu?: Maybe<Scalars["Int"]>;
  ipv6PointToPointPrefix?: Maybe<IpPointToPointPrefixType>;
  name: Scalars["String"];
  organisation?: Maybe<Scalars["String"]>;
  port?: Maybe<ServicePortSubscriptionType>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
  vlanrange: Scalars["String"];
};

export type IpSubscriptionListItemType = {
  __typename?: "IPSubscriptionListItemType";
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  product: ProductType;
  subscriptionId: Scalars["UUID"];
};

/** Information about ip gateway */
export type IpGatewayType = {
  __typename?: "IpGatewayType";
  ipPrefixes: Array<Scalars["String"]>;
  ipv4Mtu?: Maybe<Scalars["Int"]>;
  ipv6Mtu?: Maybe<Scalars["Int"]>;
  multiCast: Scalars["Boolean"];
  serviceSpeed: Scalars["Int"];
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  surfcertFilter: Scalars["String"];
  upLinkLocations: Array<UplinkLocationType>;
};

/** Information about ip ptp prefix */
export type IpPointToPointPrefixType = {
  __typename?: "IpPointToPointPrefixType";
  clientFQDN: Scalars["String"];
  clientIpAddress: Scalars["String"];
  network: Scalars["String"];
  surfFQDN: Scalars["String"];
  surfIpAddress: Scalars["String"];
};

/** Information about ip_prefix ddos */
export type IpPrefixDdosType = {
  __typename?: "IpPrefixDdosType";
  /** Get auto mitigation for this prefix */
  autoMitigation: Scalars["Boolean"];
  customerId: Scalars["String"];
  description: Scalars["String"];
  insync: Scalars["Boolean"];
  prefix: Scalars["String"];
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  version: Scalars["Int"];
};

export type IpPrefixDdosTypeConnectionV2 = {
  __typename?: "IpPrefixDdosTypeConnectionV2";
  page: Array<IpPrefixDdosType>;
  pageInfo: PageInfo;
};

export type IpPrefixSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "IpPrefixSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

/** Filter list by attribute, all ranges are inclusive */
export type IpPrefixSubscriptionDdosFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type IpPrefixSubscriptionDdosSort = {
  customerId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  ipPrefix?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type IpPrefixSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type IpPrefixSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about ipprefix */
export type IpPrefixSubscriptionType = SubscriptionDetails & {
  __typename?: "IpPrefixSubscriptionType";
  autoMitigation: Scalars["String"];
  availableIpSubscriptions: Array<IpSubscriptionListItemType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  ipPrefix?: Maybe<IpPrefixType>;
  ipSubscriptions: Array<IpSubscriptionListItemType>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

export type IpPrefixSubscriptionTypeConnectionV2 = {
  __typename?: "IpPrefixSubscriptionTypeConnectionV2";
  page: Array<IpPrefixSubscriptionType>;
  pageInfo: PageInfo;
};

/** Information about ipprefix */
export type IpPrefixType = {
  __typename?: "IpPrefixType";
  afi: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  ipamPrefixId: Scalars["Int"];
  ipamPrefixTags?: Maybe<Array<Scalars["String"]>>;
  name?: Maybe<Scalars["String"]>;
  parent?: Maybe<Scalars["Int"]>;
  parentLabel?: Maybe<Scalars["String"]>;
  prefix: Scalars["String"];
  state: Scalars["Int"];
  stateLabel?: Maybe<Scalars["String"]>;
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  tag?: Maybe<Scalars["String"]>;
  vrf: Scalars["Int"];
  vrfLabel?: Maybe<Scalars["String"]>;
};

/** Information about ip service settings */
export type IpServiceSettingsType = {
  __typename?: "IpServiceSettingsType";
  asn?: Maybe<Scalars["Int"]>;
  multicast?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  subscriptionInstanceId: Scalars["UUID"];
  surfcertFilter: Scalars["String"];
  tag: Scalars["String"];
};

/** Information about ip */
export type IpSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "IpSubscriptionDetailsType";
  availabilityZone?: Maybe<Scalars["String"]>;
  circuits: Array<CircuitType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  firewallEnabled: Scalars["Boolean"];
  insync: Scalars["Boolean"];
  ipRoutingType: Scalars["String"];
  ipss: IpServiceSettingsType;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection?: Maybe<Scalars["String"]>;
  references: Array<ReferenceType>;
  saps: Array<IpServiceAttachPointType>;
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
  version: Scalars["Int"];
};

/** Information about IP */
export type IpSubscriptionListType = SubscriptionDetails & {
  __typename?: "IpSubscriptionListType";
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

export type IpSubscriptionListTypeConnectionV2 = {
  __typename?: "IpSubscriptionListTypeConnectionV2";
  page: Array<IpSubscriptionListType>;
  pageInfo: PageInfo;
};

/** Information about irb */
export type IrbSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "IrbSubscriptionDetailsType";
  address?: Maybe<AddressType>;
  amountReserved: Scalars["Int"];
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  portId?: Maybe<Scalars["Int"]>;
  portMode?: Maybe<Scalars["String"]>;
  portSpeed?: Maybe<Scalars["Int"]>;
  portSubscription?: Maybe<Scalars["UUID"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  services: Array<ServiceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  surfLocationCode?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

/** Information about L2 endpoint */
export type L2EndpointType = {
  __typename?: "L2EndpointType";
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  ipv4PtpPrefixes: Array<Scalars["String"]>;
  ipv6PtpPrefixes: Array<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  otherSubscriptionId?: Maybe<Scalars["UUID"]>;
  product: ProductType;
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  tags: Array<Scalars["String"]>;
};

/** Information about L2 Interconnect */
export type L2InterconnectType = {
  __typename?: "L2InterconnectType";
  availabilityZone?: Maybe<Scalars["String"]>;
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId?: Maybe<Scalars["UUID"]>;
  description: Scalars["String"];
  endpoints?: Maybe<Array<ServiceAttachPointType>>;
  firewallSubscriptionId?: Maybe<Scalars["UUID"]>;
  organisation?: Maybe<Scalars["String"]>;
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
};

/** Information about L2VPN circuit */
export type L2VpnCircuitType = {
  __typename?: "L2VPNCircuitType";
  bumFilter?: Maybe<Scalars["Boolean"]>;
  endpoints: Array<ServiceAttachPointType>;
  esis: Array<EthernetSegmentIdentifierType>;
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  speedPolicer?: Maybe<Scalars["Boolean"]>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
  vlanRetagging?: Maybe<Scalars["Boolean"]>;
};

/** Information about L2VPN */
export type L2VpnSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "L2VPNSubscriptionDetailsType";
  circuits: Array<L2VpnCircuitType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  esis: Array<EthernetSegmentIdentifierType>;
  firewallEnabled: Scalars["Boolean"];
  firewallInterconnects: Array<L2InterconnectType>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  saps: Array<ServiceAttachPointType>;
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed: Scalars["Int"];
  speedPolicer: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
  version: Scalars["Int"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type L2VpnSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type L2VpnSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about l2vpn */
export type L2VpnSubscriptionType = {
  __typename?: "L2VpnSubscriptionType";
  customer: CustomerType;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description: Scalars["String"];
  domain: Scalars["String"];
  locations: Array<LocationType>;
  product: ProductType;
  services: Array<PortServiceType>;
  status: Scalars["String"];
  subscriptionId: Scalars["String"];
  tags: Array<Scalars["String"]>;
};

export type L2VpnSubscriptionTypeConnectionV2 = {
  __typename?: "L2VpnSubscriptionTypeConnectionV2";
  page: Array<L2VpnSubscriptionType>;
  pageInfo: PageInfo;
};

/** Information about L3 endpoint */
export type L3EndpointType = {
  __typename?: "L3EndpointType";
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  organisation?: Maybe<Scalars["String"]>;
  otherSubscriptionId?: Maybe<Scalars["UUID"]>;
  product: ProductType;
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  tags: Array<Scalars["String"]>;
};

/** Information about L3VPN firewall interconnect */
export type L3FwInterconnectType = {
  __typename?: "L3FwInterconnectType";
  availabilityZone: Scalars["String"];
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  firewallAsn: Scalars["String"];
  firewallSubscriptionId: Scalars["UUID"];
  ipv4AddressFirewall?: Maybe<Scalars["IPv4Address"]>;
  ipv4MaxPrefix?: Maybe<Scalars["Int"]>;
  ipv4Mtu?: Maybe<Scalars["Int"]>;
  ipv4Prefix?: Maybe<Scalars["IPv4Network"]>;
  ipv6AddressFirewall?: Maybe<Scalars["IPv6Address"]>;
  ipv6MaxPrefix?: Maybe<Scalars["Int"]>;
  ipv6Mtu?: Maybe<Scalars["Int"]>;
  ipv6Prefix?: Maybe<Scalars["IPv6Network"]>;
  organisation?: Maybe<Scalars["String"]>;
  saps: Array<ServiceAttachPointType>;
  subscriptionId: Scalars["UUID"];
  subscriptionInstanceId: Scalars["UUID"];
};

/** Information about L3VPN circuit */
export type L3VpnCircuitType = {
  __typename?: "L3VPNCircuitType";
  endpoints: Array<ServiceAttachPointType>;
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  speedPolicer?: Maybe<Scalars["Boolean"]>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

/** Information about L3VPN service settings */
export type L3VpnsapServiceSettingsType = {
  __typename?: "L3VPNSAPServiceSettingsType";
  asn: Scalars["Int"];
  bfd?: Maybe<Scalars["Boolean"]>;
  bfdMinimumInterval?: Maybe<Scalars["Int"]>;
  bfdMultiplier?: Maybe<Scalars["Int"]>;
  bgpMetric?: Maybe<Scalars["String"]>;
  bgpPassword?: Maybe<Scalars["String"]>;
  ipv4Address?: Maybe<Scalars["String"]>;
  ipv4MaxPrefix?: Maybe<Scalars["Int"]>;
  ipv4Mtu?: Maybe<Scalars["Int"]>;
  ipv4RemoteAddress?: Maybe<Scalars["String"]>;
  ipv6Address?: Maybe<Scalars["String"]>;
  ipv6MaxPrefix?: Maybe<Scalars["Int"]>;
  ipv6Mtu?: Maybe<Scalars["Int"]>;
  ipv6RemoteAddress?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  sap: ServiceAttachPointType;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

/** Information about L3VPN service settings */
export type L3VpnServiceSettingsType = {
  __typename?: "L3VPNServiceSettingsType";
  l3vpnAsn: Scalars["Int"];
  name: Scalars["String"];
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

/** Information about L3VPN */
export type L3VpnSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "L3VPNSubscriptionDetailsType";
  circuits: Array<L3VpnCircuitType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  firewallEnabled: Scalars["Boolean"];
  firewallInterconnects: Array<L3FwInterconnectType>;
  insync: Scalars["Boolean"];
  l3vpnsapss: Array<L3VpnsapServiceSettingsType>;
  l3vpnss: L3VpnServiceSettingsType;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  serviceSpeed: Scalars["Int"];
  speedPolicer: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
  version: Scalars["Int"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type L3VpnSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type L3VpnSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about l3vpn */
export type L3VpnSubscriptionType = {
  __typename?: "L3VpnSubscriptionType";
  customer: CustomerType;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description: Scalars["String"];
  domain: Scalars["String"];
  locations: Array<LocationType>;
  product: ProductType;
  services: Array<PortServiceType>;
  status: Scalars["String"];
  subscriptionId: Scalars["String"];
  tags: Array<Scalars["String"]>;
};

export type L3VpnSubscriptionTypeConnectionV2 = {
  __typename?: "L3VpnSubscriptionTypeConnectionV2";
  page: Array<L3VpnSubscriptionType>;
  pageInfo: PageInfo;
};

/** Information about light path circuit */
export type LightPathCircuitType = {
  __typename?: "LightPathCircuitType";
  endpoints: Array<ServiceAttachPointType>;
  name: Scalars["String"];
  remotePortShutdown?: Maybe<Scalars["Boolean"]>;
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  speedPolicer?: Maybe<Scalars["Boolean"]>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

/** Information about light path */
export type LightPathSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "LightPathSubscriptionDetailsType";
  circuits: Array<LightPathCircuitType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  remotePortShutdown: Scalars["Boolean"];
  saps: Array<ServiceAttachPointType>;
  serviceId: Scalars["String"];
  serviceSpeed: Scalars["Int"];
  speedPolicer: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
  version: Scalars["Int"];
};

/** Information about lightpath */
export type LightPathSubscriptionType = SubscriptionDetails & {
  __typename?: "LightPathSubscriptionType";
  customer: CustomerType;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  services: Array<PortServiceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

export type LightPathSubscriptionTypeConnectionV2 = {
  __typename?: "LightPathSubscriptionTypeConnectionV2";
  page: Array<LightPathSubscriptionType>;
  pageInfo: PageInfo;
};

export type LinkMemberDetailsType = {
  __typename?: "LinkMemberDetailsType";
  amountReserved: Scalars["Int"];
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  ieeeInterfaceType?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  patchPosition?: Maybe<Scalars["String"]>;
  portSpeed: Scalars["Int"];
  product: ProductType;
  services: Array<ServiceType>;
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  vlanrange?: Maybe<Scalars["String"]>;
};

/** Information about a location (e.g. address, description, code) */
export type LocationType = {
  __typename?: "LocationType";
  address?: Maybe<AddressType>;
  locationDescription?: Maybe<Scalars["String"]>;
  surfLocationCode?: Maybe<Scalars["String"]>;
};

/** list of subscription minimal impact notification by customer */
export type MinimalImpactNotificationType = {
  __typename?: "MinimalImpactNotificationType";
  customerId: Scalars["UUID"];
  impact: Scalars["String"];
  version: Scalars["Int"];
};

export type MscPortSubscriptionType = {
  __typename?: "MscPortSubscriptionType";
  customerDescription?: Maybe<Scalars["String"]>;
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  subscriptionId: Scalars["UUID"];
};

/** Information about msc */
export type MscSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "MscSubscriptionDetailsType";
  address?: Maybe<AddressType>;
  amountReserved: Scalars["Int"];
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  portId?: Maybe<Scalars["Int"]>;
  portMode?: Maybe<Scalars["String"]>;
  portSpeed?: Maybe<Scalars["Int"]>;
  portSubscription?: Maybe<MscPortSubscriptionType>;
  product: ProductType;
  references: Array<ReferenceType>;
  serviceTag?: Maybe<Scalars["Int"]>;
  services: Array<ServiceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  surfLocationCode?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

/** Pagination context to navigate objects with cursor-based pagination */
export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["Int"]>;
  filterFields: Array<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  sortFields: Array<Scalars["String"]>;
  startCursor?: Maybe<Scalars["Int"]>;
  totalItems?: Maybe<Scalars["Int"]>;
};

/** Information about a service on a port */
export type PortServiceType = {
  __typename?: "PortServiceType";
  description: Scalars["String"];
  percentage: Scalars["Float"];
  productType: Scalars["String"];
  speed: Scalars["Int"];
  subscriptionId: Scalars["String"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type PortSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of port results */
export type PortSubscriptionSort = {
  amountReserved?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  portMode?: InputMaybe<SortOrder>;
  portSpeed?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

export type PrivateQuery = {
  __typename?: "PrivateQuery";
  /** Return aggsp details */
  aggspDetails: AggspSubscriptionDetailsType;
  /** Grouped subscription counts */
  counts: Counts;
  /** Returns a list of evpn ptp subscriptions */
  evpnPtpList: LightPathSubscriptionTypeConnectionV2;
  /** Return firewall details */
  firewallDetails: FirewallSubscriptionDetailsType;
  /** Return a list of firewall subscriptions */
  firewallList: FirewallSubscriptionTypeConnectionV2;
  /** Return ip bgp details */
  ipBgpDetails: IpSubscriptionDetailsType;
  /** Return IP list */
  ipList: IpSubscriptionListTypeConnectionV2;
  /** Return ip prefix ddos list */
  ipPrefixDdos: IpPrefixDdosTypeConnectionV2;
  /** Return ip prefix list */
  ipPrefixList: IpPrefixSubscriptionTypeConnectionV2;
  /** Return ip static details */
  ipStaticDetails: IpSubscriptionDetailsType;
  /** Return irb details */
  irbDetails: IrbSubscriptionDetailsType;
  /** Return L2VPN details */
  l2vpnDetails: L2VpnSubscriptionDetailsType;
  /** Returns a list of l2vpn subscriptions */
  l2vpnList: L2VpnSubscriptionTypeConnectionV2;
  /** Return L2VPN details */
  l3vpnDetails: L3VpnSubscriptionDetailsType;
  /** Returns a list of l3vpn subscriptions */
  l3vpnList: L3VpnSubscriptionTypeConnectionV2;
  /** Return lightpath details */
  lpDetails: LightPathSubscriptionDetailsType;
  /** Return lightpath redundant details */
  lrDetails: LightPathSubscriptionDetailsType;
  /** Return msc details */
  mscDetails: MscSubscriptionDetailsType;
  /** Returns a list of port subscriptions */
  portList: ServicePortSubscriptionTypeConnectionV2;
  /** Return service port details */
  servicePortDetails: ServicePortSubscriptionDetailsType;
  /** Return list subscriptions with surf_cert info */
  surfCertList: SurfCertTypeConnectionV2;
  /** Return wireless details */
  wirelessDetails: WirelessSubscriptionDetailsType;
  /** Return a list of wireless subscriptions */
  wirelessList: WirelessSubscriptionTypeConnectionV2;
};

export type PrivateQueryAggspDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryEvpnPtpListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<EvpnPtpSubscriptionFilter>;
  sort?: InputMaybe<EvpnPtpSubscriptionSort>;
};

export type PrivateQueryFirewallDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryFirewallListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<FirewallSubscriptionFilter>;
  sort?: InputMaybe<FirewallSubscriptionSort>;
};

export type PrivateQueryIpBgpDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryIpListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<BaseSubscriptionFilter>;
  sort?: InputMaybe<BaseSubscriptionSort>;
};

export type PrivateQueryIpPrefixDdosArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<IpPrefixSubscriptionDdosFilter>;
  sort?: InputMaybe<IpPrefixSubscriptionDdosSort>;
};

export type PrivateQueryIpPrefixListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<IpPrefixSubscriptionFilter>;
  sort?: InputMaybe<IpPrefixSubscriptionSort>;
};

export type PrivateQueryIpStaticDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryIrbDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryL2vpnDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryL2vpnListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<L2VpnSubscriptionFilter>;
  sort?: InputMaybe<L2VpnSubscriptionSort>;
};

export type PrivateQueryL3vpnDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryL3vpnListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<L3VpnSubscriptionFilter>;
  sort?: InputMaybe<L3VpnSubscriptionSort>;
};

export type PrivateQueryLpDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryLrDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryMscDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryPortListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<PortSubscriptionFilter>;
  sort?: InputMaybe<PortSubscriptionSort>;
};

export type PrivateQueryServicePortDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQuerySurfCertListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<SurfCertFilter>;
  sort?: InputMaybe<SurfCertSort>;
};

export type PrivateQueryWirelessDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryWirelessListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<WirelessSubscriptionFilter>;
  sort?: InputMaybe<WirelessSubscriptionSort>;
};

/** Information about product */
export type ProductType = {
  __typename?: "ProductType";
  id: Scalars["UUID"];
  name: Scalars["String"];
  tag: Scalars["String"];
  type: Scalars["String"];
};

/** Metadata to reference customer specific ID's */
export type ReferenceType = {
  __typename?: "ReferenceType";
  externalId: Scalars["String"];
  name: Scalars["String"];
};

/** Information about service attach point */
export type ServiceAttachPointType = {
  __typename?: "ServiceAttachPointType";
  address?: Maybe<AddressType>;
  name: Scalars["String"];
  organisation?: Maybe<Scalars["String"]>;
  port?: Maybe<ServicePortSubscriptionType>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
  vlanrange: Scalars["String"];
};

/** Information about port */
export type ServicePortSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "ServicePortSubscriptionDetailsType";
  address?: Maybe<AddressType>;
  aggregateParent?: Maybe<AggregateParentType>;
  amountReserved: Scalars["Int"];
  autoNegotiation?: Maybe<Scalars["Boolean"]>;
  connectorType?: Maybe<Scalars["String"]>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  fiberType?: Maybe<Scalars["String"]>;
  ieeeInterfaceType?: Maybe<Scalars["String"]>;
  insync: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  patchPosition?: Maybe<Scalars["String"]>;
  portMode?: Maybe<Scalars["String"]>;
  portSpeed?: Maybe<Scalars["Int"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  services?: Maybe<Array<ServiceType>>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  surfLocationCode?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

/** Information about port */
export type ServicePortSubscriptionType = {
  __typename?: "ServicePortSubscriptionType";
  address?: Maybe<AddressType>;
  amountReserved?: Maybe<Scalars["Int"]>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description: Scalars["String"];
  domain: Scalars["String"];
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  portMode: Scalars["String"];
  portSpeed: Scalars["Int"];
  product: ProductType;
  services: Array<ServiceType>;
  speed: Scalars["String"];
  status: Scalars["String"];
  subscriptionId: Scalars["String"];
  tags: Array<Scalars["String"]>;
  usedVlans: Array<Scalars["String"]>;
};

export type ServicePortSubscriptionTypeConnectionV2 = {
  __typename?: "ServicePortSubscriptionTypeConnectionV2";
  page: Array<ServicePortSubscriptionType>;
  pageInfo: PageInfo;
};

/** information about service port services */
export type ServiceType = {
  __typename?: "ServiceType";
  amountReserved?: Maybe<Scalars["Int"]>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  insync: Scalars["Boolean"];
  organisation?: Maybe<Scalars["String"]>;
  percentage: Scalars["Float"];
  portSpeed?: Maybe<Scalars["Int"]>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product?: Maybe<ProductType>;
  services?: Maybe<Array<ServiceType>>;
  speed: Scalars["Int"];
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  vlanrange?: Maybe<Scalars["String"]>;
};

export type Sn8AggregatedServicePortSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8AggregatedServicePortSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8IpBgpSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8IpBgpSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8IpStaticSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8IpStaticSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8IrbServicePortSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8IrbServicePortSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8L2VpnSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8L2VpnSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8L3VpnSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8L3VpnSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8LightPathRedundantSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8LightPathRedundantSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8LightPathSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8LightPathSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8MscSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8MscSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8ServicePortSubscription = BaseEnrichedTagsSubscription
  & BaseHealthSubscription & {
    __typename?: "Sn8ServicePortSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

/** Sort direction: ascending or descending */
export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type SubscriptionCount = {
  __typename?: "SubscriptionCount";
  all: Scalars["Int"];
  inactive: Scalars["Int"];
  malfunction: Scalars["Int"];
  notfound: Scalars["Int"];
  notraffic: Scalars["Int"];
  ok: Scalars["Int"];
  pending: Scalars["Int"];
  unknown: Scalars["Int"];
};

export type SubscriptionCountWithFirewallsEnabled = {
  __typename?: "SubscriptionCountWithFirewallsEnabled";
  all: Scalars["Int"];
  firewallsEnabled: Scalars["Int"];
  inactive: Scalars["Int"];
  malfunction: Scalars["Int"];
  notfound: Scalars["Int"];
  notraffic: Scalars["Int"];
  ok: Scalars["Int"];
  pending: Scalars["Int"];
  unknown: Scalars["Int"];
};

export type SubscriptionDetails = {
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

export enum SubscriptionLifecycle {
  Active = "ACTIVE",
  Disabled = "DISABLED",
  Initial = "INITIAL",
  Migrating = "MIGRATING",
  Provisioning = "PROVISIONING",
  Terminated = "TERMINATED",
}

/** Filter subscriptions by attribute, all ranges are inclusive */
export type SurfCertFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type SurfCertSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about surfcert */
export type SurfCertType = {
  __typename?: "SurfCertType";
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["String"];
  description: Scalars["String"];
  insync: Scalars["Boolean"];
  productTag: Scalars["String"];
  productType: Scalars["String"];
  status: Scalars["String"];
  subscriptionId: Scalars["String"];
  surfcertFilterEnabled: Scalars["Boolean"];
};

export type SurfCertTypeConnectionV2 = {
  __typename?: "SurfCertTypeConnectionV2";
  page: Array<SurfCertType>;
  pageInfo: PageInfo;
};

export type SurfWirelessSubscription = BaseEnrichedTagsSubscription & {
  __typename?: "SurfWirelessSubscription";
  enrichedTags: Array<Scalars["String"]>;
  healthStatus?: Maybe<WirelessHealthStatus>;
  healthStatusDetail?: Maybe<WirelessHealthDetail>;
  subscriptionId: Scalars["UUID"];
};

export type SurfWirelessSubscriptionHealthStatusArgs = {
  countNumAccessPoints?: Scalars["Boolean"];
};

/** Information about topology object */
export type TopologyObjectType = {
  __typename?: "TopologyObjectType";
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description?: Maybe<Scalars["String"]>;
  otherSubscriptionId?: Maybe<Scalars["UUID"]>;
  product?: Maybe<ProductType>;
  subscriptionInstanceId: Scalars["UUID"];
  topologyObjectType: Scalars["String"];
  vlanrange?: Maybe<Scalars["String"]>;
};

/** The traffic metrics for this subscription */
export type Traffic = {
  __typename?: "Traffic";
  last?: Maybe<TrafficMetrics>;
  max?: Maybe<TrafficMetrics>;
  mean?: Maybe<TrafficMetrics>;
};

export type TrafficMetrics = {
  __typename?: "TrafficMetrics";
  in: Scalars["Float"];
  out: Scalars["Float"];
};

/** Information about uplink location */
export type UplinkLocationType = {
  __typename?: "UplinkLocationType";
  address?: Maybe<AddressType>;
  locationDescription?: Maybe<Scalars["String"]>;
};

export type WirelessHealthDetail = {
  __typename?: "WirelessHealthDetail";
  last3Days?: Maybe<WirelessHealthDetailModel>;
  lastWeek?: Maybe<WirelessHealthDetailModel>;
  today?: Maybe<WirelessHealthDetailModel>;
};

export type WirelessHealthDetailModel = {
  __typename?: "WirelessHealthDetailModel";
  five?: Maybe<WirelessKpi>;
  maxActiveUsers?: Maybe<Scalars["Int"]>;
  twofour?: Maybe<WirelessKpi>;
};

export type WirelessHealthStatus = {
  __typename?: "WirelessHealthStatus";
  activeAps?: Maybe<Scalars["Int"]>;
  inactiveAps?: Maybe<Scalars["Int"]>;
  kpiAverages?: Maybe<WirelessKpi>;
};

export type WirelessKpi = {
  __typename?: "WirelessKPI";
  DHCP?: Maybe<Scalars["Float"]>;
  MOSVOIPDOWN?: Maybe<Scalars["Float"]>;
  MOSVOIPUP?: Maybe<Scalars["Float"]>;
  RADIUS?: Maybe<Scalars["Float"]>;
  THROUGHPUTDOWN?: Maybe<Scalars["Float"]>;
  THROUGHPUTUP?: Maybe<Scalars["Float"]>;
};

/** Information about wireless */
export type WirelessLocationActivityType = {
  __typename?: "WirelessLocationActivityType";
  activeAccessPoints?: Maybe<Scalars["Int"]>;
  inactiveAccessPoints?: Maybe<Scalars["Int"]>;
};

/** Information about wireless location */
export type WirelessLocationDetailsType = {
  __typename?: "WirelessLocationDetailsType";
  accessPoints: Array<AccessPointType>;
  activeAccessPoints?: Maybe<Scalars["Int"]>;
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  inactiveAccessPoints?: Maybe<Scalars["Int"]>;
  institute?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** Information about wireless */
export type WirelessSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "WirelessSubscriptionDetailsType";
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  location?: Maybe<WirelessLocationDetailsType>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  sensorPresent: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  supplier: Scalars["String"];
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type WirelessSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type WirelessSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about SURFwireless */
export type WirelessSubscriptionType = SubscriptionDetails & {
  __typename?: "WirelessSubscriptionType";
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  location: WirelessLocationActivityType;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  sensorPresent: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  supplier: Scalars["String"];
  tags: Array<Scalars["String"]>;
  version: Scalars["Int"];
};

export type WirelessSubscriptionTypeConnectionV2 = {
  __typename?: "WirelessSubscriptionTypeConnectionV2";
  page: Array<WirelessSubscriptionType>;
  pageInfo: PageInfo;
};

export type CertMitigationPrefixesQueryVariables = Exact<{
  customer: Scalars["UUID"];
}>;

export type CertMitigationPrefixesQuery = {
  __typename?: "PrivateQuery";
  ipPrefixDdos: {
    __typename?: "IpPrefixDdosTypeConnectionV2";
    page: Array<{
      __typename?: "IpPrefixDdosType";
      subscriptionId: any;
      autoMitigation: boolean;
      customerId: string;
      description: string;
      insync: boolean;
      prefix: string;
      status: string;
      version: number;
    }>;
  };
};

type BaseSubscriptionFragment_AggspSubscriptionDetailsType_Fragment = {
  __typename?: "AggspSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_FirewallSubscriptionDetailsType_Fragment = {
  __typename?: "FirewallSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_FirewallSubscriptionType_Fragment = {
  __typename?: "FirewallSubscriptionType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_IpPrefixSubscriptionType_Fragment = {
  __typename?: "IpPrefixSubscriptionType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_IpSubscriptionDetailsType_Fragment = {
  __typename?: "IpSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_IpSubscriptionListType_Fragment = {
  __typename?: "IpSubscriptionListType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_IrbSubscriptionDetailsType_Fragment = {
  __typename?: "IrbSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_L2VpnSubscriptionDetailsType_Fragment = {
  __typename?: "L2VPNSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_L3VpnSubscriptionDetailsType_Fragment = {
  __typename?: "L3VPNSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_LightPathSubscriptionDetailsType_Fragment = {
  __typename?: "LightPathSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_LightPathSubscriptionType_Fragment = {
  __typename?: "LightPathSubscriptionType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_MscSubscriptionDetailsType_Fragment = {
  __typename?: "MscSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_ServicePortSubscriptionDetailsType_Fragment = {
  __typename?: "ServicePortSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_WirelessSubscriptionDetailsType_Fragment = {
  __typename?: "WirelessSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_WirelessSubscriptionType_Fragment = {
  __typename?: "WirelessSubscriptionType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

export type BaseSubscriptionFragmentFragment =
  | BaseSubscriptionFragment_AggspSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_FirewallSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_FirewallSubscriptionType_Fragment
  | BaseSubscriptionFragment_IpPrefixSubscriptionType_Fragment
  | BaseSubscriptionFragment_IpSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_IpSubscriptionListType_Fragment
  | BaseSubscriptionFragment_IrbSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_L2VpnSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_L3VpnSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_LightPathSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_LightPathSubscriptionType_Fragment
  | BaseSubscriptionFragment_MscSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_ServicePortSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_WirelessSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_WirelessSubscriptionType_Fragment;

export type LinkedServicesFragmentFragment = {
  __typename?: "ServiceType";
  insync: boolean;
  subscriptionId: any;
  portSubscriptionInstanceId?: any | null;
  customerId: any;
  status: string;
  description: string;
  customerDescription: string;
  organisation?: string | null;
  speed: number;
  percentage: number;
  vlanrange?: string | null;
  portSpeed?: number | null;
  amountReserved?: number | null;
  product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
  services?: Array<{
    __typename?: "ServiceType";
    description: string;
    percentage: number;
    speed: number;
    product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
  }> | null;
};

export type LinkMemberFragmentFragment = {
  __typename?: "LinkMemberDetailsType";
  subscriptionId: any;
  customerId: any;
  description: string;
  organisation?: string | null;
  customerDescription: string;
  vlanrange?: string | null;
  patchPosition?: string | null;
  ieeeInterfaceType?: string | null;
  portSpeed: number;
  amountReserved: number;
  tags: Array<string>;
  product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
  services: Array<{
    __typename?: "ServiceType";
    insync: boolean;
    subscriptionId: any;
    portSubscriptionInstanceId?: any | null;
    customerId: any;
    status: string;
    description: string;
    customerDescription: string;
    organisation?: string | null;
    speed: number;
    percentage: number;
    vlanrange?: string | null;
    portSpeed?: number | null;
    amountReserved?: number | null;
    product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
    services?: Array<{
      __typename?: "ServiceType";
      description: string;
      percentage: number;
      speed: number;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
    }> | null;
  }>;
};

export type PortSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type PortSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  servicePortDetails: {
    __typename: "ServicePortSubscriptionDetailsType";
    label?: string | null;
    locationDescription?: string | null;
    surfLocationCode?: string | null;
    portSpeed?: number | null;
    portMode?: string | null;
    autoNegotiation?: boolean | null;
    patchPosition?: string | null;
    fiberType?: string | null;
    connectorType?: string | null;
    ieeeInterfaceType?: string | null;
    domain: string;
    amountReserved: number;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    address?: {
      __typename?: "AddressType";
      city?: string | null;
      street?: string | null;
      number?: string | null;
    } | null;
    services?: Array<{
      __typename?: "ServiceType";
      insync: boolean;
      subscriptionId: any;
      portSubscriptionInstanceId?: any | null;
      customerId: any;
      status: string;
      description: string;
      customerDescription: string;
      organisation?: string | null;
      speed: number;
      percentage: number;
      vlanrange?: string | null;
      portSpeed?: number | null;
      amountReserved?: number | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      services?: Array<{
        __typename?: "ServiceType";
        description: string;
        percentage: number;
        speed: number;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }> | null;
    }> | null;
    aggregateParent?: { __typename?: "AggregateParentType"; subscriptionId: any; customerDescription: string } | null;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type MscSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type MscSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  mscDetails: {
    __typename?: "MscSubscriptionDetailsType";
    label?: string | null;
    locationDescription?: string | null;
    surfLocationCode?: string | null;
    portSpeed?: number | null;
    portId?: number | null;
    portMode?: string | null;
    domain: string;
    amountReserved: number;
    subscriptionInstanceId?: any | null;
    serviceTag?: number | null;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    address?: {
      __typename?: "AddressType";
      city?: string | null;
      street?: string | null;
      number?: string | null;
    } | null;
    portSubscription?: {
      __typename?: "MscPortSubscriptionType";
      subscriptionId: any;
      customerId: any;
      description: string;
    } | null;
    services: Array<{
      __typename?: "ServiceType";
      insync: boolean;
      subscriptionId: any;
      portSubscriptionInstanceId?: any | null;
      customerId: any;
      status: string;
      description: string;
      customerDescription: string;
      organisation?: string | null;
      speed: number;
      percentage: number;
      vlanrange?: string | null;
      portSpeed?: number | null;
      amountReserved?: number | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      services?: Array<{
        __typename?: "ServiceType";
        description: string;
        percentage: number;
        speed: number;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }> | null;
    }>;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type AggspSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type AggspSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  aggspDetails: {
    __typename?: "AggspSubscriptionDetailsType";
    label?: string | null;
    locationDescription?: string | null;
    surfLocationCode?: string | null;
    portSpeed?: number | null;
    portId?: number | null;
    portMode?: string | null;
    domain: string;
    amountReserved: number;
    subscriptionInstanceId?: any | null;
    portSubscription?: any | null;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    linkMemberSubscriptions: Array<{
      __typename?: "LinkMemberDetailsType";
      subscriptionId: any;
      customerId: any;
      description: string;
      organisation?: string | null;
      customerDescription: string;
      vlanrange?: string | null;
      patchPosition?: string | null;
      ieeeInterfaceType?: string | null;
      portSpeed: number;
      amountReserved: number;
      tags: Array<string>;
      product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
      services: Array<{
        __typename?: "ServiceType";
        insync: boolean;
        subscriptionId: any;
        portSubscriptionInstanceId?: any | null;
        customerId: any;
        status: string;
        description: string;
        customerDescription: string;
        organisation?: string | null;
        speed: number;
        percentage: number;
        vlanrange?: string | null;
        portSpeed?: number | null;
        amountReserved?: number | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
        services?: Array<{
          __typename?: "ServiceType";
          description: string;
          percentage: number;
          speed: number;
          product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
        }> | null;
      }>;
    }>;
    address?: {
      __typename?: "AddressType";
      city?: string | null;
      street?: string | null;
      number?: string | null;
    } | null;
    services?: Array<{
      __typename?: "ServiceType";
      insync: boolean;
      subscriptionId: any;
      portSubscriptionInstanceId?: any | null;
      customerId: any;
      status: string;
      description: string;
      customerDescription: string;
      organisation?: string | null;
      speed: number;
      percentage: number;
      vlanrange?: string | null;
      portSpeed?: number | null;
      amountReserved?: number | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      services?: Array<{
        __typename?: "ServiceType";
        description: string;
        percentage: number;
        speed: number;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }> | null;
    }> | null;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type IrbSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type IrbSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  irbDetails: {
    __typename?: "IrbSubscriptionDetailsType";
    label?: string | null;
    locationDescription?: string | null;
    surfLocationCode?: string | null;
    portSpeed?: number | null;
    portId?: number | null;
    portMode?: string | null;
    domain: string;
    amountReserved: number;
    subscriptionInstanceId?: any | null;
    portSubscription?: any | null;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    address?: {
      __typename?: "AddressType";
      city?: string | null;
      street?: string | null;
      number?: string | null;
    } | null;
    services: Array<{
      __typename?: "ServiceType";
      insync: boolean;
      subscriptionId: any;
      portSubscriptionInstanceId?: any | null;
      customerId: any;
      status: string;
      description: string;
      customerDescription: string;
      organisation?: string | null;
      speed: number;
      percentage: number;
      vlanrange?: string | null;
      portSpeed?: number | null;
      amountReserved?: number | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      services?: Array<{
        __typename?: "ServiceType";
        description: string;
        percentage: number;
        speed: number;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }> | null;
    }>;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type LightPathSubscriptionDetailsFragmentFragment = {
  __typename?: "LightPathSubscriptionDetailsType";
  domain: string;
  protection: string;
  serviceId: string;
  serviceSpeed: number;
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
  saps: Array<{
    __typename?: "ServiceAttachPointType";
    name: string;
    organisation?: string | null;
    subscriptionInstanceId: any;
    tag: string;
    vlanrange: string;
  }>;
  circuits: Array<{
    __typename?: "LightPathCircuitType";
    name: string;
    remotePortShutdown?: boolean | null;
    serviceId?: string | null;
    serviceSpeed?: number | null;
    speedPolicer?: boolean | null;
    subscriptionInstanceId: any;
    tag: string;
    endpoints: Array<{
      __typename?: "ServiceAttachPointType";
      name: string;
      organisation?: string | null;
      subscriptionInstanceId: any;
      tag: string;
      vlanrange: string;
      port?: {
        __typename?: "ServicePortSubscriptionType";
        amountReserved?: number | null;
        customerDescription: string;
        description: string;
        domain: string;
        portMode: string;
        portSpeed: number;
        speed: string;
        status: string;
        subscriptionId: string;
        tags: Array<string>;
        usedVlans: Array<string>;
        customer?: { __typename?: "CustomerType"; customerId: string } | null;
        product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
        services: Array<{
          __typename?: "ServiceType";
          insync: boolean;
          subscriptionId: any;
          portSubscriptionInstanceId?: any | null;
          customerId: any;
          status: string;
          description: string;
          customerDescription: string;
          organisation?: string | null;
          speed: number;
          percentage: number;
          vlanrange?: string | null;
          portSpeed?: number | null;
          amountReserved?: number | null;
          product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
          services?: Array<{
            __typename?: "ServiceType";
            description: string;
            percentage: number;
            speed: number;
            product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
          }> | null;
        }>;
        address?: {
          __typename?: "AddressType";
          addition?: string | null;
          city?: string | null;
          countryCode?: string | null;
          extraAddressLine?: string | null;
          number?: string | null;
          street?: string | null;
          zipcode?: string | null;
        } | null;
      } | null;
    }>;
  }>;
  topology: Array<
    Array<{
      __typename?: "TopologyObjectType";
      customerDescription: string;
      otherSubscriptionId?: any | null;
      subscriptionInstanceId: any;
      topologyObjectType: string;
      vlanrange?: string | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
    }>
  >;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

export type LightpathSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type LightpathSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  lpDetails: {
    __typename?: "LightPathSubscriptionDetailsType";
    domain: string;
    protection: string;
    serviceId: string;
    serviceSpeed: number;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    saps: Array<{
      __typename?: "ServiceAttachPointType";
      name: string;
      organisation?: string | null;
      subscriptionInstanceId: any;
      tag: string;
      vlanrange: string;
    }>;
    circuits: Array<{
      __typename?: "LightPathCircuitType";
      name: string;
      remotePortShutdown?: boolean | null;
      serviceId?: string | null;
      serviceSpeed?: number | null;
      speedPolicer?: boolean | null;
      subscriptionInstanceId: any;
      tag: string;
      endpoints: Array<{
        __typename?: "ServiceAttachPointType";
        name: string;
        organisation?: string | null;
        subscriptionInstanceId: any;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          amountReserved?: number | null;
          customerDescription: string;
          description: string;
          domain: string;
          portMode: string;
          portSpeed: number;
          speed: string;
          status: string;
          subscriptionId: string;
          tags: Array<string>;
          usedVlans: Array<string>;
          customer?: { __typename?: "CustomerType"; customerId: string } | null;
          product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
          services: Array<{
            __typename?: "ServiceType";
            insync: boolean;
            subscriptionId: any;
            portSubscriptionInstanceId?: any | null;
            customerId: any;
            status: string;
            description: string;
            customerDescription: string;
            organisation?: string | null;
            speed: number;
            percentage: number;
            vlanrange?: string | null;
            portSpeed?: number | null;
            amountReserved?: number | null;
            product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
            services?: Array<{
              __typename?: "ServiceType";
              description: string;
              percentage: number;
              speed: number;
              product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
            }> | null;
          }>;
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        } | null;
      }>;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type LightpathRedundantSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type LightpathRedundantSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  lrDetails: {
    __typename?: "LightPathSubscriptionDetailsType";
    domain: string;
    protection: string;
    serviceId: string;
    serviceSpeed: number;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    saps: Array<{
      __typename?: "ServiceAttachPointType";
      name: string;
      organisation?: string | null;
      subscriptionInstanceId: any;
      tag: string;
      vlanrange: string;
    }>;
    circuits: Array<{
      __typename?: "LightPathCircuitType";
      name: string;
      remotePortShutdown?: boolean | null;
      serviceId?: string | null;
      serviceSpeed?: number | null;
      speedPolicer?: boolean | null;
      subscriptionInstanceId: any;
      tag: string;
      endpoints: Array<{
        __typename?: "ServiceAttachPointType";
        name: string;
        organisation?: string | null;
        subscriptionInstanceId: any;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          amountReserved?: number | null;
          customerDescription: string;
          description: string;
          domain: string;
          portMode: string;
          portSpeed: number;
          speed: string;
          status: string;
          subscriptionId: string;
          tags: Array<string>;
          usedVlans: Array<string>;
          customer?: { __typename?: "CustomerType"; customerId: string } | null;
          product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
          services: Array<{
            __typename?: "ServiceType";
            insync: boolean;
            subscriptionId: any;
            portSubscriptionInstanceId?: any | null;
            customerId: any;
            status: string;
            description: string;
            customerDescription: string;
            organisation?: string | null;
            speed: number;
            percentage: number;
            vlanrange?: string | null;
            portSpeed?: number | null;
            amountReserved?: number | null;
            product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
            services?: Array<{
              __typename?: "ServiceType";
              description: string;
              percentage: number;
              speed: number;
              product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
            }> | null;
          }>;
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        } | null;
      }>;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type WirelessSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type WirelessSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  wirelessDetails: {
    __typename?: "WirelessSubscriptionDetailsType";
    sensorPresent: boolean;
    supplier: string;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    location?: {
      __typename?: "WirelessLocationDetailsType";
      country?: string | null;
      accessPoints: Array<{
        __typename?: "AccessPointType";
        brand?: string | null;
        model?: string | null;
        supplier?: string | null;
        ciStatus?: string | null;
      }>;
    } | null;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type FirewallSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type FirewallSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  firewallDetails: {
    __typename?: "FirewallSubscriptionDetailsType";
    availabilityZone?: string | null;
    customerAsn?: number | null;
    domain: string;
    firewallEnabled: boolean;
    fwAsn: number;
    protection: string;
    serviceSpeed: number;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    ipGateway?: {
      __typename?: "IpGatewayType";
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      serviceSpeed: number;
      subscriptionInstanceId?: any | null;
      surfcertFilter: string;
      ipPrefixes: Array<string>;
    } | null;
    l3Endpoints: Array<{
      __typename?: "L3EndpointType";
      customerId: any;
      description: string;
      organisation?: string | null;
      otherSubscriptionId?: any | null;
      subscriptionInstanceId?: any | null;
      tags: Array<string>;
      product: { __typename?: "ProductType"; type: string; tag: string };
    }>;
    l2Endpoints: Array<{
      __typename?: "L2EndpointType";
      customerId: any;
      description: string;
      ipv4PtpPrefixes: Array<string>;
      ipv6PtpPrefixes: Array<string>;
      organisation?: string | null;
      otherSubscriptionId?: any | null;
      subscriptionInstanceId?: any | null;
      tags: Array<string>;
      product: { __typename?: "ProductType"; type: string; tag: string };
    }>;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type L2vpnSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type L2vpnSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  l2vpnDetails: {
    __typename?: "L2VPNSubscriptionDetailsType";
    firewallEnabled: boolean;
    domain: string;
    serviceSpeed: number;
    protection: string;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    circuits: Array<{
      __typename?: "L2VPNCircuitType";
      speedPolicer?: boolean | null;
      endpoints: Array<{
        __typename?: "ServiceAttachPointType";
        name: string;
        organisation?: string | null;
        subscriptionInstanceId: any;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          portMode: string;
          subscriptionId: string;
          tags: Array<string>;
          speed: string;
          customerDescription: string;
          services: Array<{ __typename?: "ServiceType"; vlanrange?: string | null; insync: boolean }>;
        } | null;
        address?: {
          __typename?: "AddressType";
          city?: string | null;
          street?: string | null;
          number?: string | null;
        } | null;
      }>;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    saps: Array<{
      __typename?: "ServiceAttachPointType";
      subscriptionInstanceId: any;
      name: string;
      port?: {
        __typename?: "ServicePortSubscriptionType";
        subscriptionId: string;
        description: string;
        domain: string;
        portMode: string;
        portSpeed: number;
        amountReserved?: number | null;
        speed: string;
        status: string;
        tags: Array<string>;
        customerDescription: string;
        customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
        product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
        services: Array<{
          __typename?: "ServiceType";
          description: string;
          speed: number;
          percentage: number;
          vlanrange?: string | null;
          status: string;
          product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
        }>;
        locations: Array<{
          __typename?: "LocationType";
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        }>;
      } | null;
    }>;
    esis: Array<{
      __typename?: "EthernetSegmentIdentifierType";
      segmentId?: number | null;
      firewallInterconnect?: {
        __typename?: "L2InterconnectType";
        firewallSubscriptionId?: any | null;
        subscriptionInstanceId?: any | null;
      } | null;
      endpoints?: Array<{
        __typename?: "ServiceAttachPointType";
        subscriptionInstanceId: any;
        organisation?: string | null;
        name: string;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          subscriptionId: string;
          description: string;
          amountReserved?: number | null;
          portSpeed: number;
          tags: Array<string>;
          customerDescription: string;
          usedVlans: Array<string>;
          customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
          locations: Array<{
            __typename?: "LocationType";
            address?: {
              __typename?: "AddressType";
              addition?: string | null;
              city?: string | null;
              number?: string | null;
              street?: string | null;
            } | null;
          }>;
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
          services: Array<{
            __typename?: "ServiceType";
            percentage: number;
            customerDescription: string;
            description: string;
            subscriptionId: any;
            vlanrange?: string | null;
            product?: { __typename?: "ProductType"; id: any; type: string; tag: string } | null;
          }>;
          product: { __typename?: "ProductType"; id: any; type: string; tag: string };
        } | null;
      }> | null;
    }>;
    firewallInterconnects: Array<{
      __typename?: "L2InterconnectType";
      availabilityZone?: string | null;
      firewallSubscriptionId?: any | null;
      subscriptionInstanceId?: any | null;
      organisation?: string | null;
      customerId?: any | null;
    }>;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type L3vpnSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type L3vpnSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  l3vpnDetails: {
    __typename?: "L3VPNSubscriptionDetailsType";
    firewallEnabled: boolean;
    domain: string;
    insync: boolean;
    serviceSpeed: number;
    protection: string;
    customerId: any;
    description: string;
    endDate?: number | null;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    circuits: Array<{
      __typename?: "L3VPNCircuitType";
      speedPolicer?: boolean | null;
      endpoints: Array<{
        __typename?: "ServiceAttachPointType";
        name: string;
        organisation?: string | null;
        subscriptionInstanceId: any;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          customerDescription: string;
          portMode: string;
          subscriptionId: string;
          tags: Array<string>;
          speed: string;
          services: Array<{ __typename?: "ServiceType"; vlanrange?: string | null; insync: boolean }>;
        } | null;
        address?: {
          __typename?: "AddressType";
          city?: string | null;
          street?: string | null;
          number?: string | null;
        } | null;
      }>;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    l3vpnss: { __typename?: "L3VPNServiceSettingsType"; l3vpnAsn: number };
    l3vpnsapss: Array<{
      __typename?: "L3VPNSAPServiceSettingsType";
      subscriptionInstanceId: any;
      name: string;
      bfd?: boolean | null;
      bfdMinimumInterval?: number | null;
      bfdMultiplier?: number | null;
      bgpPassword?: string | null;
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      bgpMetric?: string | null;
      ipv4Address?: string | null;
      ipv4MaxPrefix?: number | null;
      ipv6Address?: string | null;
      ipv4RemoteAddress?: string | null;
      ipv6MaxPrefix?: number | null;
      ipv6RemoteAddress?: string | null;
      sap: {
        __typename?: "ServiceAttachPointType";
        subscriptionInstanceId: any;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          description: string;
          domain: string;
          portMode: string;
          portSpeed: number;
          amountReserved?: number | null;
          speed: string;
          status: string;
          subscriptionId: string;
          tags: Array<string>;
          customerDescription: string;
          product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
          customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
          services: Array<{
            __typename?: "ServiceType";
            description: string;
            speed: number;
            percentage: number;
            vlanrange?: string | null;
            status: string;
            product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
          }>;
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
          locations: Array<{
            __typename?: "LocationType";
            address?: {
              __typename?: "AddressType";
              addition?: string | null;
              city?: string | null;
              countryCode?: string | null;
              extraAddressLine?: string | null;
              number?: string | null;
              street?: string | null;
              zipcode?: string | null;
            } | null;
          }>;
        } | null;
      };
    }>;
    firewallInterconnects: Array<{
      __typename?: "L3FwInterconnectType";
      availabilityZone: string;
      firewallAsn: string;
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      ipv4Prefix?: any | null;
      ipv6Prefix?: any | null;
      ipv4MaxPrefix?: number | null;
      ipv6MaxPrefix?: number | null;
      ipv4AddressFirewall?: any | null;
      ipv6AddressFirewall?: any | null;
      firewallSubscriptionId: any;
      subscriptionInstanceId: any;
      organisation?: string | null;
      customerId: any;
      customerDescription: string;
      saps: Array<{
        __typename?: "ServiceAttachPointType";
        subscriptionInstanceId: any;
        name: string;
        tag: string;
        organisation?: string | null;
        vlanrange: string;
        address?: {
          __typename?: "AddressType";
          street?: string | null;
          number?: string | null;
          city?: string | null;
        } | null;
      }>;
    }>;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
  };
};

export type IpSapPortDataFragment = {
  __typename?: "IPServiceAttachPointType";
  port?: {
    __typename?: "ServicePortSubscriptionType";
    amountReserved?: number | null;
    description: string;
    domain: string;
    locationDescription?: string | null;
    portMode: string;
    portSpeed: number;
    speed: string;
    status: string;
    subscriptionId: string;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
    services: Array<{
      __typename?: "ServiceType";
      description: string;
      percentage: number;
      speed: number;
      status: string;
      vlanrange?: string | null;
      product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
    }>;
    locations: Array<{
      __typename?: "LocationType";
      address?: {
        __typename?: "AddressType";
        addition?: string | null;
        city?: string | null;
        countryCode?: string | null;
        extraAddressLine?: string | null;
        number?: string | null;
        street?: string | null;
        zipcode?: string | null;
      } | null;
    }>;
  } | null;
};

export type IpSapDataFragment = {
  __typename?: "IPServiceAttachPointType";
  subscriptionInstanceId: any;
  bfd?: boolean | null;
  bfdMinimumInterval?: number | null;
  bfdMultiplier?: number | null;
  bgpExportPolicy?: string | null;
  bgpHashAlgorithm?: string | null;
  bgpPassword?: string | null;
  bgpSessionPriority?: string | null;
  ipv4Mtu?: number | null;
  ipv6Mtu?: number | null;
  vlanrange: string;
  ipPrefixes: Array<string>;
  ipv4PointToPointPrefix?: {
    __typename?: "IpPointToPointPrefixType";
    clientFQDN: string;
    clientIpAddress: string;
    network: string;
    surfFQDN: string;
    surfIpAddress: string;
  } | null;
  ipv6PointToPointPrefix?: {
    __typename?: "IpPointToPointPrefixType";
    clientFQDN: string;
    clientIpAddress: string;
    network: string;
    surfFQDN: string;
    surfIpAddress: string;
  } | null;
  port?: {
    __typename?: "ServicePortSubscriptionType";
    amountReserved?: number | null;
    description: string;
    domain: string;
    locationDescription?: string | null;
    portMode: string;
    portSpeed: number;
    speed: string;
    status: string;
    subscriptionId: string;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
    services: Array<{
      __typename?: "ServiceType";
      description: string;
      percentage: number;
      speed: number;
      status: string;
      vlanrange?: string | null;
      product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
    }>;
    locations: Array<{
      __typename?: "LocationType";
      address?: {
        __typename?: "AddressType";
        addition?: string | null;
        city?: string | null;
        countryCode?: string | null;
        extraAddressLine?: string | null;
        number?: string | null;
        street?: string | null;
        zipcode?: string | null;
      } | null;
    }>;
  } | null;
};

export type IpBgpSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type IpBgpSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  ipBgpDetails: {
    __typename?: "IpSubscriptionDetailsType";
    domain: string;
    firewallEnabled: boolean;
    ipRoutingType: string;
    protection?: string | null;
    serviceSpeed?: number | null;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    ipss: { __typename?: "IpServiceSettingsType"; surfcertFilter: string; asn?: number | null };
    saps: Array<{
      __typename?: "IPServiceAttachPointType";
      subscriptionInstanceId: any;
      bfd?: boolean | null;
      bfdMinimumInterval?: number | null;
      bfdMultiplier?: number | null;
      bgpExportPolicy?: string | null;
      bgpHashAlgorithm?: string | null;
      bgpPassword?: string | null;
      bgpSessionPriority?: string | null;
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      vlanrange: string;
      ipPrefixes: Array<string>;
      ipv4PointToPointPrefix?: {
        __typename?: "IpPointToPointPrefixType";
        clientFQDN: string;
        clientIpAddress: string;
        network: string;
        surfFQDN: string;
        surfIpAddress: string;
      } | null;
      ipv6PointToPointPrefix?: {
        __typename?: "IpPointToPointPrefixType";
        clientFQDN: string;
        clientIpAddress: string;
        network: string;
        surfFQDN: string;
        surfIpAddress: string;
      } | null;
      port?: {
        __typename?: "ServicePortSubscriptionType";
        amountReserved?: number | null;
        description: string;
        domain: string;
        locationDescription?: string | null;
        portMode: string;
        portSpeed: number;
        speed: string;
        status: string;
        subscriptionId: string;
        tags: Array<string>;
        customerDescription: string;
        product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
        customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
        services: Array<{
          __typename?: "ServiceType";
          description: string;
          percentage: number;
          speed: number;
          status: string;
          vlanrange?: string | null;
          product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
        }>;
        locations: Array<{
          __typename?: "LocationType";
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        }>;
      } | null;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        topologyObjectType: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        vlanrange?: string | null;
        customerDescription: string;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type IpStaticSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type IpStaticSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  ipStaticDetails: {
    __typename?: "IpSubscriptionDetailsType";
    serviceSpeed?: number | null;
    firewallEnabled: boolean;
    protection?: string | null;
    domain: string;
    ipRoutingType: string;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    ipss: { __typename?: "IpServiceSettingsType"; surfcertFilter: string; asn?: number | null };
    saps: Array<{
      __typename?: "IPServiceAttachPointType";
      subscriptionInstanceId: any;
      bfd?: boolean | null;
      bfdMinimumInterval?: number | null;
      bfdMultiplier?: number | null;
      bgpExportPolicy?: string | null;
      bgpHashAlgorithm?: string | null;
      bgpPassword?: string | null;
      bgpSessionPriority?: string | null;
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      vlanrange: string;
      ipPrefixes: Array<string>;
      ipv4PointToPointPrefix?: {
        __typename?: "IpPointToPointPrefixType";
        clientFQDN: string;
        clientIpAddress: string;
        network: string;
        surfFQDN: string;
        surfIpAddress: string;
      } | null;
      ipv6PointToPointPrefix?: {
        __typename?: "IpPointToPointPrefixType";
        clientFQDN: string;
        clientIpAddress: string;
        network: string;
        surfFQDN: string;
        surfIpAddress: string;
      } | null;
      port?: {
        __typename?: "ServicePortSubscriptionType";
        amountReserved?: number | null;
        description: string;
        domain: string;
        locationDescription?: string | null;
        portMode: string;
        portSpeed: number;
        speed: string;
        status: string;
        subscriptionId: string;
        tags: Array<string>;
        customerDescription: string;
        product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
        customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
        services: Array<{
          __typename?: "ServiceType";
          description: string;
          percentage: number;
          speed: number;
          status: string;
          vlanrange?: string | null;
          product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
        }>;
        locations: Array<{
          __typename?: "LocationType";
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        }>;
      } | null;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export const LinkedServicesFragmentFragmentDoc = gql`
  fragment LinkedServicesFragment on ServiceType {
    insync
    subscriptionId
    portSubscriptionInstanceId
    customerId
    status
    description
    customerDescription
    organisation
    status
    speed
    percentage
    vlanrange
    portSpeed
    amountReserved
    product {
      id
      name
      type
      tag
    }
    services {
      description
      percentage
      speed
      product {
        id
        name
        type
        tag
      }
    }
  }
`;
export const LinkMemberFragmentFragmentDoc = gql`
  fragment LinkMemberFragment on LinkMemberDetailsType {
    subscriptionId
    customerId
    description
    organisation
    customerDescription
    vlanrange
    patchPosition
    ieeeInterfaceType
    portSpeed
    amountReserved
    tags
    product {
      id
      name
      type
      tag
    }
    services {
      ...LinkedServicesFragment
    }
  }
  ${LinkedServicesFragmentFragmentDoc}
`;
export const BaseSubscriptionFragmentFragmentDoc = gql`
  fragment BaseSubscriptionFragment on SubscriptionDetails {
    customerId
    description
    endDate
    insync
    name
    organisation
    startDate
    status
    subscriptionId
    tags
    customerDescription
    minimalImpactNotifications {
      customerId
      impact
    }
    product {
      id
      name
      tag
      type
    }
    references {
      externalId
      name
    }
  }
`;
export const LightPathSubscriptionDetailsFragmentFragmentDoc = gql`
  fragment LightPathSubscriptionDetailsFragment on LightPathSubscriptionDetailsType {
    ...BaseSubscriptionFragment
    domain
    protection
    serviceId
    serviceSpeed
    product {
      id
      name
      type
      tag
    }
    saps {
      name
      organisation
      subscriptionInstanceId
      tag
      vlanrange
    }
    circuits {
      name
      remotePortShutdown
      serviceId
      serviceSpeed
      speedPolicer
      subscriptionInstanceId
      tag
      endpoints {
        name
        organisation
        subscriptionInstanceId
        tag
        vlanrange
        port {
          amountReserved
          customerDescription
          description
          domain
          portMode
          portSpeed
          speed
          status
          subscriptionId
          tags
          usedVlans
          customer {
            customerId
          }
          product {
            id
            name
            type
            tag
          }
          services {
            ...LinkedServicesFragment
          }
          address {
            addition
            city
            countryCode
            extraAddressLine
            number
            street
            zipcode
          }
        }
      }
    }
    topology {
      customerDescription
      otherSubscriptionId
      subscriptionInstanceId
      topologyObjectType
      vlanrange
      product {
        id
        name
        type
        tag
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
  ${LinkedServicesFragmentFragmentDoc}
`;
export const IpSapPortDataFragmentDoc = gql`
  fragment IpSapPortData on IPServiceAttachPointType {
    port {
      amountReserved
      description
      domain
      locationDescription
      portMode
      portSpeed
      speed
      status
      subscriptionId
      tags
      customerDescription
      product {
        id
        name
        tag
        type
      }
      customer {
        fullname
        customerId
      }
      services {
        description
        percentage
        speed
        status
        vlanrange
        product {
          type
          tag
          id
          name
        }
      }
      locations {
        address {
          addition
          city
          countryCode
          extraAddressLine
          number
          street
          zipcode
        }
      }
    }
  }
`;
export const IpSapDataFragmentDoc = gql`
  fragment IpSapData on IPServiceAttachPointType {
    ...IpSapPortData
    subscriptionInstanceId
    bfd
    bfdMinimumInterval
    bfdMultiplier
    bgpExportPolicy
    bgpHashAlgorithm
    bgpPassword
    bgpSessionPriority
    ipv4Mtu
    ipv6Mtu
    vlanrange
    ipPrefixes
    ipv4PointToPointPrefix {
      clientFQDN
      clientIpAddress
      network
      surfFQDN
      surfIpAddress
    }
    ipv6PointToPointPrefix {
      clientFQDN
      clientIpAddress
      network
      surfFQDN
      surfIpAddress
    }
  }
  ${IpSapPortDataFragmentDoc}
`;
export const CertMitigationPrefixesDocument = gql`
  query CertMitigationPrefixes($customer: UUID!) {
    ipPrefixDdos(searchFilter: { accessCustomerId: $customer }) {
      page {
        subscriptionId
        autoMitigation
        customerId
        description
        insync
        prefix
        status
        version
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CertMitigationPrefixesGQL extends Apollo.Query<
  CertMitigationPrefixesQuery,
  CertMitigationPrefixesQueryVariables
> {
  document = CertMitigationPrefixesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PortSubscriptionDetailsDocument = gql`
  query PortSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    servicePortDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      __typename
      ...BaseSubscriptionFragment
      label
      locationDescription
      surfLocationCode
      portSpeed
      portMode
      autoNegotiation
      patchPosition
      fiberType
      connectorType
      ieeeInterfaceType
      domain
      amountReserved
      address {
        city
        street
        number
      }
      services {
        ...LinkedServicesFragment
      }
      aggregateParent {
        subscriptionId
        customerDescription
      }
      product {
        id
        name
        type
        tag
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
  ${LinkedServicesFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class PortSubscriptionDetailsGQL extends Apollo.Query<
  PortSubscriptionDetailsQuery,
  PortSubscriptionDetailsQueryVariables
> {
  document = PortSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MscSubscriptionDetailsDocument = gql`
  query MscSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    mscDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      label
      locationDescription
      surfLocationCode
      portSpeed
      portId
      portMode
      domain
      amountReserved
      subscriptionInstanceId
      serviceTag
      address {
        city
        street
        number
      }
      portSubscription {
        subscriptionId
        customerId
        description
      }
      services {
        ...LinkedServicesFragment
      }
      product {
        id
        name
        type
        tag
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
  ${LinkedServicesFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class MscSubscriptionDetailsGQL extends Apollo.Query<
  MscSubscriptionDetailsQuery,
  MscSubscriptionDetailsQueryVariables
> {
  document = MscSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AggspSubscriptionDetailsDocument = gql`
  query AggspSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    aggspDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      label
      locationDescription
      surfLocationCode
      portSpeed
      portId
      portMode
      domain
      amountReserved
      subscriptionInstanceId
      portSubscription
      linkMemberSubscriptions {
        ...LinkMemberFragment
      }
      address {
        city
        street
        number
      }
      services {
        ...LinkedServicesFragment
      }
      product {
        id
        name
        type
        tag
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
  ${LinkMemberFragmentFragmentDoc}
  ${LinkedServicesFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class AggspSubscriptionDetailsGQL extends Apollo.Query<
  AggspSubscriptionDetailsQuery,
  AggspSubscriptionDetailsQueryVariables
> {
  document = AggspSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IrbSubscriptionDetailsDocument = gql`
  query IrbSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    irbDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      label
      locationDescription
      surfLocationCode
      portSpeed
      portId
      portMode
      domain
      amountReserved
      subscriptionInstanceId
      portSubscription
      address {
        city
        street
        number
      }
      services {
        ...LinkedServicesFragment
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
  ${LinkedServicesFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class IrbSubscriptionDetailsGQL extends Apollo.Query<
  IrbSubscriptionDetailsQuery,
  IrbSubscriptionDetailsQueryVariables
> {
  document = IrbSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LightpathSubscriptionDetailsDocument = gql`
  query LightpathSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    lpDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...LightPathSubscriptionDetailsFragment
    }
  }
  ${LightPathSubscriptionDetailsFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class LightpathSubscriptionDetailsGQL extends Apollo.Query<
  LightpathSubscriptionDetailsQuery,
  LightpathSubscriptionDetailsQueryVariables
> {
  document = LightpathSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LightpathRedundantSubscriptionDetailsDocument = gql`
  query LightpathRedundantSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    lrDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...LightPathSubscriptionDetailsFragment
    }
  }
  ${LightPathSubscriptionDetailsFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class LightpathRedundantSubscriptionDetailsGQL extends Apollo.Query<
  LightpathRedundantSubscriptionDetailsQuery,
  LightpathRedundantSubscriptionDetailsQueryVariables
> {
  document = LightpathRedundantSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const WirelessSubscriptionDetailsDocument = gql`
  query WirelessSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    wirelessDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      sensorPresent
      supplier
      location {
        country
        accessPoints {
          brand
          model
          supplier
          ciStatus
        }
      }
      product {
        id
        name
        type
        tag
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class WirelessSubscriptionDetailsGQL extends Apollo.Query<
  WirelessSubscriptionDetailsQuery,
  WirelessSubscriptionDetailsQueryVariables
> {
  document = WirelessSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FirewallSubscriptionDetailsDocument = gql`
  query FirewallSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    firewallDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      topology {
        customerDescription
        otherSubscriptionId
        subscriptionInstanceId
        topologyObjectType
        vlanrange
        product {
          id
          name
          type
          tag
        }
      }
      availabilityZone
      customerAsn
      domain
      firewallEnabled
      fwAsn
      protection
      serviceSpeed
      ipGateway {
        ipv4Mtu
        ipv6Mtu
        serviceSpeed
        subscriptionInstanceId
        surfcertFilter
        ipPrefixes
      }
      l3Endpoints {
        customerId
        description
        organisation
        otherSubscriptionId
        subscriptionInstanceId
        tags
        product {
          type
          tag
        }
      }
      l2Endpoints {
        customerId
        description
        ipv4PtpPrefixes
        ipv6PtpPrefixes
        organisation
        otherSubscriptionId
        subscriptionInstanceId
        tags
        product {
          type
          tag
        }
      }
      product {
        id
        name
        tag
        type
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class FirewallSubscriptionDetailsGQL extends Apollo.Query<
  FirewallSubscriptionDetailsQuery,
  FirewallSubscriptionDetailsQueryVariables
> {
  document = FirewallSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const L2vpnSubscriptionDetailsDocument = gql`
  query L2vpnSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    l2vpnDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      firewallEnabled
      domain
      serviceSpeed
      protection
      product {
        id
        name
        tag
        type
      }
      product {
        name
        tag
      }
      circuits {
        speedPolicer
        endpoints {
          name
          organisation
          subscriptionInstanceId
          tag
          vlanrange
          port {
            portMode
            subscriptionId
            tags
            speed
            customerDescription
            services {
              vlanrange
              insync
            }
          }
          address {
            city
            street
            number
          }
        }
      }
      topology {
        customerDescription
        otherSubscriptionId
        subscriptionInstanceId
        topologyObjectType
        vlanrange
        product {
          id
          name
          type
          tag
        }
      }
      saps {
        subscriptionInstanceId
        name
        port {
          subscriptionId
          description
          domain
          portMode
          portSpeed
          amountReserved
          speed
          status
          tags
          customerDescription
          customer {
            fullname
            customerId
          }
          product {
            id
            name
            tag
            type
          }
          services {
            description
            speed
            percentage
            product {
              type
              tag
              id
              name
            }
            vlanrange
            status
          }
          locations {
            address {
              addition
              city
              countryCode
              extraAddressLine
              number
              street
              zipcode
            }
          }
        }
      }
      esis {
        segmentId
        firewallInterconnect {
          firewallSubscriptionId
          subscriptionInstanceId
        }
        endpoints {
          subscriptionInstanceId
          organisation
          name
          tag
          vlanrange
          port {
            subscriptionId
            description
            amountReserved
            portSpeed
            tags
            customerDescription
            usedVlans
            customer {
              fullname
              customerId
            }
            locations {
              address {
                addition
                city
                number
                street
              }
            }
            address {
              addition
              city
              countryCode
              extraAddressLine
              number
              street
              zipcode
            }
            services {
              percentage
              customerDescription
              description
              subscriptionId
              vlanrange
              product {
                id
                type
                tag
              }
            }
            product {
              id
              type
              tag
            }
          }
        }
      }
      firewallInterconnects {
        availabilityZone
        firewallSubscriptionId
        subscriptionInstanceId
        organisation
        customerId
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class L2vpnSubscriptionDetailsGQL extends Apollo.Query<
  L2vpnSubscriptionDetailsQuery,
  L2vpnSubscriptionDetailsQueryVariables
> {
  document = L2vpnSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const L3vpnSubscriptionDetailsDocument = gql`
  query L3vpnSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    l3vpnDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      firewallEnabled
      domain
      insync
      serviceSpeed
      protection
      references {
        externalId
        name
      }
      product {
        id
        name
        tag
        type
      }
      circuits {
        endpoints {
          name
          organisation
          subscriptionInstanceId
          tag
          vlanrange
          port {
            customerDescription
            portMode
            subscriptionId
            tags
            speed
            services {
              vlanrange
              insync
            }
          }
          address {
            city
            street
            number
          }
        }
        speedPolicer
      }
      topology {
        customerDescription
        otherSubscriptionId
        subscriptionInstanceId
        topologyObjectType
        vlanrange
        product {
          id
          name
          type
          tag
        }
      }
      l3vpnss {
        l3vpnAsn
      }
      l3vpnsapss {
        subscriptionInstanceId
        name
        bfd
        bfdMinimumInterval
        bfdMultiplier
        bgpPassword
        ipv4Mtu
        ipv6Mtu
        bgpMetric
        ipv4Address
        ipv4MaxPrefix
        ipv6Address
        ipv4RemoteAddress
        ipv6MaxPrefix
        ipv6RemoteAddress
        sap {
          subscriptionInstanceId
          vlanrange
          port {
            description
            domain
            portMode
            portSpeed
            amountReserved
            speed
            status
            subscriptionId
            tags
            customerDescription
            product {
              id
              name
              tag
              type
            }
            customer {
              fullname
              customerId
            }
            services {
              description
              speed
              percentage
              vlanrange
              status
              product {
                type
                tag
                id
                name
              }
            }
            address {
              addition
              city
              countryCode
              extraAddressLine
              number
              street
              zipcode
            }
            locations {
              address {
                addition
                city
                countryCode
                extraAddressLine
                number
                street
                zipcode
              }
            }
          }
        }
      }
      firewallInterconnects {
        availabilityZone
        firewallAsn
        ipv4Mtu
        ipv6Mtu
        ipv4Prefix
        ipv6Prefix
        ipv4MaxPrefix
        ipv6MaxPrefix
        ipv4AddressFirewall
        ipv6AddressFirewall
        firewallSubscriptionId
        subscriptionInstanceId
        organisation
        customerId
        customerDescription
        saps {
          subscriptionInstanceId
          name
          tag
          organisation
          vlanrange
          address {
            street
            number
            city
          }
        }
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class L3vpnSubscriptionDetailsGQL extends Apollo.Query<
  L3vpnSubscriptionDetailsQuery,
  L3vpnSubscriptionDetailsQueryVariables
> {
  document = L3vpnSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IpBgpSubscriptionDetailsDocument = gql`
  query IpBgpSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    ipBgpDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      domain
      firewallEnabled
      ipRoutingType
      protection
      serviceSpeed
      product {
        id
        name
        tag
        type
      }
      ipss {
        surfcertFilter
        asn
      }
      saps {
        ...IpSapData
      }
      topology {
        topologyObjectType
        otherSubscriptionId
        subscriptionInstanceId
        vlanrange
        customerDescription
        product {
          id
          name
          type
          tag
        }
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
  ${IpSapDataFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class IpBgpSubscriptionDetailsGQL extends Apollo.Query<
  IpBgpSubscriptionDetailsQuery,
  IpBgpSubscriptionDetailsQueryVariables
> {
  document = IpBgpSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IpStaticSubscriptionDetailsDocument = gql`
  query IpStaticSubscriptionDetails($subscriptionId: UUID!, $accessCustomerId: UUID!) {
    ipStaticDetails(subscriptionId: $subscriptionId, accessCustomerId: $accessCustomerId) {
      ...BaseSubscriptionFragment
      serviceSpeed
      firewallEnabled
      protection
      domain
      ipRoutingType
      product {
        id
        name
        tag
        type
      }
      ipss {
        surfcertFilter
        asn
      }
      saps {
        ...IpSapData
      }
      topology {
        customerDescription
        otherSubscriptionId
        subscriptionInstanceId
        topologyObjectType
        vlanrange
        product {
          id
          name
          type
          tag
        }
      }
    }
  }
  ${BaseSubscriptionFragmentFragmentDoc}
  ${IpSapDataFragmentDoc}
`;

@Injectable({
  providedIn: "root",
})
export class IpStaticSubscriptionDetailsGQL extends Apollo.Query<
  IpStaticSubscriptionDetailsQuery,
  IpStaticSubscriptionDetailsQueryVariables
> {
  document = IpStaticSubscriptionDetailsDocument;
  client = "replicatedData";
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
