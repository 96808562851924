import { Component, inject, input, Input } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { DialogSlsComponent } from "../../../../dialogs/dialog-sls/dialog-sls";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "availability-item",
  templateUrl: "availability-item.html",
  styleUrls: ["../../shortcut-menu.scss"],
  imports: [TranslateModule, AngularSvgIconModule],
})
export class AvailabilityItem {
  private dialog = inject(MatDialog);

  protected subscription = input.required();

  showSlsDialog() {
    const dialogRef = this.dialog.open(DialogSlsComponent, {
      data: this.subscription(),
    });
  }
}
