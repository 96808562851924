<section class="section">
  <h3>{{ "Services.Filter.FilterByStatus" | translate }}</h3>
  <filter-bar
    filterTitle="{{ 'Services.Status.Ok' | translate }}"
    filterIcon="status-ok"
    filterType="state"
    filterValue="ok"
    filterClassActive="active"
    filterDefaultActive="false"
  ></filter-bar>
  <filter-bar
    filterTitle="{{ 'Services.Status.NoTraffic' | translate }}"
    filterIcon="status-no-traffic"
    filterType="state"
    filterValue="notraffic"
    filterClassActive="active"
    filterDefaultActive="false"
  >
  </filter-bar>
  <filter-bar
    filterTitle="{{ 'Services.Status.Warning' | translate }}"
    filterIcon="status-warning"
    filterType="state"
    filterValue="malfunction"
    filterClassActive="active"
    filterDefaultActive="false"
  >
  </filter-bar>
  <filter-bar
    filterTitle="{{ 'Services.Status.Pending' | translate }}"
    filterIcon="status-pending"
    filterType="state"
    filterValue="pending"
    filterClassActive="active"
    filterDefaultActive="false"
  >
  </filter-bar>
  <filter-bar
    filterTitle="{{ 'Services.Status.Unknown' | translate }}"
    filterIcon="status-unknown"
    filterType="state"
    filterValue="unknown"
    filterClassActive="active"
    filterDefaultActive="false"
  >
  </filter-bar>
</section>
