<button
  [matTooltip]="impactDescription"
  matTooltipPosition="above"
  class="button button--secondary"
  [ngClass]="{ active: isActive }"
  (click)="openNotificationSettingsDialog()"
>
  @if (isActive) {
    <svg-icon src="assets/images/icons/notification-set.svg"></svg-icon>
  } @else {
    <svg-icon src="assets/images/icons/notification-off.svg"></svg-icon>
  }
</button>
