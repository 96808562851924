import { Component, EventEmitter, inject, Input } from "@angular/core";
import { PageTabComponent } from "../../../../../../components/subscription-detail/page-tab/page-tab";
import { NetworkgraphComponent } from "../../../../../../components/subscription-detail/networkgraph/networkgraph";
import { ConnectionComponent } from "../../../../../../components/subscription-detail/connection/connection";
import { LightPathSubscriptionDetails, LightPathRedundantSubscriptionDetails } from "../../../../types";
import { SubscriptionDetailTab } from "../../../../../../components/subscription-detail/page-tab/tab-types";
import { Router } from "@angular/router";

@Component({
  selector: "lighpath-config",
  templateUrl: "lightpath-config.html",
  imports: [PageTabComponent, NetworkgraphComponent, ConnectionComponent],
})
export class LightpathConfigComponent {
  @Input() productType: string;
  @Input() subscription: LightPathSubscriptionDetails | LightPathRedundantSubscriptionDetails;
  @Input() activeTab: SubscriptionDetailTab;

  public messageBus: EventEmitter<string> = new EventEmitter();
  private readonly router = inject(Router);

  changeActiveTab(tabName: SubscriptionDetailTab) {
    this.router.navigate([
      "/subscription/LightPath",
      this.subscription.product.tag,
      this.subscription.subscriptionId,
      tabName,
    ]);
  }

  protected readonly SubscriptionDetailTab = SubscriptionDetailTab;
}
