import { Component, Input, Output, Inject, EventEmitter } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { FulfilmentRequest } from "../../../helpers/self-service/models/types";
import { AuthService } from "../../../services/AuthService";
import {
  IpSubscriptionDetails,
  isIp,
  isL2vpn,
  isLightPath,
  L2vpnSubscriptionDetails,
  LightPathBothSubscriptionDetails,
} from "../../../pages/subscription-detail/types";
import { SelfServiceDialogData, SelfServiceSubscription } from "./dialog-selfservice.types";
import {
  SelfserviceLoaderComponent,
  SubscriptionUpdateMessage,
} from "../../selfservice/selfservice-loader/selfservice-loader";
import { MessageService } from "../../../services/messageservice";
import { AngularSvgIconModule } from "angular-svg-icon";
import { LoaderComponent } from "../../common/loader/loader";
import { CommonModule } from "@angular/common";
import { SelfserviceDdosComponent } from "../../selfservice/selfservice-ddos/selfservice-ddos";
import { SelfserviceBfdComponent } from "../../selfservice/selfservice-bfd/selfservice-bfd";
import { SelfserviceUnavailableComponent } from "../../selfservice/selfservice-unavailable/selfservice-unavailable";
import { SelfserviceAddPortComponent } from "../../selfservice/selfservice-add-port/selfservice-add-port";
import { SelfserviceRpsComponent } from "../../selfservice/selfservice-rps/selfservice-rps";
import { SelfserviceSpeedPolicerComponent } from "../../selfservice/selfservice-speedpolicer/selfservice-speedpolicer";
import { SelfserviceBgpComponent } from "../../selfservice/selfservice-bgp/selfservice-bgp";
import { SelfserviceIPPrefixes } from "../../selfservice/selfservice-ip-prefixes/selfservice-ip-prefixes";
import { SelfserviceRemovePortComponent } from "../../selfservice/selfservice-remove-port/selfservice-remove-port";
import { SelfserviceSuccessComponent } from "../../selfservice/selfservice-success/selfservice-success";
import { SelfserviceVlanL2vpnComponent } from "../../selfservice/selfservice-vlan-l2vpn/selfservice-vlan-l2vpn";
import { SelfserviceVlanLightpath } from "../../selfservice/selfservice-vlan-lightpath/selfservice-vlan-lightpath";
import { SelfserviceDdosAutoMitigationComponent } from "../../selfservice/selfservice-ddos-auto-mitigation/selfservice-ddos-auto-mitigation";

// TODO: this whole class needs to die (painfully). It's just a huge switch that dispatches to the different
// self-service actions.

export interface StartLoadMessage {
  processId: string;
  action: string;
}
export interface statusMessage {
  kind: "status";
  status: string;
}
export type SelfserviceMessage<S> = statusMessage | SubscriptionUpdateMessage<S>;

export interface CommandMessage {
  kind: "command";
  event: Event;
}
export interface StringMessage {
  kind: "string";
  string: string;
}
export type SelfserviceCloseMessage<S> = StringMessage | CommandMessage | SubscriptionUpdateMessage<S>;

@Component({
  selector: "dialog-selfservice",
  templateUrl: "dialog-selfservice.html",
  styleUrls: ["dialog-selfservice.scss"],
  imports: [
    AngularSvgIconModule,
    CommonModule,
    LoaderComponent,
    SelfserviceBfdComponent,
    SelfserviceUnavailableComponent,
    SelfserviceDdosComponent,
    SelfserviceAddPortComponent,
    SelfserviceRpsComponent,
    SelfserviceSpeedPolicerComponent,
    SelfserviceBgpComponent,
    SelfserviceIPPrefixes,
    SelfserviceRemovePortComponent,
    SelfserviceLoaderComponent,
    SelfserviceSuccessComponent,
    SelfserviceVlanL2vpnComponent,
    SelfserviceVlanLightpath,
    SelfserviceDdosAutoMitigationComponent,
  ],
})
export class DialogSelfServiceComponent<S> {
  @Input() loader = false;
  @Input() loadingData = false;
  @Input() computedTitle = "";
  @Input() processId: string;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<SelfserviceCloseMessage<S>> = new EventEmitter();

  public communication: EventEmitter<StartLoadMessage> = new EventEmitter();
  public loadCommunication: EventEmitter<SelfserviceMessage<S>> = new EventEmitter();
  public subscription: SelfServiceSubscription;
  public circuit: { endpoints: { port?: { subscriptionId: string } }[] };
  public instance: { ipPrefixes: Record<string, unknown>[] };
  public sapIndex: number;
  public runningAction: string;
  protected success = false;
  currentStep: number;
  selectedPrefix: string;
  type: any;
  isIp = isIp;

  constructor(
    public dialogRef: MatDialogRef<DialogSelfServiceComponent<S>>,
    @Inject(MAT_DIALOG_DATA)
    public data: SelfServiceDialogData,
    private translate: TranslateService,
    private api: ApiHelper,
    public auth: AuthService,
    protected msgsrv: MessageService,
  ) {
    dialogRef.disableClose = true;
    this.subscription = data.subscription;
    this.instance = data.instance;
    this.sapIndex = data.sapIndex;
    this.circuit = data.circuit;

    this.communication.subscribe((event) => {
      this.processId = event.processId;
      this.runningAction = event.action || "unknown";
      this.loader = true;
    });

    this.loadCommunication.subscribe((event) => {
      switch (event.kind) {
        case "status":
          switch (event.status) {
            case "failed":
              alert("err");
              break;
            case "notify_support":
              this.notifySupport();
              break;
            case "error":
              this.notifySupport();
              break;
            case "completed":
              this.loader = false;
              this.success = true;
              break;
          }
          break;
        case "subscription-update":
          this.close.emit(event);
          this.msgsrv.setSubscription(event);
          this.dialogRef.close();
          break;
      }
    });
  }

  get title() {
    const title = this.translate.get("Selfservice.Title." + this.data.type).subscribe((res: string) => {
      this.computedTitle = res;
    });
    return this.computedTitle;
  }

  get titleSuffix() {
    return this.data.titleSuffix ? this.data.titleSuffix : null;
  }

  get ipSubscriptionDetails(): IpSubscriptionDetails {
    const s = this.subscription;
    if (isIp(s)) {
      return s;
    } else {
      throw Error("Attempting to use subscription as IP subscription while it isn't one");
    }
  }

  get l2vpnSubscriptionDetails(): L2vpnSubscriptionDetails {
    const s = this.subscription;
    if (isL2vpn(s)) {
      return s;
    } else {
      throw Error("Attempting to use subscription as L2VPN subscription while it isn't one");
    }
  }

  get lightpathSubscriptionDetails(): LightPathBothSubscriptionDetails {
    const s = this.subscription;
    if (isLightPath(s)) {
      return s;
    } else {
      throw Error("Attempting to use subscription as lightpath subscription while it isn't one");
    }
  }

  get selfServiceSpCompatible(): L2vpnSubscriptionDetails | LightPathBothSubscriptionDetails {
    const s = this.subscription;
    if (isL2vpn(s) || isLightPath(s)) {
      return s;
    } else {
      throw Error("Attempting to use subscription as L2VPN or LP/LR subscription while it isn't one");
    }
  }

  closeDialog(event: string | Event): void {
    this.dialogRef.close();
    if (typeof event !== "string") {
      this.close.emit({ kind: "command", event }); // notify subscription-detail
    } else {
      this.close.emit({ kind: "string", string: event }); // notify subscription-detail
    }
  }

  refresh(event: string): void {
    this.success = true;
    this.close.emit({ kind: "string", string: event }); // notify subscription-detail
  }

  notifySupport(): void {
    const customerId = localStorage.getItem("viewingCustomerGUID");
    // note: not the type from /components/models.
    // we don't need any verification / errorchecking here.
    const request: FulfilmentRequest = {
      contacts: [
        {
          fullName: this.auth.state.currentUser.displayName,
          email: this.auth.state.currentUser.email,
          phone: "06123", // placeholder, since we don't know the value.
        },
      ],
      serviceRequest: "problem",
      text: this.translate.instant("Selfservice.FulfilmentProblem.Message", {
        subscription_name: this.subscription.description,
        action: this.runningAction,
        process_id: this.processId,
      }),
      subscriptionId: this.subscription.subscriptionId,
      product: this.subscription.product.type,
    };

    this.api
      .add_new_fulfilment_problem(customerId, JSON.stringify(request))
      .then((result) => {
        this.closeDialog("refresh");
      })
      .catch((err) => this.closeDialog("refresh"));
  }
}
