import { Component, OnChanges, computed, inject, input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { DialogIncidentComponent } from "../../dialogs/dialog-incident/dialog-incident";
import { Instance } from "../../models/instance";
import { knownVendors } from "../../../helpers/kpi/kpi-item";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";
import { TranslateModule } from "@ngx-translate/core";
import { LoaderComponent } from "../../common/loader/loader";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { StatusToIconPipe } from "../../../helpers/statusiconpipe/statusiconpipe";
import { AliasEditorComponent } from "../../common/alias-editor/alias-editor.component";
import { ServiceStatusComponent } from "../service-status/service-status";
import { PortVisualiser } from "../../subscription-detail/port-visualiser/port-visualiser";
import { GuidPipe } from "../../../helpers/guidpipe/guidpipe";
import { KpiRowComponent } from "../kpi-row/kpi-row";
import { ReadableSizePipe } from "../../../helpers/bitspipe/bitspipe";
import { ServiceElementTagsComponent } from "./components/service-element-tags/service-element-tags";
import { LocationAddressPipe } from "./components/location-address-pipe";

@Component({
  selector: "service-element-simple",
  templateUrl: "service-element-simple.html",
  imports: [
    AliasEditorComponent,
    AngularSvgIconModule,
    CommonModule,
    GuidPipe,
    KpiRowComponent,
    LoaderComponent,
    LocationAddressPipe,
    PortVisualiser,
    ReadableSizePipe,
    RouterModule,
    ServiceElementTagsComponent,
    ServiceStatusComponent,
    StatusToIconPipe,
    TranslateModule,
  ],
})
export class ServiceElementSimple implements OnChanges {
  private dialog = inject(MatDialog);
  private subscriptionService = inject(SubscriptionService);

  protected subscription = input.required<Instance>();
  protected productType = input.required<string>();

  protected subscriptionId = computed(() => this.subscription().subscriptionId);
  protected owner = computed(() => this.subscription().organisation || this.subscription().customer?.fullname);
  protected customerDescription = computed(() => this.subscription().customerDescription);
  protected locations = computed(() => this.subscription().locations || []);
  protected firewallEnabled = computed(() => this.subscription().firewallEnabled);
  protected tags = computed(() => this.subscription().tags || []);

  private isPort = computed(() => this.productType() === "Port");
  protected health = computed(() => this.subscription()?.health);
  protected traffic = computed(() => this.health()?.traffic);
  protected isWireless = computed(() => this.subscription().product.type === "Wireless");

  protected showPortVisualizer = computed(() => this.isPort() && !this.subscription().tags?.includes("LINK_MEMBER"));

  protected wirelessKpiList: {
    activeAps: number | null;
    inactiveAps: number | null;
    kpiAverages: { [key: string]: number }[];
  };

  // TODO: extract wireless (and other) specific code from this 'generic' service-element
  get wirelessVendor(): string | undefined {
    const serviceTags: string[] = this.tags().map((tag) => tag.toLowerCase());
    const wirelessVendor: string | undefined = serviceTags.filter((tag) => knownVendors.includes(tag)).at(0);
    return wirelessVendor;
  }

  ngOnChanges() {
    // have not fetched health before, but we have a subscription
    if (!this.health() && this.subscriptionId()) {
      this.loadHealth();
    }

    if (this.isWireless()) {
      this.subscriptionService.getWirelessListKpiForSubscription(this.subscriptionId()).then((kpiList) => {
        this.wirelessKpiList = kpiList;

        this.wirelessKpiList.activeAps = this.subscription()?.location.activeAccessPoints;
        this.wirelessKpiList.inactiveAps = this.subscription()?.location.inactiveAccessPoints;
      });
    }
  }

  loadHealth() {
    this.subscriptionService.getHealthDataForSubscription(this.subscriptionId()).then((healthData) => {
      this.subscription().health = healthData;
    });
  }

  openIncidentDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogIncidentComponent, {
      data: {
        subscription: this.subscription(),
      },
    });
    return false;
  }

  getRouterLink() {
    return getDetailRouterLink(this.subscription(), this.productType());
  }
}
