import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { WirelessHealthDetail } from "../../../gql/generated";

@Component({
  selector: "user-stats",
  templateUrl: "user-stats.html",
  styleUrls: ["user-stats.scss"],
  imports: [TranslateModule],
})
export class UserStatsComponent {
  protected wirelessHealthData = input.required<WirelessHealthDetail>();
}
