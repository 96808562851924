import { Component, inject, Inject, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LoginResponse, OidcSecurityService } from "angular-auth-oidc-client";
import { AuthService } from "../services/AuthService";
import { OidcAuthenticationConfig } from "./auth/types";
import { OidcTokenResult } from "../helpers/types";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: false,
})
export class AppComponent implements OnInit {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  title = "Surfnet";
  constructor(@Inject(TranslateService) translate: TranslateService) {
    // this.matomoInjector.init(MatomoConfig.url, MatomoConfig.site_id);

    translate.addLangs(["nl", "en"]);
    // this language will be used as a fallback when a translation isn't found in the current language
    const currentLanguage = translate.getBrowserLang();
    translate.setDefaultLang("nl");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const storedLanguage = localStorage.getItem("lang");
    translate.use(storedLanguage !== null ? storedLanguage : currentLanguage);
  }

  ngOnInit(): void {
    this.oidcSecurityService.getConfiguration(OidcAuthenticationConfig.Default).subscribe((config) => {
      if (config.secureRoutes.length > 0) {
        // the secure routes are not added when oauthEnabled is false
        this.handleOidc();
      } else {
        this.authService.fetchUser();
      }
    });
  }

  handleOidc(): void {
    this.oidcSecurityService.checkAuthMultiple().subscribe((responses: LoginResponse[]) => {
      const authResponse = responses.find((response) => response.configId === OidcAuthenticationConfig.Default);
      const stepupResponse = responses.find((response) => response.configId === OidcAuthenticationConfig.SelfService);

      if (authResponse.isAuthenticated) {
        this.authService.fetchUser();
      }

      if (stepupResponse.isAuthenticated) {
        this.oidcSecurityService
          .getPayloadFromIdToken(false, OidcAuthenticationConfig.SelfService)
          .subscribe((result: OidcTokenResult) => {
            this.authService.updateSelfserviceTokenStatus();
            this.oidcSecurityService.getState(OidcAuthenticationConfig.SelfService).subscribe((state) => {
              if (state && state.length) {
                this.handleRedirect(state);
              }
            });
            setInterval(() => this.authService.updateSelfserviceTokenStatus(), 30000);
          });
      }
    });
  }

  handleRedirect(state: string): void {
    try {
      const parsedState = JSON.parse(state);
      if (parsedState.redirect) {
        this.router.navigateByUrl(parsedState.redirect);
      }
    } catch (e) {
      console.log("No usable state found. Not redirecting.");
    }
  }
}
