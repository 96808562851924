import { Component, EventEmitter, Input, OnInit, output, inject } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";
import { HttpErrorResponse } from "@angular/common/http";
import { ModifyL2VPNRemovePort } from "../../../helpers/self-service/modify_l2vpn";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { transformError } from "../../../helpers/self-service/transform-error";
import { AuthService } from "../../../services/AuthService";
import { ServicePort } from "../selfservice-add-port/ServicePort";
import { L2VPNEndpoint, L2VPNESI } from "../../../helpers/subscription/l2vpn";
import { L2vpnSubscriptionDetails } from "../../../pages/subscription-detail/types";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SelfserviceToggleComponent } from "../selfservice-toggle/selfservice-toggle";
import { FormsModule } from "@angular/forms";
import { GuidPipe } from "../../../helpers/guidpipe/guidpipe";
import { CustomDescriptionPipe } from "../../../helpers/descriptionpipe/customdescription";
import { SelfserviceAuthenticationBoxComponent } from "../selfservice-authentication-box/selfservice-authentication-box";

@Component({
  selector: "selfservice-remove-port",
  templateUrl: "selfservice-remove-port.html",
  styleUrls: ["../selfservice-add-port/selfservice-add-port.scss"],
  imports: [
    AngularSvgIconModule,
    CommonModule,
    CustomDescriptionPipe,
    FormsModule,
    SelfserviceToggleComponent,
    GuidPipe,
    SelfserviceAuthenticationBoxComponent,
    TranslateModule,
  ],
})
export class SelfserviceRemovePortComponent implements OnInit {
  protected api = inject(ApiHelper);
  protected subscriptionService = inject(SubscriptionService);
  protected auth = inject(AuthService);

  @Input() currentStep = 1;
  @Input() subscription: L2vpnSubscriptionDetails;
  @Input() bus: EventEmitter<any>;

  dialogCloseEmitter = output<string>();

  public hasErrors = false;
  public errors: ModifyErrorDetail[];
  public buttonLoading = false;
  public loadingData = false;
  public mappedPorts: Array<L2VPNESI & { selected: boolean }> = [];
  public summaryItemOneExpanded = true;
  public summaryItemTwoExpanded = false;
  private processId: string;

  get removedPorts() {
    return this.mappedPorts.filter((port) => port.selected === false);
  }

  get existingPorts() {
    return this.mappedPorts.filter((port) => port.selected === true);
  }

  get disableSubmitButton() {
    return !this.removedPorts.length || this.existingPorts.length < 2;
  }

  ngOnInit() {
    const setSelected = (obj: L2VPNESI) => ({ ...obj, selected: true });
    this.mappedPorts = this.subscription.esis.map(setSelected);
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  toggleSummaryItemOne() {
    this.summaryItemOneExpanded = !this.summaryItemOneExpanded;
  }

  toggleSummaryItemTwo() {
    this.summaryItemTwoExpanded = !this.summaryItemTwoExpanded;
  }

  prev() {
    this.currentStep--;
  }

  next() {
    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.currentStep++;
  }

  submit() {
    this.buttonLoading = true;
    const modify = new ModifyL2VPNRemovePort();
    const command = new SelfServiceCommand(
      this.subscription.subscriptionId,
      SelfServiceCommandKeys.ModifyL2VpnRemovePort,
    );
    // set default values (current)
    modify.subscription = this.subscription;

    const esis_to_remove = this.removedPorts.map((esi: L2VPNESI) =>
      esi.endpoints.map((endpoint: L2VPNEndpoint) => ({
        subscription_id: endpoint.port.subscriptionId,
        vlan: endpoint.vlanrange,
      })),
    );
    // Fill in only the ESIs we want to add
    modify.setEsis(esis_to_remove);
    command.payload = modify;

    // reset error div trigger
    this.hasErrors = false;

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({
          processId: this.processId,
          action: SelfServiceCommandKeys.ModifyL2VpnRemovePort,
        });
      })
      .catch((err) => {
        this.onError(err);
        this.buttonLoading = false;
      });
  }
  onError(err: HttpErrorResponse) {
    try {
      this.errors = transformError(err.error.detail);
      this.hasErrors = true;
    } catch (e) {
      console.log("unknown error", err);
    }
  }

  isMyPort(port: ServicePort): boolean {
    return port.customer.customerId === this.auth.viewingCustomerId;
  }
}
