import { Component, computed, inject, input, Input, OnChanges, signal, SimpleChanges } from "@angular/core";
import { MessageService } from "../../../services/messageservice";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { CommonModule, ViewportScroller } from "@angular/common";
import { ServiceType } from "../../../gql/generated-ingestor";
import { LocationType } from "../../../gql/generated-ingestor";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CustomDescriptionPipe } from "../../../helpers/descriptionpipe/customdescription";
import { GuidPipe } from "../../../helpers/guidpipe/guidpipe";
import { ServiceSpeedPipe } from "../../../pages/subscription-detail/components/service-speed-pipe/service-speed-pipe";
import { PortVisualiser } from "../port-visualiser/port-visualiser";
import { RouterModule } from "@angular/router";
import { StatusToIconPipe } from "../../../helpers/statusiconpipe/statusiconpipe";
import { TrafficSpeedComponent } from "./components/traffic-speed/traffic-speed";

@Component({
  selector: "service-summary",
  templateUrl: "service-summary.html",
  imports: [
    AngularSvgIconModule,
    CommonModule,
    CustomDescriptionPipe,
    GuidPipe,
    PortVisualiser,
    RouterModule,
    ServiceSpeedPipe,
    StatusToIconPipe,
    TranslateModule,
    TrafficSpeedComponent,
  ],
})
export class ServiceSummaryComponent implements OnChanges {
  private msgsrv = inject(MessageService);
  private subscriptionService = inject(SubscriptionService);
  private viewportScroller = inject(ViewportScroller);

  service = input.required<any>();
  showTraffic = input(false);

  @Input() portId: string;
  @Input() subscriptionInstanceId: string;
  @Input() forceTrafficBottom = false;
  @Input() showTrafficButton: boolean;
  @Input() showMoreDetailsButton = true;
  @Input() vlanranges: number[] = [];
  @Input() parent: any;

  public isAccessible: boolean;
  public healthData = signal(undefined);
  public sortedTags = [];
  public icon = "ip";

  protected isMsc = computed(() => this.service().product?.tag?.startsWith("MSC"));
  protected isPort = computed(() => this.service().product.type === "Port");
  protected isFirewall = computed(() => this.service().product.type === "FW");
  protected showFirewallEnabled = computed(() => this.service().firewallEnabled && !this.isFirewall());

  organisationName = computed(() => {
    if (typeof this.service().organisation === "string") {
      return this.service().organisation;
    }
    if (this.service().customer?.fullname) {
      return this.service().customer?.fullname;
    }

    return "-";
  });

  get locations(): string[] {
    const locs: LocationType[] = this.service().locations || [];
    return locs.map((l) => `${l.address.city} ${l.address.street} ${l.address.number}${l.address.addition ?? ""}`);
  }

  setIcon() {
    if (this.service().ipGateway) {
      this.icon = "ip";
    } else if (!this.service().product) {
      this.icon = "ip";
    } else {
      this.icon = this.service().product.type.toLowerCase();
    }
  }

  routerLinkForService(service: ServiceType): string[] {
    return [getDetailRouterLink(service, service.product.type)];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["service"]) {
      this.isAccessible = this.subscriptionService.canViewSubscription(this.service());
      this.setIcon();

      if (this.isAccessible) {
        this.subscriptionService
          .getHealthDataForSubscription(
            this.service().subscriptionId,
            this.service().product.type === "FW" ? this.subscriptionInstanceId : null, // Optional
          )
          .then((status) => {
            const trafficIn = status.traffic.last.in;
            const trafficOut = status.traffic.last.out;

            this.healthData.set({
              status: status.status,
              trafficIn,
              trafficOut,
            });
          });
      }
    }
  }

  navigateToChart(duration?: number) {
    this.viewportScroller.scrollToAnchor("chart");
    const filterProps = {
      connection: this.service,
      vlanranges: this.vlanranges,
    };

    this.msgsrv.setChartFilter(filterProps);
  }
}
