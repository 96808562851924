<page [subscription]="subscription">
  @if (isLoading()) {
    <loader />
  } @else if (isNotFound()) {
    <not-found />
  } @else if (isNotAuthorized()) {
    <not-authorized />
  } @else if (pageLoaded()) {
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col--flex">
          <wireless-shortcut-menu
            [subscription]="subscription"
            [isTerminated]="isTerminated"
            [activities]="activities"
          ></wireless-shortcut-menu>
        </div>

        <div class="col-lg-9">
          <div class="card">
            <subscription-detail-header [subscription]="subscription" [isTerminated]="isTerminated" />

            <div class="row">
              <div class="col-12 col-sm-7">
                <table class="table-properties table-properties--no-margin">
                  <tr>
                    <td>{{ "ServiceElement.Owner" | translate }}</td>
                    <td>{{ owner() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.OwnAlias" | translate }}</td>
                    <td>
                      <alias-editor [customerDescription]="customerDescription()" [subscriptionId]="subscriptionId()" />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.SURFnetDescription" | translate }}</td>
                    <td>{{ description() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ServiceID" | translate }}</td>
                    <td><service-id [subscriptionId]="subscriptionId()" /></td>
                  </tr>
                </table>
              </div>
              <div class="col-12 col-sm-5">
                <table class="table-properties table-properties--no-margin">
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.Status" | translate }}</td>
                      <td>{{ status() }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Start" | translate }}</td>
                    <td>{{ startDate() | subscription_date }}</td>
                  </tr>
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.End" | translate }}</td>
                      <td>{{ endDate() | subscription_date }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.ProductName" | translate }}</td>
                    <td>{{ productName() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.Supplier" | translate }}</td>
                    <td>{{ supplier() }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      @if (isTerminated) {
        <div class="hide-everything-after-this-div"></div>
      }
      <hr />
      <hr />
      <hr />
      <div>
        <access-points-section [subscription]="subscription" [healthData]="healthData()" />
        <kpi-section [subscription]="subscription" [healthData]="healthData()" />
      </div>

      <linechart id="chart" #chart [subscription]="subscription" [chartChoices]="chartChoices()" />
    </div>
  }
</page>
