import { Component, inject, input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { CertTabsComponent } from "../../components/cert/cert-tabs/cert-tabs";
import { CertHeaderComponent } from "../../components/cert/cert-header/cert-header";
import { DdosTabComponent } from "./components/ddos-tab/ddos-tab";
import { ScanTabComponent } from "./components/scan-tab/scan-tab";
import { FooterComponent } from "../../components/common/footer/footer";

@Component({
  selector: "page-cert",
  templateUrl: "cert.html",
  styleUrls: ["cert.scss"],
  imports: [
    CertHeaderComponent,
    CertTabsComponent,
    DdosTabComponent,
    FooterComponent,
    NavigationComponent,
    ScanTabComponent,
    TranslateModule,
  ],
})
export class CertPage implements OnInit {
  private router = inject(Router);

  // this value is read from the URL (see dashboard-routing.module.ts)
  readonly initialTab = input("scan");
  public activeTab = "scan";

  ngOnInit() {
    if (["scan", "ddos"].includes(this.initialTab())) {
      this.activeTab = this.initialTab();
    }
  }

  changeTab(tab: string) {
    this.router.navigate(["/cert", tab], { replaceUrl: true });
    this.activeTab = tab;
  }
}
