import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { apiUrl, ENV } from "../app.runtime";
import { NWDUserConfig } from "../../helpers/types";
import { OidcAuthenticationConfig } from "./types";

export const httpLoaderFactory = (httpClient: HttpClient) => {
  const configObservable$ = httpClient.get<NWDUserConfig>(apiUrl("user/config"));
  const config$ = configObservable$.pipe(
    map((customConfig: NWDUserConfig) => ({
      configId: OidcAuthenticationConfig.Default,
      authority: customConfig.issuer,
      redirectUrl: `${window.location.origin}/oauth2/callback`,
      clientId: customConfig.clientId,
      responseType: "code",
      scope: "openid profile email", // scope 'offline' provides a refresh token
      postLogoutRedirectUri: ENV.NWD_DASHBOARD_URL,
      // startCheckSession: customConfig.start_checksession,
      silentRenew: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      // postLoginRoute: customConfig.startup_route,
      forbiddenRoute: `${window.location.origin}/forbidden`,
      unauthorizedRoute: `${window.location.origin}/unauthorized`,
      logLevel: LogLevel.Error,
      // maxIdTokenIatOffsetAllowedInSeconds: customConfig.max_id_token_iat_offset_allowed_in_seconds,
      historyCleanupOff: true,
      checkRedirectUrlWhenCheckingIfIsCallback: false,
      secureRoutes: customConfig.oauthEnabled ? [apiUrl(""), ENV.DOMAINS_API] : [],
      // autoUserInfo: false,
    })),
  );
  const configStepup$ = configObservable$.pipe(
    map((customConfig: NWDUserConfig) => ({
      configId: OidcAuthenticationConfig.SelfService,
      authority: customConfig.issuer,
      redirectUrl: `${window.location.origin}/oauth2/callback`,
      autoCleanStateAfterAuthentication: false, // don't clean state, so we can redirect the user afterwards.
      clientId: customConfig.clientId,
      responseType: "code",
      scope: "openid profile email", // scope 'offline' provides a refresh token
      postLogoutRedirectUri: ENV.NWD_DASHBOARD_URL,
      // startCheckSession: customConfig.start_checksession,
      silentRenew: false,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      // postLoginRoute: customConfig.startup_route,
      forbiddenRoute: `${window.location.origin}/forbidden`,
      unauthorizedRoute: `${window.location.origin}/unauthorized`,
      logLevel: LogLevel.Error,
      // maxIdTokenIatOffsetAllowedInSeconds: customConfig.max_id_token_iat_offset_allowed_in_seconds,
      historyCleanupOff: true,
      checkRedirectUrlWhenCheckingIfIsCallback: false,
      secureRoutes: customConfig.oauthEnabled ? [apiUrl(""), ENV.DOMAINS_API] : [],
      customParamsAuthRequest: {
        acr_values: customConfig.loa,
      },
      // autoUserInfo: false,
    })),
  );

  return new StsConfigHttpLoader([config$, configStepup$]);
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthHttpConfigModule {}
