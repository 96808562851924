import { CommonModule } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "malfunction-item",
  templateUrl: "malfunction-item.html",
  styleUrls: ["../../shortcut-menu.scss"],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule],
})
export class MalfunctionItem {
  private router = inject(Router);

  protected subscription = input.required<{ product: { type: string }; subscriptionId: string }>();
  protected messages = input.required<Notification[]>();

  redirectToMalfunction() {
    this.router.navigate([
      "/storing-en-onderhoud/" + this.subscription().product.type + "/" + this.subscription().subscriptionId,
    ]);
  }
}
