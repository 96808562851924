<div class="section">
  <div class="row">
    <div class="col-12">
      <h3 class="title-with-icon">
        <svg-icon src="assets/images/icons/performance.svg" class="black-medium"></svg-icon>
        <span>Key performance indicators</span>
      </h3>
      <ng-container>
        @switch (sensorPresent()) {
          @case (undefined) {
            <loader class="card" [paddingSize]="'small'"></loader>
          }
          @case (true) {
            <ng-container>
              @if (healthData() !== undefined) {
                <kpi-table [vendor]="vendor()" [wirelessHealthData]="healthData()"></kpi-table>
              } @else {
                <loader class="card" [paddingSize]="'small'"></loader>
              }
            </ng-container>
          }
          @case (false) {
            <div class="card">No sensor present</div>
          }
          @default {
            <div class="card">Unknown sensor state</div>
          }
        }
      </ng-container>
    </div>
  </div>
</div>
