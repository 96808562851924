<div class="dialog">
  <div class="dialog__header">
    <h3 class="dialog__title">
      {{ title }}
      @if (titleSuffix) {
        <span class="dialog__title-suffix">{{ titleSuffix }}</span>
      }
    </h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog('refresh')" />
  </div>

  @if (loadingData) {
    <div
      class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
    >
      <div>
        <loader></loader>
      </div>
    </div>
  }

  <div *ngIf="!loadingData && subscription && !auth.isCurrentOrganisation(subscription.customerId); else allowed">
    No access
  </div>

  <ng-template #allowed>
    <selfservice-unavailable *ngIf="subscription && !subscription.insync" class="dialog__grow-wrapper" />

    @if (!loader && subscription.insync) {
      <selfservice-ddos
        *ngIf="data.type === 'ddos' && isIp(subscription)"
        class="dialog__grow-wrapper"
        (dialogCloseEmitter)="closeDialog($event)"
        [subscription]="subscription"
        [bus]="communication"
      />

      <selfservice-rps
        *ngIf="data.type === 'rps'"
        class="dialog__grow-wrapper"
        (dialogCloseEmitter)="closeDialog($event)"
        [subscription]="subscription"
        [bus]="communication"
      />

      <selfservice-speedpolicer
        *ngIf="data.type === 'sp'"
        class="dialog__grow-wrapper"
        (dialogCloseEmitter)="closeDialog($event)"
        [subscription]="selfServiceSpCompatible"
        [bus]="communication"
      />

      <selfservice-bfd
        *ngIf="data.type === 'bfd'"
        class="dialog__grow-wrapper"
        (dialogCloseEmitter)="closeDialog($event)"
        [subscription]="subscription"
        [instance]="instance"
        [sapIndex]="sapIndex"
        [bus]="communication"
      />

      <selfservice-bgp
        *ngIf="data.type === 'bgp'"
        class="dialog__grow-wrapper"
        (dialogCloseEmitter)="closeDialog($event)"
        [subscription]="ipSubscriptionDetails"
        [bus]="communication"
      />

      <selfservice-ip-prefixes
        *ngIf="data.type === 'ip-prefixes'"
        class="dialog__grow-wrapper"
        (dialogCloseEmitter)="closeDialog($event)"
        [subscription]="ipSubscriptionDetails"
        [sapIndex]="sapIndex"
        [bus]="communication"
        [currentStep]="data.currentStep"
        [selectedPrefix]="data.selectedPrefix"
      />

      <selfservice-ddos-auto-mitigation
        *ngIf="!success && data.type === 'autoMitigation'"
        class="dialog__grow-wrapper"
        (dialogCloseEmitter)="closeDialog($event)"
        [subscription]="subscription"
        [bus]="loadCommunication"
        [mitigationState]="data.mitigationState"
      />

      <selfservice-add-port
        *ngIf="data.type === 'add-port'"
        class="dialog__grow-wrapper"
        [subscription]="subscription"
        [bus]="communication"
      />

      <selfservice-remove-port
        *ngIf="data.type === 'remove-port'"
        class="dialog__grow-wrapper"
        [subscription]="l2vpnSubscriptionDetails"
        [bus]="communication"
      />

      <selfservice-vlan-lightpath
        *ngIf="data.type === 'vlan-lightpath'"
        class="dialog__grow-wrapper"
        [subscription]="lightpathSubscriptionDetails"
        [bus]="communication"
      />

      <selfservice-vlan-l2vpn
        *ngIf="data.type === 'vlan-l2vpn'"
        class="dialog__grow-wrapper"
        [subscription]="subscription"
        [bus]="communication"
      />
    }
    <selfservice-loader
      *ngIf="loader && !success"
      class="dialog__grow-wrapper"
      [processId]="processId"
      [subscriptionId]="subscription.subscriptionId"
      [bus]="loadCommunication"
    />

    <selfservice-success *ngIf="success" class="dialog__grow-wrapper" />
  </ng-template>
</div>
