// page dots
(function (window, factory) {
  // universal module definition
  if (typeof define == 'function' && define.amd) {
    // AMD
    define(['./flickity', 'unipointer/unipointer', 'fizzy-ui-utils/utils'], function (Flickity, Unipointer, utils) {
      return factory(window, Flickity, Unipointer, utils);
    });
  } else if (typeof module == 'object' && module.exports) {
    // CommonJS
    module.exports = factory(window, require('./flickity'), require('unipointer'), require('fizzy-ui-utils'));
  } else {
    // browser global
    factory(window, window.Flickity, window.Unipointer, window.fizzyUIUtils);
  }
})(window, function factory(window, Flickity, Unipointer, utils) {
  // -------------------------- PageDots -------------------------- //

  'use strict';

  function PageDots(parent) {
    this.parent = parent;
    this._create();
  }
  PageDots.prototype = Object.create(Unipointer.prototype);
  PageDots.prototype._create = function () {
    // create holder element
    this.holder = document.createElement('ol');
    this.holder.className = 'flickity-page-dots';
    // create dots, array of elements
    this.dots = [];
    // events
    this.handleClick = this.onClick.bind(this);
    this.on('pointerDown', this.parent.childUIPointerDown.bind(this.parent));
  };
  PageDots.prototype.activate = function () {
    this.setDots();
    this.holder.addEventListener('click', this.handleClick);
    this.bindStartEvent(this.holder);
    // add to DOM
    this.parent.element.appendChild(this.holder);
  };
  PageDots.prototype.deactivate = function () {
    this.holder.removeEventListener('click', this.handleClick);
    this.unbindStartEvent(this.holder);
    // remove from DOM
    this.parent.element.removeChild(this.holder);
  };
  PageDots.prototype.setDots = function () {
    // get difference between number of slides and number of dots
    var delta = this.parent.slides.length - this.dots.length;
    if (delta > 0) {
      this.addDots(delta);
    } else if (delta < 0) {
      this.removeDots(-delta);
    }
  };
  PageDots.prototype.addDots = function (count) {
    var fragment = document.createDocumentFragment();
    var newDots = [];
    var length = this.dots.length;
    var max = length + count;
    for (var i = length; i < max; i++) {
      var dot = document.createElement('li');
      dot.className = 'dot';
      dot.setAttribute('aria-label', 'Page dot ' + (i + 1));
      fragment.appendChild(dot);
      newDots.push(dot);
    }
    this.holder.appendChild(fragment);
    this.dots = this.dots.concat(newDots);
  };
  PageDots.prototype.removeDots = function (count) {
    // remove from this.dots collection
    var removeDots = this.dots.splice(this.dots.length - count, count);
    // remove from DOM
    removeDots.forEach(function (dot) {
      this.holder.removeChild(dot);
    }, this);
  };
  PageDots.prototype.updateSelected = function () {
    // remove selected class on previous
    if (this.selectedDot) {
      this.selectedDot.className = 'dot';
      this.selectedDot.removeAttribute('aria-current');
    }
    // don't proceed if no dots
    if (!this.dots.length) {
      return;
    }
    this.selectedDot = this.dots[this.parent.selectedIndex];
    this.selectedDot.className = 'dot is-selected';
    this.selectedDot.setAttribute('aria-current', 'step');
  };
  PageDots.prototype.onTap =
  // old method name, backwards-compatible
  PageDots.prototype.onClick = function (event) {
    var target = event.target;
    // only care about dot clicks
    if (target.nodeName != 'LI') {
      return;
    }
    this.parent.uiChange();
    var index = this.dots.indexOf(target);
    this.parent.select(index);
  };
  PageDots.prototype.destroy = function () {
    this.deactivate();
    this.allOff();
  };
  Flickity.PageDots = PageDots;

  // -------------------------- Flickity -------------------------- //

  utils.extend(Flickity.defaults, {
    pageDots: true
  });
  Flickity.createMethods.push('_createPageDots');
  var proto = Flickity.prototype;
  proto._createPageDots = function () {
    if (!this.options.pageDots) {
      return;
    }
    this.pageDots = new PageDots(this);
    // events
    this.on('activate', this.activatePageDots);
    this.on('select', this.updateSelectedPageDots);
    this.on('cellChange', this.updatePageDots);
    this.on('resize', this.updatePageDots);
    this.on('deactivate', this.deactivatePageDots);
  };
  proto.activatePageDots = function () {
    this.pageDots.activate();
  };
  proto.updateSelectedPageDots = function () {
    this.pageDots.updateSelected();
  };
  proto.updatePageDots = function () {
    this.pageDots.setDots();
  };
  proto.deactivatePageDots = function () {
    this.pageDots.deactivate();
  };

  // -----  ----- //

  Flickity.PageDots = PageDots;
  return Flickity;
});