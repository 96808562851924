<navigation
  [activeItem]="1"
  [detailData]="detailData()"
  [firewallIconVisible]="firewalled()"
  [productType]="productType()"
  [terminated]="terminated()"
></navigation>

<ng-content></ng-content>

<div class="container">
  <footer></footer>
</div>
