import { Component, inject } from "@angular/core";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { IpBgpSubscriptionDetailsGQL, IpBgpSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { ApolloQueryResult } from "@apollo/client";
import { IpBgpSubscriptionDetails } from "../../types";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { PageComponent } from "../../../../components/page/page";
import { SubscriptionDetailHeader } from "../../../../components/subscription-detail/subscription-detail-header/subscription-detail-header";
import { AliasEditorComponent } from "../../../../components/common/alias-editor/alias-editor.component";
import { SubscriptionDatePipe } from "../../../../helpers/subscription-datepipe/subscription-datepipe";
import { ServiceId } from "../../../../components/subscription-detail/service-id/service-id";
import { LinechartComponent } from "../../../../components/subscription-detail/linechart/linechart";
import { ThirdPartyServiceIdsRowContentComponent } from "../../../../components/subscription-detail/third-party-service-ids-row-content/third-party-service-ids-row-content";
import { ServiceSpeedPipe } from "../service-speed-pipe/service-speed-pipe";
import { EnabledPipe } from "../../../../helpers/enabledpipe/enabledpipe";
import { SelfserviceToggleComponent } from "../../../../components/selfservice/selfservice-toggle/selfservice-toggle";
import { PageTabComponent } from "../../../../components/subscription-detail/page-tab/page-tab";
import { TabbedDetailContainer } from "../../../../components/subscription-detail/tabbed-detail-container/tabbed-detail-container";
import { NetworkgraphComponent } from "../../../../components/subscription-detail/networkgraph/networkgraph";
import { IpShortCutMenuComponent } from "./components/ip-shortcut-menu/ip-shortcut-menu";
import { SubscriptionDetailTab } from "../../../../components/subscription-detail/page-tab/tab-types";
import { NotFoundComponent } from "../../../../components/common/not-found/not-found";
import { LoaderComponent } from "../../../../components/common/loader/loader";
import { NotAuthorizedComponent } from "../../../../components/common/not-authorized/not-authorized";

@Component({
  selector: "ip-bgp-subscription",
  templateUrl: "ip-subscription.html",
  imports: [
    CommonModule,
    PageComponent,
    IpShortCutMenuComponent,
    SubscriptionDetailHeader,
    TranslateModule,
    AliasEditorComponent,
    SubscriptionDatePipe,
    ServiceId,
    ServiceSpeedPipe,
    EnabledPipe,
    LinechartComponent,
    ThirdPartyServiceIdsRowContentComponent,
    SelfserviceToggleComponent,
    PageTabComponent,
    TabbedDetailContainer,
    NetworkgraphComponent,
    NotFoundComponent,
    LoaderComponent,
    NotAuthorizedComponent,
  ],
})
export class IpBgpSubscriptionComponent extends GenericSubscriptionComponent<
  IpBgpSubscriptionDetailsGQL,
  IpBgpSubscriptionDetailsQuery,
  IpBgpSubscriptionDetails
> {
  protected query = inject(IpBgpSubscriptionDetailsGQL);

  protected onQuerySuccess(result: ApolloQueryResult<IpBgpSubscriptionDetailsQuery>): IpBgpSubscriptionDetails {
    return result.data.ipBgpDetails;
  }

  override changeActiveTab(tabName: SubscriptionDetailTab) {
    this.router.navigate(["/subscription", this.productType(), this.productTag(), this._subscriptionId, tabName], {
      replaceUrl: true,
    });
  }

  protected readonly SubscriptionDetailTab = SubscriptionDetailTab;
}
