<navigation [isSticky]="false"></navigation>
<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-lg-4">
        <malfunction-filter [presetSubscriptionId]="presetSubscriptionId" [presetProductType]="presetProductType">
        </malfunction-filter>
      </div>
      <div class="col-xs-12 col-lg-8">
        @if (filterSubscriptionId !== "") {
          <webarchive
            [filterSubscriptionName]="filterSubscriptionName"
            [filterSubscriptionId]="filterSubscriptionId"
          ></webarchive>
        }
        <div *ngIf="filterSubscriptionId === '' && filteredMessages.length > 0">
          <h3 *ngIf="filterProductType === ''">{{ "Malfunction.Default.Filter.Title" | translate }}</h3>
          <h3 *ngIf="filterProductType === 'LightPath'">
            {{ "Malfunction.Default.Filter.Title" | translate }} {{ "Malfunction.For" | translate }}
            {{ "Subscription.LightPath.Name" | translate }}
          </h3>
          <h3 *ngIf="filterProductType === 'IP'">
            {{ "Malfunction.Default.Filter.Title" | translate }} {{ "Malfunction.For" | translate }}
            {{ "Subscription.IP.Name" | translate }}
          </h3>
          <h3 *ngIf="filterProductType === 'L2VPN'">
            {{ "Malfunction.Default.Filter.Title" | translate }} {{ "Malfunction.For" | translate }}
            {{ "Subscription.L2VPN.Name" | translate }}
          </h3>
          <h3 *ngIf="filterProductType === 'Firewall'">
            {{ "Malfunction.Default.Filter.Title" | translate }} {{ "Malfunction.For" | translate }}
            {{ "Subscription.FW.Name" | translate }}
          </h3>
          <h3 *ngIf="filterProductType === 'Port'">
            {{ "Malfunction.Default.Filter.Title" | translate }} {{ "Malfunction.For" | translate }}
            {{ "Subscription.Port.Name" | translate }}
          </h3>
        </div>
        <div class="timeline" [ngClass]="{ loading: loading }">
          <malfunction-card
            *ngFor="let message of filteredMessages; let i = index"
            [message]="message"
            [showTimeElement]="isNewDate(i)"
          ></malfunction-card>
        </div>
        @if (!loading) {
          <timeline-footer
            [showEmptyState]="filteredMessages.length === 0"
            [hasMoreResults]="hasMore"
          ></timeline-footer>
        } @else {
          <loader />
        }
      </div>
    </div>
    <footer></footer>
  </div>
</div>
