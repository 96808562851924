<div class="chart" *ngIf="subscription">
  <div style="position: relative">
    <div class="row">
      <div class="col-6">
        <h3 class="title-with-icon">
          <svg-icon src="assets/images/icons/chart.svg" class="black-medium"></svg-icon>
          @if (firewallMode) {
            <span>{{ "Chart.Firewall.Traffic" | translate }}</span>
          } @else {
            <span>{{ "Chart.TrafficGraph" | translate }}</span>
          }
        </h3>
      </div>
      <div *ngIf="!firewallMode" class="col-6 text-right">
        <span *ngIf="productType === 'LightPath' || productType === 'IP'" (click)="showSlsDialog()" class="blue-link">{{
          "Chart.Availability" | translate
        }}</span>
      </div>
    </div>
    <div class="card card--no-padding">
      <div class="chart__header">
        <div
          class="chart__header-left"
          *ngIf="productType === 'Port' && [null, undefined].includes(subscription.linkMemberSubscriptions)"
        >
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label">{{ "Chart.SelectService" | translate }}</label>
            <div class="chart__select-wrapper">
              <select
                class="chart__select"
                [(ngModel)]="circuitFilter"
                (change)="updateInstanceIdFilters(circuitFilter.subscriptionId, circuitFilter.vlanrange)"
              >
                <option value="" selected="selected">{{ "Chart.AllServices" | translate }}</option>
                <option *ngFor="let service of subscription.services" [ngValue]="service">
                  {{ service.description }} - {{ service.vlanrange }}
                </option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>
        <div class="chart__header-left" *ngIf="firewallMode">
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label">{{ "Chart.Firewall.Select.view" | translate }}</label>
            <div class="chart__select-wrapper">
              <select class="chart__select" [(ngModel)]="firewallFilter" (change)="updateFirewallSeries()">
                <option value="Average" selected="selected">Average</option>
                <option value="Peak">Peak</option>
                <option value="Average_Peak">Average & Peak</option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>
        <div
          class="chart__header-left"
          *ngIf="
            productType === 'Port'
            && subscription.linkMemberSubscriptions !== null
            && subscription.linkMemberSubscriptions !== undefined
          "
        >
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label">{{ "Chart.SelectServiceOrLinkmember" | translate }}</label>
            <div class="chart__select-wrapper">
              <select class="chart__select" (change)="updateInstanceIdFilters($any($event.target).value)">
                <option disabled>Link members:</option>
                <option value="" selected="selected">{{ "Chart.AllLinkmembers" | translate }}</option>
                <option
                  *ngFor="let service of subscription.linkMemberSubscriptions"
                  value="{{ service.subscriptionId }}"
                >
                  {{ service.description }} - {{ service?.patchPosition }}
                </option>
                <option disabled>{{ "Navigation.Subscriptions" | translate }}:</option>
                <option *ngFor="let service of subscription.services" value="{{ service.subscriptionId }}">
                  {{ service.description }} - {{ service.vlanrange }}
                </option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>
        <div
          class="chart__header-left"
          *ngIf="productType === 'L2VPN' || productType === 'L3VPN' || productType === 'FW'"
        >
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label" *ngIf="productType === 'L2VPN' || productType === 'L3VPN'">{{
              "Chart.SelectPort" | translate
            }}</label>
            <label for="" class="chart__select-label" *ngIf="productType === 'FW'">{{
              "Chart.SelectNetwork" | translate
            }}</label>
            <div class="chart__select-wrapper">
              <select
                class="chart__select"
                [(ngModel)]="portFilter"
                (change)="updateServiceInstanceIdFilters(portFilter)"
              >
                <option value="" selected="selected" *ngIf="productType === 'L2VPN' || productType === 'L3VPN'">
                  {{ "Chart.AllPorts" | translate }}
                </option>
                <option value="" selected="selected" *ngIf="productType === 'FW'">
                  {{ "Chart.AllNetworks" | translate }}
                </option>
                <option *ngFor="let portData of portsFilterData" [ngValue]="portData['value']">
                  {{ portData["name"] }}
                </option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>
        <div class="chart__header-left" *ngIf="productType === 'Wireless'">
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label">{{ "Chart.SelectKPI" | translate }}</label>
            <div class="chart__select-wrapper">
              <select
                class="chart__select"
                [(ngModel)]="kpiFilter"
                (change)="updateServiceInstanceIdFilters(kpiFilter)"
              >
                <option value="clients">Aantal gebruikers (2.4GHz & 5GHz)</option>
                <option value="{{ choice.key }}" *ngFor="let choice of chartChoices">{{ choice.label }}</option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>
        <div class="chart__header-left" *ngIf="productType === 'LightPath'">
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label">{{ "Chart.SelectConnection" | translate }}</label>
            <div class="chart__select-wrapper">
              <select
                class="chart__select"
                [(ngModel)]="circuitFilter"
                (change)="updateServiceInstanceIdFilters(circuitFilter ? circuitFilter?.endpoints[0] : '-1')"
              >
                <option value="" *ngIf="isRedundant()">Alle verkeer</option>
                <option *ngFor="let circuit of subscription.circuits; let i = index" [ngValue]="circuit">
                  {{ i + 1 }}. {{ circuit.endpoints[0]?.port?.address?.city ?? "city" }} -
                  {{ circuit.endpoints[1]?.port?.address?.city ?? "city" }}
                </option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
          <div class="chart__header-left-col" *ngIf="circuitFilter">
            <label for="" class="chart__select-label">{{ "Chart.SelectPort" | translate }}</label>
            <div class="chart__select-wrapper">
              <select
                class="chart__select"
                [(ngModel)]="portFilter"
                (change)="updateServiceInstanceIdFilters(portFilter)"
              >
                <option value="" selected="selected">{{ "Chart.AllPorts" | translate }}</option>
                <option *ngFor="let endpoint of circuitFilter?.endpoints; let i = index" [ngValue]="endpoint">
                  {{ endpoint.port.description }}
                </option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>
        <div class="chart__header-left" *ngIf="productType === 'IP' && !firewallMode">
          <div class="chart__header-left-col">
            <label for="" class="chart__select-label">{{ "Chart.SelectPort" | translate }}</label>
            <div class="chart__select-wrapper">
              <select
                class="chart__select"
                [(ngModel)]="portFilter"
                (change)="updateServiceInstanceIdFilters(portFilter)"
              >
                <option value="" selected="selected">{{ "Chart.AllPorts" | translate }}</option>
                <option *ngFor="let sap of subscription.saps; let i = index" [ngValue]="sap">
                  {{ i + 1 }} - {{ sap.port.description }} - {{ "ServiceElement.VLANID" | translate }} ({{
                    sap.vlanrange !== "0" ? sap.vlanrange : ("Subscription.UntaggedPort" | translate)
                  }})
                </option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
          </div>
        </div>
        <div class="chart__header-right">
          <div class="chart__header-right-flex">
            <label for="" class="chart__select-label">
              <span>{{ "Chart.Period" | translate }}</span>
              <span class="chart__select-reset" (click)="resetDatePickers()" *ngIf="!defaultDate">Reset</span>
            </label>
            <div class="chart__period-filter">
              <button
                *ngFor="let preset of presets"
                [ngClass]="{ active: selectedPreset === preset }"
                (click)="setDatePreset(preset)"
              >
                {{ preset }}
              </button>
            </div>
          </div>
          <div class="chart__header-date-inputs">
            <div class="chart__select-wrapper">
              <input
                class="chart__input"
                type="text"
                id="startDate"
                mwlFlatpickr
                [enableTime]="true"
                [dateFormat]="'d-m-Y H:i'"
                [time24hr]="true"
                [convertModelValue]="true"
                [disableMobile]="true"
                [(ngModel)]="firstDate"
                [prevArrow]="'<i></i>'"
                [nextArrow]="'<i></i>'"
                [maxDate]="tomorrow"
                (ngModelChange)="onDateChange()"
              />
              <svg-icon class="chart__select-calendar-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
            </div>
            <span class="chart__header-date-seperator">-</span>
            <div class="chart__select-wrapper">
              <input
                class="chart__input"
                type="text"
                mwlFlatpickr
                [enableTime]="true"
                [dateFormat]="'d-m-Y H:i'"
                [time24hr]="true"
                [convertModelValue]="true"
                [disableMobile]="true"
                [(ngModel)]="secondDate"
                [maxDate]="tomorrow"
                (ngModelChange)="onDateChange()"
              />
              <svg-icon class="chart__select-calendar-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="chart__center">
        <div class="chart__current-traffic" *ngIf="productType !== 'Wireless'">
          <div class="chart__current-traffic-text">
            <span>{{ "ServiceElement.Chart.NowIn" | translate }}</span>
            {{ latestChartData.in | readable_size }}
          </div>
          <div class="chart__current-traffic-text">
            <span>{{ "ServiceElement.Chart.NowOut" | translate }}</span>
            {{ latestChartData.out | readable_size }}
          </div>
        </div>
        @if (isBrowser()) {
          <highcharts-chart
            *ngIf="!happyState"
            [Highcharts]="highcharts"
            [constructorType]="chartConstructor"
            [options]="chartOptions"
            [(update)]="updateFlag"
            [oneToOne]="oneToOneFlag"
            (chartInstance)="setChartInstance($event)"
            style="width: 100%; display: block"
          ></highcharts-chart>
        }

        <div *ngIf="happyState" class="chart__happy-state">
          <div>
            <svg-icon src="assets/images/icons/rocket.svg"></svg-icon>
            <div>{{ "Chart.TrafficGraph.NotUnderAttack" | translate }}</div>
          </div>
        </div>
      </div>
      <div class="chart__bottom">
        <div class="chart__bottom-left">
          <div class="chart__legend">
            <div *ngIf="chartDataModus === 'packets'" class="chart__select-wrapper">
              <select class="chart__select" (change)="updatePacketsSubFilters($any($event.target).value)">
                <option *ngFor="let packetsSubFilter of packetsSubFilters" value="{{ packetsSubFilter }}">
                  {{ packetsSubFilter }}
                </option>
              </select>
              <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
            </div>
            <div
              class="chart__legend-item chart__legend-item--1"
              *ngIf="splineData && splineData['in']"
              (click)="toggleLegendItem('in')"
              [class.inactive]="isSeriesHidden('in')"
            >
              <span>{{ "Chart.Label.In" | translate }}</span>
            </div>
            <div
              class="chart__legend-item chart__legend-item--2"
              *ngIf="splineData && splineData['out']"
              (click)="toggleLegendItem('out')"
              [class.inactive]="isSeriesHidden('out')"
            >
              <span>{{ "Chart.Label.Out" | translate }}</span>
            </div>
            <div
              class="chart__legend-item chart__legend-item--3"
              *ngIf="splineData && splineData['spike_in']"
              (click)="toggleLegendItem('spike_in')"
              [class.inactive]="isSeriesHidden('spike_in')"
            >
              <span>{{ "Chart.Label.PeakIn" | translate }}</span>
            </div>
            <div
              class="chart__legend-item chart__legend-item--4"
              *ngIf="splineData && splineData['spike_out']"
              (click)="toggleLegendItem('spike_out')"
              [class.inactive]="isSeriesHidden('spike_out')"
            >
              <span>{{ "Chart.Label.PeakOut" | translate }}</span>
            </div>

            <!-- FIREWALL LEGEND-->

            <div
              class="chart__legend-item chart__legend-item--3"
              *ngIf="splineData && splineData['Unfiltered - in']"
              (click)="toggleLegendItem('Unfiltered - in')"
              [class.inactive]="isSeriesHidden('Unfiltered - in')"
            >
              <span>Unfiltered - in</span>
            </div>

            <div
              class="chart__legend-item chart__legend-item--1"
              *ngIf="splineData && splineData['Filtered - in']"
              (click)="toggleLegendItem('Filtered - in')"
              [class.inactive]="isSeriesHidden('Filtered - in')"
            >
              <span>Filtered - in</span>
            </div>

            <div
              class="chart__legend-item chart__legend-item--4"
              *ngIf="splineData && splineData['Unfiltered - out']"
              (click)="toggleLegendItem('Unfiltered - out')"
              [class.inactive]="isSeriesHidden('Unfiltered - out')"
            >
              <span>Unfiltered - out</span>
            </div>

            <div
              class="chart__legend-item chart__legend-item--2"
              *ngIf="splineData && splineData['Filtered - out']"
              (click)="toggleLegendItem('Filtered - out')"
              [class.inactive]="isSeriesHidden('Filtered - out')"
            >
              <span>Filtered - out</span>
            </div>

            <div
              class="chart__legend-item chart__legend-item--7"
              *ngIf="splineData && splineData['Unfiltered - peak in']"
              (click)="toggleLegendItem('Unfiltered - peak in')"
              [class.inactive]="isSeriesHidden('Unfiltered - peak in')"
            >
              <span>Unfiltered - peak in</span>
            </div>

            <div
              class="chart__legend-item chart__legend-item--5"
              *ngIf="splineData && splineData['Filtered - peak in']"
              (click)="toggleLegendItem('Filtered - peak in')"
              [class.inactive]="isSeriesHidden('Filtered - peak in')"
            >
              <span>Filtered - peak in</span>
            </div>

            <div
              class="chart__legend-item chart__legend-item--8"
              *ngIf="splineData && splineData['Unfiltered - peak out']"
              (click)="toggleLegendItem('Unfiltered - peak out')"
              [class.inactive]="isSeriesHidden('Unfiltered - peak out')"
            >
              <span>Unfiltered - peak out</span>
            </div>

            <div
              class="chart__legend-item chart__legend-item--6"
              *ngIf="splineData && splineData['Filtered - peak out']"
              (click)="toggleLegendItem('Filtered - peak out')"
              [class.inactive]="isSeriesHidden('Filtered - peak out')"
            >
              <span>Filtered - peak out</span>
            </div>
            <!-- Wireless Legend -->
            <div
              class="chart__legend-item chart__legend-item--1"
              *ngIf="splineData && splineData['twofour']"
              (click)="toggleLegendItem('twofour')"
              [class.inactive]="isSeriesHidden('twofour')"
            >
              <span>2.4GHz</span>
            </div>
            <!-- one label is shown if only max clients is queried,
               otherwise 2 labels are shown for both frequencies -->
            <div
              class="chart__legend-item chart__legend-item--2"
              *ngIf="splineData && splineData['five'] && splineData['twofour']"
              (click)="toggleLegendItem('five')"
              [class.inactive]="isSeriesHidden('five')"
            >
              <span>5GHz</span>
            </div>
            <div
              class="chart__legend-item chart__legend-item--1"
              *ngIf="splineData && splineData['five'] && !splineData['twofour']"
              (click)="toggleLegendItem('five')"
              [class.inactive]="isSeriesHidden('five')"
            >
              <span>2.4GHz & 5GHz</span>
            </div>
          </div>
        </div>
        <div
          class="chart__bottom-right"
          *ngIf="
            subscription.domain === 'SURFNET8'
            || subscription.domain === 'NETHERLIGHT8'
            || subscription.product?.productType === 'FW'
            || subscription.product?.productType === 'Wireless'
          "
        >
          <div class="chart__radio-buttons" *ngIf="productType !== 'Wireless'">
            <div
              class="chart__radio-buttons-item"
              [ngClass]="{ active: chartDataModus === 'bits' }"
              (click)="toggleBitsPackets()"
            >
              {{ "Chart.Label.BitsS" | translate }}
            </div>
            <div
              class="chart__radio-buttons-item"
              [ngClass]="{ active: chartDataModus === 'packets' }"
              (click)="toggleBitsPackets()"
            >
              {{ "Chart.Label.PacketsS" | translate }}
            </div>
          </div>
          <div class="chart__export-button-wrapper" (clickOutside)="onClickedOutside()">
            <div (click)="toggleExportOptions()" class="chart__export-button">
              <svg-icon src="assets/images/icons/icon-export.svg"></svg-icon>
            </div>
            <div *ngIf="exportOverflowVisible" class="chart__export-overflow">
              <div class="chart__export-overflow-item" (click)="exportAsPNG()">
                {{ "Chart.Export.PNG" | translate }}
              </div>
              <div class="chart__export-overflow-item" (click)="exportAsJPG()">
                {{ "Chart.Export.JPG" | translate }}
              </div>
              <div class="chart__export-overflow-item" (click)="exportAsCSV()">
                {{ "Chart.Export.CSV" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
