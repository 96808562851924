import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { FooterComponent } from "../../components/common/footer/footer";

@Component({
  selector: "page-support",
  templateUrl: "support.html",
  imports: [FooterComponent, NavigationComponent, TranslateModule],
})
export class SupportPage {}
