import { PartialObserver, Subject, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { WebsocketService } from "./websocket.service";
import { ENV } from "../app/app.runtime";

export class WebsocketListener<M> {
  public messages: Observable<M>;
  private subscriber: Subscription;

  constructor(private wsService: WebsocketService) {}

  public listen(url: string, handlers: PartialObserver<M>) {
    const parseJson = map(
      // Casting because impossible to know type here
      (response: MessageEvent): M => JSON.parse(response.data) as M,
    );

    const connection = this.wsService.connect(this.webserviceUrl(url));
    this.messages = connection.pipe(parseJson);
    this.subscriber = this.messages.subscribe(handlers);
  }

  public close() {
    this.subscriber.unsubscribe();
    this.wsService.close();
  }

  private webserviceUrl(url: string) {
    const apiUrl = ENV.NWD_API_BASE_URL.replace(/^https:\/\//i, "wss://");
    return apiUrl + url;
  }
}
