import { Component, computed, inject } from "@angular/core";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  IrbSubscriptionDetailsGQL,
  IrbSubscriptionDetailsQuery,
  IrbSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ApolloQueryResult } from "@apollo/client";
import { TranslateModule } from "@ngx-translate/core";
import { PageComponent } from "../../../../components/page/page";
import { CommonModule } from "@angular/common";
import { AliasEditorComponent } from "../../../../components/common/alias-editor/alias-editor.component";
import { LinechartComponent } from "../../../../components/subscription-detail/linechart/linechart";
import { ServiceId } from "../../../../components/subscription-detail/service-id/service-id";
import { SubscriptionDetailHeader } from "../../../../components/subscription-detail/subscription-detail-header/subscription-detail-header";
import { ThirdPartyServiceIdsRowContentComponent } from "../../../../components/subscription-detail/third-party-service-ids-row-content/third-party-service-ids-row-content";
import { SubscriptionDatePipe } from "../../../../helpers/subscription-datepipe/subscription-datepipe";
import { ServiceSpeedPipe } from "../service-speed-pipe/service-speed-pipe";
import { SubscriptionDetailContainer } from "../../../../components/subscription-detail/subscription-detail-container/subscription-detail-container";
import { PortShortcutMenuComponent } from "../port-subscription/components/port-shortcut-menu/port-shortcut-menu";
import { NotFoundComponent } from "../../../../components/common/not-found/not-found";
import { LoaderComponent } from "../../../../components/common/loader/loader";
import { NotAuthorizedComponent } from "../../../../components/common/not-authorized/not-authorized";

@Component({
  selector: "irb-subscription",
  templateUrl: "irb-subscription.html",
  imports: [
    CommonModule,
    PageComponent,
    PortShortcutMenuComponent,
    SubscriptionDetailHeader,
    TranslateModule,
    AliasEditorComponent,
    SubscriptionDatePipe,
    ServiceId,
    ServiceSpeedPipe,
    LinechartComponent,
    ThirdPartyServiceIdsRowContentComponent,
    SubscriptionDetailContainer,
    NotFoundComponent,
    LoaderComponent,
    NotAuthorizedComponent,
  ],
})
export class IrbSubscriptionComponent extends GenericSubscriptionComponent<
  IrbSubscriptionDetailsGQL,
  IrbSubscriptionDetailsQuery,
  IrbSubscriptionDetailsType
> {
  protected query = inject(IrbSubscriptionDetailsGQL);

  protected domain = computed(() => this._subscription().domain);
  protected portSpeed = computed(() => this._subscription().portSpeed);

  protected onQuerySuccess(result: ApolloQueryResult<IrbSubscriptionDetailsQuery>): IrbSubscriptionDetailsType {
    return result.data.irbDetails as IrbSubscriptionDetailsType;
  }
}
