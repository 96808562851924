import { Component, EventEmitter, inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription as RxSubscription } from "rxjs";
import { AuthService } from "../../services/AuthService";
import { Apollo, gql } from "apollo-angular";
import { getDetailRouterLink } from "../../helpers/getDetailRouterLink";
import { LoaderComponent } from "../../components/common/loader/loader";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { FooterComponent } from "../../components/common/footer/footer";

/** Class/page only serves to detect which subscription type we have and forward to the right detail page */

const FIND_SUBSCRIPTION_TAG = gql`
  query FindSubscriptionTag($subscriptionId: ID!) {
    subscription(id: $subscriptionId) {
      subscriptionId
      product {
        tag
        type
      }
    }
  }
`;

interface SubscriptionTagType {
  subscription: {
    subscriptionId: string;
    product: {
      tag: string;
      type: string;
    };
  };
}

@Component({
  selector: "page-subscription-detail-redirection",
  templateUrl: "subscription-detail-redirection.html",
  imports: [FooterComponent, LoaderComponent, NavigationComponent],
})
export class SubscriptionDetailRedirectionPage implements OnInit, OnDestroy {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private auth = inject(AuthService);
  private apollo = inject(Apollo);

  public messageBus: EventEmitter<string> = new EventEmitter();
  private rxSubscriptions: RxSubscription[] = [];

  ngOnInit() {
    this.rxSubscriptions.push(
      this.auth.userLoaded.subscribe(() => {
        this.init();
      }),
    );
  }

  ngOnDestroy(): void {
    this.rxSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  async init() {
    const subscriptionId = this.route.snapshot.paramMap.get("subscriptionId");

    this.queryBaseSubscription(subscriptionId);
  }

  async queryBaseSubscription(subscriptionId: string) {
    this.apollo
      .query<SubscriptionTagType>({
        query: FIND_SUBSCRIPTION_TAG,
        variables: {
          subscriptionId,
        },
        errorPolicy: "all",
      })
      .subscribe((result) => {
        const subscription = result.data.subscription;
        const link = getDetailRouterLink(subscription, subscription.product.type);
        this.router.navigate([link]);
      });
  }
}
