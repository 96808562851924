!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.datebook = t() : e.datebook = t();
}(this, function () {
  return function (e) {
    var t = {};
    function r(n) {
      if (t[n]) return t[n].exports;
      var a = t[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return e[n].call(a.exports, a, a.exports, r), a.l = !0, a.exports;
    }
    return r.m = e, r.c = t, r.d = function (e, t, n) {
      r.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: n
      });
    }, r.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, r.t = function (e, t) {
      if (1 & t && (e = r(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var n = Object.create(null);
      if (r.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var a in e) r.d(n, a, function (t) {
        return e[t];
      }.bind(null, a));
      return n;
    }, r.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return r.d(t, "a", t), t;
    }, r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, r.p = "", r(r.s = 5);
  }([function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var n = r(1),
      a = function (e) {
        return void 0 === e && (e = ""), ("0" + parseInt(e.toString(), 10)).slice(-2);
      },
      o = function (e, t) {
        void 0 === e && (e = new Date());
        var r = {
          YYYY: e.getUTCFullYear(),
          MM: a(e.getUTCMonth() + 1),
          DD: a(e.getUTCDate()),
          hh: a(e.getUTCHours()),
          mm: a(e.getUTCMinutes()),
          ss: a(e.getUTCSeconds())
        };
        return Object.keys(r).reduce(function (e, t) {
          return e.replace(t, r[t].toString());
        }, t);
      };
    t.default = {
      addLeadingZero: a,
      getDuration: function (e, t) {
        var r = Math.floor((t - e) / 1e3),
          n = Math.floor(r / 3600),
          o = r / 3600 % 1 * 60;
        return "" + a(n) + a(o);
      },
      getHoursDiff: function (e, t) {
        var r = Math.floor((t - e) / 1e3);
        return Math.floor(r / 3600);
      },
      getRecurrenceLengthDays: function (e) {
        var t = e.frequency,
          r = e.interval,
          a = n.RECURRENCE.FREQUENCY;
        if (r) switch (t) {
          case a.YEARLY:
            return 365.25 * r;
          case a.MONTHLY:
            return 30.42 * r;
          case a.WEEKLY:
            return 7 * r;
          default:
            return r;
        }
        return 36525;
      },
      formatDate: o,
      formatDateNoUtc: function (e, t) {
        void 0 === e && (e = new Date());
        var r = {
          YYYY: e.getFullYear(),
          MM: a(e.getMonth() + 1),
          DD: a(e.getDate()),
          hh: a(e.getHours()),
          mm: a(e.getMinutes()),
          ss: a(e.getSeconds())
        };
        return Object.keys(r).reduce(function (e, t) {
          return e.replace(t, r[t].toString());
        }, t);
      },
      getTimeCreated: function () {
        return o(new Date(), n.FORMAT.DATE);
      },
      incrementDate: function (e, t) {
        var r = 864e5 * t,
          n = new Date();
        return n.setTime(e.getTime() + r), n;
      }
    };
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.URL = t.FORMAT = t.RECURRENCE = void 0, t.RECURRENCE = {
      FREQUENCY: {
        DAILY: "DAILY",
        WEEKLY: "WEEKLY",
        MONTHLY: "MONTHLY",
        YEARLY: "YEARLY"
      }
    }, t.FORMAT = {
      DATE: "YYYYMMDD",
      TIME: "ThhmmssZ",
      FULL: "YYYYMMDDThhmmssZ",
      NO_UTC_FULL: "YYYYMMDDThhmmss",
      OUTLOOK_DATE: "YYYY-MM-DD",
      OUTLOOK_TIME: "Thh:mm:ssZ",
      OUTLOOK_FULL: "YYYY-MM-DDThh:mm:ssZ"
    }, t.URL = {
      YAHOO: "https://calendar.yahoo.com/",
      GOOGLE: "https://calendar.google.com/calendar/render",
      OUTLOOK: "https://outlook.{{host}}.com/calendar/0/deeplink/compose"
    };
  }, function (e, t, r) {
    "use strict";

    var n = this && this.__assign || function () {
      return (n = Object.assign || function (e) {
        for (var t, r = 1, n = arguments.length; r < n; r++) for (var a in t = arguments[r]) Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
        return e;
      }).apply(this, arguments);
    };
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var a = function (e) {
        return e;
      },
      o = function (e, t, r) {
        void 0 === t && (t = ";"), void 0 === r && (r = a);
        var n = [];
        for (var o in e) e.hasOwnProperty(o) && void 0 !== e[o] && n.push(o + "=" + r(e[o]));
        return n.join(t);
      };
    t.default = {
      toParamString: o,
      toQueryString: function (e) {
        var t = Object.keys(e).filter(function (t) {
          return null !== e[t];
        }).reduce(function (t, r) {
          var a;
          return n(n({}, t), ((a = {})[r] = e[r], a));
        }, {});
        return o(t, "&", encodeURIComponent);
      },
      toIcsParamString: function (e) {
        return o(e, ";");
      },
      toMailtoList: function (e) {
        return e.map(function (e) {
          var t = e.email,
            r = e.name;
          return r ? r + " <" + t + ">" : t;
        });
      },
      toProperCase: function (e) {
        return [e[0].toUpperCase(), e.slice(1 - e.length).toLowerCase()].join("");
      }
    };
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var n = r(0),
      a = function () {
        function e(e) {
          var t = this;
          this.isAllDay = !1, this.description = "", this.title = "", this.location = "", this.start = new Date(), this.end = new Date(), this.params = {}, this.attendees = [], this.setText = function (e) {
            t.description = e.description || "", t.title = e.title || "", t.location = e.location || "";
          }, this.setTimestamps = function (e) {
            t.isAllDay = !e.end, t.start = e.start, t.end = e.end || n.default.incrementDate(t.start, 1), t.recurrence = e.recurrence;
          }, this.setParam = function (e, r) {
            return t.params[e] = r, t;
          }, this.setText(e), this.setTimestamps(e), this.setAttendees(e);
        }
        return e.prototype.setAttendees = function (e) {
          this.attendees = Array.isArray(e.attendees) ? e.attendees : [];
        }, e;
      }();
    t.default = a;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var n = r(7),
      a = r(2),
      o = r(0),
      i = r(8),
      u = r(1),
      s = function (e) {
        return new Blob([e], {
          type: "application/octet-stream"
        });
      };
    t.default = {
      formatText: function (e) {
        return void 0 === e && (e = ""), e.replace(/\n/g, "\\n");
      },
      getBlob: s,
      getFileName: function (e) {
        return e ? e.replace(/[^\w ]/g, "") + ".ics" : "event.ics";
      },
      getUid: function () {
        return Math.random().toString(36).substr(2);
      },
      getProdId: function () {
        return "undefined" != typeof window ? window.location.host : "datebook";
      },
      getRrule: function (e) {
        var t,
          r,
          n = {
            FREQ: e.frequency,
            INTERVAL: null === (t = e.interval) || void 0 === t ? void 0 : t.toString(),
            COUNT: null === (r = e.count) || void 0 === r ? void 0 : r.toString(),
            WKST: e.weekstart,
            BYDAY: e.weekdays,
            BYMONTHDAY: e.monthdays
          };
        return e.end && (n.UNTIL = o.default.formatDate(e.end, u.FORMAT.FULL)), a.default.toIcsParamString(n);
      },
      download: function (e, t) {
        if (window.hasOwnProperty("safari") || /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !/(cr|fx)ios[^a-z]/i.test(navigator.userAgent)) i.default(t, e);else {
          var r = s(t);
          n.saveAs(r, e);
        }
      }
    };
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.ICSPropertyValue = t.ICSDuration = t.ICSAttachment = t.ICSAlarm = t.CalendarRecurrence = t.CalendarOptions = t.ICalendar = t.OutlookCalendar = t.YahooCalendar = t.GoogleCalendar = t.CalendarBase = void 0;
    var n = r(3);
    Object.defineProperty(t, "CalendarBase", {
      enumerable: !0,
      get: function () {
        return n.default;
      }
    });
    var a = r(6);
    Object.defineProperty(t, "GoogleCalendar", {
      enumerable: !0,
      get: function () {
        return a.default;
      }
    });
    var o = r(9);
    Object.defineProperty(t, "YahooCalendar", {
      enumerable: !0,
      get: function () {
        return o.default;
      }
    });
    var i = r(11);
    Object.defineProperty(t, "OutlookCalendar", {
      enumerable: !0,
      get: function () {
        return i.default;
      }
    });
    var u = r(12);
    Object.defineProperty(t, "ICalendar", {
      enumerable: !0,
      get: function () {
        return u.default;
      }
    });
    var s = r(13);
    Object.defineProperty(t, "CalendarOptions", {
      enumerable: !0,
      get: function () {
        return s.default;
      }
    });
    var c = r(14);
    Object.defineProperty(t, "CalendarRecurrence", {
      enumerable: !0,
      get: function () {
        return c.default;
      }
    });
    var l = r(15);
    Object.defineProperty(t, "ICSAlarm", {
      enumerable: !0,
      get: function () {
        return l.default;
      }
    });
    var d = r(16);
    Object.defineProperty(t, "ICSAttachment", {
      enumerable: !0,
      get: function () {
        return d.default;
      }
    });
    var f = r(17);
    Object.defineProperty(t, "ICSDuration", {
      enumerable: !0,
      get: function () {
        return f.default;
      }
    });
    var p = r(18);
    Object.defineProperty(t, "ICSPropertyValue", {
      enumerable: !0,
      get: function () {
        return p.default;
      }
    });
  }, function (e, t, r) {
    "use strict";

    var n,
      a = this && this.__extends || (n = function (e, t) {
        return (n = Object.setPrototypeOf || {
          __proto__: []
        } instanceof Array && function (e, t) {
          e.__proto__ = t;
        } || function (e, t) {
          for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
        })(e, t);
      }, function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
        function r() {
          this.constructor = e;
        }
        n(e, t), e.prototype = null === t ? Object.create(t) : (r.prototype = t.prototype, new r());
      });
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var o = r(3),
      i = r(2),
      u = r(4),
      s = r(0),
      c = r(1),
      l = function (e) {
        function t(t) {
          var r = e.call(this, t) || this;
          return r.setInitialParams = function () {
            var e = c.FORMAT.DATE;
            r.isAllDay || (e += c.FORMAT.TIME);
            var t = [s.default.formatDate(r.start, e), s.default.formatDate(r.end, e)].join("/");
            r.setParam("action", "TEMPLATE").setParam("dates", t).setParam("text", r.title).setParam("details", r.description).setParam("location", r.location).setParam("allday", r.isAllDay.toString()), r.recurrence && r.setParam("recur", "RRULE:" + u.default.getRrule(r.recurrence)), r.attendees.length > 0 && r.setParam("add", i.default.toMailtoList(r.attendees).join(","));
          }, r.render = function () {
            return c.URL.GOOGLE + "?" + i.default.toQueryString(r.params);
          }, r.setInitialParams(), r;
        }
        return a(t, e), t;
      }(o.default);
    t.default = l;
  }, function (e, t, r) {
    var n, a, o;
    a = [], void 0 === (o = "function" == typeof (n = function () {
      "use strict";

      function t(e, t, r) {
        var n = new XMLHttpRequest();
        n.open("GET", e), n.responseType = "blob", n.onload = function () {
          i(n.response, t, r);
        }, n.onerror = function () {
          console.error("could not download file");
        }, n.send();
      }
      function r(e) {
        var t = new XMLHttpRequest();
        t.open("HEAD", e, !1);
        try {
          t.send();
        } catch (e) {}
        return 200 <= t.status && 299 >= t.status;
      }
      function n(e) {
        try {
          e.dispatchEvent(new MouseEvent("click"));
        } catch (r) {
          var t = document.createEvent("MouseEvents");
          t.initMouseEvent("click", !0, !0, window, 0, 0, 0, 80, 20, !1, !1, !1, !1, 0, null), e.dispatchEvent(t);
        }
      }
      var a = "object" == typeof window && window.window === window ? window : "object" == typeof self && self.self === self ? self : "object" == typeof global && global.global === global ? global : void 0,
        o = a.navigator && /Macintosh/.test(navigator.userAgent) && /AppleWebKit/.test(navigator.userAgent) && !/Safari/.test(navigator.userAgent),
        i = a.saveAs || ("object" != typeof window || window !== a ? function () {} : "download" in HTMLAnchorElement.prototype && !o ? function (e, o, i) {
          var u = a.URL || a.webkitURL,
            s = document.createElement("a");
          o = o || e.name || "download", s.download = o, s.rel = "noopener", "string" == typeof e ? (s.href = e, s.origin === location.origin ? n(s) : r(s.href) ? t(e, o, i) : n(s, s.target = "_blank")) : (s.href = u.createObjectURL(e), setTimeout(function () {
            u.revokeObjectURL(s.href);
          }, 4e4), setTimeout(function () {
            n(s);
          }, 0));
        } : "msSaveOrOpenBlob" in navigator ? function (e, a, o) {
          if (a = a || e.name || "download", "string" != typeof e) navigator.msSaveOrOpenBlob(function (e, t) {
            return void 0 === t ? t = {
              autoBom: !1
            } : "object" != typeof t && (console.warn("Deprecated: Expected third argument to be a object"), t = {
              autoBom: !t
            }), t.autoBom && /^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(e.type) ? new Blob(["\ufeff", e], {
              type: e.type
            }) : e;
          }(e, o), a);else if (r(e)) t(e, a, o);else {
            var i = document.createElement("a");
            i.href = e, i.target = "_blank", setTimeout(function () {
              n(i);
            });
          }
        } : function (e, r, n, i) {
          if ((i = i || open("", "_blank")) && (i.document.title = i.document.body.innerText = "downloading..."), "string" == typeof e) return t(e, r, n);
          var u = "application/octet-stream" === e.type,
            s = /constructor/i.test(a.HTMLElement) || a.safari,
            c = /CriOS\/[\d]+/.test(navigator.userAgent);
          if ((c || u && s || o) && "undefined" != typeof FileReader) {
            var l = new FileReader();
            l.onloadend = function () {
              var e = l.result;
              e = c ? e : e.replace(/^data:[^;]*;/, "data:attachment/file;"), i ? i.location.href = e : location = e, i = null;
            }, l.readAsDataURL(e);
          } else {
            var d = a.URL || a.webkitURL,
              f = d.createObjectURL(e);
            i ? i.location = f : location.href = f, i = null, setTimeout(function () {
              d.revokeObjectURL(f);
            }, 4e4);
          }
        });
      a.saveAs = i.saveAs = i, e.exports = i;
    }) ? n.apply(t, a) : n) || (e.exports = o);
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    t.default = function (e, t) {
      var r = document.createElement("a"),
        n = encodeURIComponent(e);
      if (r.setAttribute("href", "data:text/calendar;charset=utf-8," + n), r.setAttribute("download", t), document.createEvent) {
        var a = document.createEvent("MouseEvents");
        a.initEvent("click", !0, !0), r.dispatchEvent(a);
      } else r.click();
    };
  }, function (e, t, r) {
    "use strict";

    var n,
      a = this && this.__extends || (n = function (e, t) {
        return (n = Object.setPrototypeOf || {
          __proto__: []
        } instanceof Array && function (e, t) {
          e.__proto__ = t;
        } || function (e, t) {
          for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
        })(e, t);
      }, function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
        function r() {
          this.constructor = e;
        }
        n(e, t), e.prototype = null === t ? Object.create(t) : (r.prototype = t.prototype, new r());
      });
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var o = r(3),
      i = r(1),
      u = r(2),
      s = r(0),
      c = r(10),
      l = function (e) {
        function t(t) {
          var r = e.call(this, t) || this;
          return r.setInitialParams = function () {
            r.setParam("v", "60").setParam("title", r.title).setParam("desc", r.description).setParam("in_loc", r.location), r.setTimeParams(), r.setRecurrenceParams(), r.attendees.length > 0 && r.setParam("inv_list", u.default.toMailtoList(r.attendees).join(","));
          }, r.setTimeParams = function () {
            r.isAllDay ? r.setParam("dur", "allday").setParam("st", s.default.formatDateNoUtc(r.start, i.FORMAT.DATE)) : (r.setParam("st", s.default.formatDateNoUtc(r.start, i.FORMAT.NO_UTC_FULL)), s.default.getHoursDiff(r.start.getTime(), r.end.getTime()) > 99 ? r.setParam("et", s.default.formatDateNoUtc(r.end, i.FORMAT.NO_UTC_FULL)) : r.setParam("dur", s.default.getDuration(r.start.getTime(), r.end.getTime())));
          }, r.setRecurrenceParams = function () {
            if (r.recurrence) if (r.setParam("RPAT", c.default.getRecurrence(r.recurrence)), r.recurrence.end) r.setParam("REND", s.default.formatDateNoUtc(r.recurrence.end, i.FORMAT.DATE));else {
              var e = s.default.getRecurrenceLengthDays(r.recurrence),
                t = s.default.incrementDate(r.end, Math.ceil(e));
              r.setParam("REND", s.default.formatDateNoUtc(t, i.FORMAT.DATE));
            }
          }, r.render = function () {
            return i.URL.YAHOO + "?" + u.default.toQueryString(r.params);
          }, r.setInitialParams(), r;
        }
        return a(t, e), t;
      }(o.default);
    t.default = l;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var n = r(1),
      a = r(2),
      o = r(0),
      i = function (e) {
        return void 0 === e && (e = []), e.map(function (e) {
          return a.default.toProperCase(e.replace(/[^A-Z]/gi, ""));
        }).join("");
      },
      u = function (e) {
        var t = n.RECURRENCE.FREQUENCY;
        switch (e) {
          case t.YEARLY:
            return "Yr";
          case t.MONTHLY:
            return "Mh";
          case t.WEEKLY:
            return "Wk";
          default:
            return "Dy";
        }
      };
    t.default = {
      getWeekdays: i,
      getFrequency: u,
      getRecurrence: function (e) {
        var t,
          r = u(e.frequency),
          a = i(e.weekdays),
          s = e.interval,
          c = void 0 === s ? 1 : s,
          l = "";
        if ((null === (t = e.weekdays) || void 0 === t ? void 0 : t.length) && e.frequency === n.RECURRENCE.FREQUENCY.MONTHLY) {
          var d = e.weekdays[0].match(/^([1-5])/);
          l = d ? d[0] : "1";
        }
        return [o.default.addLeadingZero(c), r, l, a].join("");
      }
    };
  }, function (e, t, r) {
    "use strict";

    var n,
      a = this && this.__extends || (n = function (e, t) {
        return (n = Object.setPrototypeOf || {
          __proto__: []
        } instanceof Array && function (e, t) {
          e.__proto__ = t;
        } || function (e, t) {
          for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
        })(e, t);
      }, function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
        function r() {
          this.constructor = e;
        }
        n(e, t), e.prototype = null === t ? Object.create(t) : (r.prototype = t.prototype, new r());
      });
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var o = r(3),
      i = r(1),
      u = r(2),
      s = r(0),
      c = function (e) {
        function t(t) {
          var r = e.call(this, t) || this;
          return r.baseUrl = i.URL.OUTLOOK, r.setInitialParams = function () {
            var e = i.FORMAT.OUTLOOK_DATE;
            r.isAllDay || (e += i.FORMAT.OUTLOOK_TIME), r.setParam("rru", "addevent").setParam("path", "/calendar/action/compose").setParam("startdt", s.default.formatDate(r.start, e)).setParam("enddt", s.default.formatDate(r.end, e)).setParam("subject", r.title).setParam("body", r.description).setParam("location", r.location).setParam("allday", r.isAllDay.toString()), r.attendees.length > 0 && r.setParam("to", u.default.toMailtoList(r.attendees).join(","));
          }, r.setHost = function (e) {
            return ["live", "office"].includes(e) && (r.baseUrl = i.URL.OUTLOOK.replace("{{host}}", e)), r;
          }, r.render = function () {
            var e = u.default.toQueryString(r.params);
            return r.baseUrl + "?" + e;
          }, r.setInitialParams(), r.setHost("live"), r;
        }
        return a(t, e), t;
      }(o.default);
    t.default = c;
  }, function (e, t, r) {
    "use strict";

    var n,
      a = this && this.__extends || (n = function (e, t) {
        return (n = Object.setPrototypeOf || {
          __proto__: []
        } instanceof Array && function (e, t) {
          e.__proto__ = t;
        } || function (e, t) {
          for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
        })(e, t);
      }, function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
        function r() {
          this.constructor = e;
        }
        n(e, t), e.prototype = null === t ? Object.create(t) : (r.prototype = t.prototype, new r());
      }),
      o = this && this.__spreadArray || function (e, t) {
        for (var r = 0, n = t.length, a = e.length; r < n; r++, a++) e[a] = t[r];
        return e;
      };
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var i = r(3),
      u = r(1),
      s = r(2),
      c = r(4),
      l = r(0),
      d = function (e) {
        function t(t) {
          var r = e.call(this, t) || this;
          return r.additionalEvents = [], r.properties = [], r.meta = {}, r.setInitialParams = function () {
            r.setMeta("UID", c.default.getUid()).setMeta("DTSTAMP", l.default.getTimeCreated()).setMeta("PRODID", c.default.getProdId()).addProperty("CLASS", "PUBLIC").addProperty("DESCRIPTION", c.default.formatText(r.description)).addProperty("LOCATION", c.default.formatText(r.location)).addProperty("SUMMARY", c.default.formatText(r.title)).addProperty("TRANSP", "TRANSPARENT"), r.isAllDay ? r.addProperty("DTSTART;VALUE=DATE", l.default.formatDateNoUtc(r.start, u.FORMAT.DATE)).addProperty("DTEND;VALUE=DATE", l.default.formatDateNoUtc(l.default.incrementDate(r.start, 1), u.FORMAT.DATE)) : r.addProperty("DTSTART", l.default.formatDate(r.start, u.FORMAT.FULL)).addProperty("DTEND", l.default.formatDate(r.end, u.FORMAT.FULL)), r.recurrence && r.addProperty("RRULE", c.default.getRrule(r.recurrence)), r.attendees.length > 0 && r.attendees.forEach(function (e) {
              var t = e.email,
                n = e.name,
                a = e.icsOptions,
                o = void 0 === a ? {} : a,
                i = r.getAttendeeParams(o, n),
                u = "MAILTO:" + t;
              r.addProperty(i, u);
            });
          }, r.getAttendeeParams = function (e, t) {
            var r = {};
            return t && (r.CN = t), e.delegatedFrom && (r["DELEGATED-FROM"] = e.delegatedFrom), e.partStat && (r.PARTSTAT = e.partStat), e.role && (r.ROLE = e.role), e.sentBy && (r["SENT-BY"] = e.sentBy), r.RSVP = e.rsvp ? "TRUE" : "FALSE", "ATTENDEE;" + s.default.toParamString(r, ";");
          }, r.getAlarmDuration = function (e) {
            var t = [e.weeks + "W", e.days + "D", e.hours + "H", e.minutes + "M", e.seconds + "S"].filter(function (e) {
              return /^[0-9]+[A-Z]$/.exec(e);
            });
            return t.unshift(e.after ? "PT" : "-PT"), t.join("");
          }, r.setMeta = function (e, t) {
            return r.meta[e] = t, r;
          }, r.addEvent = function (e) {
            return r.additionalEvents.push(e), r;
          }, r.addProperty = function (e, t) {
            if ("object" == typeof t) {
              for (var n in r.properties.push("BEGIN:" + e), t) r.addProperty(n, t[n]);
              r.properties.push("END:" + e);
            } else r.properties.push(e + ":" + t.toString());
            return r;
          }, r.addAlarm = function (e) {
            var t = {
              ACTION: e.action
            };
            (e.description && (t.DESCRIPTION = c.default.formatText(e.description)), e.summary && (t.SUMMARY = c.default.formatText(e.summary)), e.duration && (t.DURATION = r.getAlarmDuration(e.duration)), e.repeat && (t.REPEAT = e.repeat), e.attach) && (t[e.attach.params ? "ATTACH;" + e.attach.params : "ATTACH"] = e.attach.url);
            return e.trigger instanceof Date ? t["TRIGGER;VALUE=DATE-TIME"] = l.default.formatDate(e.trigger, u.FORMAT.FULL) : t.TRIGGER = r.getAlarmDuration(e.trigger), r.addProperty("VALARM", t);
          }, r.download = function (e) {
            c.default.download(e || c.default.getFileName(r.title), r.render());
          }, r.render = function () {
            var e = r.additionalEvents.concat(r).reduce(function (e, t) {
                return o(o(o(o([], e), ["BEGIN:VEVENT"]), t.properties), ["END:VEVENT"]);
              }, []),
              t = Object.keys(r.meta).map(function (e) {
                return e + ":" + r.meta[e];
              });
            return o(o(o(["BEGIN:VCALENDAR", "VERSION:2.0"], e), ["END:VCALENDAR"]), t).join("\n");
          }, r.setInitialParams(), r;
        }
        return a(t, e), t;
      }(i.default);
    t.default = d;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
  }]);
});