<div class="card card--no-padding">
  <div class="user-stats">
    <div class="user-stats__item">
      <div class="user-stats__number">
        {{ wirelessHealthData() ? wirelessHealthData().today?.maxActiveUsers : "-" }}
      </div>
      <div class="user-stats__text">{{ "Wireless.Stats.Today" | translate }}</div>
    </div>
    <div class="user-stats__item">
      <div class="user-stats__number">
        {{ wirelessHealthData() ? wirelessHealthData().last3Days?.maxActiveUsers : "-" }}
      </div>
      <div class="user-stats__text">{{ "Wireless.Stats.3Days" | translate }}</div>
    </div>
    <div class="user-stats__item">
      <div class="user-stats__number">
        {{ wirelessHealthData() ? wirelessHealthData().lastWeek?.maxActiveUsers : "-" }}
      </div>
      <div class="user-stats__text">{{ "Wireless.Stats.LastWeek" | translate }}</div>
    </div>
  </div>
</div>
