import { PipeTransform, Pipe } from "@angular/core";
import { Instance } from "../../components/models/instance";

// TODO: either one of these pipes should die. Currently customer_description isn't used

@Pipe({
  name: "custom_description",
})
export class CustomDescriptionPipe implements PipeTransform {
  transform(value: any): string {
    const obj = Object.assign(new Instance(), value);
    return obj.presentableName;
  }
}

@Pipe({
  name: "customer_description",
})
export class CustomerDescriptionPipe implements PipeTransform {
  transform(subscription: CustomerDescriptionInput, fallback: string = "-"): string {
    const customerId = localStorage.getItem("viewingCustomerGUID");
    const comparator = (cd: CustomerDescription) => cd.customerId === customerId;
    const description = subscription?.customerDescriptions.find(comparator);
    return description?.description ?? fallback;
  }
}

interface CustomerDescriptionInput {
  customerDescriptions: CustomerDescription[];
}

export interface CustomerDescription {
  customerId: string;
  description: string;
}
