<section>
  <div class="cert__filter-and-chart">
    <cert-filter [prefixes]="prefixes" (filtersChanged)="onFiltersChanged($event)"></cert-filter>
    <i class="cert__filter-divider">
      <span></span>
      <span></span>
      <span></span>
    </i>
    <cert-chart [searchFilter]="searchFilter"></cert-chart>
  </div>
  <cert-table
    [airtScans]="airtScans"
    [pageInfo]="pageInfo"
    (paginationChanged)="onPaginationChanged($event)"
    (sortingChanged)="onSortingChanged($event)"
  ></cert-table>
</section>
